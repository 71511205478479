import React, { useState } from 'react';
import AddToWatchlist from './AddToWatchlist'
import HelpModal from '../../Company/Financials/HelpModal'
import WatchlistIcon from '../../icon/WatchlistIcon'

function WatchlistContainerMobile(props) {
    const [show, setShow] = useState(false);


    return (
        <div className="optionButtonMobile" style={{ marginBottom: "45px", marginTop: "10px", display: "flex", justifyContent: "space-between" }} onClick={() => setShow(true)}> <div style={{ paddingRight: "5px" }}><WatchlistIcon /></div> <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>Watchlist</div>

            <HelpModal title="Watchlist" onClose={() => setShow(false)} show={show}>
                <AddToWatchlist symbol={props.symbol} />
            </HelpModal>
        </div>
    );
}
export default WatchlistContainerMobile;