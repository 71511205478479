import React, { useState, useEffect } from 'react';
import DividendMobile from './DividendMobile'

export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};


function CorporateActionMobile(props) {

    const [optionSelected, setValue] = useState('dividend');
    useEffect(() => {
    }, [props.compSymbol])
    return (
        <div className="CorporateActionsMain">
           
            <section className="CorporateActions">
                {optionSelected === "dividend" &&
                    <DividendMobile compSymbol={props.compSymbol} />
                }
                
            </section>

        </div>
    );
}

export default CorporateActionMobile;