import React from 'react';
import './FixedBanner.css';
import { Link } from 'react-router-dom';


class FixedBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontWeight: 'normal',
            headerItems: [['Screener', './Screener'], ['Stock Map', './StockMap'], ['Stock Ideas', './StockIdeas'], ['Learning', './Learning'], ['Stock Watchlist', './StockWatchlist'], ['Resources', './Resources'], ['Blogs', './Blogs']]
        }
        this.updateState = this.updateState.bind(this);
    };
    updateState(e) {
        this.setState({ fontWeight: '400' });
    }
    render() {
        return (
            this.state.headerItems.map((value, index) => {
                return (
                    <Link className="HeaderAnchor" key={index + 25} to={value[1]} onClick={this.updateState} style={this.state.fontWeight ? { fontWeight: 'normal' } : { fontWeight: 'bold' }}>
                        <div className="FixedBannerMember" key={index}> {value[0]}</ div>
                    </Link>
                );
            })
        );
    }
}


export default FixedBanner;