import React, { useEffect } from 'react';

function Meter(props) {
	useEffect(() => {
	}, [props.ind])


    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 512 512"
			style={{ fill: props.ind === "meter" ? "#FFFFFF" : "#e06666" }}>
			<g>
				<path className="st0" d="M255.996,74.939C114.838,74.939,0,189.784,0,330.942c0,19.646,2.24,39.233,6.667,58.228
		c0.509,2.18,1.093,4.202,1.745,6.074c7.714,24.672,30.948,41.817,56.865,41.817h381.364c24.104,0,45.847-14.785,55-36.679
		c1.108-2.18,2.03-4.599,2.734-7.296c0.27-1.026,0.689-2.741,1.318-5.528c4.179-18.478,6.307-37.54,6.307-56.617
		C512,189.784,397.155,74.939,255.996,74.939z M475.763,380.811c-1.026,4.547-1.356,5.64-1.431,5.64l0.067-0.921
		c-3.579,12.35-14.897,20.852-27.758,20.852H65.277c-12.831,0-24.119-8.456-27.729-20.77l0.21,1.416
		c-0.045,0-0.33-1.026-1.214-4.816c-3.835-16.471-5.865-33.631-5.865-51.27c0-124.448,100.877-225.324,225.317-225.324
		c124.44,0,225.324,100.876,225.324,225.324C481.321,348.08,479.396,364.767,475.763,380.811z"/>
				<path className="st0" d="M267.569,297.207c-3.633-1.244-7.52-1.955-11.572-1.955c-19.707,0-35.683,15.976-35.683,35.69
		c0,19.706,15.976,35.682,35.683,35.682c18.036,0,32.912-13.392,35.308-30.769c0.225-1.618,0.374-3.243,0.374-4.913
		C291.679,315.288,281.59,302.015,267.569,297.207z"/>
				<path className="st0" d="M399.716,194.787c-3.138-3.392-8.808-3.468-13.257-0.18l-123.594,91.004c2.696,0.412,5.378,1.049,8.014,1.955
		c15.968,5.483,27.406,19.182,30.275,35.383l97.79-114.913C402.548,203.813,402.877,198.143,399.716,194.787z"/>
				<path className="st0" d="M340.402,182.908c5.543,3.214,12.628,1.303,15.834-4.239c3.206-5.551,1.303-12.636-4.239-15.849
		c-5.543-3.19-12.644-1.288-15.849,4.247C332.956,172.624,334.844,179.717,340.402,182.908z"/>
				<path className="st0" d="M259.239,161.172c6.412,0,11.595-5.206,11.595-11.595c0-6.411-5.183-11.602-11.595-11.602
		c-6.396,0-11.594,5.191-11.594,11.602C247.645,155.966,252.843,161.172,259.239,161.172z"/>
				<path className="st0" d="M76.175,320.898c-6.726,0-12.209,5.468-12.209,12.209s5.482,12.194,12.209,12.194
		c6.741,0,12.194-5.453,12.194-12.194S82.916,320.898,76.175,320.898z"/>
				<path className="st0" d="M114.441,226.471c-5.542-3.206-12.643-1.289-15.849,4.254c-3.19,5.542-1.288,12.628,4.239,15.841
		c5.558,3.206,12.658,1.296,15.864-4.247C121.886,236.776,119.984,229.677,114.441,226.471z"/>
				<path className="st0" d="M166.497,162.82c-5.558,3.213-7.445,10.298-4.239,15.849c3.191,5.542,10.291,7.453,15.834,4.239
		c5.543-3.19,7.445-10.299,4.254-15.841C179.141,161.531,172.04,159.629,166.497,162.82z"/>
				<path className="st0" d="M404.038,226.471c-5.542,3.206-7.445,10.306-4.239,15.849c3.206,5.558,10.292,7.453,15.849,4.247
		c5.542-3.213,7.445-10.299,4.239-15.841C416.696,225.182,409.595,223.265,404.038,226.471z"/>
				<path className="st0" d="M433.144,335.062c6.412,0,11.595-5.184,11.595-11.587c0-6.412-5.183-11.61-11.595-11.61
		c-6.411,0-11.594,5.198-11.594,11.61C421.55,329.878,426.733,335.062,433.144,335.062z"/>
			</g>
        </svg>
    )
}
export default Meter;