import React, { useState, useEffect } from 'react';
import './ManageScreener.css'
import Select from 'react-select';
import MultiRange from './MultiRange'
import Icon from "../icon/Icon"
//import ManageScreenerOption from './ManageScreenerOption'
import useAuth from '../hooks/useAuth';
import Locked from '../Locked'


const customExceptionList = ["Sector", "Industry", "relative_rank"]


const lookupData = {
    "PR002": "Market Cap",
    "PR027": "Enterprise Value",
    "PR001": "Price",
    "PR006": "Returns 3y cagr",
    "PR007": "Returns 5y cagr",
    "PR009": "Returns 10y cagr",
    "PR003": "Shares",
    "RM162": "Shares 3y growth",
    "RM163": "Shares 5y growth",
    "PR004": "Volume",
    "RM182": "Revenue 3y cagr",
    "RM183": "Revenue 5y cagr",
    "RM184": "Revenue 7y cagr",
    "RM185": "Revenue 10y cagr",
    "RM186": "Interest 3y cagr",
    "RM187": "Interest 5y cagr",
    "RM188": "Interest 7y cagr",
    "RM189": "Interest 10y cagr",
    "RM202": "Net Income 3y cagr",
    "RM203": "Net Income 5y cagr",
    "RM204": "Net Income 7y cagr",
    "RM205": "Net Income 10y cagr",
    "RM210": "EPS 3y cagr",
    "RM211": "EPS 5y cagr",
    "RM212": "EPS 7y cagr",
    "RM213": "EPS 10y cagr",
    "RM166": "Book value 3y cagr",
    "RM167": "Book value 5y cagr",
    "RM168": "Book value 7y cagr",
    "RM169": "Book value 10y cagr",
    "RM272": "FCF 3y cagr",
    "RM273": "FCF 5y cagr",
    "RM274": "FCF 7y cagr",
    "RM275": "FCF 10y cagr",
    "RM539": "Net Margin ttm",
    "RM493": "Net Margin 3y",
    "RM494": "Net Margin 5y",
    "RM495": "Net Margin 10y",
    "RM544": "Interest Margin ttm",
    "RM501": "Interest Margin 3y",
    "RM502": "Interest Margin 5y",
    "RM503": "Interest Margin 10y",
    "RM537": "Operating Margin ttm",
    "RM485": "Operating Margin 3y",
    "RM486": "Operating Margin 5y",
    "RM487": "Operating Margin 10y",
    "RM540": "ROE ttm",
    "RM226": "ROE 3y",
    "RM227": "ROE 5y",
    "RM228": "ROE 10y",
    "RM541": "ROCE ttm",
    "RM232": "ROCE 3y",
    "RM233": "ROCE 5y",
    "RM234": "ROCE 10y",
    "RM542": "ROA ttm",
    "RM235": "ROA 3y",
    "RM236": "ROA 5y",
    "RM237": "ROA 10y",
    "RM527": "Debt/Equity",
    "RM531": "Leverage",
    "RM525": "Current Ratio",
    "RM526": "Quick Ratio",
    "PR021": "PE ttm",
    "PR028": "PE 3 years",
    "PR029": "PE 5 years",
    "PR014": "% down 52w high",
    "PR022": "PB ttm",
    "PR032": "PB 3 years",
    "PR033": "PB 5 years",
    "PR015": "% up 52w low",
    "PR024": "PS ttm",
    "PR040": "PS 3 years",
    "PR041": "PS 5 years",
    "PR049": "Dividend Yield %",
    "PR025": "PFCF ttm",
    "PR044": "PFCF 3 years",
    "PR045": "PFCF 5 years",
    "RM524": "Div Payout 5 years",
    "PR023": "PTBV ttm",
    "PR036": "PTBV 3 years",
    "PR037": "PTBV 5 years",
    "PR026": "Earning Yield",
    "Sector": "Sector",
    "Industry": "Industry",
    "RM574": "FCF to Revenue 5Y",
    "RM576": "FCF to Revenue 10Y",
    "moat": "Moat",
    "management": "Management",
    "margin_of_safety": "Margin of Safety",
    "predictability": "Predictability",
    "relative_rank": "Relative Rank"
}


const marketCapOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Large Cap(20,000 cr & above)" },
    { "value": "04", "label": "Mid Cap(5,000 to 20,000 cr)" },
    { "value": "05", "label": "Small Cap(5,000 cr & below)" },
    { "value": "06", "label": "+ Large(20,000 cr & above)" },
    { "value": "07", "label": "+ Mid(5,000 cr & above)" },
    { "value": "08", "label": "- Large(20,000 cr & below)" },
    { "value": "09", "label": "- Mid(5,000 cr & below)" },
    { "value": "02", "label": "Custom" }
]

const EnterpriseValue = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Large Cap (20,000 cr & above)" },
    { "value": "04", "label": "Mid Cap(5,000 to 20,000 cr)" },
    { "value": "05", "label": "Small Cap(5,000 cr & below)" },
    { "value": "06", "label": "+ Large(20,000 cr & above)" },
    { "value": "07", "label": "+ Mid(5,000 cr & above)" },
    { "value": "08", "label": "- Large(20,000 cr & below)" },
    { "value": "09", "label": "- Mid(5,000 cr & below)" },
    { "value": "02", "label": "Custom" }
]


const sector = [
    { "value": "01", "label": "Any" },
    { "value": "02", "label": "Communication Services" },
    { "value": "03", "label": "Consumer Discretionary" },
    { "value": "04", "label": "Consumer Staples" },
    { "value": "05", "label": "Energy" },
    { "value": "06", "label": "Financials" },
    { "value": "07", "label": "Health Care" },
    { "value": "08", "label": "Industrials" },
    { "value": "09", "label": "Information Technology" },
    { "value": "10", "label": "Materials" },
    { "value": "11", "label": "Real Estate" },
    { "value": "12", "label": "Utilities" }
]

const industry = [
    { "value": "01", "label": "Any" },
    { "value": "02", "label": "Aerospace & Defense" },
    { "value": "03", "label": "Air Freight & Logistics" },
    { "value": "04", "label": "Auto Components" },
    { "value": "05", "label": "Automobiles" },
    { "value": "06", "label": "Banks" },
    { "value": "07", "label": "Beverages" },
    { "value": "08", "label": "Biotechnology" },
    { "value": "09", "label": "Broadline Retail" },
    { "value": "10", "label": "Building Products" },
    { "value": "11", "label": "Capital Markets" },
    { "value": "12", "label": "Chemicals" },
    { "value": "13", "label": "Commercial Services" },
    { "value": "14", "label": "Communications Equipment" },
    { "value": "15", "label": "Construction & Engineering" },
    { "value": "16", "label": "Construction Materials" },
    { "value": "17", "label": "Consumer Finance" },
    { "value": "18", "label": "Distribution & Retail" },
    { "value": "19", "label": "Containers & Packaging" },
    { "value": "20", "label": "Distributors" },
    { "value": "21", "label": "Consumer Services" },
    { "value": "22", "label": "Diversified Financial" },
    { "value": "23", "label": "Telecommunication" },
    { "value": "24", "label": "Electric Utilities" },
    { "value": "25", "label": "Electrical Equipment" },
    { "value": "26", "label": "Electronic Components" },
    { "value": "27", "label": "Equipment & Services" },
    { "value": "28", "label": "Entertainment" },
    { "value": "29", "label": "Financial Services" },
    { "value": "30", "label": "Food & Staples Retailing" },
    { "value": "31", "label": "Food Products" },
    { "value": "32", "label": "Gas Utilities" },
    { "value": "33", "label": "Ground Transportation" },
    { "value": "34", "label": "Health Care Equipment" },
    { "value": "35", "label": "Health Care Providers" },
    { "value": "36", "label": "Health Care Technology" },
    { "value": "37", "label": "Hotels & Restaurants" },
    { "value": "38", "label": "Household Durables" },
    { "value": "39", "label": "Household Products" },
    { "value": "40", "label": "Power and Renewable" },
    { "value": "41", "label": "Industrial Conglomerates" },
    { "value": "42", "label": "Insurance" },
    { "value": "43", "label": "Media & Services" },
    { "value": "44", "label": "IT Services" },
    { "value": "45", "label": "Leisure Products" },
    { "value": "46", "label": "Life Sciences" },
    { "value": "47", "label": "Machinery" },
    { "value": "48", "label": "Marine Transportation" },
    { "value": "49", "label": "Media" },
    { "value": "50", "label": "Metals & Mining" },
    { "value": "51", "label": "Oil, Gas & Consumable Fuels" },
    { "value": "52", "label": "Paper & Forest Products" },
    { "value": "53", "label": "Passenger Airlines" },
    { "value": "54", "label": "Personal Care Products" },
    { "value": "55", "label": "Personal Products" },
    { "value": "56", "label": "Pharmaceuticals" },
    { "value": "57", "label": "Professional Services" },
    { "value": "58", "label": "Construction" },
    { "value": "59", "label": "Semiconductors" },
    { "value": "60", "label": "Software" },
    { "value": "61", "label": "Specialty Retail" },
    { "value": "62", "label": "Hardware" },
    { "value": "63", "label": "Apparel & Luxury Goods" },
    { "value": "64", "label": "Tobacco" },
    { "value": "65", "label": "Trading" },
    { "value": "66", "label": "Transportation Infra" },
    { "value": "67", "label": "Water Utilities" },
    { "value": "68", "label": "Telecom Services" }
]

const returnThreeYearCAGR = [
    { "value": "01", "label": "Any" }, 
    { "value": "03", "label": "Below 5%" },
    { "value": "04", "label": "Below 10%" },
    { "value": "05", "label": "Below 15%" },
    { "value": "06", "label": "Below 20%" },
    { "value": "07", "label": "Below 25%" },
    { "value": "08", "label": "Below 30%" },
    { "value": "09", "label": "Over 5%" },
    { "value": "10", "label": "Over 10%" },
    { "value": "11", "label": "Over 15%" },
    { "value": "12", "label": "Over 20%" },
    { "value": "13", "label": "Over 25%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "02", "label": "Custom" }
]

const returnFiveYearCAGR = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Below 5%" },
    { "value": "04", "label": "Below 10%" },
    { "value": "05", "label": "Below 15%" },
    { "value": "06", "label": "Below 20%" },
    { "value": "07", "label": "Below 25%" },
    { "value": "08", "label": "Below 30%" },
    { "value": "09", "label": "Over 5%" },
    { "value": "10", "label": "Over 10%" },
    { "value": "11", "label": "Over 15%" },
    { "value": "12", "label": "Over 20%" },
    { "value": "13", "label": "Over 25%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "02", "label": "Custom" }
]

const sharesThreeYearGrowth = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Below 5%" },
    { "value": "04", "label": "Below 10%" },
    { "value": "05", "label": "Below 15%" },
    { "value": "06", "label": "Below 20%" },
    { "value": "07", "label": "Below 25%" },
    { "value": "08", "label": "Below 30%" },
    { "value": "09", "label": "Over 5%" },
    { "value": "10", "label": "Over 10%" },
    { "value": "11", "label": "Over 15%" },
    { "value": "12", "label": "Over 20%" },
    { "value": "13", "label": "Over 25%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "02", "label": "Custom" }
]

const sharesFiveYearGrowth = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Below 5%" },
    { "value": "04", "label": "Below 10%" },
    { "value": "05", "label": "Below 15%" },
    { "value": "06", "label": "Below 20%" },
    { "value": "07", "label": "Below 25%" },
    { "value": "08", "label": "Below 30%" },
    { "value": "09", "label": "Over 5%" },
    { "value": "10", "label": "Over 10%" },
    { "value": "11", "label": "Over 15%" },
    { "value": "12", "label": "Over 20%" },
    { "value": "13", "label": "Over 25%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "02", "label": "Custom" }
]

const returnTenYearCAGR = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Below 5%" },
    { "value": "04", "label": "Below 10%" },
    { "value": "05", "label": "Below 15%" },
    { "value": "06", "label": "Below 20%" },
    { "value": "07", "label": "Below 25%" },
    { "value": "08", "label": "Below 30%" },
    { "value": "09", "label": "Over 5%" },
    { "value": "10", "label": "Over 10%" },
    { "value": "11", "label": "Over 15%" },
    { "value": "12", "label": "Over 20%" },
    { "value": "13", "label": "Over 25%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "02", "label": "Custom" }
]

const price = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under INR 50" },
    { "value": "04", "label": "Under INR 100" },
    { "value": "05", "label": "Under INR 500" },
    { "value": "06", "label": "Under INR 1000" },
    { "value": "07", "label": "Under INR 5000" },
    { "value": "08", "label": "Under INR 10,000" },
    { "value": "09", "label": "Over INR 50" },
    { "value": "10", "label": "Over INR 100" },
    { "value": "11", "label": "Over INR 500" },
    { "value": "12", "label": "Over INR 1000" },
    { "value": "13", "label": "Over INR 5000" },
    { "value": "14", "label": "Over INR 10,000" },
    { "value": "02", "label": "Custom" }
]

const shares = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Below 1 cr" },
    { "value": "04", "label": "Below 5 cr" },
    { "value": "05", "label": "Below 10 cr" },
    { "value": "06", "label": "Below 50 cr" },
    { "value": "07", "label": "Below 100 cr" },
    { "value": "08", "label": "Below 500 cr" },
    { "value": "09", "label": "Over 1 cr" },
    { "value": "10", "label": "Over 5 cr" },
    { "value": "11", "label": "Over 10 cr" },
    { "value": "12", "label": "Over 50 cr" },
    { "value": "13", "label": "Over 100 cr" },
    { "value": "14", "label": "Over 500 cr" },
    { "value": "02", "label": "Custom" }
]

const volumes = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 10K" },
    { "value": "04", "label": "Under 50K" },
    { "value": "05", "label": "Under 100K" },
    { "value": "06", "label": "Under 500K" },
    { "value": "07", "label": "Over 10K" },
    { "value": "08", "label": "Over 50K" },
    { "value": "09", "label": "Over 100K" },
    { "value": "10", "label": "Over 500K" },
    { "value": "02", "label": "Custom" }
]

const moatOption = [
    { "value": "01", "label": "Any" },
    { "value": "09", "label": "Over 5%" },
    { "value": "10", "label": "Over 10%" },
    { "value": "11", "label": "Over 15%" },
    { "value": "12", "label": "Over 20%" },
    { "value": "13", "label": "Over 25%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "03", "label": "Below 5%" },
    { "value": "04", "label": "Below 10%" },
    { "value": "05", "label": "Below 15%" },
    { "value": "06", "label": "Below 20%" },
    { "value": "07", "label": "Below 25%" },
    { "value": "08", "label": "Below 30%" },
    { "value": "02", "label": "Custom" }
]

const qualityOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Over 0%" },
    { "value": "04", "label": "Over 5%" },
    { "value": "05", "label": "Over 10%" },
    { "value": "06", "label": "Over 15%" },
    { "value": "07", "label": "Over 20%" },
    { "value": "08", "label": "Over 25%" },
    { "value": "09", "label": "Over 30%" },
    { "value": "10", "label": "Below 0%" },
    { "value": "11", "label": "Below 5%" },
    { "value": "12", "label": "Below 10%" },
    { "value": "13", "label": "Below 15%" },
    { "value": "14", "label": "Below 20%" },
    { "value": "15", "label": "Below 25%" },
    { "value": "16", "label": "Below 30%" },
    { "value": "02", "label": "Custom" }
]

const debtEquity = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 2" },
    { "value": "04", "label": "Under 1.5" },
    { "value": "05", "label": "Under 1" },
    { "value": "06", "label": "Under 0.9" },
    { "value": "07", "label": "Under 0.8" },
    { "value": "08", "label": "Under 0.7" },
    { "value": "09", "label": "Under 0.6" },
    { "value": "10", "label": "Under 0.5" },
    { "value": "11", "label": "Under 0.4" },
    { "value": "12", "label": "Under 0.3" },
    { "value": "13", "label": "Under 0.3" },
    { "value": "14", "label": "Under 0.1" },
    { "value": "02", "label": "Custom" }
]

const leverage = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 0.5" },
    { "value": "04", "label": "Under 1" },
    { "value": "05", "label": "Under 2" },
    { "value": "06", "label": "Under 3" },
    { "value": "07", "label": "Under 4" },
    { "value": "08", "label": "Under 5" },
    { "value": "09", "label": "Over 0.5" },
    { "value": "10", "label": "Over 1" },
    { "value": "11", "label": "Over 2" },
    { "value": "12", "label": "Over 3" },
    { "value": "13", "label": "Over 4" },
    { "value": "14", "label": "Over 5" },
    { "value": "02", "label": "Custom" }
]

const quickCurrentRatioOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 0.5" },
    { "value": "04", "label": "Under 1" },
    { "value": "05", "label": "Under 2" },
    { "value": "06", "label": "Over 0.5" },
    { "value": "07", "label": "Over 1" },
    { "value": "08", "label": "Over 2" },
    { "value": "02", "label": "Custom" }

]

const peOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 5" },
    { "value": "04", "label": "Under 10" },
    { "value": "05", "label": "Under 15" },
    { "value": "06", "label": "Under 20" },
    { "value": "07", "label": "Under 25" },
    { "value": "08", "label": "Under 30" },
    { "value": "09", "label": "Under 40" },
    { "value": "10", "label": "Under 50" },
    { "value": "11", "label": "Over 5" },
    { "value": "12", "label": "Over 10" },
    { "value": "13", "label": "Over 15" },
    { "value": "14", "label": "Over 20" },
    { "value": "15", "label": "Over 25" },
    { "value": "16", "label": "Over 30" },
    { "value": "17", "label": "Over 40" },
    { "value": "18", "label": "Over 50" },
    { "value": "02", "label": "Custom" }
]

const pbOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 0.5" },
    { "value": "04", "label": "Under 0.75" },
    { "value": "05", "label": "Under 1" },
    { "value": "06", "label": "Under 1.5" },
    { "value": "07", "label": "Under 2" },
    { "value": "08", "label": "Under 3" },
    { "value": "09", "label": "Under 4" },
    { "value": "10", "label": "Under 5" },
    { "value": "11", "label": "Over 0.5" },
    { "value": "12", "label": "Over 0.75" },
    { "value": "13", "label": "Over 1" },
    { "value": "14", "label": "Over 1.5" },
    { "value": "15", "label": "Over 2" },
    { "value": "16", "label": "Over 3" },
    { "value": "17", "label": "Over 4" },
    { "value": "18", "label": "Over 5" },
    { "value": "02", "label": "Custom" }
]

const downOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Over 5%" },
    { "value": "04", "label": "Over 10%" },
    { "value": "05", "label": "Over 15%" },
    { "value": "06", "label": "Over 20%" },
    { "value": "07", "label": "Over 25%" },
    { "value": "08", "label": "Over 30%" },
    { "value": "09", "label": "Over 40%" },
    { "value": "10", "label": "Over 50%" },
    { "value": "11", "label": "Over 60%" },
    { "value": "12", "label": "Over 70%" },
    { "value": "13", "label": "Over 80%" },
    { "value": "14", "label": "Over 90%" },
    { "value": "02", "label": "Custom" }
]

const psOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 0.5" },
    { "value": "04", "label": "Under 0.75" },
    { "value": "05", "label": "Under 1" },
    { "value": "06", "label": "Under 1.5" },
    { "value": "07", "label": "Under 2" },
    { "value": "08", "label": "Under 3" },
    { "value": "09", "label": "Under 4" },
    { "value": "10", "label": "Under 5" },
    { "value": "11", "label": "Over 0.5" },
    { "value": "12", "label": "Over 0.75" },
    { "value": "13", "label": "Over 1" },
    { "value": "14", "label": "Over 1.5" },
    { "value": "15", "label": "Over 2" },
    { "value": "16", "label": "Over 3" },
    { "value": "17", "label": "Over 4" },
    { "value": "18", "label": "Over 5" },
    { "value": "02", "label": "Custom" }
]

const pfcfOption = [
    { "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 5" },
    { "value": "04", "label": "Under 10" },
    { "value": "05", "label": "Under 15" },
    { "value": "06", "label": "Under 20" },
    { "value": "07", "label": "Under 25" },
    { "value": "08", "label": "Under 30" },
    { "value": "09", "label": "Under 40" },
    { "value": "10", "label": "Under 50" },
    { "value": "11", "label": "Over 5" },
    { "value": "12", "label": "Over 10" },
    { "value": "13", "label": "Over 15" },
    { "value": "14", "label": "Over 20" },
    { "value": "15", "label": "Over 25" },
    { "value": "16", "label": "Over 30" },
    { "value": "17", "label": "Over 40" },
    { "value": "18", "label": "Over 50" },
    { "value": "02", "label": "Custom" }
]

const upOption = [{ "value": "01", "label": "Any" },
    { "value": "03", "label": "Over 5%" },
    { "value": "04", "label": "Over 10%" },
    { "value": "05", "label": "Over 15%" },
    { "value": "06", "label": "Over 20%" },
    { "value": "07", "label": "Over 25%" },
    { "value": "08", "label": "Over 30%" },
    { "value": "09", "label": "Over 40%" },
    { "value": "10", "label": "Over 50%" },
    { "value": "11", "label": "Over 60%" },
    { "value": "12", "label": "Over 70%" },
    { "value": "13", "label": "Over 80%" },
    { "value": "14", "label": "Over 90%" },
    { "value": "02", "label": "Custom" }]

const yieldOption = [{ "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 1%" },
    { "value": "04", "label": "Under 2%" },
    { "value": "05", "label": "Under 3%" },
    { "value": "06", "label": "Under 4%" },
    { "value": "07", "label": "Under 5%" },
    { "value": "08", "label": "Under 6%" },
    { "value": "09", "label": "Under 7%" },
    { "value": "10", "label": "Under 8%" },
    { "value": "11", "label": "Over 1%" },
    { "value": "12", "label": "Over 2%" },
    { "value": "13", "label": "Over 3%" },
    { "value": "14", "label": "Over 4%" },
    { "value": "15", "label": "Over 5%" },
    { "value": "16", "label": "Over 6%" },
    { "value": "17", "label": "Over 7%" },
    { "value": "18", "label": "Over 8%" },
    { "value": "02", "label": "Custom" }]

const payoutOption = [{ "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 10%" },
    { "value": "04", "label": "Under 20%" },
    { "value": "05", "label": "Under 30%" },
    { "value": "06", "label": "Under 40%" },
    { "value": "07", "label": "Under 50%" },
    { "value": "08", "label": "Under 60%" },
    { "value": "09", "label": "Under 70%" },
    { "value": "10", "label": "Under 80%" },
    { "value": "11", "label": "Under 90%" },
    { "value": "12", "label": "Over 10%" },
    { "value": "13", "label": "Over 20%" },
    { "value": "14", "label": "Over 30%" },
    { "value": "15", "label": "Over 40%" },
    { "value": "16", "label": "Over 50%" },
    { "value": "17", "label": "Over 60%" },
    { "value": "18", "label": "Over 70%" },
    { "value": "19", "label": "Over 80%" },
    { "value": "20", "label": "Over 90%" },
    { "value": "02", "label": "Custom" }]

const eYieldOption = [{ "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 1%" },
    { "value": "04", "label": "Under 2%" },
    { "value": "05", "label": "Under 3%" },
    { "value": "06", "label": "Under 4%" },
    { "value": "07", "label": "Under 5%" },
    { "value": "08", "label": "Under 6%" },
    { "value": "09", "label": "Under 7%" },
    { "value": "10", "label": "Under 8%" },
    { "value": "11", "label": "Over 1%" },
    { "value": "12", "label": "Over 2%" },
    { "value": "13", "label": "Over 3%" },
    { "value": "14", "label": "Over 4%" },
    { "value": "15", "label": "Over 5%" },
    { "value": "16", "label": "Over 6%" },
    { "value": "17", "label": "Over 7%" },
    { "value": "18", "label": "Over 8%" },
    { "value": "02", "label": "Custom" }]


const ptbvOption = [{ "value": "01", "label": "Any" },
    { "value": "03", "label": "Under 0.5" },
    { "value": "04", "label": "Under 0.75" },
    { "value": "05", "label": "Under 1" },
    { "value": "06", "label": "Under 1.5" },
    { "value": "07", "label": "Under 2" },
    { "value": "08", "label": "Under 3" },
    { "value": "09", "label": "Under 4" },
    { "value": "10", "label": "Under 5" },
    { "value": "11", "label": "Over 0.5" },
    { "value": "12", "label": "Over 0.75" },
    { "value": "13", "label": "Over 1" },
    { "value": "14", "label": "Over 1.5" },
    { "value": "15", "label": "Over 2" },
    { "value": "16", "label": "Over 3" },
    { "value": "17", "label": "Over 4" },
    { "value": "18", "label": "Over 5" },
    { "value": "02", "label": "Custom" }]


const fcfOption = [{ "value": "01", "label": "Any" },
    { "value": "10", "label": "Over 0%" },
    { "value": "11", "label": "Over 3%" },
    { "value": "12", "label": "Over 5%" },
    { "value": "13", "label": "Over 7%" },
    { "value": "14", "label": "Over 10%" },
    { "value": "15", "label": "Over 12%" },
    { "value": "16", "label": "Over 15%" },
    { "value": "03", "label": "Under 0%" },
    { "value": "04", "label": "Under 3%" },
    { "value": "05", "label": "Under 5%" },
    { "value": "06", "label": "Under 7%" },
    { "value": "07", "label": "Under 10%" },
    { "value": "08", "label": "Under 12%" },
    { "value": "09", "label": "Under 15%" },
    { "value": "02", "label": "Custom" }]


const scoreOption = [{ "value": "01", "label": "Any" },
    { "value": "10", "label": "Over 40" },
    { "value": "11", "label": "Over 50" },
    { "value": "12", "label": "Over 60" },
    { "value": "13", "label": "Over 70" },
    { "value": "14", "label": "Over 80" },
    { "value": "15", "label": "Over 90" },
    { "value": "03", "label": "Under 40" },
    { "value": "04", "label": "Under 50" },
    { "value": "05", "label": "Under 60" },
    { "value": "06", "label": "Under 70" },
    { "value": "07", "label": "Under 80" },
    { "value": "08", "label": "Under 90" },
    { "value": "09", "label": "Under 100" },
    { "value": "02", "label": "Custom" }]


const rankOption = [{ "value": "01", "label": "Any" },
    { "value": "02", "label": "High" },
    { "value": "03", "label": "Good" },
    { "value": "04", "label": "Average" },
    { "value": "05", "label": "Poor" },
    { "value": "06", "label": "Worst/Insufficient data" }]

export const getKeyLookup = (code) => {
    return lookupData[code]
}

export function getOptionLookup(code, codeData){
    let tempData = OptionLookup(code)
    if (codeData["Source"].toString() === "PreSelected") {
        let i = 0;
        while (i < tempData.length) {
            if (tempData[i]['value'].toString() === codeData["Value"].toString()) {
                console.log(tempData[i]['label'])
                return tempData[i]['label']
            }
            i++;
        }
    } else {
        console.log(codeData)
        return "In between " + codeData["minValue"].toString() + " and " + codeData["maxValue"].toString()
    }
    return ""
}

export const OptionLookup = (code) => {
    if (code === "PR002") {
        return marketCapOption
    } else if (code === "PR027") {
        return EnterpriseValue
    } else if (code === "Sector") {
        return sector
    } else if (code === "Industry") {
        return industry
    } else if (code === "PR001") {
        return price
    } else if (code === "PR006") {
        return returnThreeYearCAGR
    } else if (code === "PR007") {
        return returnFiveYearCAGR
    } else if (code === "PR009") {
        return returnTenYearCAGR
    } else if (code === "PR003") {
        return shares
    } else if (code === "RM162") {
        return sharesThreeYearGrowth
    } else if (code === "RM163") {
        return sharesFiveYearGrowth
    } else if (code === "PR004") {
        return volumes
    } else if (["RM182", "RM183", "RM184", "RM185", "RM186", "RM187", "RM188", "RM189", "RM202", "RM203", "RM204", "RM205", "RM210", "RM211", "RM212", "RM213", "RM166", "RM167", "RM168", "RM169", "RM272", "RM273", "RM274", "RM275"].includes(code)) {
        return moatOption
    } else if (["RM539", "RM493", "RM494", "RM495", "RM544", "RM501", "RM502", "RM503", "RM537", "RM485", "RM486", "RM487", "RM540", "RM226", "RM227", "RM228", "RM541", "RM232", "RM233", "RM234", "RM542", "RM235", "RM236", "RM237"].includes(code)) {
        return qualityOption
    } else if (code === "RM527") {
        return debtEquity
    } else if (code === "RM531") {
        return leverage
    } else if (["RM525", "RM526"].includes(code)) {
        return quickCurrentRatioOption
    } else if (["PR021", "PR028", "PR029"].includes(code)) {
        return peOption
    } else if (["PR022", "PR032", "PR033"].includes(code)) {
        return pbOption
    } else if (["PR024", "PR040", "PR041"].includes(code)) {
        return psOption
    } else if (["PR025", "PR044", "PR045"].includes(code)) {
        return pfcfOption
    } else if (code === "PR014") {
        return downOption
    } else if (code === "PR015") {
        return upOption
    } else if (code === "PR049") {
        return yieldOption
    } else if (code === "RM524") {
        return payoutOption
    } else if (code === "PR026") {
        return eYieldOption
    } else if (["PR023", "PR036", "PR037"].includes(code)) {
        return ptbvOption
    } else if (["RM574", "RM576"].includes(code)) {
        return fcfOption
    } else if (["moat", "management", "margin_of_safety", "predictability"].includes(code)) {
        return scoreOption
    } else if (code === "relative_rank") {
        return rankOption
    }

    

}


function ManageScreener(props) {
    const [option, setOption] = useState("DS");
    
    const [inputChange, setInputChange] = useState(false)
    const { auth } = useAuth();
    const toggleInputChange = () => {
        if (inputChange) {
            setInputChange(false)
        } else {
            setInputChange(true)
        }
    }

    const CustomException = (key) => {
        if (customExceptionList.includes(key)) {
            return false
        } else {
            return true 
        }
    }

    const filterPresent = () => {
        for (const [key, value] of Object.entries(props.filter)) {
            if (value["Value"] !== "01") {
                return false
            }
        }
        return true
    }

    function isOptionSelected(key){
        try {
            if (props.filter[key]["Value"] === "01") {
                return true
            } else {
                return false
            }
        } catch (err) {
            return false
        }
    }


    const getDefaultOption = (code) => {
        let tempData = OptionLookup(code)
        try {

            let i = 0;
            while (i < tempData.length) {
                if (tempData[i]['value'].toString() === props.filter[code]["Value"].toString()) {
                    return { "value": props.filter[code]["Value"], "label": tempData[i]['label']}
                }
                i++;
            }
        } catch (err) {
            return tempData[0]
        }



    }

    const handlChange = (inputData, source) => {
        if (inputData["value"] === "02" && CustomException(source)) {
            props.filter[source] = { "Source": "Custom", "minValue": "", "maxValue": "", "Value": "02"}
        } else {
            props.filter[source] = { "Source": "PreSelected", "Value": inputData["value"] }
        }


        //else if (CustomException(source)) {
        //    props.filter[source] = { "Source": "PreSelected", "Value": inputData["value"] }
        //} else {
        //    props.filter[source] = { "Source": "PreSelected", "Value": inputData["label"] }
       // }
        toggleInputChange()
    }

    const removeSelected = (code) => {
        console.log(code)
        props.filter[code] = { "Source": "PreSelected", "Value": "01" }
       // document.getElementById(code).value = { label: 'Any', value: "01" } 
        console.log(props.filter)
        toggleInputChange()
    }

  function isDisabled(key) {
        return [].includes(key)
    }


    window.scrollTo(0, 0)
    useEffect(() => {
    }, [])
    return (
        <div className="ManageScreener">
            <div className="LeftManageScreenerOption">
                <div className="LeftManageScreenerOptionButtonStart" onClick={() => setOption("DS")} style={option === "DS" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                    Descriptive
                    </div>
                <div className="LeftManageScreenerOptionButton" onClick={() => setOption("BM")} style={option === "BM" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                    Business Moat
                    </div>
                <div className="LeftManageScreenerOptionButton" onClick={() => setOption("MQ")} style={option === "MQ" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                    Management Quality
                    </div>
                <div className="LeftManageScreenerOptionButton" onClick={() => setOption("MS")} style={option === "MS" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                    Margin of Saftey
                    </div>
                <div className="LeftManageScreenerOptionButtonEnd" onClick={() => setOption("SC")} style={option === "SC" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                    3M Score
                    </div>
            </div>
            <div className="ScreenerOption">
                {option === "DS" &&
                    <>
                    <div className="LeftScreenerOption">
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Market Cap:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR002?.Value === "01" || props.filter?.PR002?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                    defaultValue={getDefaultOption("PR002")}
                                    options={marketCapOption}
                                    onChange={event => handlChange(event, "PR002")}
                                    value={getDefaultOption("PR002")}
                                    id="PR002"
                                    isDisabled={isDisabled("PR002")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR002?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="3000000" Min="0" Max="3000000" Step="100000" filter={props.filter} source="PR002" />
                                        </div>
                                    }
                                </div>
                            </div>
                            
                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Enterprise Value:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR027?.Value === "01" || props.filter?.PR027?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("PR027")}
                                        options={EnterpriseValue}
                                        onChange={event => handlChange(event, "PR027")}
                                        value={getDefaultOption("PR027")}
                                        id="PR027"
                                        isDisabled={isDisabled("PR027")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR027?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="3000000" Min="0" Max="3000000" Step="100000" filter={props.filter} source="PR027" />
                                        </div>
                                    }
                                </div>
                            </div>
                            
                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Sector:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.Sector?.Value === "01" || props.filter?.Sector?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                    defaultValue={getDefaultOption("Sector")}
                                    options={sector}
                                    onChange={event => handlChange(event, "Sector")}
                                    value={getDefaultOption("Sector")}
                                    id="Sector"
                                    isDisabled={isDisabled("Sector")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">

                                </div>
                            </div>
                            
                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Industry:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.Industry?.Value === "01" || props.filter?.Industry?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                    defaultValue={getDefaultOption("Industry")}
                                    options={industry}
                                    onChange={event => handlChange(event, "Industry")}
                                    value={getDefaultOption("Industry")}
                                    id="Industry"
                                    isDisabled={isDisabled("Industry")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">

                                </div>
                            </div>
                            
                        </div>


                        
                    </div>
                    <div className="MiddleScreenerOption">
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Price:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR001?.Value === "01" || props.filter?.PR001?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                    defaultValue={getDefaultOption("PR001")}
                                    options={price}
                                        onChange={event => handlChange(event, "PR001")}
                                        value={getDefaultOption("PR001")}
                                        id="PR001"
                                        isDisabled={isDisabled("PR001")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR001?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="100000" Min="0" Max="100000" Step="1" filter={props.filter} source="PR001" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Returns 3Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR006?.Value === "01" || props.filter?.PR006?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("PR006")}
                                    options={returnThreeYearCAGR}
                                        onChange={event => handlChange(event, "PR006")}
                                        value={getDefaultOption("PR006")}
                                        id="PR006"
                                        isDisabled={isDisabled("PR006")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR006?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="PR006" />
                                        </div>
                                    }
                                </div>
                            </div>
                            
                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Returns 5Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR007?.Value === "01" || props.filter?.PR007?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("PR007")}
                                    options={returnFiveYearCAGR}
                                        onChange={event => handlChange(event, "PR007")}
                                        value={getDefaultOption("PR007")}
                                        id="PR007"
                                        isDisabled={isDisabled("PR007")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR007?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="PR007" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Returns 10Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR009?.Value === "01" || props.filter?.PR009?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("PR009")}
                                    options={returnTenYearCAGR}
                                        onChange={event => handlChange(event, "PR009")}
                                        value={getDefaultOption("PR009")}
                                        id="PR009"
                                        isDisabled={isDisabled("PR009")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR009?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="PR009" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="RightScreenerOption">
                        
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Shares:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR003?.Value === "01" || props.filter?.PR003?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("PR003")}
                                    options={shares}
                                        onChange={event => handlChange(event, "PR003")}
                                        value={getDefaultOption("PR003")}
                                        id="PR003"
                                        isDisabled={isDisabled("PR003")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR003?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="100000" Min="0" Max="100000" Step="1" filter={props.filter} source="PR003" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Shares 3Y Growth:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM162?.Value === "01" || props.filter?.RM162?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("RM162")}
                                    options={sharesThreeYearGrowth}
                                        onChange={event => handlChange(event, "RM162")}
                                        value={getDefaultOption("RM162")}
                                        id="RM162"
                                        isDisabled={isDisabled("RM162")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM162?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM162" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                   
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Shares 5Y Growth:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM163?.Value === "01" || props.filter?.RM163?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("RM163")}
                                    options={sharesFiveYearGrowth}
                                        onChange={event => handlChange(event, "RM163")}
                                        value={getDefaultOption("RM163")}
                                        id="RM163"
                                        isDisabled={isDisabled("RM163")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM163?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM163" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                    Volume:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR004?.Value === "01" || props.filter?.PR004?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                <Select
                                        defaultValue={getDefaultOption("PR004")}
                                    options={volumes}
                                        onChange={event => handlChange(event, "PR004")}
                                        value={getDefaultOption("PR004")}
                                        id="PR004"
                                        isDisabled={isDisabled("PR004")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR004?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="100000" Min="0" Max="100000" Step="1" filter={props.filter} source="PR004" />
                                        </div>
                                    }
                                </div>
                            </div>
                            
                        </div>
                        </div>
                    </>
                }

                {option === "BM" &&
                    <>
                        <div className="LeftScreenerOption">
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Revenue 3Y CAGR:
                                </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM182?.Value === "01" || props.filter?.RM182?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM182")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM182")}
                                        value={getDefaultOption("RM182")}
                                        id="RM182"
                                        isDisabled={isDisabled("RM182")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM182?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM182" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Revenue 5Y CAGR:
                                </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM183?.Value === "01" || props.filter?.RM183?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM183")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM183")}
                                        value={getDefaultOption("RM183")}
                                        id="RM183"
                                        isDisabled={isDisabled("RM183")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM183?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM183" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Revenue 7Y CAGR:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM184?.Value === "01" || props.filter?.RM184?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM184")}
                                            options={moatOption}
                                        onChange={event => handlChange(event, "RM184")}
                                        value={getDefaultOption("RM184")}
                                        id="RM184"
                                        isDisabled={isDisabled("RM184")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM184?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM184" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Revenue 10Y CAGR:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM185?.Value === "01" || props.filter?.RM185?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM185")}
                                            options={moatOption}
                                        onChange={event => handlChange(event, "RM185")}
                                        value={getDefaultOption("RM185")}
                                        id="RM185"
                                        isDisabled={isDisabled("RM185")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM185?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM185" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Interest 3Y CAGR:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM186?.Value === "01" || props.filter?.RM186?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM186")}
                                            options={moatOption}
                                        onChange={event => handlChange(event, "RM186")}
                                        value={getDefaultOption("RM186")}
                                        id="RM186"
                                        isDisabled={isDisabled("RM186")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM186?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM186" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest 5Y CAGR:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM187?.Value === "01" || props.filter?.RM187?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM187")}
                                            options={moatOption}
                                        onChange={event => handlChange(event, "RM187")}
                                        value={getDefaultOption("RM187")}
                                        id="RM187"
                                        isDisabled={isDisabled("RM187")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM187?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM187" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest 7Y CAGR:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM188?.Value === "01" || props.filter?.RM188?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM188")}
                                            options={moatOption}
                                        onChange={event => handlChange(event, "RM188")}
                                        value={getDefaultOption("RM188")}
                                        id="RM188"
                                        isDisabled={isDisabled("RM188")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM188?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM188" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest 10Y CAGR:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM189?.Value === "01" || props.filter?.RM189?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                        <Select
                                        defaultValue={getDefaultOption("RM189")}
                                            options={moatOption}
                                        onChange={event => handlChange(event, "RM189")}
                                        value={getDefaultOption("RM189")}
                                        id="RM189"
                                        isDisabled={isDisabled("RM189")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM189?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM189" />
                                            </div>
                                        }
                                    </div>
                                </div>

                        </div>

                        </div>
                        <div className="MiddleScreenerOption">
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Net Income 3Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM202?.Value === "01" || props.filter?.RM202?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM202")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM202")}
                                        value={getDefaultOption("RM202")}
                                        id="RM202"
                                        isDisabled={isDisabled("RM202")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM202?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM202" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Net Income 5Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM203?.Value === "01" || props.filter?.RM203?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM203")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM203")}
                                        value={getDefaultOption("RM203")}
                                        id="RM203"
                                        isDisabled={isDisabled("RM203")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM203?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM203" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Net Income 7Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM204?.Value === "01" || props.filter?.RM204?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM204")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM204")}
                                        value={getDefaultOption("RM204")}
                                        id="RM204"
                                        isDisabled={isDisabled("RM204")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM204?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM204" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Net Income 10Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM205?.Value === "01" || props.filter?.RM205?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM205")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM205")}
                                        value={getDefaultOption("RM205")}
                                        id="RM205"
                                        isDisabled={isDisabled("RM205")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM205?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM205" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                EPS 3Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM210?.Value === "01" || props.filter?.RM210?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM210")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM210")}
                                        value={getDefaultOption("RM210")}
                                        id="RM210"
                                        isDisabled={isDisabled("RM210")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM210?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM210" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                EPS 5Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM211?.Value === "01" || props.filter?.RM211?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM211")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM211")}
                                        value={getDefaultOption("RM211")}
                                        id="RM211"
                                        isDisabled={isDisabled("RM211")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM211?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM211" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                EPS 7Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM212?.Value === "01" || props.filter?.RM212?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM212")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM212")}
                                        value={getDefaultOption("RM212")}
                                        id="RM212"
                                        isDisabled={isDisabled("RM212")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM212?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM212" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                EPS 10Y CAGR:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM213?.Value === "01" || props.filter?.RM213?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM213")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM213")}
                                        value={getDefaultOption("RM213")}
                                        id="RM213"
                                        isDisabled={isDisabled("RM213")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM213?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM213" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                        </div>
                        <div className="RightScreenerOption">

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Book Value 3Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM166?.Value === "01" || props.filter?.RM166?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM166")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM166")}
                                        value={getDefaultOption("RM166")}
                                        id="RM166"
                                        isDisabled={isDisabled("RM166")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM166?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM166" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Book Value 5Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM167?.Value === "01" || props.filter?.RM167?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM167")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM167")}
                                        value={getDefaultOption("RM167")}
                                        id="RM167"
                                        isDisabled={isDisabled("RM167")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM167?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM167" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Book Value 7Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM168?.Value === "01" || props.filter?.RM168?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM168")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM168")}
                                        value={getDefaultOption("RM168")}
                                        id="RM168"
                                        isDisabled={isDisabled("RM168")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM168?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM168" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Book Value 10Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM169?.Value === "01" || props.filter?.RM169?.Value === undefined  ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM169")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM169")}
                                        value={getDefaultOption("RM169")}
                                        id="RM169"
                                        isDisabled={isDisabled("RM169")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM169?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM169" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                FCF 3Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM272?.Value === "01" || props.filter?.RM272?.Value === undefined  ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM272")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM272")}
                                        value={getDefaultOption("RM272")}
                                        id="RM272"
                                        isDisabled={isDisabled("RM272")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM272?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM272" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                FCF 5Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM273?.Value === "01" || props.filter?.RM273?.Value === undefined  ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM273")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM273")}
                                        value={getDefaultOption("RM273")}
                                        id="RM273"
                                        isDisabled={isDisabled("RM273")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM273?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM273" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                FCF 7Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM274?.Value === "01" || props.filter?.RM274?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM274")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM274")}
                                        value={getDefaultOption("RM274")}
                                        id="RM274"
                                        isDisabled={isDisabled("RM274")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM274?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM274" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                FCF 10Y CAGR:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM275?.Value === "01" || props.filter?.RM275?.Value=== undefined? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM275")}
                                        options={moatOption}
                                        onChange={event => handlChange(event, "RM275")}
                                        value={getDefaultOption("RM275")}
                                        id="RM275"
                                        isDisabled={isDisabled("RM275")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM275?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="50" Min="0" Max="50" Step="1" filter={props.filter} source="RM275" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                        </div>
                    </>
                }


                {option === "MQ" &&
                    <>
                        <div className="LeftScreenerOption">
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Net Margin ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM539?.Value === "01" || props.filter?.RM539?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                    <Select
                                        defaultValue={getDefaultOption("RM539")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM539")}
                                        value={getDefaultOption("RM539")}
                                        id="RM539"
                                        isDisabled={isDisabled("RM539")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM539?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM539" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Net Margin 3Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM493?.Value === "01" || props.filter?.RM493?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM493")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM493")}
                                        value={getDefaultOption("RM493")}
                                        id="RM493"
                                        isDisabled={isDisabled("RM493")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM493?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM493" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Net Margin 5Y:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM494?.Value === "01" || props.filter?.RM494?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM494")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM494")}
                                        value={getDefaultOption("RM494")}
                                        id="RM494"
                                        isDisabled={isDisabled("RM494")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM494?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM494" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Net Margin 10Y:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM495?.Value === "01" || props.filter?.RM495?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM495")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM495")}
                                        value={getDefaultOption("RM495")}
                                        id="RM495"
                                        isDisabled={isDisabled("RM495")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM495?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM495" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest Margin ttm:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM544?.Value === "01" || props.filter?.RM544?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM544")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM544")}
                                        value={getDefaultOption("RM544")}
                                        id="RM544"
                                        isDisabled={isDisabled("RM544")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM544?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM544" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest Margin 3Y:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM501?.Value === "01" || props.filter?.RM501?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM501")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM501")}
                                        value={getDefaultOption("RM501")}
                                        id="RM501"
                                        isDisabled={isDisabled("RM501")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM501?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM501" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest Margin 5Y:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM502?.Value === "01" || props.filter?.RM502?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM502")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM502")}
                                        value={getDefaultOption("RM502")}
                                        id="RM502"
                                        isDisabled={isDisabled("RM502")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM502?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM502" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Interest Margin 10Y:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM503?.Value === "01" || props.filter?.RM503?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM503")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM503")}
                                        value={getDefaultOption("RM503")}
                                        id="RM503"
                                        isDisabled={isDisabled("RM503")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM503?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM503" />
                                            </div>
                                        }
                                    </div>
                                </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Current Ratio:
                                    </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM525?.Value === "01" || props.filter?.RM525?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM525")}
                                        options={quickCurrentRatioOption}
                                        onChange={event => handlChange(event, "RM525")}
                                        value={getDefaultOption("RM525")}
                                        id="RM525"
                                        isDisabled={isDisabled("RM525")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM525?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="5" Min="0" Max="5" Step="0.1" filter={props.filter} source="RM525" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>



                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Quick Ratio:
                                    </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM526?.Value === "01" || props.filter?.RM526?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM526")}
                                        options={quickCurrentRatioOption}
                                        onChange={event => handlChange(event, "RM526")}
                                        value={getDefaultOption("RM526")}
                                        id="RM526"
                                        isDisabled={isDisabled("RM526")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM526?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="5" Min="0" Max="5" Step="0.1" filter={props.filter} source="RM526" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        </div>
                        <div className="MiddleScreenerOption">
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Operating Margin ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM537?.Value === "01" || props.filter?.RM537?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM537")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM537")}
                                        value={getDefaultOption("RM537")}
                                        id="RM537"
                                        isDisabled={isDisabled("RM537")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM537?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM537" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Operating Margin 3Y:
                            </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM485?.Value === "01" || props.filter?.RM485?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM485")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM485")}
                                        value={getDefaultOption("RM485")}
                                        id="RM485"
                                        isDisabled={isDisabled("RM485")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM485?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM485" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Operating Margin 5Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM486?.Value === "01" || props.filter?.RM486?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM486")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM486")}
                                        value={getDefaultOption("RM486")}
                                        id="RM486"
                                        isDisabled={isDisabled("RM486")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM486?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM486" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Operating Margin 10Y:
                            </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM487?.Value === "01" || props.filter?.RM487?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM487")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM487")}
                                        value={getDefaultOption("RM487")}
                                        id="RM487"
                                        isDisabled={isDisabled("RM487")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM487?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM487" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROE ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM540?.Value === "01" || props.filter?.RM540?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM540")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM540")}
                                        value={getDefaultOption("RM540")}
                                        id="RM540"
                                        isDisabled={isDisabled("RM540")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM540?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM540" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROE 3Y:
                            </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM226?.Value === "01" || props.filter?.RM226?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM226")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM226")}
                                        value={getDefaultOption("RM226")}
                                        id="RM226"
                                        isDisabled={isDisabled("RM226")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM226?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM226" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROE 5Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM227?.Value === "01" || props.filter?.RM227?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM227")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM227")}
                                        value={getDefaultOption("RM227")}
                                        id="RM227"
                                        isDisabled={isDisabled("RM227")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM227?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM227" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROE 10Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM228?.Value === "01" || props.filter?.RM228?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM228")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM228")}
                                        value={getDefaultOption("RM228")}
                                        id="RM228"
                                        isDisabled={isDisabled("RM228")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM228?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM228" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Debt/Equity:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM527?.Value === "01" || props.filter?.RM527?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM527")}
                                        options={debtEquity}
                                        onChange={event => handlChange(event, "RM527")}
                                        value={getDefaultOption("RM527")}
                                        id="RM527"
                                        isDisabled={isDisabled("RM527")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM527?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="5" Min="0" Max="5" Step="0.1" filter={props.filter} source="RM527" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>




                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    FCF to Revenue 5Y:
                                        </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                        style={props.filter?.RM574?.Value === "01" || props.filter?.RM574?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM574")}
                                        options={fcfOption}
                                        onChange={event => handlChange(event, "RM574")}
                                        value={getDefaultOption("RM574")}
                                        id="RM574"
                                        isDisabled={isDisabled("RM574")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                        {props.filter?.RM574?.Source === "Custom" &&
                                            <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="30" Min="0" Max="30" Step="1" filter={props.filter} source="RM574" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>



                           




                        </div>
                        <div className="RightScreenerOption">

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROCE ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM541?.Value === "01" || props.filter?.RM541?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM541")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM541")}
                                        value={getDefaultOption("RM541")}
                                        id="RM541"
                                        isDisabled={isDisabled("RM541")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM541?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM541" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROCE 3Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM232?.Value === "01" || props.filter?.RM232?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM232")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM232")}
                                        value={getDefaultOption("RM232")}
                                        id="RM232"
                                        isDisabled={isDisabled("RM232")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM232?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM232" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROCE 5Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM233?.Value === "01" || props.filter?.RM233?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM233")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM233")}
                                        value={getDefaultOption("RM233")}
                                        id="RM233"
                                        isDisabled={isDisabled("RM233")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM233?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM233" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROCE 10Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM234?.Value === "01" || props.filter?.RM234?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM234")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM234")}
                                        value={getDefaultOption("RM234")}
                                        id="RM234"
                                        isDisabled={isDisabled("RM234")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM234?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM234" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROA ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM542?.Value === "01" || props.filter?.RM542?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM542")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM542")}
                                        value={getDefaultOption("RM542")}
                                        id="RM542"
                                        isDisabled={isDisabled("RM542")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM542?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM542" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROA 3Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM235?.Value === "01" || props.filter?.RM235?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM235")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM235")}
                                        value={getDefaultOption("RM235")}
                                        id="RM235"
                                        isDisabled={isDisabled("RM235")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM235?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM235" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROA 5Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM236?.Value === "01" || props.filter?.RM236?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM236")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM236")}
                                        value={getDefaultOption("RM236")}
                                        id="RM236"
                                        isDisabled={isDisabled("RM236")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM236?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM236" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                ROA 10Y:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM237?.Value === "01" || props.filter?.RM237?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM237")}
                                        options={qualityOption}
                                        onChange={event => handlChange(event, "RM237")}
                                        value={getDefaultOption("RM237")}
                                        id="RM237"
                                        isDisabled={isDisabled("RM237")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM237?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM237" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                Leverage:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM531?.Value === "01" || props.filter?.RM531?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                    <Select
                                        defaultValue={getDefaultOption("RM531")}
                                        options={leverage}
                                        onChange={event => handlChange(event, "RM531")}
                                        value={getDefaultOption("RM531")}
                                        id="RM531"
                                        isDisabled={isDisabled("RM531")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.RM531?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="10" Min="0" Max="10" Step="0.5" filter={props.filter} source="RM531" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    FCF to Revenue 10Y:
                                            </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.RM576?.Value === "01" || props.filter?.RM576?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("RM576")}
                                        options={fcfOption}
                                        onChange={event => handlChange(event, "RM576")}
                                        value={getDefaultOption("RM576")}
                                        id="RM576"
                                        isDisabled={isDisabled("RM576")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                        {props.filter?.RM576?.Source === "Custom" &&
                                            <div>
                                                <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="30" Min="0" Max="30" Step="1" filter={props.filter} source="RM576" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                }








                {option === "MS" &&
                    <>
                        <div className="LeftScreenerOption">
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PE ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR021?.Value === "01" || props.filter?.PR021?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR021")}
                                            options={peOption}
                                        onChange={event => handlChange(event, "PR021")}
                                        value={getDefaultOption("PR021")}
                                        id="PR021"
                                        isDisabled={isDisabled("PR021")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR021?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR021" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PE 3 Years:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR028?.Value === "01" || props.filter?.PR028?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR028")}
                                            options={peOption}
                                        onChange={event => handlChange(event, "PR028")}
                                        value={getDefaultOption("PR028")}
                                        id="PR028"
                                        isDisabled={isDisabled("PR028")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                        {props.filter?.PR028?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR028" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PE 5 Years:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                        style={props.filter?.PR029?.Value === "01" || props.filter?.PR029?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR029")}
                                        options={peOption}
                                        onChange={event => handlChange(event, "PR029")}
                                        value={getDefaultOption("PR029")}
                                        id="PR029"
                                        isDisabled={isDisabled("PR029")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR029?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR029" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                PS ttm:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR024?.Value === "01" || props.filter?.PR024?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR024")}
                                        options={psOption}
                                        onChange={event => handlChange(event, "PR024")}
                                        value={getDefaultOption("PR024")}
                                        id="PR024"
                                        isDisabled={isDisabled("PR024")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR024?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR024" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                PS 3 years:
                            </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR040?.Value === "01" || props.filter?.PR040?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR040")}
                                        options={psOption}
                                        onChange={event => handlChange(event, "PR040")}
                                        value={getDefaultOption("PR040")}
                                        id="PR040"
                                        isDisabled={isDisabled("PR040")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR040?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR040" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                PS 5 years:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR041?.Value === "01" || props.filter?.PR041?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR041")}
                                        options={psOption}
                                        onChange={event => handlChange(event, "PR041")}
                                        value={getDefaultOption("PR041")}
                                        id="PR041"
                                        isDisabled={isDisabled("PR041")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR041?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR041" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                % down 52w high:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR014?.Value === "01" || props.filter?.PR014?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR014")}
                                            options={downOption}
                                        onChange={event => handlChange(event, "PR014")}
                                        value={getDefaultOption("PR014")}
                                        id="PR014"
                                        isDisabled={isDisabled("PR014")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR014?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="99" Min="0" Max="99" Step="1" filter={props.filter} source="PR014" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="MiddleScreenerOption">
                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                PB ttm:
                                    </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR022?.Value === "01" || props.filter?.PR022?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR022")}
                                        options={pbOption}
                                        onChange={event => handlChange(event, "PR022")}
                                        value={getDefaultOption("PR022")}
                                        id="PR022"
                                        isDisabled={isDisabled("PR022")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR022?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="99" Min="0" Max="99" Step="1" filter={props.filter} source="PR022" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                PB 3 years:
                                    </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR032?.Value === "01" || props.filter?.PR032?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR032")}
                                        options={pbOption}
                                        onChange={event => handlChange(event, "PR032")}
                                        value={getDefaultOption("PR032")}
                                        id="PR032"
                                        isDisabled={isDisabled("PR032")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR032?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR032" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                PB 5 years:
                                    </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR033?.Value === "01" || props.filter?.PR033?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR033")}
                                        options={pbOption}
                                        onChange={event => handlChange(event, "PR033")}
                                        value={getDefaultOption("PR033")}
                                        id="PR033"
                                        isDisabled={isDisabled("PR033")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR033?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR033" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PFCF ttm:
                            </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR025?.Value === "01" || props.filter?.PR025?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR025")}
                                            options={pfcfOption}
                                        onChange={event => handlChange(event, "PR025")}
                                        value={getDefaultOption("PR025")}
                                        id="PR025"
                                        isDisabled={isDisabled("PR025")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR025?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR025" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PFCF 3 years:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR044?.Value === "01" || props.filter?.PR044?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR044")}
                                            options={pfcfOption}
                                        onChange={event => handlChange(event, "PR044")}
                                        value={getDefaultOption("PR044")}
                                        id="PR044"
                                        isDisabled={isDisabled("PR044")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR044?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR044" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PFCF 5 years:
                            </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR045?.Value === "01" || props.filter?.PR045?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR045")}
                                            options={pfcfOption}
                                        onChange={event => handlChange(event, "PR045")}
                                        value={getDefaultOption("PR045")}
                                        id="PR045"
                                        isDisabled={isDisabled("PR045")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR045?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR045" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    % up 52w low:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR015?.Value === "01" || props.filter?.PR015?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR015")}
                                            options={upOption}
                                        onChange={event => handlChange(event, "PR015")}
                                        value={getDefaultOption("PR015")}
                                        id="PR015"
                                        isDisabled={isDisabled("PR015")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR015?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="200" Min="0" Max="200" Step="1" filter={props.filter} source="PR015" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="RightScreenerOption">

                            


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PTBV ttm:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR023?.Value === "01" || props.filter?.PR023?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR023")}
                                            options={ptbvOption}
                                        onChange={event => handlChange(event, "PR023")}
                                        value={getDefaultOption("PR023")}
                                        id="PR023"
                                        isDisabled={isDisabled("PR023")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR023?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR023" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PTBV 3 years:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR036?.Value === "01" || props.filter?.PR036?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR036")}
                                            options={ptbvOption}
                                        onChange={event => handlChange(event, "PR036")}
                                        value={getDefaultOption("PR036")}
                                        id="PR036"
                                        isDisabled={isDisabled("PR036")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR036?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR036" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    PTBV 5 years:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.PR037?.Value === "01" || props.filter?.PR037?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("PR037")}
                                            options={ptbvOption}
                                        onChange={event => handlChange(event, "PR037")}
                                        value={getDefaultOption("PR037")}
                                        id="PR037"
                                        isDisabled={isDisabled("PR037")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.PR037?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR037" />
                                            </div>
                                        }
                                    </div>
                                </div>

                        </div>

                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Dividend Yield %:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR049?.Value === "01" || props.filter?.PR049?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR049")}
                                        options={yieldOption}
                                        onChange={event => handlChange(event, "PR049")}
                                        value={getDefaultOption("PR049")}
                                        id="PR049"
                                        isDisabled={isDisabled("PR049")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR049?.Source === "Custom" &&
                                        <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="20" Min="0" Max="20" Step="1" filter={props.filter} source="PR049" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Div Payout 5 years:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.RM524?.Value === "01" || props.filter?.RM524?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("RM524")}
                                        options={payoutOption}
                                        onChange={event => handlChange(event, "RM524")}
                                        value={getDefaultOption("RM524")}
                                        id="RM524"
                                        isDisabled={isDisabled("RM524")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.RM524?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="RM524" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Earning Yield:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.PR026?.Value === "01" || props.filter?.PR026?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("PR026")}
                                        options={eYieldOption}
                                        onChange={event => handlChange(event, "PR026")}
                                        value={getDefaultOption("PR026")}
                                        id="PR026"
                                        isDisabled={isDisabled("PR026")}
                                    />
                                </div>
                                <div className="VariableOptionCustom">
                                    {props.filter?.PR026?.Source === "Custom" &&
                                        <div>
                                            <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="10" Min="0" Max="10" Step="1" filter={props.filter} source="PR026" />
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                            
                        </div>
                    </>
                }

                { /* auth.roles === true && */}
                {option === "SC" && 
                    <>
                        <div className="LeftScreenerOption">
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Moat:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.moat?.Value === "01" || props.filter?.moat?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("moat")}
                                        options={scoreOption}
                                        onChange={event => handlChange(event, "moat")}
                                        value={getDefaultOption("moat")}
                                        id="moat"
                                        isDisabled={isDisabled("moat")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.moat?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="moat" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>


                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Predictability:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.predictability?.Value === "01" || props.filter?.predictability?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("predictability")}
                                        options={scoreOption}
                                        onChange={event => handlChange(event, "predictability")}
                                        value={getDefaultOption("predictability")}
                                        id="predictability"
                                        isDisabled={isDisabled("predictability")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.predictability?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="predictability" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="MiddleScreenerOption">
                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Management:
                                    </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.management?.Value === "01" || props.filter?.management?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("management")}
                                        options={scoreOption}
                                        onChange={event => handlChange(event, "management")}
                                        value={getDefaultOption("management")}
                                        id="management"
                                        isDisabled={isDisabled("management")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.management?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={true} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="management" />
                                            </div>
                                        }
                                    </div>
                                </div>

                        </div>


                        <div className="ScreenerOptionRow">
                            <div className="VariableOption">
                                Relative Rank:
                                </div>
                            <div className="VariableOptionPreDefined">
                                <div
                                    style={props.filter?.relative_rank?.Value === "01" || props.filter?.relative_rank?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                >
                                    <Select
                                        defaultValue={getDefaultOption("relative_rank")}
                                        options={rankOption}
                                        onChange={event => handlChange(event, "relative_rank")}
                                        value={getDefaultOption("relative_rank")}
                                        id="relative_rank"
                                        isDisabled={isDisabled("relative_rank")}
                                    />
                                </div>
                                
                            </div>

                        </div>

                        </div>
                    <div className="RightScreenerOption">

                            <div className="ScreenerOptionRow">
                                <div className="VariableOption">
                                    Margin of Saftey:
                                </div>
                                <div className="VariableOptionPreDefined">
                                    <div
                                    style={props.filter?.margin_of_safety?.Value === "01" || props.filter?.margin_of_safety?.Value === undefined ? { borderColor: "#B3B3B3", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" } : { borderColor: "blue", borderRadius: "4px", borderWidth: "1px", borderStyle: "solid" }}
                                    >
                                        <Select
                                        defaultValue={getDefaultOption("margin_of_safety")}
                                        options={scoreOption}
                                        onChange={event => handlChange(event, "margin_of_safety")}
                                        value={getDefaultOption("margin_of_safety")}
                                        id="margin_of_safety"
                                        isDisabled={isDisabled("margin_of_safety")}
                                        />
                                    </div>
                                    <div className="VariableOptionCustom">
                                    {props.filter?.margin_of_safety?.Source === "Custom" &&
                                            <div>
                                        <MultiRange toggleInputChange={() => toggleInputChange} percentIndicator={false} defaultMin="0" defaultMax="100" Min="0" Max="100" Step="1" filter={props.filter} source="margin_of_safety" />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                }
                {/*option === "SC" && !auth.roles === true &&
                    <div style={{ paddingTop: "40px", textAlign: "center", paddingLeft: "150px" }}>
                    <Locked />
                    </div>
                */}

            </div>

            {/* <div className="QueryViewer selectedRatios"> */}
            <div>
                <p style={{ fontSize: "12px" }}>
                {Object.keys(props.filter).length !== 0 && 
                    Object.keys(props.filter).map((item, index) => (
                        props.filter[item]["Source"] === "PreSelected" ?
                            <>
                                {props.filter[item]["Value"] === "01" ?
                                    <></>
                                    : 
                                    <div className="dragableSection">
                                        <div style={{ display: "inline-block" }}>
                                            <b> {getKeyLookup(item)}</b> &nbsp; {getOptionLookup(item, props.filter[item])}
                                        </div>
                                        <div style={{ display: "inline-block", marginLeft: "10px", cursor: "pointer"  }} className="removableSection" onClick={() => removeSelected(item)}>
                                            &#10006;
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <div className="dragableSection">
                                <div style={{ display: "inline-block" }}>
                                    <b> {getKeyLookup(item)}</b> &nbsp; Custom
                                </div>
                                <div style={{ display: "inline-block", marginLeft: "10px", cursor: "pointer" }} className="removableSection" onClick={() => removeSelected(item)}>
                                    &#10006;
                                </div>
                            </div>
                        ))
                }
                </p>
            </div>
        </div>
    );
}
export default ManageScreener;