import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import WatchlistCompanies from '../WatchlistCompanies'
import AddWatchlist from '../AddWatchlist'

function WatchlistSection(props) {
    const axiosPrivate = useAxiosPrivate();
    const [pageData, setpageData] = useState([]);
    const [pageLoaded, setPageLoadedData] = useState(false);
    const [watchlistData, setWatchlistData] = useState([]);
    const [optionSelected, setOptionSelected] = useState("");
    


    

    const runQuery = async () => {
        setpageData([])
        setPageLoadedData(false)
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const response = await axiosPrivate.get(url);
        let tempData = []
        let tempDataTwo = []
        if (response.data.watch_lists != null) {
            for (let i = 0; i < response.data.watch_lists.length; i++) {
                
                tempData.push(response.data.watch_lists[i]["list_name"])
            }
            setOptionSelected(tempData[0])
            tempDataTwo = response.data.watch_lists
        }
        if (response.data.watch_lists === null || response.data.watch_lists.length === 0) {
            props.setWatchlistPresent(false)
        }
        setWatchlistData(tempDataTwo)
        setpageData(tempData)
        setPageLoadedData(true)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
           runQuery()
            
        }, 50)
        return () => clearTimeout(timeout)
    }, [props.isProfileUpdated])
    return (
        <div style={{ paddingTop: "50px", paddingBottom: "110px" }}>
            {pageLoaded && <div>

            {pageData.length !== 0 &&
                <>
                <div style={{ margin: "10px 50px 20px 50px", backgroundColor: "White", borderRadius: "10px" }}>
                <div>
                    <div style={{ display: "flex", columnGap: "5px", paddingTop: "20px", paddingLeft: "50px" }}>
                        <div style={{ paddingTop: "5px", fontWeight: "600" }}>Watchlist:</div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <select
                                    className="ViewSelected"
                                    value={optionSelected}
                                    onChange={e => setOptionSelected(e.target.value)}
                                >
                                    {pageData.map(item => (
                                        <option value={item}>{item}</option>
                                    ))
                                    }
                                </select>
                                </div>
                                <div style={{ marginLeft: "-30px", fontSize: "30px", paddingTop: "1px" }}>
                                    <svg
                                        width="20px"
                                        height="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 300 300"
                                        shapeRendering="geometricPrecision"
                                        textRendering="geometricPrecision"
                                        fill="#555"
                                        {...props}
                                    >
                                        <path
                                            d="M67.79 65.49l170.343-.697L150 176.963 67.79 65.49z"
                                            transform="matrix(1 0 0 .68815 -8 16.35)"
                                            stroke="#555"
                                            strokeWidth={0.6}
                                        />
                                    </svg>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: "50px", paddingRight: "50px", paddingBottom: "20px" }}>
                        <h3 style={{ textAlign: "left" }}>{optionSelected}</h3>
                            <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                                <WatchlistCompanies watchlistName={optionSelected} ratioData={props.ratioData} optionData={props.optionData} watchlistData={watchlistData} />
                        </div>
                    </div>
                    </div>
                  
                </div>
                {/*<div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "50px", paddingRight: "50px" }}>
                    <div style={{ backgroundColor: "White", borderRadius: "10px", minWidth: "49%" }}>
                        <div style={{ paddingTop: "20px", paddingLeft: "50px", minHeight: "200px" }}>
                            <div style={{ paddingTop: "5px", fontWeight: "600", textAlign: "left" }}>Earning Calender:</div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "White", borderRadius: "10px", minWidth: "49%" }}>
                        <div style={{ paddingTop: "20px", paddingLeft: "50px", minHeight: "200px" }}>
                            <div style={{ paddingTop: "5px", fontWeight: "600", textAlign: "left" }}>News:</div>
                        </div>
                    </div>
                </div>*/}
                </>


            }
            {pageData.length === 0 &&
                <div
                style={{
                    backgroundColor: "white",
                    marginLeft: "50px",
                    marginRight: "50px",
                    borderRadius: "10px",
                    minHeight: "250px",
                    paddingTop: "100px"
                }}>
                <div style={{ padding: "100px" }}>
                    <div>
                        There are no active watchlists created for your account. Please create a watchlist.
                                </div>
                    <div style={{ marginLeft: "auto", marginRight: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <AddWatchlist toggleProfile={props.toggleProfile} />
                    </div>
                </div>
                </div>
                }
            </div>
            }
        </div>
    );
}
export default WatchlistSection;