import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Charting from "./Charting"
import CommoditySearchbox from '../../Commodity/CommoditySearchbox'
import Loading from '../../Commodity/Loading'


function ChartWrapper(props) {
    const [pageData, setpageData] = useState([]);
    const [commoditySymbol, setCommoditySymbol] = useState({ label: "All commodities", value: "1000000000" });
    const [mapData, setMapData] = useState({});
    const [repaint, setRepaint] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const CommodityList = async () => {
        setpageData([])
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/commodities-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let tempData = []
        cdresponse.data.data.forEach(item => (
            tempData.push({ label: item.name, value: item.code })
        ))
        setpageData(tempData)
        
    }

    const getAlphabeticMonth = (month) => {
        if (month === 0) {
            return "Jan"
        } else if (month === 1) {
            return "Feb"
        } else if (month === 2) {
            return "Mar"
        } else if (month === 3) {
            return "Apr"
        } else if (month === 4) {
            return "May"
        } else if (month === 5) {
            return "Jun"
        } else if (month === 6) {
            return "Jul"
        } else if (month === 7) {
            return "Aug"
        } else if (month === 8) {
            return "Sep"
        } else if (month === 9) {
            return "Oct"
        } else if (month === 10) {
            return "Nov"
        } else if (month === 11) {
            return "Dec"
        }
    }

    function getDataMatch(stockData, commodityData) {
        let returnValue = null
        let year = stockData[0].getFullYear();
        let month = getAlphabeticMonth(stockData[0].getMonth());
        let day = stockData[0].getDay();
        let comparisonDate = [month, year].join("-")
        
        commodityData.indexes.forEach(data => {
           // if (data.date_header === comparisonDate && day === 1) {
            if (data.date_header === comparisonDate) {
                returnValue = data.value
            }
        })
        return returnValue;
    }

    const updateData = async (item) => {
        let url = ""
        let vmapData = mapData
        let tempData = mapData.series[0]['data']
        console.log(tempData)
        let tempDataTwo = []
        let tempDataThree = []
        setMapData({})
        url = "https://api.niveshkarma.com/api/v1/commodities/" + item.value + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let commodityData = cdresponse.data
        tempData.forEach((arr, index) => {
            if (index !== 0) {   
                tempDataThree.push(getDataMatch(arr, commodityData))
                tempDataTwo.push([arr[0], getDataMatch(arr, commodityData)])
            }
        })
        let minVal = Math.min.apply(null, tempDataThree.filter(Boolean))
        let maxVal = Math.max(...tempDataThree)
        vmapData.options.yaxis[1]['min'] = minVal
        vmapData.options.yaxis[1]['max'] = maxVal
        vmapData.series[1]['data'] = tempDataTwo
        console.log(vmapData)
        setMapData(vmapData)
    }



    const updateSymbol = (item) => {
        setRepaint(false)
        updateData(item)
        setCommoditySymbol(item)
        setRepaint(true)
    }



    useEffect(() => {
        CommodityList()
    }, [props.compName])

    return (
        <div >
            {pageData.length > 0 &&
                <>
                    <div style={{ display: "flex", justifyContent: "start", paddingBottom: "15px" }}>
                        <div style={{ width: "400px", paddingTop: "27px"}}>
                        <CommoditySearchbox commodityOptions={pageData} updateOption={updateSymbol} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "start", paddingTop: "25px" }}>

                        <div className="CommodityElement"><div>{commoditySymbol.label}</div></div>

                        </div>
                    </div>
                <Charting compSymbol={props.compSymbol} compName={props.compName} mapData={mapData} setMapData={setMapData} repaint={repaint} commoditySymbol={commoditySymbol} />
                {/*<div>
                    {commoditySymbol.map(item => (
                        <CommodityChart CommoditySymbol={item} />
                    ))}
                </div>
                */}
                </>
            }
            {pageData.length === 0 &&
                <div style={{ paddingTop: "100px" }}>
                    <Loading />
                </div>
            }
        </div>
    );
}

export default ChartWrapper                                                  