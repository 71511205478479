import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../icon/Icon'

function FooterMobile() {

    return (

        <div className="Footer" style={{ backgroundColor: "#253037" }}>
            <div style={{ marginTop: "10px" }}>
                <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                    <Link style={{ padding: "20px", backgroundColor: "#36454f", border: "5px solid #253037", textDecoration: "none" }} to="./OurStory"><div className="footerLinkElement" style={{ fontSize: "12px" }}>Our Story</div></Link>
                    <Link style={{ padding: "20px", backgroundColor: "#36454f", border: "5px solid #253037", textDecoration: "none" }} to="./FAQ"><div className="footerLinkElement" style={{ fontSize: "12px" }}>FAQs</div></Link>
                    <Link style={{ padding: "20px", backgroundColor: "#36454f", border: "5px solid #253037", textDecoration: "none" }} to="./Feedback"><div className="footerLinkElement" style={{ fontSize: "12px" }}>Feedback/ Questions?</div></Link>
                    <Link style={{ padding: "20px", backgroundColor: "#36454f", border: "5px solid #253037", textDecoration: "none" }} to="./Pricing"><div className="footerLinkElement" style={{ fontSize: "12px" }}>Pricing</div></Link>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "100%", color: "white", paddingTop: "40px"}}>
                    Join our Investor Community
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <a className="socialAnchor" href="https://www.facebook.com/profile.php?id=100090703927364" target="_blank"><Icon source="fb" /></a>
                    <a className="socialAnchor" href="https://www.youtube.com/@niveshkarma" target="_blank"><Icon source="yt" /></a>
                    <a className="socialAnchor" href="https://www.linkedin.com/company/nivesh-karma" target="_blank"><Icon source="in" /></a>
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "100%", color: "white", paddingTop: "40px", fontSize: "12px" }}>

                    <i>"I have no special talent. I am only passionately curious."</i>
                    <div style={{ textAlign: "right", paddingRight: "20px", fontSize: "11px" }}>--Albert Einstein</div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <Link style={{ padding: "20px", backgroundColor: "#36454f", border: "5px solid #253037", textDecoration: "none" }} to="./Disclaimer"><div className="footerLinkElement" style={{ fontSize: "12px" }}>Disclaimer</div></Link>
                    <Link style={{ padding: "20px", backgroundColor: "#36454f", border: "5px solid #253037", textDecoration: "none" }} to="./Terms"><div className="footerLinkElement" style={{ fontSize: "12px" }}>Terms & Privacy</div></Link>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "100%", color: "white", paddingTop: "40px", paddingBottom: "100px" }}>
                    <div style={{ fontWeight: "600", fontSize: "11px" }}>Copyright &#169; 2023 NiveshKarma 3M LLP.<br /> All Rights Reserved.</div>
                </div>
            </div>
        </div>
    );
}
export default FooterMobile;