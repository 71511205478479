import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import CommodityChart from './CommodityChart'
import Loading from './Loading'

function CoreIndustries(props) {
    const [pageData, setpageData] = useState([]);
    const [commoditySymbol, setCommoditySymbol] = useState({ label: "Overall Index", value: "9000000000" });
    const axiosPrivate = useAxiosPrivate();
    const CommodityList = async () => {
        setpageData([])
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/commodities-list?category=core_infra&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        //document.title = cdresponse.data.stock_name + "- 3M Score | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes | Investing checklist. ";
        setpageData(cdresponse.data.data)
    }

    const updateSelection = (item, name) => {
        setCommoditySymbol({ label: name, value: item })
    }

    useEffect(() => {
        CommodityList()
    }, [])

    return (
        <div >
            {pageData.length > 0 &&
                <>
                <p>
                    {pageData.map(item => (
                        <div className="CommodityElement" onClick={() => updateSelection(item.code, item.name)}>
                            {item.name}
                        </div>
                    ))
                    }
                </p>
                 <div>
                        <CommodityChart CommoditySymbol={commoditySymbol} />
                </div>
                </>
            }
            {pageData.length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default CoreIndustries                                                  