import React, { useState, useEffect } from 'react';
import Loading from "../Company/Loading"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useAuth from '../hooks/useAuth';

function FeedbackMobile() {
    window.scrollTo(0, 0)
    const [error, setError] = useState('');
    const [category, setCategory] = useState('');
    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const { auth } = useAuth();
    document.title = "Nivesh Karma - Feedback";
    const axiosPrivate = useAxiosPrivate();
    const postMessage = async () => {

        if (!category) {
            setError('Please choose a Category');
            return;
        }
        if (!message) {
            setError('Please enter your Message');
            return;
        }
        setError("")
        setLoading(true)
        var body = {
            'category': category,
            'description': message
        }
        let url = "https://api.niveshkarma.com/api/v1/feedback?access_token=asefhjdshfgjdb544545rytgfduyjrlksdjr"
        const res = await axiosPrivate.post(url, body)
        if (res.status === 201) {
            setMessageSent(true)
        }
        setLoading(false)
    }

    useEffect(() => {
    }, [auth.user])

    return (
        <div className="Feedback commonContainer">
            {auth.user && <>
                <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Feedback or Questions?</div>
                {!messageSent && !loading &&
                    <>
                        <div style={{ textAlign: "left", fontSize: "14px", paddingTop: "5px" }}>
                            You can provide your valuable feedback or send questions to our support team.
                        </div>
                        <div className="ErrorPlaceholder">{error && <b>{error}</b>}</div>
                        <div style={{ border: "2px solid #F5F5F5", borderRadius: "25px", minHeight: "350px", marginTop: "10px" }}>

                            <div className="FeedbackRow" style={{ display: "flex", justifyContent: "left", height: "260px", marginTop: "50px" }}>
                                <div className="FeedbackBracket">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="50"
                                        viewBox="0 0 490 490"
                                        style={{ fill: "#000000" }}>
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <g>
                                                <g>
                                                    <path d="M393.872,454.517c-2.304,0-4.583-0.804-6.412-2.354l-99.315-84.194H68.115C30.556,367.968,0,337.242,0,299.474V103.977 c0-37.768,30.556-68.494,68.115-68.494h353.77c37.559,0,68.115,30.727,68.115,68.494v195.497 c0,37.768-30.556,68.494-68.115,68.494h-18.071v76.549c0,3.891-2.243,7.428-5.752,9.067 C396.723,454.21,395.293,454.517,393.872,454.517z M68.115,55.483c-26.592,0-48.226,21.754-48.226,48.494v195.497 c0,26.739,21.634,48.494,48.226,48.494h223.662c2.346,0,4.616,0.834,6.411,2.354l85.737,72.685v-65.039c0-5.523,4.453-10,9.945-10 h28.015c26.592,0,48.226-21.755,48.226-48.494V103.977c0-26.74-21.634-48.494-48.226-48.494H68.115z">
                                                    </path>
                                                </g>
                                                <g>
                                                    <g> <
                                                        path d="M405.168,147.469H84.832c-5.492,0-9.944-4.478-9.944-10c0-5.523,4.452-10,9.944-10h320.335c5.492,0,9.944,4.477,9.944,10 C415.111,142.991,410.66,147.469,405.168,147.469z">
                                                    </path>
                                                    </g>
                                                    <g>
                                                        <path d="M405.168,211.503H84.832c-5.492,0-9.944-4.478-9.944-10c0-5.523,4.452-10,9.944-10h320.335c5.492,0,9.944,4.477,9.944,10 C415.111,207.025,410.66,211.503,405.168,211.503z">
                                                        </path>
                                                    </g>
                                                    <g>
                                                        <path d="M405.168,275.538H84.832c-5.492,0-9.944-4.478-9.944-10c0-5.523,4.452-10,9.944-10h320.335c5.492,0,9.944,4.476,9.944,10 C415.111,271.06,410.66,275.538,405.168,275.538z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div style={{ width: "80%", paddingTop: "20px" }}>
                                    <div>
                                        <label>
                                            Category:
				                <select value={category} onChange={(e) => setCategory(e.target.value)} style={{ width: "76%", padding: "5px", marginLeft: "12px" }}>
                                                <option value="">Please Select...</option>
                                                <option value="Login or Access issue">Login or Access issue</option>
                                                <option value="Billing or Subscription">Billing or Subscription</option>
                                                <option value="Market Data">Market Data</option>
                                                <option value="Technical Issues">Technical Issues</option>
                                                <option value="Request for new feature">Request for new feature</option>
                                                <option value="Feedback">Feedback</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </label>
                                    </div>



                                    <textarea
                                        type="text"
                                        placeholder="Message..."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows="6"
                                        maxLength="1000"
                                        style={{
                                            width: "90%",
                                            border: "2px solid #F5F5F5",
                                            height: "170px",
                                            outline: "none",
                                            lineHeight: "1.8",
                                            fontSize: "18px",
                                            marginTop: "10px"
                                        }} />

                                </div>
                            </div>
                            <div className="FeedbackRow" style={{ display: "flex", justifyContent: "right", height: "45px" }}>
                                <div className="Login" onClick={() => postMessage()} style={{ marginRight: "20px", marginBottom: "10px" }}> Send</div>
                            </div>
                        </div>
                    </>
                }

                {
                    messageSent &&
                    <div style={{ textAlign: "center", paddingTop: "60px", paddingBottom: "60px", border: "2px solid #F5F5F5", fontWeight: "500" }}>
                        Thank you for choosing us, and we look forward to serving you!
                </div>
                }

                {
                    loading &&
                    <Loading />
                }
            </>
            }
            {!auth.user &&
                <div style={{ border: "2px solid #F5F5F5", borderRadius: "25px", minHeight: "150px", marginTop: "10px", textAlign: "center", paddingTop: "120px" }}>
                    Please login to access this section.
                </div>
            }
        </div>
    );
}
export default FeedbackMobile;