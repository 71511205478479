import React from 'react';
import "./Footer.css"
import { Link } from 'react-router-dom';
import Icon from './icon/Icon'

function Footer() {
    
    return (

            <div className="Footer">
                <div className="FooterRow">
                    <div className="FooterColumn">
                        <div className="companyLinks">
                            <div className="companyLinksContent">
                            <Link className="LinkRoute" to="./OurStory"><div className="footerLinkElement">Our Story</div></Link>
                                {/*   <Link className="LinkRoute" to="./Pricing"><div>Pricing</div></Link>*/}
                                <Link className="LinkRoute" to="./FAQ"><div className="footerLinkElement">FAQs</div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="FooterColumnOneTwo">
                        <div className="companyLinks">
                            <div className="companyLinksContent">
                            <Link className="LinkRoute" to="./Feedback"><div className="footerLinkElement">Feedback/Questions?</div></Link>
                            <Link className="LinkRoute" to="#"><div className="footerLinkElement">&nbsp;</div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="FooterColumnTwo">
                        <div className="SocialElementHeading">
                            Join our Investor Community
                        </div>
                        <div className="SocialElement">
                        {/* <a className="socialAnchor" href="https://www.facebook.com/profile.php?id=100090703927364" target="_blank"><Icon source="fb" /></a>*/}
                        <a className="socialAnchor" href="https://www.youtube.com/@niveshkarma" target="_blank"><Icon source="yt" /></a>
                        <a className="socialAnchor" href="https://www.linkedin.com/company/nivesh-karma" target="_blank"><Icon source="in" /></a>
                        {/* <a className="socialAnchor" href="https://chat.whatsapp.com/IwFcDxFqyXA3hgkuaJB4qF" target="_blank"><Icon source="wa" /></a>*/}
                        </div>
                    </div>
                    <div className="FooterColumnThree">
                        <div className="RightElement">
                            <i>"I have no special talent. I am only passionately curious."</i>
                            <br />
                            <br/>
                            --Albert Einstein
                        </div>
                    </div>
                </div>

            <div className="FooterRow">
                <div className="FooterColumn">
                    <div className="companyLinks">
                        <div className="companyLinksContent">
                            <Link className="LinkRoute" to="./Disclaimer"><div className="footerLinkElement">Disclaimer</div></Link>

                        </div>
                    </div>
                </div>
                <div className="FooterColumnOneTwo">
                    <div className="companyLinks">
                        <div className="companyLinksContent">
                            <Link className="LinkRoute" to="./Terms"><div className="footerLinkElement">Terms & Privacy</div></Link>

                        </div>
                    </div>
                </div>
                <div className="FooterColumnfour">
                    <div className="Rights">
                        </div>
                </div>
            </div>
            <div className="FooterRow" style={{ justifyContent: "center" }}>
                <div className="userInfoTwo" style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "600" }}>Copyright &#169; 2023 Nivesh Karma. All Rights Reserved.</div>
                    <div style={{ paddingTop: "10px" }}>Designed for Desktops & Laptops</div>
                </div>
            </div>

           
            
                
        </div>
    );
}
export default Footer;