import React, { useState, useEffect, useRef } from 'react';
import WatchlistActionOption from './WatchlistActionOption'
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate"

function WatchlistAction(props) {
    const axiosPrivate = useAxiosPrivate();
    function getDefaultCheck() {
        if (props.watchlistData.companiesList.includes(props.compSymbol)) {
            return true
        } else {
            return false
        }
    }
    const [show, setShow] = useState(false);
    const [check, setCheck] = useState(false);
    const [userInput, setUserInput] = useState("")
    const { auth } = useAuth();
    const ref = useRef();
    const getWatchlistLimit = () => {
        if (auth.roles) {
            return 20
        } else {
            return 2
        }
    }
    const [watchlistLimit] = useState(getWatchlistLimit())









    const createWatchlist = async () => {
        if (userInput) {
            let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            let tempData = []
            if (props.watchlistData.watchlistList.length !== 0) {
                tempData = props.watchlistData.fullData.watch_lists
                console.log(tempData)
                tempData.push({ "list_name": userInput, watch_list: [] })
            } else {
                tempData.push({ "list_name": userInput, watch_list: [] })
            }
            const response = await axiosPrivate.post(url, tempData);
            setUserInput("")
            props.toggleVariableChange()
        }
    }


    useEffect(() => {
        setCheck(getDefaultCheck())

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };


    }, [props.watchlistData.companiesList, props.variableChanged])
    return (
        <> 
            <div class="checkbox-wrapper-31" onClick={() => setShow(true)}>
                <input type="checkbox" checked={check} />
                <svg viewBox="0 0 35.6 35.6">
                    <circle class="background" cx="17.8" cy="17.8" r="17.8"></circle>
                    <circle class="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                    <polyline class="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                </svg>
            </div>

            {show && auth.user && <div className="watchlist-menu" ref={ref}>
                {props.loaded &&
                    props.watchlistData.watchlistList.map(item => (
                        <WatchlistActionOption name={item} watchlistData={props.watchlistData.fullData} compSymbol={props.compSymbol} variableChanged={props.variableChanged} toggleVariableChange={props.toggleVariableChange}/>
                        ))
                }
                {watchlistLimit > props.watchlistData.watchlistList.length && props.loaded &&
                    <div style={{ padding: "10px 20px 10px 10px", borderBottom: "1px solid #F5F5F5", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}>
                        <input className="WatchlistInput" type="text" placeholder="Create watchlist" value={userInput} onChange={(e) => setUserInput(e.target.value)} />
                        </div>
                        <div style={{ paddingTop: "3px" }}>
                        <button onClick={() => createWatchlist()}>Create</button>
                        </div>
                    </div>
                }
            </div>
            }
            {show && !auth.user && <div className="watchlist-menu" ref={ref}>
                <div style={{padding: "5px 10px"}}>
                    Please login to add in watchlist
                </div>
            </div>
            }
        </>
    );
}
export default WatchlistAction;

