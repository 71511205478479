import React from 'react';
import useAuth from '../hooks/useAuth';


function PersonalDetails() {
    const { auth } = useAuth();
    return (
        <div style={{ paddingTop: "20px", paddingLeft: "20px" }} >
            <div style={{ fontWeight: "600", fontSize: "16px", textAlign: "left" }}>
                Personal Details
            </div>
            <div style={{ paddingLeft: "40px", paddingTop: "50px"}}>
                <div style={{ display: "grid", gridTemplateColumns: "30% 70%", maxWidth:"500px", padding: "5px"}}>
                    <div style={{ textAlign: "left" }}>Name</div>
                    <div style={{ textAlign:"left" }}>{auth.user}</div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "30% 70%", maxWidth: "500px", padding: "5px" }}>
                    <div style={{ textAlign: "left" }}>Email</div>
                    <div style={{ textAlign: "left" }}>{auth.email}</div>
                </div>
            </div>
        </div>
    );
}
export default PersonalDetails;