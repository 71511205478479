import React, { useState, useEffect, useMemo } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import './Ratios.css'
import RowChart from './Financials/RowChart'
import { Popup } from 'semantic-ui-react'
import Loading from "./Loading"
import HelpModal from './HelpModal'
import ManageRatios from '../ManageRatios/ManageRatios'
import useAuth from '../hooks/useAuth';
import { downloadExcel } from "react-export-table-to-excel";
import ExportSVG from '../icon/ExportSVG'
import KnowMore from '../icon/KnowMore'
import Asc from '../icon/Asc'
import Desc from '../icon/Desc'

const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL + "/google-auth/api/v1/token";
function Ratios(props) {
    
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [sort, setsort] = useState(false);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(3);
    const [disableButton, setDisableButton] = useState(true);
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, historicalRatios: itemsData.historicalRatios }))
        }
        setShow(false)
    }


    const toggleSort = () => {
        if (sort) {
            setsort(false)
        } else {
            setsort(true)
        }
    }
    const getURL = () => {
        return process.env.PUBLIC_URL + '/All Ratios.pdf'
    }
    const isDataPresent = (dataObject, keyArr) => {
        let dataPresent = false
        if (keyArr) {
            keyArr.forEach(item => {
                dataObject.forEach(dataKey => {
                    if (dataKey[item.field_name]) {
                        dataPresent = true
                    }
                })
            })
        }
        return dataPresent
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    function getDisplayValue(data, origin) {
        let pointer = data['display']
        let value = data[pointer]
        if (value) {
            if (parseFloat(value) === 0) {
                return "-"
            }
            if (pointer === "percentage" && origin !== "m") {
                return Math.round(value * 100) / 100 + "%"
            }
            if (origin === "date") {
                return value
            }
            return Math.round(value * 10000) / 10000
        } else {
            return "-"

        }
    }
    const roundPercentValue = (data) => {
        if (data === "-" || isNaN(data)) {
            return data
        }
        if (data) {
            let result = data.replace("%", "");
            let value = parseFloat(result);
            var intvalue = Math.ceil(value);
            return intvalue + "%"
        } else {
            return "-"
        }

    }


    const showManageRatios = () => {
        setShow(true)

    }
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        let ratioData = ""
        let prMap = ""
        let lirMap = ""
        let lerMap = ""
        let erMap = ""
        let prrMap = ""
        let userMap = ""
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        if (sort) {
            ratioData = cdresponse.data['historical_ratios']
        } else {
            ratioData = cdresponse.data['historical_ratios'].reverse()

        }
        company_name = cdresponse.data['stock_name']
        prMap = cdresponse.data['prompts']['ProfitabilityRatios']
        lirMap = cdresponse.data['prompts']['LiquidityRatios']
        lerMap = cdresponse.data['prompts']['LeverageRatios']
        erMap = cdresponse.data['prompts']['EfficiencyRatios']
        prrMap = cdresponse.data['prompts']['ProprietaryRatios']
        userMap = cdresponse.data['prompts']['UserRatios']
        document.title = company_name + "- Ratios | Historical Ratios | Profitability Ratios | Liquidity Ratios | Leverage Ratios | Efficiency Ratios | Proprietary Ratios | User Ratios";
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(3)
        }

        setpageData({
            "ratio": ratioData,
            "profitabilityRatioMapping": prMap,
            "liquidityRatioMapping": lirMap,
            "leverageRatioMapping": lerMap,
            "efficiencyRatioMapping": erMap,
            "proprietaryRatioMapping": prrMap,
            "userRatioMapping": userMap,
            "CompanyName": company_name
        })
    }


    const premiumUser = () => {
        if (auth.user && auth.roles) {
            return true
        } else {
            return false
        }

    }


    function handleDownloadExcel() {

        let header = [""]
        let body = []
        pageData.ratio.forEach(item => {
            header.push("")
        })
        let tempData = ["Profitability Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.profitabilityRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(roundPercentValue(data[item.field_name]))
            })
            body.push(tempData)
        })
        tempData=[""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)
        tempData = ["Liquidity Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.liquidityRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)

        tempData = ["Leverage Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.leverageRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)

        tempData = ["Efficiency Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.efficiencyRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)

        tempData = ["Proprietary Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.proprietaryRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })

        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)
        tempData = ["User Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.userRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })

        downloadExcel({
            fileName: "Ratios",
            sheet: "ratios",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }




    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort, isProfileUpdated, auth.user])


    return (
        <div className="RatiosMain">
            {Object.keys(pageData).length !== 0 &&
                <>
                    <section className="optionsFinData">
                        <div className="leftAlign">
                        {pageData.CompanyName}
                        </div>
                    <div className="rightAlign">

                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={toggleSort} style={{ cursor: "pointer" }}>
                                    {props.sort ? <Asc /> : <Desc />}
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Sort.</div>}
                            style={{ zIndex: "999" }}
                        />

                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={() => setShowTwo(true)} style={{ cursor: "pointer" }}>
                                    <KnowMore />
                                    <HelpModal title="User Manual" onClose={() => setShowTwo(false)} show={showTwo} source="manageRatios">
                                        <embed src={getURL()} width="100%" height="100%" />
                                    </HelpModal>
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Know More.</div>}
                            style={{ zIndex: "999" }}
                        />

                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer" }}>
                                    <ExportSVG />
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Export to Excel</div>}
                            style={{ zIndex: "999" }}
                        />
                        </div>
                    </section>
                    {pageData.ratio &&
                    <section className="Ratios shadowBlock">
                            <table className="finData">
                                <tbody>
                                    <tr>
                                <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">Profitability Ratios</td>
                                        {pageData.ratio.map(data => (
                                            <td className="columnHeading">{data.date_header}</td>
                                        ))}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.profitabilityRatioMapping.map(key => (
                                        <>
                                            <tr>
                                                <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                    <div className="headingWithPopup">
                                                        <div>
                                                            {key.field_prompt}
                                                        </div>
                                                        <div>
                                                            <Popup
                                                                className="popupChart"
                                                                trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                content={<RowChart mapkey={key.field_name} mapData={pageData.ratio} />}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                {pageData.ratio.map(dataKey => (

                                                    <td className="tabledata SubHeader"> {roundPercentValue(dataKey[key.field_name])}</td>

                                                ))}
                                            </tr>
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </section>
                    }
                    {pageData.ratio && isDataPresent(pageData.ratio, pageData.liquidityRatioMapping) &&
                    <section className="Ratios shadowBlock">
                            <table className="finData">
                                <tbody>
                                    <tr>
                                <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">Liquidity Ratios</td>
                                        {pageData.ratio.map(data => (
                                            <td className="columnHeading">{data.date_header}</td>
                                        ))}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.liquidityRatioMapping.map(key => (
                                        <>
                                            <tr>
                                                <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                    <div className="headingWithPopup">
                                                        <div>
                                                            {key.field_prompt}
                                                        </div>
                                                        <div>
                                                            <Popup
                                                                className="popupChart"
                                                                trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                content={<RowChart mapkey={key.field_name} mapData={pageData.ratio} />}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>

                                                {pageData.ratio.map(dataKey => (

                                                    <td className="tabledata SubHeader"> {dataKey[key.field_name]}</td>

                                                ))}
                                            </tr>
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </section>
                    }
                {pageData.ratio &&
                    <section className="Ratios shadowBlock">
                            <table className="finData">
                                <tbody>
                                    <tr>
                                <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">Leverage Ratios</td>
                                {pageData.ratio.map(data => (
                                    <td className="columnHeading">{data.date_header}</td>
                                        ))}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.leverageRatioMapping.map(key => (
                                        <>
                                            <tr>
                                                <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                    <div className="headingWithPopup">
                                                        <div>
                                                            {key.field_prompt}
                                                        </div>
                                                        <div>
                                                            <Popup
                                                                className="popupChart"
                                                                trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                content={<RowChart mapkey={key.field_name} mapData={pageData.ratio} />}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>

                                                {pageData.ratio.map(dataKey => (

                                                    <td className="tabledata SubHeader"> {dataKey[key.field_name]}</td>

                                                ))}
                                            </tr>
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </section>
                    }
                {pageData.ratio && isDataPresent(pageData.ratio, pageData.efficiencyRatioMapping) &&
                    <section className="Ratios shadowBlock">
                            <table className="finData">
                                <tbody>
                                    <tr>
                                <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">Efficiency Ratios</td>
                                {pageData.ratio.map(data => (
                                    <td className="columnHeading">{data.date_header}</td>
                                        ))}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.efficiencyRatioMapping.map(key => (
                                        <>
                                            <tr>
                                                <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                    <div className="headingWithPopup">
                                                        <div>
                                                            {key.field_prompt}
                                                        </div>
                                                        <div>
                                                            <Popup
                                                                className="popupChart"
                                                                trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                content={<RowChart mapkey={key.field_name} mapData={pageData.ratio} />}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>

                                                {pageData.ratio.map(dataKey => (

                                                    <td className="tabledata SubHeader"> {dataKey[key.field_name]}</td>

                                                ))}
                                            </tr>
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </section>
                    }
                {pageData.ratio &&
                    <section className="Ratios shadowBlock" >
                            
                            <table className="finData">
                                <tbody>
                                    <tr>
                                <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">Proprietary Ratios</td>
                                {pageData.ratio.map(data => (
                                    <td className="columnHeading">{data.date_header}</td>
                                        ))}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.proprietaryRatioMapping.map(key => (
                                        <>
                                            <tr>
                                                <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                    <div className="headingWithPopup">
                                                        <div>
                                                            {key.field_prompt}
                                                        </div>
                                                        <div>
                                                            <Popup
                                                                className="popupChart"
                                                                trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                content={<RowChart mapkey={key.field_name} mapData={pageData.ratio} />}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>

                                                {pageData.ratio.map(dataKey => (

                                                    <td className="tabledata SubHeader"> {dataKey[key.field_name]}</td>

                                                ))}
                                            </tr>
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>

                        </section>
                }

                
                {pageData.ratio &&
                    <div>

                    <section className="Ratios shadowBlock" style={{ marginBottom: "100px"}}>
                        <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", paddingRight: "10px", marginBottom: "10px" }}>
                            <div style={{ fontWeight: "600" }}>&nbsp;</div>


                            <Popup
                                className="popupChart"
                                trigger={
                                    <svg
                                        onClick={() => showManageRatios()}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 256 256"
                                        height="30px"
                                        width="30px"
                                        {...props}
                                    >
                                        <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                                    </svg>
                                }
                                content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                                style={{ zIndex: "999" }}
                            />

                            <HelpModal title="Manage Your Ratios" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} disableButton={disableButton} source="histRatios">
                                <ManageRatios ratioData={props.ratioData} source="histRatios" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                            </HelpModal>
                        </div>
                        <table className="finData">
                            <tbody>
                                <tr>
                                    <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">User Ratios</td>
                                    {pageData.ratio.map(data => (
                                        <td className="columnHeading">{data.date_header}</td>
                                    ))}
                                </tr>
                            </tbody>
                        <tbody>
                            {pageData.userRatioMapping && pageData.userRatioMapping.map(key => (
                                    <>
                                        <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                    <div>
                                                        {key.field_prompt}
                                                    </div>
                                                    <div>
                                                        <Popup
                                                            className="popupChart"
                                                            trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                            content={<RowChart mapkey={key.field_name} mapData={pageData.ratio} />}
                                                        />
                                                    </div>
                                                </div>
                                            </td>

                                            {pageData.ratio.map(dataKey => (

                                                <td className="tabledata SubHeader"> {dataKey[key.field_name]}</td>

                                            ))}
                                        </tr>
                                    </>
                                ))
                                }
                            </tbody>
                        </table>
                    </section>
                </div>
                    
                }
                        
                </>
            }
            {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>

    );
}

export default Ratios;