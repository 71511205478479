import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
//import './Peers.css'
import Loading from "./Company/Loading"
import useAxiosPrivate from "./hooks/useAxiosPrivate"
import InvestorSectorChart from "./InvestorSectorChart"
import InvestmentHistory from "./InvestmentHistory"
import HelpModal from './Company/Financials/HelpModal'
import useAuth from './hooks/useAuth';
import UpArrow from "./icon/UpArrow"
import DownArrow from "./icon/DownArrow"
import { downloadExcel } from "react-export-table-to-excel";
import ExportSVG from './icon/ExportSVG'

function Investor(props) {
    const { investor } = useParams();
    const [pageData, setpageData] = useState({});
    const [stock, setStock] = useState();
    const [show, setShow] = useState(false);
    const { auth } = useAuth();
    const getUrl = (url) => {
        return "/Company/" + url
    }

    const viewHelper = (symbol) => {
        setStock(symbol)
        setShow(true)
    }

    const axiosPrivate = useAxiosPrivate();

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('TableData'))
        let tempData = itemsData["investments"]
        if (finalSortData["pointer"]) {
            if (['sector', 'industry', 'activity'].includes(pointer[0])) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['sector', 'industry', 'activity'].includes(pointer[0])) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }
        let finalData = itemsData
        finalData["investments"] = tempData
        setpageData(finalData)
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replace('₹', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replace('₹', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replace('₹', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replace('₹', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replace('₹', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replace('₹', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replace('₹', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replace('₹', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        console.log("inside sort")
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    } 

    const companyDetails = async () => {
        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/investors/" + investor +"?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const cdresponse = await axiosPrivate.get(url);
        let userData = cdresponse.data
        document.title = cdresponse.data.name + "- Superinvestor Portfolio | Shareholdings & investment value";
        localStorage.setItem("TableData", JSON.stringify(userData));
        setpageData(userData)
    }

    const getSectorURL = (url) => {
        return "/Sector/" + url
    }

    const getIndustryURL = (url) => {
        return "/Industry/" + url
    }


    function handleDownloadExcel() {

        let header = ["S. No.", "Stock Symbol", "Name", "% of Portfolio", "Activity", "Change", "Change%", "Shares", "Current Price", "Investment Value (Cr)", "52W Low", "52W High", "% Above 52W low", "Industry", "Sector"]
        let body = []

        pageData?.investments.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_symbol)
            tempData.push(item.name)
            tempData.push(item.pctOfProfile)
            tempData.push(item.activity)
            tempData.push(item.change)
            tempData.push(item.pctChange)
            tempData.push(item.share)
            tempData.push(item.currentPrice)
            tempData.push(item.investmentValue)
            tempData.push(item.low52)
            tempData.push(item.high52)
            tempData.push(item.pctAbove52WLow)
            tempData.push(item.industry)
            tempData.push(item.sector)
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Investor",
            sheet: "Investor",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }


    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        setTimeout(function () { companyDetails(); }, 50);
    }, [auth.user])




    return (
        <div className="PeersMain">
            {auth.user &&
            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>
                    <section className="optionsFinData">
                        <div className="leftAlign growthCompanyName" style={{ paddingLeft: "0px", fontWeight: "600" }}>
                            {pageData.name}
                        </div>
                        <div className="rightAlign">
                            <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{cursor: "pointer"}}>
                                <ExportSVG />
                            </div>
                        </div>
                    </section>


                    <HelpModal title="Investment History" onClose={() => setShow(false)} show={show}>
                        <InvestmentHistory investorID={investor} stock={stock}/>
                    </HelpModal>
                    <div style={{ maxHeight: "600px", overflowY: "auto", backgroundColor: "white", borderRadius: "10px" }}>
                        <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                            <thead style={{ backgroundColor: "white", zIndex: "20" }}>
                                <tr>
                                    <th className="PeerColumnHeading leftPeer intersection" style={{ fontWeight: 600, }}><div>S.&nbsp;No.</div></th>
                                    <th className="PeerColumnHeading" style={{ whiteSpace: "nowrap" }}><div>Stock Symbol</div></th>
                                    <th className="PeerColumnHeading"><div>Name</div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['pctOfProfile'])}><div>% of Portfolio</div><div style={{ paddingTop: "5px" }}>{SortIndicator("pctOfProfile")[0] ? <div>{SortIndicator("pctOfProfile")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['activity'])}><div>Activity</div><div style={{ paddingTop: "5px" }}>{SortIndicator("activity")[0] ? <div>{SortIndicator("activity")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['change'])}><div>Change</div><div style={{ paddingTop: "5px" }}>{SortIndicator("change")[0] ? <div>{SortIndicator("change")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['pctChange'])}><div>Change%</div><div style={{ paddingTop: "5px" }}>{SortIndicator("pctChange")[0] ? <div>{SortIndicator("pctChange")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['share'])}><div>Shares</div><div style={{ paddingTop: "5px" }}>{SortIndicator("share")[0] ? <div>{SortIndicator("share")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['currentPrice'])}><div>Current Price</div><div style={{ paddingTop: "5px" }}>{SortIndicator("currentPrice")[0] ? <div>{SortIndicator("currentPrice")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['investmentValue'])}><div>Investment Value (Cr)</div><div style={{ paddingTop: "5px" }}>{SortIndicator("investmentValue")[0] ? <div>{SortIndicator("investmentValue")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['low52'])}><div>52W Low</div><div style={{ paddingTop: "5px" }}>{SortIndicator("low52")[0] ? <div>{SortIndicator("low52")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['high52'])}><div>52W High</div><div style={{ paddingTop: "5px" }}>{SortIndicator("high52")[0] ? <div>{SortIndicator("high52")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['pctAbove52WLow'])}><div>% Above 52W low</div><div style={{ paddingTop: "5px" }}>{SortIndicator("pctAbove52WLow")[0] ? <div>{SortIndicator("pctAbove52WLow")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['industry'])}><div>Industry</div><div style={{ paddingTop: "5px" }}>{SortIndicator("industry")[0] ? <div>{SortIndicator("industry")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th className="PeerColumnHeading PeerTabledataCenter"><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "start", paddingTop: "5px" }} onClick={() => showSorted(['sector'])}><div>Sector</div><div style={{ paddingTop: "5px" }}>{SortIndicator("sector")[0] ? <div>{SortIndicator("sector")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>

                                </tr>
                            </thead>
                            <tbody>
                            {pageData.investments.map((dataKey, index) => (
                                    <tr>
                                    <td className="leftPeer">{index + 1}</td>
                                    <td className="PeerTabledataLeft SubHeader"><div className="StockLink" onClick={() => viewHelper(dataKey.stock_symbol)}>{dataKey.stock_symbol}</div></td>
                                    <td className="PeerTabledataLeft SubHeader">{dataKey.name}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.pctOfProfile}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.activity}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.change}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.pctChange}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.share}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.currentPrice}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.investmentValue}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.low52}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.high52}</td>
                                    <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.pctAbove52WLow}</td>
                                    <td className="PeerTabledataLeft SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getIndustryURL(dataKey.industry)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.industry}</a></td>
                                    <td className="PeerTabledataLeft SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getSectorURL(dataKey.sector)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.sector}</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                        <div>
                            <InvestorSectorChart investor={investor }/>
                        </div>
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
                </section>
            }
            {!auth.user &&
                <section className="Peers">
                    Please Login to view this section.
                </section>
            }
        </div>
    );
}

export default Investor;