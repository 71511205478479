import React, { useState, useEffect } from 'react';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { OptionLookup, getKeyLookup} from './ManageScreener'
import Select from 'react-select';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
    position: "fixed",
    bottom: "10px",
    right: "10px",
    paddingTop: "150px"
};

const customExceptionList = ["Sector", "Industry", "relative_rank"]

function ManageScreenerMini(props) {

    
    const CustomException = (key) => {
        if (customExceptionList.includes(key)) {
            return false
        } else {
            return true
        }
    }
    const getDefaultOption = (code) => {
        let tempData = OptionLookup(code)
        try {

            let i = 0;
            while (i < tempData.length) {
                if (tempData[i]['value'].toString() === props.filter[code]["Value"].toString()) {
                    return { "value": props.filter[code]["Value"], "label": tempData[i]['label'] }
                }
                i++;
            }
        } catch (err) {
            return tempData[0]
        }



    }

    const handlChange = (inputData, source) => {
        if (inputData["value"] === "02" && CustomException(source)) {
            props.filter[source] = { "Source": "Custom", "minValue": "", "maxValue": "", "Value": "02" }
            props.setShow(true)
        } else {
            props.filter[source] = { "Source": "PreSelected", "Value": inputData["value"] }
            props.setShow(false)
        }
    }

    const handleMinChange = (event) => {
        props.filter[props.item]["minValue"] = parseInt(event.target.value)
        props.setMinValue(event.target.value)
    }

    const handleMaxChange = (event) => {
        props.filter[props.item]["maxValue"] = parseInt(event.target.value)
        props.setMaxValue(event.target.value)
    }



    const runQuery = () => {
        props.run()
        props.close()
    }


    useEffect(() => {
    }, []);

    return (
        <>
            {props.item &&
                <div style={styles}>
                <Modal open={props.state} onClose={() => props.close()}>
                    <div style={{ minHeight: "400px", minWidth: "650px"}}>
                        <div><h2>{getKeyLookup(props.item)}</h2></div>
                        <div style={{ minWidth: "250px", margin: "25px 80px", border: "1px solid #f5f5f5" }}>
                        <Select
                            defaultValue={getDefaultOption(props.item)}
                            options={OptionLookup(props.item)}
                            onChange={event => handlChange(event, props.item)}
                            id={props.item}
                        />
                        </div>
                        <div>
                        {props.show &&
                                <div>
                                    <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", padding: "0px 80px"}}>
                                    <div>
                                        <input className="customInputMini" type="number" placeholder="minValue" defaultValue={props.filter[props.item]["minValue"]} value={props.minValue} onChange={(e) => handleMinChange(e) } />
                                    </div>
                                    <br />
                                    <div>
                                        <input className="customInputMini" type="number" placeholder="maxValue" defaultValue={props.filter[props.item]["maxValue"]} value={props.maxValue} onChange={(e) => handleMaxChange(e)}/>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                        <div style={{ float: "right", marginTop: "250px" }}>
                            <button onClick={() => runQuery()}>Update Results</button>
                        </div>
                    </div>
                    </Modal>
                    
                </div>
            }
        </>
    );
}


export default ManageScreenerMini;