import React, { useEffect } from 'react'; 


function PageNotFound(props) {
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])

    return (
        <div className="PageNotFound">
            <div>
                <div className="commonContainer" >
                    <div className="AboutUs-container" style={{ textAlign: "center", marginTop: "100px" }}>
                        Page you are looking for does not exist.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;