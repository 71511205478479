import React from 'react';


function InvestingFrameworkMobile() {
    window.scrollTo(0, 0)
    document.title = '"3M" Investing Framework | Long term investment strategy taught by Warren Buffett | Moat, Management & Margin of Safety | Warren Buffett�s Value Investing | Warren Buffett�s Investing Strategy';
    return (
        <div className="Disclaimer">
            <div style={{ border: "1px solid #ccc", borderRadius: "10px", paddingLeft: "40px", paddingRight: "40px" }}>
                <br />
                <span style={{ color: "#0062B1", fontSize: "14px", fontWeight: "600" }} >Our "3M" Investment Framework</span><br />
                <p style={{ fontSize: "13px" }} >Our investing framework is based on long-term investment strategy taught by legendry
                investor Warren Buffett. We famously call it "3M" Investing Framework.
                </p>
                <p style={{ fontSize: "13px" }} >
                    It's based on following fundamental principles taught by Warren Buffett.
                </p>
                <p style={{ fontSize: "13px" }} >
                    <b>MOAT</b><br />
                    <ul>
                        <li>Understand the company's core business model and how it generates revenue. </li>
                        <li>The business shall have competitive durable advantage that protects business from competitors.</li>
                        <li>It grows revenue, profit, EPS, shareholder equity and free cash flow consistently.</li>
                    </ul>
                    <br />
                </p>
                <p style={{ fontSize: "13px" }} >
                    <b>MANAGEMENT</b><br />
                    <ul>
                        <li>The business should be run by efficient & result driven Management team with honesty, integrity, and effective corporate governance.</li>
                        <li>Analyze how the management allocates resources, reinvests profits, and manages debt.</li>
                        <li>Management Performance should reflect in key financial metrics.</li>
                        <li>Predictable and high ROE, ROCE, ROA, consistent & high Profit Margin and low or manageable debt level.</li>
                    </ul>
                    <br />
                </p>
                <p style={{ fontSize: "13px" }} >
                    <b>MARGIN OF SAFETY (MOS)</b><br />
                    <ul>
                        <li>Determine Intrinsic value using various valuation techniques such as relative valuation, DCF etc.</li>
                        <li>Identify mispricing as buying opportunities in the market. </li>
                        <li>Buy stocks at great margin of safety to achieve superior compounded annual returns over a long period.</li>
                    </ul>
                    <br />
                </p>

                <p style={{ fontSize: "13px" }} >
                    <b>"3M" Investing Framework </b><br />
                Based on lessons from Warren Buffett's Investing Framework:
                <br />


                    <div className="StratergyContainer" style={{ textAlign: "left" }}>
                        <div className="StratergyContainerChild">
                            <div style={{ fontSize: "13px", textAlign: "center", backgroundColor: "#36454f", color: "white", fontWeight: "600" }}>Moat</div>
                            <div className="StratergyContainerChildContent">
                                <ol>
                                    <li style={{ fontSize: "13px" }} >Identify business that you are capable of understanding</li><br />
                                    <li style={{ fontSize: "13px" }} >Analyze your business along 5 parameters: Product or Service, Customer, Form, Geography and Growth</li><br />
                                    <li style={{ fontSize: "13px" }} >Perform competitive position analysis & identify economic Moat like Brand, Price, Secret, Monopoly and Network</li><br />
                                    <li style={{ fontSize: "13px" }} >Validate evidence of strong Moat by analyzing financial statements</li><br />
                                    <li style={{ fontSize: "13px" }} >Predictable growth in Revenue, Profit, EPS and Free Cash Flow</li><br />
                                </ol>
                            </div>
                        </div>
                        <div className="StratergyContainerChild">
                            <div style={{ fontSize: "13px", textAlign: "center", backgroundColor: "#36454f", color: "white", fontWeight: "600" }}>Management</div>
                            <div className="StratergyContainerChildContent">
                                <ol>
                                    <li style={{ fontSize: "13px" }} >A great business run by honest management with track record of effective corporate governance</li><br />
                                    <li style={{ fontSize: "13px" }} >Analyze Management team performance using well defined checklist</li><br />
                                    <li style={{ fontSize: "13px" }} >Management performance reflecting in key financial metrics</li><br />
                                    <li style={{ fontSize: "13px" }} >Predictable & high Return on Equity(ROE), Return on Capital Employed(ROCE), high Profit Margin and low or manageable Debt level preferably less than 2 years of net earnings</li><br />
                                </ol>
                            </div>
                        </div>
                        <div className="StratergyContainerChild">
                            <div style={{ fontSize: "13px", textAlign: "center", backgroundColor: "#36454f", color: "white", fontWeight: "600" }} >Margin of Saftey</div>
                            <div className="StratergyContainerChildContent">
                                <ol>
                                    <li style={{ fontSize: "13px" }} >Determine Intrinsic value using discounted cash flow(DCF), EPS, excess return(ER) or Graham's Number</li><br />
                                    <li style={{ fontSize: "13px" }} >Perform relative valuation like P/E, P/S and P/B comparing current ratios and historical ratios and within companies with similar business model and size</li><br />
                                    <li style={{ fontSize: "13px" }} >Identify mispricing as buying opportunities in the market </li><br />
                                    <li style={{ fontSize: "13px" }} >Buy stocks at great margin of saftey to achieve more than 15% Compounded annual returns on consistent basis</li><br />
                                </ol>
                            </div>
                        </div>
                    </div>


                </p>
            </div>
        </div>
    );
}
export default InvestingFrameworkMobile;