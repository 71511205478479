import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from "react-google-charts";
import Select from 'react-select';
import './Charts.css'


function Charts(props) {
    const [dataBSC, setdataBSC] = useState([]);
    const [dataBSS, setdataBSS] = useState([]);
    const [dataARC, setdataARC] = useState([]);
    const [dataARS, setdataARS] = useState([]);
    const [dataCFC, setdataCFC] = useState([]);
    const [dataCFS, setdataCFS] = useState([]);
    const [dataRAC, setdataRAC] = useState([]);
    const [dataRAS, setdataRAS] = useState([]);
    const [dataHLD, setdataHLD] = useState([]);
    const [dataBSCMap, setdataBSCMap] = useState([]);
    const [dataBSSMap, setdataBSSMap] = useState([]);
    const [dataARCMap, setdataARCMap] = useState([]);
    const [dataARSMap, setdataARSMap] = useState([]);
    const [dataCFCMap, setdataCFCMap] = useState([]);
    const [dataCFSMap, setdataCFSMap] = useState([]);
    const [dataRACMap, setdataRACMap] = useState([]);
    const [dataRASMap, setdataRASMap] = useState([]);
    const [dataHLDMap, setdataHLDMap] = useState([]);
    const [userOptions, setuserOptions] = useState([]);
    const [mapData, setMapData] = useState([])
    const [readyToDisplay, setreadyToDisplay] = useState(false);
    const [serviceList, setServiceList] = useState([{ service: {value: "", label: "Please select"} }]); 
    const options = {
        chart: {
            title: "User Analysis",
            subtitle: " ",
        },
    };

    const handleServiceChange = (e, index) => {
        const list = [...serviceList];
        list[index]['service'] = e;
        setServiceList(list);
        handleRenderMap();
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, { service: { value: "", label: "Please select" } }]);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    function handleRenderMap() {
        const mapDataVariableList = []
        const mapLookupList = []
        let tempList = []
        let tempData = []
        serviceList.map(item => {
            mapDataVariableList.push(item.service.value.substring(4, item.service.value.length))
            mapLookupList.push(item.service.value.substring(0, 3))
        })  
        dataBSC.map(dateItem => (
            tempList.push([dateItem['date_header']])
        ))
        console.log(mapDataVariableList)
        mapLookupList.map((element, index) => {
            if (element === "BSC") {
                tempData = dataBSC
            } else if (element === "BSS") {
                tempData = dataBSS
            } else if (element === "ARC") {
                tempData = dataARC
            } else if (element === "ARS") {
                tempData = dataARS
            } else if (element === "CFC") {
                tempData = dataCFC
            } else if (element === "CFS") {
                tempData = dataCFS
            } else if (element === "RAC") {
                tempData = dataRAC
            } else if (element === "RAS") {
                tempData = dataRAS
            } else if (element === "HLD") {
                tempData = dataHLD
            }
            console.log(element)
            tempData.map((mapData, mapIndex) => {
                tempList[mapIndex].push(mapData[mapDataVariableList[index]])
            })
        })
        const finalVarList = ["Year", ...mapDataVariableList]
        const finalList = [finalVarList, ...tempList]
        finalList.map((finalData, finalIndex) => {
            if (finalVarList.length !== finalData.length) {
                finalList.splice(finalIndex, 1)
            }
        })
        setMapData(finalList)
        setreadyToDisplay(true) 
    };


    const companyDetails = async () => {
        const url = './' + props.compSymbol + "_combined_all_curated.json";
        const cdresponse = await axios.get(url);
        setdataBSC(await cdresponse.data['consolidated']['balance-sheet']['data'])
        setdataBSS(await cdresponse.data['standalone']['balance-sheet']['data'])
        setdataARC(await cdresponse.data['consolidated']['profit-loss']['data'])
        setdataARS(await cdresponse.data['standalone']['profit-loss']['data'])
        setdataCFC(await cdresponse.data['consolidated']['cash-flow']['data'])
        setdataCFS(await cdresponse.data['standalone']['cash-flow']['data'])
        setdataRAC(await cdresponse.data['consolidated']['ratios']['data'])
        setdataRAS(await cdresponse.data['standalone']['ratios']['data'])
        setdataHLD(await cdresponse.data['both']['shareholding']['data'])
        setdataBSCMap(await cdresponse.data['consolidated']['balance-sheet']['extended_mapping'])
        setdataBSSMap(await cdresponse.data['standalone']['balance-sheet']['extended_mapping'])
        setdataARCMap(await cdresponse.data['consolidated']['profit-loss']['extended_mapping'])
        setdataARSMap(await cdresponse.data['standalone']['profit-loss']['extended_mapping'])
        setdataCFCMap(await cdresponse.data['consolidated']['cash-flow']['extended_mapping'])
        setdataCFSMap(await cdresponse.data['standalone']['cash-flow']['extended_mapping'])
        setdataRACMap(await cdresponse.data['consolidated']['ratios']['extended_mapping'])
        setdataRASMap(await cdresponse.data['standalone']['ratios']['extended_mapping'])
        setdataHLDMap(await cdresponse.data['both']['shareholding']['extended_mapping'])
        setuserOptions([])
        

        Object.keys(dataBSCMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "BSC ".concat(key), "label": "BalanceSheet (Consolidated) :".concat(key) }])
            dataBSCMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "BSC ".concat(item), "label": "BalanceSheet (Consolidated) :".concat(item) }])
                ))
        })
        Object.keys(dataBSSMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "BSS ".concat(key), "label": "BalanceSheet (Standalone) :".concat(key) }])
            dataBSSMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "BSS ".concat(item), "label": "BalanceSheet (Standalone) :".concat(item) }])
            ))
        })
        Object.keys(dataARCMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "ARC ".concat(key), "label": "Annual Result (Consolidated) :".concat(key) }])
            dataARCMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "ARC ".concat(item), "label": "Annual Result (Consolidated) :".concat(item) }])
            ))
        })
        Object.keys(dataARSMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "ARS ".concat(key), "label": "Annual Result (Standalone) :".concat(key) }])
            dataARSMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "ARS ".concat(item), "label": "Annual Result (Standalone) :".concat(item) }])
            ))
        })
        Object.keys(dataCFCMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "CFC ".concat(key), "label": "Cashflow (Consolidated) :".concat(key) }])
            dataCFCMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "CFC ".concat(item), "label": "Cashflow (Consolidated) :".concat(item) }])
            ))
        })
        Object.keys(dataCFSMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "CFS ".concat(key), "label": "Cashflow (Standalone) :".concat(key) }])
            dataCFSMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "CFS ".concat(item), "label": "Cashflow (Standalone) :".concat(item) }])
            ))
        })
        Object.keys(dataRACMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "RAC ".concat(key), "label": "Ratios (Consolidated) :".concat(key) }])
            dataRACMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "RAC ".concat(item), "label": "Ratios (Consolidated) :".concat(item) }])
            ))
        })
        Object.keys(dataRASMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "RAS ".concat(key), "label": "Ratios (Standalone) :".concat(key) }])
            dataRASMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "RAS ".concat(item), "label": "Ratios (Standalone) :".concat(item) }])
            ))
        })
        Object.keys(dataHLDMap).map(key => {
            setuserOptions(userOptions => [...userOptions, { "value": "HLD ".concat(key), "label": "Holdings :".concat(key) }])
            dataHLDMap[key].map(item => (
                setuserOptions(userOptions => [...userOptions, { "value": "HLD ".concat(item), "label": "Holdings :".concat(item) }])
            ))
        })
        setreadyToDisplay(true) 
    }

    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, readyToDisplay])
    return (
        <div className="Charts">
            <section>
                <div className="ChartInputRow">
                    <div className="ChartLeft">
                        {serviceList.map((singleService, index) => (
                            <>
                        <Select
                                className="ChartInput"
                                classNamePrefix="select"
                                defaultValue={userOptions[0]}
                                name="User Input"
                                options={userOptions}
                                value={singleService.service}
                                    onChange={(e) => {
                                        handleServiceChange(e, index);
                                    }}
                                required
                            />
                                {
                                    serviceList.length - 1 === index && serviceList.length < 4 && (
                                        <button
                                            type="button"
                                            onClick={handleServiceAdd}
                                            className="add-btn"
                                        >
                                            <span>New Item</span>
                                        </button>
                                    )
                                }
                                {serviceList.length !== 1 && (
                                    <button
                                        type="button"
                                        onClick={() => handleServiceRemove(index)}
                                        className="remove-btn"
                                    >
                                        <span>Remove</span>
                                    </button>
                                )}

                                </>

                        ))
                        }
                    </div>
                    <div className="ChartRight">
                        <Chart
                            chartType="ComboChart"
                            width="100%"
                            height="400px"
                            data={mapData}
                            options={options}

                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Charts;