import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import "./dcf.css"
import Chart from "react-apexcharts";
import Icon from "../../icon/Icon"
import Loading from "./Loading"

export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};


function Dcf(props) {
    const [pageData, setpageData] = useState({
        "price": 0,
        "mos": 0,
        "pvRatio": 0,
        "avgFCFPerYear": 0,
        "assumedFCFGRNextFiveYear": 0,
        "assumedFCFGRFiveTenYear": 0,
        "terminalGrowthRate": 0,
        "discountRate": 0,
        "debt": 0,
        "cashEquivalent": 0,
        "shareOutstanding": 0,
        "dcfInput": [],
        "discountedFCF": 0,
        "discountedTerminal": 0,
        "valueOfFirm": 0,
        "marketCap": 0,
        "ivps": 0,
        "currentYear": 0,
        "mapData": [],

    });
    const axiosPrivate = useAxiosPrivate();
    const [readyToDisplay, setreadyToDisplay] = useState(false)
    function getPercentValue(data) {
        if (data) {
            let finalData = data.replace("%", "");
            return parseFloat(finalData)/100
        } else {
            return data
        }

    }
    const forceDefault = (data) => {
        if (data) {
            return data
        } else {
            return parseFloat(0.0)
        }
    }

    function getDisplayValue(data) {
        if (data) {
            return (Math.round(data * 100) / 100).toLocaleString('en-IN')
        } else {
            return ""
        }
    }

    const geMOS = (data) => {
        if (data === 0) {
            return "-"
        } else {
            let tempMOS = Math.round(data * 100) / 100
            return tempMOS.toString() + "%"
        }

    }
    const getColor = (data) => {
        let tempData = parseFloat(data)
        if (tempData < 10.0) {
            return "#FF5733"
        } else if (tempData >= 10.0 && tempData < 30.0) {
            return "#E49B0F"
        } else {
            return "#00A36C"
        }
    }
    function evaluateDCF() {
        let var_price = pageData.price
        let rate = 0
        let lastFCF = pageData.avgFCFPerYear
        let outputDiscountedFCF = 0
        let var_dcf_input = [{ "year": "Current", "fcf": lastFCF, "GrowthRate": "", "FCFPV": "" }, { "year": "+1", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+2", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+3", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+4", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+5", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+6", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+7", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+8", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+9", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+10", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "Terminal", "fcf": "", "GrowthRate": "", "FCFPV": "" }]
        let outputDiscountedTerminal = 0
        let varLabel = [String(pageData.currentYear)]
        let varMapData = [Math.round(pageData.avgFCFPerYear)]
        var_dcf_input.map((fcfData, fcfIndex) => {
            if (fcfIndex >= 1) {
                if (fcfIndex < 6) {
                    rate = pageData.assumedFCFGRNextFiveYear / 100
                } else if (fcfIndex < 11) {
                    rate = pageData.assumedFCFGRFiveTenYear / 100
                } else {
                    rate = pageData.terminalGrowthRate/100
                }
                let varFCF = lastFCF * (1 + rate)
                let finalRate = rate
                lastFCF = varFCF
                let varFCFPV = 0
                if (fcfIndex < 11) {
                    varFCFPV = lastFCF / Math.pow((1 + pageData.discountRate / 100), (fcfIndex))
                    outputDiscountedFCF = outputDiscountedFCF + varFCFPV
                } else {
                    varFCFPV = lastFCF / (pageData.discountRate / 100 - rate)
                    outputDiscountedTerminal = varFCFPV / Math.pow((1 + pageData.discountRate / 100), (10))
                }
                let finalFCFPV = 0
                if (fcfIndex < 11) {
                    finalFCFPV = varFCFPV
                } else {
                    finalFCFPV = outputDiscountedTerminal
                }
                varMapData.push(Math.round(lastFCF))
                varLabel.push(String(pageData.currentYear + fcfIndex))
                fcfData.fcf = Math.round(lastFCF)
                fcfData.GrowthRate = (finalRate * 100).toFixed(2)
                fcfData.FCFPV = Math.round(finalFCFPV)
            }
        })
        let firmValue = outputDiscountedFCF + outputDiscountedTerminal + parseFloat(pageData.cashEquivalent) - parseFloat(pageData.debt)
        let firmIvps = firmValue / pageData.shareOutstanding
        let pv = pageData.price / firmIvps
        let mos = ""
        console.log(firmIvps)
        if (firmIvps > 0.0) {
            let tempVal = ((firmIvps - var_price) / firmIvps) * 100
            mos = tempVal.toString()
        } else {
            mos = 0
        }
        let updatedValue = {};
        updatedValue = {
            "mos": mos,
            "dcfInput": var_dcf_input,
            "discountedFCF": Math.round(outputDiscountedFCF),
            "discountedTerminal": Math.round(outputDiscountedTerminal),
            "valueOfFirm": Math.round(firmValue),
            "ivps": Math.round(firmIvps),
            "pvRatio": Math.round(pv * 100) / 100,
            "mapData": {
                options: {
                    chart: { id: "dcf-chart" },
                    colors: ['#9cb2cf', '#C9D8E9'],
                    dataLabels: { enabled: false },
                    xaxis: { categories: varLabel },
                    yaxis: [{ forceNiceScale: true }]

                },
                series: [{ name: "DCF Projection", data: varMapData }]
            }
        };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));



    }
    const handlePlus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedFCFGRNextFiveYear)
            value = value + 0.5
            if (value >= 30 ) {
                value = 30
            }
            updatedValue = { "assumedFCFGRNextFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedFCFGRFiveTenYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedFCFGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "grterm") {
            let value = parseFloat(pageData.terminalGrowthRate)
            value = value + 0.5
            if (value >= 10) {
                value = 10
            }
            updatedValue = { "terminalGrowthRate": value.toFixed(2)};
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "discountRate": value.toFixed(2)};
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const handleMinus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedFCFGRNextFiveYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedFCFGRNextFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedFCFGRFiveTenYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedFCFGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "grterm") {
            let value = parseFloat(pageData.terminalGrowthRate)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "terminalGrowthRate": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const handleFCFRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedFCFGRNextFiveYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleFCFTenRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedFCFGRFiveTenYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleFCFTerminalChange = e => {
        let updatedValue = {};
        updatedValue = { "terminalGrowthRate": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDiscountRateChange = e => {
        let updatedValue = {};
        updatedValue = { "discountRate": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };

    const handleAvgFCFChange = e => {
        let updatedValue = {};
        updatedValue = { "avgFCFPerYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDebtChange = e => {
        let updatedValue = {};
        updatedValue = { "debt": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleCashChange = e => {
        let updatedValue = {};
        updatedValue = { "cashEquivalent": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleShareOutstandingChange = e => {
        let updatedValue = {};
        updatedValue = { "shareOutstanding": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDCFCalculation = () => {
        evaluateDCF()
    }
    const getDefaultInput = async () => {
        setpageData({
            "price": 0,
            "mos": 0,
            "pvRatio": 0,
            "avgFCFPerYear": 0,
            "assumedFCFGRNextFiveYear": 0,
            "assumedFCFGRFiveTenYear": 0,
            "terminalGrowthRate": 0,
            "discountRate": 0,
            "debt": 0,
            "cashEquivalent": 0,
            "shareOutstanding": 0,
            "dcfInput": [],
            "discountedFCF": 0,
            "discountedTerminal": 0,
            "valueOfFirm": 0,
            "marketCap": 0,
            "ivps": 0,
            "currentYear": 0,
            "mapData": [],

        })
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);


        let var_avg_fcf = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR005)) / 10
        var currentTime = new Date()
        let var_current_year = currentTime.getFullYear()
        let var_dcf_input = [{
            "year": "Current",
            "fcf": Math.round(var_avg_fcf),
            "GrowthRate": "",
            "FCFPV": ""
        }, { "year": "+1", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+2", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+3", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+4", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+5", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+6", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+7", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+8", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+9", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+10", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "Terminal", "fcf": "", "GrowthRate": "", "FCFPV": "" }]
        let var_assumed_fcf_gr_next_five_year = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR003))
        let var_assumed_fcf_gr_five_ten_year = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR004))
        let var_terminal_growth_rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR011))
        let var_discount_rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR010))
        let var_debt = (forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR008))).toFixed(0)
        let var_cash_equiv = (forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR009))).toFixed(0)
        let var_share = (forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR017))).toFixed(0)
        let var_market_cap = (forceDefault(parseFloat(cdresponse.data.market_cap))).toFixed(0)
        let var_price = (forceDefault(parseFloat(cdresponse.data.close_price))).toFixed(0)


        let rate = 0
        let lastFCF = var_avg_fcf
        let outputDiscountedFCF = 0
        let outputDiscountedTerminal = 0
        let varLabel = [String(var_current_year)]
        let varMapData = [Math.round(var_avg_fcf)]
        var_dcf_input.map((fcfData, fcfIndex) => {
            if (fcfIndex >= 1) {
                if (fcfIndex < 6) {
                    rate = var_assumed_fcf_gr_next_five_year/100
                } else if (fcfIndex < 11) {
                    rate = var_assumed_fcf_gr_five_ten_year/100
                } else {
                    rate = var_terminal_growth_rate/100
                }
                let varFCF = lastFCF * (1 + rate)
                let finalRate = rate
                lastFCF = varFCF
                let varFCFPV = 0
                if (fcfIndex < 11) {
                    varFCFPV = lastFCF / Math.pow((1 + var_discount_rate / 100), (fcfIndex))
                    outputDiscountedFCF = outputDiscountedFCF + varFCFPV
                } else {
                    varFCFPV = lastFCF / (var_discount_rate / 100 - finalRate)
                    outputDiscountedTerminal = varFCFPV / Math.pow((1 + var_discount_rate / 100), (10))
                }
                let finalFCFPV = 0
                if (fcfIndex < 11) {
                    finalFCFPV = varFCFPV
                } else {
                    finalFCFPV = outputDiscountedTerminal
                }
               // let finalFCFPV = varFCFPV
                varMapData.push(Math.round(lastFCF))
                varLabel.push(String(var_current_year + fcfIndex))
                fcfData.fcf = Math.round(lastFCF)
                fcfData.GrowthRate = (finalRate * 100).toFixed(2)
                fcfData.FCFPV = Math.round(finalFCFPV)
            }
        })

        let firmValue = outputDiscountedFCF + outputDiscountedTerminal - parseFloat(var_debt) + parseFloat(var_cash_equiv)
        let firmIvps = firmValue / var_share
        let pv = var_price / firmIvps
        let mos = 0
        if (firmIvps > 0) {
            mos = ((firmIvps - var_price) / firmIvps) * 100
        } else {
            mos = 0
        }
        setpageData(
            {
                "price": var_price,
                "mos": mos,
                "pvRatio": Math.round(pv * 100) / 100,
                "avgFCFPerYear": Math.round(var_avg_fcf),
                "assumedFCFGRNextFiveYear": var_assumed_fcf_gr_next_five_year,
                "assumedFCFGRFiveTenYear": var_assumed_fcf_gr_five_ten_year,
                "terminalGrowthRate": var_terminal_growth_rate,
                "discountRate": var_discount_rate,
                "debt": var_debt,
                "cashEquivalent": var_cash_equiv,
                "shareOutstanding": var_share,
                "dcfInput": var_dcf_input,
                "discountedFCF": Math.round(outputDiscountedFCF),
                "discountedTerminal": Math.round(outputDiscountedTerminal),
                "valueOfFirm": Math.round(firmValue),
                "marketCap": Math.round(var_market_cap),
                "ivps": Math.round(firmIvps),
                "currentYear": var_current_year,
                "mapData": {
                    options: {
                        chart: { id: "dcf-chart" },
                        colors: ['#9cb2cf', '#C9D8E9'],
                        dataLabels: { enabled: false },
                        xaxis: { categories: varLabel },
                        yaxis: [{ forceNiceScale: true }]

                    },
                    series: [{ name: "DCF Projection", data: varMapData }]
                }
            }
        )
        console.log(pageData?.mapData)
    }


    useEffect(() => {
        getDefaultInput()
        
    }, [props.compSymbol, readyToDisplay])

    return (
        
        <>
            {pageData['price'] !== 0 &&
                <>
                <div className="ValuationRow" style={{ marginTop: "20px" }}>
                    <div className="halfQuarter shadowBlock" style={{ padding: "20px 10px", borderRadius: "10px", marginBottom: "110px" }}>
                    <div className="ValuationOddDiv"><div className="leftIntend middle">Initial Free Cash Flow (FCF):  </div><div className="userInput"><div className="paddedInput grey leftRounded">₹</div><div className="paddedUserInput"><input name="avg_fcf" type="number" className="customInput" placeholder="Average FCF per year" value={pageData.avgFCFPerYear} onChange={handleAvgFCFChange} /></div><div className="paddedInput grey rightRounded">Cr</div></div></div>
                    <div className="ValuationEvenDiv"><div className="leftIntend middle">Growth Rate (1 - 5 years): </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.assumedFCFGRNextFiveYear}</div><div className="userButton" onClick={() => handleMinus("grfive")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="gr_five" type="range" className="customInputRange" min="1" max="30" step="0.5" value={pageData.assumedFCFGRNextFiveYear} onChange={handleFCFRateChange} /></div><div className="userButton" onClick={() => handlePlus("grfive")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                    <div className="ValuationOddDiv"><div className="leftIntend middle">Growth Rate (6 - 10 years): </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.assumedFCFGRFiveTenYear}</div><div className="userButton" onClick={() => handleMinus("grten")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="gr_ten" type="range" className="customInputRange" min="1" max="30" step="0.5" value={pageData.assumedFCFGRFiveTenYear} onChange={handleFCFTenRateChange} /></div><div className="userButton" onClick={() => handlePlus("grten")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                    <div className="ValuationEvenDiv"><div className="leftIntend middle">Terminal Growth Rate: </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.terminalGrowthRate}</div><div className="userButton" onClick={() => handleMinus("grterm")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="gr_ter" type="range" className="customInputRange" min="1" max="10" step="0.5" value={pageData.terminalGrowthRate} onChange={handleFCFTerminalChange} /></div><div className="userButton" onClick={() => handlePlus("grterm")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                    <div className="ValuationOddDiv"><div className="leftIntend middle">Discount Rate: </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.discountRate}</div><div className="userButton" onClick={() => handleMinus("discRate")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="disc_rate" type="range" className="customInputRange" min="1" max="30" step="0.5" value={pageData.discountRate} onChange={handleDiscountRateChange} /></div><div className="userButton" onClick={() => handlePlus("discRate")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                    <div className="ValuationEvenDiv"><div className="leftIntend middle">Total Debt: </div><div className="userInput"><div className="paddedInput grey leftRounded">₹</div><div className="paddedUserInput"><input name="debt" type="number" className="customInput" placeholder="Debt" value={pageData.debt} onChange={handleDebtChange} /></div><div className="paddedInput grey rightRounded">Cr</div></div></div>
                    <div className="ValuationOddDiv"><div className="leftIntend middle">Cash and Cash Equivalents: </div><div className="userInput"><div className="paddedInput grey leftRounded">₹</div><div className="paddedUserInput"><input name="cash" type="number" className="customInput" placeholder="Cash and Cash Equivalent" value={pageData.cashEquivalent} onChange={handleCashChange} /></div><div className="paddedInput grey rightRounded">Cr</div></div></div>
                    <div className="ValuationEvenDiv"><div className="leftIntend middle">Shares Outstanding: </div><div className="userInput"><div className="paddedInput grey leftRounded">₹</div><div className="paddedUserInput"><input name="share" type="number" className="customInput" placeholder="Share Outstanding" value={pageData.shareOutstanding} onChange={handleShareOutstandingChange} /></div><div className="paddedInput grey rightRounded">Cr</div></div></div>
                    <div className="optionButtonPrimary topMarginVal">
                        <div className="optionButtonVal" onClick={handleDCFCalculation}> Calculate DCF</div>
                        <div className="optionButtonVal" onClick={getDefaultInput}> Reset</div>
                    </div>
                </div>
                <div className="halfQuarter">
                    <div className="ValuationRowInner">
                            <div className="halfQuarter shadowBlock" style={{ padding: "10px 0px 20px 0px", borderRadius: "10px" }}>
                            <div className="ValuationOddDivS"><div className="leftIntend middle">Market Capitalization: </div><div className="paddedUserOutput"><b>{getDisplayValue(pageData.marketCap)}</b></div></div>
                                <div className="ValuationEvenDivS"><div className="leftIntend middle">Value of Firm:</div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos) }}><b>{getDisplayValue(pageData.valueOfFirm)}</b></div></div>
                            <div className="ValuationOddDivS"><div className="leftIntend middle">Margin of Saftey:  </div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos) }}><b>{geMOS(pageData.mos)}</b></div></div>
                        </div>
                            <div className="halfQuarter shadowBlock" style={{ padding: "10px 0px 20px 0px", borderRadius: "10px" }}>
                            <div className="ValuationOddDivS"><div className="leftIntend middle">Current Price: </div><div className="paddedUserOutput"><b>{getDisplayValue(pageData.price)}</b></div></div>
                                <div className="ValuationEvenDivS"><div className="leftIntend middle">Intrinsic Value </div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos) }}><b>{getDisplayValue(pageData.ivps)}</b></div></div>
                            <div className="ValuationOddDivS"><div className="leftIntend middle">Price/Value: </div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos)}}><b>{getDisplayValue(pageData.pvRatio)}</b></div></div>
                        </div>
                    </div>

                    <div className="ValuationRowInnerI" style={{ paddingTop: "30px" }}>
                            <div className="FullQuarter borderEnclosed shadowBlock" style={{borderRadius: "10px"}}>
                            <div className="headerChart" style={{ paddingLeft: "20px" }}>Future FCF Estimation:</div>
                            <div className="valCharts">
                                    <Chart
                                        options={pageData.mapData.options}
                                        series={pageData.mapData.series}
                                        type="bar"
                                        width="100%"
                                        height="150"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                <div className="ValuationRow" style={{ paddingTop: "20px", marginTop: "-100px" }}>
                {pageData.dcfInput &&
                        <div className="FullQuarter shadowBlock" style={{ backgroundColor: "white", borderRadius: "10px", padding: '5px 10px' }}>
                    <h3 class="leftHeading" style={{ color: "#606f7b" }}>Discounted Cash Flow Model (DCF)</h3>
                        <table className="finData valuationResult">
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Year
                            </td >
                                {
                                    pageData.dcfInput.map(data => (
                                        <td className="rightIntend bold" style={{ color: "#606f7b" }}>{data["year"]}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Free Cash Flow
                            </td>
                                {
                                    pageData.dcfInput.map(data => (
                                        <td className="rightIntend">{getDisplayValue(data["fcf"])}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Growth Rate
                            </td>
                                {
                                    pageData.dcfInput.map(data => (
                                        <td className="rightIntend">{data["GrowthRate"]}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Present Value of FCF
                            </td>
                                {
                                    pageData.dcfInput.map(data => (
                                        <td className="rightIntend">{getDisplayValue(data["FCFPV"])}</td>
                                    ))
                                }
                            </tr>
                        </table>
                        <div style={{ textAlign: "left", color: "grey", fontSize: "14px", paddingTop: "10px" }}>
                            **This data has been generated by a machine. It is necessary to adjust the required inputs for
                            business valuation based on your estimation of future growth.
                        </div>
                        </div>

                }
                </div>
            </>
        }{
                pageData.price === 0 &&
                <Loading />
            }
        </>
    );
}

export default Dcf;
