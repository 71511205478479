import React, { useState, useEffect } from 'react';
import './ManageRatios.css'
import useAuth from '../hooks/useAuth';
import Icon from "../icon/Icon"
import Loading from "../Company/Loading"
import Locked from '../Locked'

const userRatiosData = [
    { "code": "HR035", "prompt": "FCF/share", "hint": "FCF per share" },
    { "code": "HR049", "prompt": "Avg shares", "hint": "Diluted Shares Outstanding" },
    { "code": "HR031", "prompt": "CFO/NI", "hint": "CFO to Net Income" },
    { "code": "HR025", "prompt": "WC turnover", "hint": "Working Capital Turnover" },
    { "code": "HR034", "prompt": "Sales per share", "hint": "Sales/share" },
    { "code": "HR032", "prompt": "TBV/share", "hint": "Tangible BV per Share" },
    { "code": "HR033", "prompt": "BV/share", "hint": "Book Value per share" },
    { "code": "HR036", "prompt": "Debt", "hint": "Total Debt" },
    { "code": "HR037", "prompt": "Net debt", "hint": "Net Debt" },
    { "code": "HR038", "prompt": "Non-Curr assets", "hint": "Non-Current Assets" },
    { "code": "HR039", "prompt": "Non-Curr liab", "hint": "Non-Current Liabilities" },
    { "code": "HR041", "prompt": "BV", "hint": "Book Value" },
    { "code": "HR042", "prompt": "Assets", "hint": "Total Assets" },
    { "code": "HR043", "prompt": "Goodwill", "hint": "Goodwill" },
    { "code": "HR044", "prompt": "Accu dep", "hint": "Accumulated Depreciation" },
    { "code": "HR045", "prompt": "Receivables", "hint": "Total Receivables" },
    { "code": "HR046", "prompt": "Cash & Inv", "hint": "Cash & Short Term Invest" },
    { "code": "HR047", "prompt": "Net loans", "hint": "Net Loans" },
    { "code": "HR048", "prompt": "Shares", "hint": "Shares Outstanding" },
    { "code": "HR052", "prompt": "FCF", "hint": "Free Cash Flow" },
    { "code": "HR053", "prompt": "CAPEX", "hint": "CAPEX" },
    { "code": "HR054", "prompt": "CFO", "hint": "Cash from Operating Activity" },
    { "code": "HR040", "prompt": "TBV", "hint": "Tangible Book Value" },
    { "code": "HR030", "prompt": "Tax %", "hint": "Tax %" },
    { "code": "HR026", "prompt": "WC days", "hint": "Working Capital Days" },
    { "code": "HR050", "prompt": "Debt/FCF", "hint": "Total Debt to FCF" },
    { "code": "HR051", "prompt": "LTDebt/FCF", "hint": "Long Term Debt to FCF" },
    { "code": "HR055", "prompt": "CFI", "hint": "Cash from Investing Activity" },
    { "code": "HR056", "prompt": "CFF", "hint": "Cash from Financing Activity" },
    { "code": "HR059", "prompt": "Int cov", "hint": "Interest Coverage" }
]
function ManageRatios(props) {
    window.scrollTo(0, 0)
    const { auth, setAuth } = useAuth();
    console.log(auth)
    const getDefault = () => {
        if (props.source === "ratio") {
            if (auth.ratios) {
                return auth.ratios
            } else {
                return []
            }
        } else if (props.source === "peerColumns") {
            if (auth.peerColumns) {
                return auth.peerColumns
            } else {
                return []
            }
        } else {
            if (auth.historicalRatios) {
                return auth.historicalRatios
            } else {
                return []
            }
        }
    }
    

    const [option, setOption] = useState("Current");
    const [optionTwo, setOptionTwo] = useState("QPL");
    const [selectedRatios, setSelectedRatios] = useState(getDefault());
    const preLoadAuthData = () => {
        let dataArr = {}
        selectedRatios.forEach(item => {
            dataArr[item] = true
        })
        return dataArr
    }

    const [checked, setChecked] = useState(preLoadAuthData());
    
    
    
    const handleSelectedRatios = () => {
        let dataArr = []
        for (let k in checked) {
            if (checked[k]) {
                dataArr.push(k)
            }
            if (props.source === "ratio") {
                setAuth(prevAuth => ({ ...prevAuth, ratios: dataArr }))
            } else if (props.source === "peerColumns"){
                setAuth(prevAuth => ({ ...prevAuth, peerColumns: dataArr }))
            } else {
                setAuth(prevAuth => ({ ...prevAuth, historicalRatios: dataArr }))
            }
            if (dataArr.length > props.restrictCount) {
                props.setDisableButton(false)
            } else {
                props.setDisableButton(true)
            }
            setSelectedRatios(dataArr)
        }
    }
    const handleChange = (item) => {
        if (checked[item]) {
            checked[item] = false
        } else {
            checked[item] = true
        }
        handleSelectedRatios()
    }

    const removeItem = (item) => {
        checked[item] = false
        handleSelectedRatios()
    }

    const isPresent = (key) => {
        return selectedRatios.includes(key)
    }


    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        if (props.source === "histRatios") {
            userRatiosData.map(item => {
                if (item.code === itemCode) {
                    returnData = [item.prompt, item.hint]
                }
            })
        } else {
            Object.keys(props.ratioData).map(item => (
                Object.keys(props.ratioData[item]).map(childItem => (
                    props.ratioData[item][childItem].map(data => {
                        if (data.code === itemCode) {
                            returnData = [data.prompt, data.hint]
                        }
                    })
                ))

            ))
        }
        return returnData
    }

    

    useEffect(() => {
    }, [selectedRatios])
    return (
        <div className="ManageRatios">
            {auth.user &&
                <>
                    {!props.viewLoading &&
                        <div className="ManageRatiosContainer">
                            {props.source === "histRatios" &&
                                <>
                                    {props.restrictCount < selectedRatios.length &&
                                        <div style={{ color: "red" }}>
                                            You are allowed to select only {props.restrictCount} ratios.
                        </div>
                                    }
                                    <div className="ManageRatioContent">
                                        {
                                            userRatiosData.map(data => (
                                                <div className="MROptions">
                                                    <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                    <div>{data.hint}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {props.source !== "histRatios" &&
                                <>
                                    <div className="LeftManageRatioOption">
                                        <div className="LeftManageRatioOptionButtonStart" onClick={() => setOptionTwo("QPL")} style={optionTwo === "QPL" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Quarterly P&L
                                </div>
                                        <div className="LeftManageRatioOptionButton" onClick={() => setOptionTwo("APL")} style={optionTwo === "APL" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Annual P&L
                                </div>
                                        <div className="LeftManageRatioOptionButton" onClick={() => setOptionTwo("BS")} style={optionTwo === "BS" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Balance Sheet
                                </div>
                                        <div className="LeftManageRatioOptionButton" onClick={() => setOptionTwo("CF")} style={optionTwo === "CF" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Cash Flows
                                </div>
                                        <div className="LeftManageRatioOptionButton" onClick={() => setOptionTwo("RA")} style={optionTwo === "RA" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Ratios
                                </div>
                                        <div className="LeftManageRatioOptionButton" onClick={() => setOptionTwo("PR")} style={optionTwo === "PR" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Price
                                </div>
                                        <div className="LeftManageRatioOptionButtonEnd" onClick={() => setOptionTwo("VA")} style={optionTwo === "VA" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                            Valuations
                                </div>
                                    </div>
                                    {optionTwo === "QPL" &&
                                        <div className="RightManageRatioOption">

                                            <div className="RightManageRatioOptionButtonStart" onClick={() => setOption("Current")} style={option === "Current" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Current
                    </div>
                                            <div className="RightManageRatioOptionButton" onClick={() => setOption("Preceding")} style={option === "Preceding" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Preceding Quarter
                    </div>
                                            <div className="RightManageRatioOptionButtonEnd" onClick={() => setOption("PreceedingYearQuarter")} style={option === "PreceedingYearQuarter" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Preceding Year Quarter
                    </div>
                                        </div>
                                    }
                                    {optionTwo === "APL" &&
                                        <div className="RightManageRatioOption">

                                            <div className="RightManageRatioOptionButtonStart" onClick={() => setOption("Current")} style={option === "Current" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Current Year
                    </div>
                                            <div className="RightManageRatioOptionButton" onClick={() => setOption("Historical")} style={option === "Historical" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Historical
                    </div>
                                            <div className="RightManageRatioOptionButton" onClick={() => setOption("Preceding")} style={option === "Preceding" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Preceding Year
                    </div>
                                            <div className="RightManageRatioOptionButtonEnd" onClick={() => setOption("TTM")} style={option === "TTM" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                TTM
                    </div>
                                        </div>
                                    }
                                    {(optionTwo === "BS" || optionTwo === "RA" || optionTwo === "PR") &&
                                        <div className="RightManageRatioOption">

                                            <div className="RightManageRatioOptionButtonStart" onClick={() => setOption("Current")} style={option === "Current" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Current Year
                    </div>
                                            <div className="RightManageRatioOptionButton" onClick={() => setOption("Historical")} style={option === "Historical" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Historical
                    </div>
                                            <div className="RightManageRatioOptionButtonEnd" onClick={() => setOption("TTM")} style={option === "TTM" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                TTM
                    </div>
                                        </div>
                                    }
                                    {optionTwo === "CF" &&
                                        <div className="RightManageRatioOption">

                                            <div className="RightManageRatioOptionButtonStart" onClick={() => setOption("Current")} style={option === "Current" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Current Year
                    </div>
                                            <div className="RightManageRatioOptionButton" onClick={() => setOption("Historical")} style={option === "Historical" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Historical
                    </div>
                                            <div className="RightManageRatioOptionButton" onClick={() => setOption("Preceding")} style={option === "Preceding" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                Preceding Year
                    </div>
                                            <div className="RightManageRatioOptionButtonEnd" onClick={() => setOption("TTM")} style={option === "TTM" ? { backgroundColor: "#000", color: "#fff" } : { backgroundColor: "#f8f8fc", color: "#000" }}>
                                                TTM
                    </div>
                                        </div>
                                    }
                                    {props.restrictCount < selectedRatios.length &&
                                        <div style={{ color: "red" }}>
                                            You are allowed to only select {props.restrictCount} ratios.
                        </div>
                                    }

                                    <div className="ManageRatioContent">
                                        {optionTwo === "QPL" && option === "Current" &&
                                            <>
                                                {
                                                    props.ratioData['QuarterlyPL']['Current quarter'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "QPL" && option === "Preceding" &&
                                            <>
                                                {
                                                    props.ratioData['QuarterlyPL']['Preceding quarter'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "QPL" && option === "PreceedingYearQuarter" &&
                                            <>
                                                {
                                                    props.ratioData['QuarterlyPL']['Preceding year quarter '].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }

                                        {optionTwo === "APL" && option === "Current" &&
                                            <>
                                                {
                                                    props.ratioData['AnnualPL']['Current year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "APL" && option === "Historical" &&
                                            <>
                                                {
                                                    props.ratioData['AnnualPL']['Historical'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "APL" && option === "Preceding" &&
                                            <>
                                                {
                                                    props.ratioData['AnnualPL']['Preceding year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "APL" && option === "TTM" &&
                                            <>
                                                {
                                                    props.ratioData['AnnualPL']['TTM'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }


                                        {optionTwo === "BS" && option === "Current" &&
                                            <>
                                                {
                                                    props.ratioData['BalanceSheet']['Current year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "BS" && option === "Historical" &&
                                            <>
                                                {
                                                    props.ratioData['BalanceSheet']['Historical'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "BS" && option === "TTM" &&
                                            <>
                                                {
                                                    props.ratioData['BalanceSheet']['TTM'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false}  /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }

                                        {optionTwo === "CF" && option === "Current" &&
                                            <>
                                                {
                                                    props.ratioData['CashFlow']['Current year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "CF" && option === "Historical" &&
                                            <>
                                                {
                                                    props.ratioData['CashFlow']['Historical'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "CF" && option === "Preceding" &&
                                            <>
                                                {
                                                    props.ratioData['CashFlow']['Preceding year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "CF" && option === "TTM" &&
                                            <>
                                                {
                                                    props.ratioData['CashFlow']['TTM'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }




                                        {optionTwo === "PR" && option === "Current" &&
                                            <>
                                                {
                                                    props.ratioData['Price']['Current year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "PR" && option === "Historical" &&
                                            <>
                                                {
                                                    props.ratioData['Price']['Historical'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "PR" && option === "TTM" &&
                                            <>
                                                {
                                                    props.ratioData['Price']['TTM'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }


                                        {optionTwo === "RA" && option === "Current" &&
                                            <>
                                                {
                                                    props.ratioData['Ratios']['Current year'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "RA" && option === "Historical" &&
                                            <>
                                                {
                                                    props.ratioData['Ratios']['Historical'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "RA" && option === "TTM" &&
                                            <>
                                                {
                                                    props.ratioData['Ratios']['TTM'].map(data => (
                                                        <div className="MROptions">
                                                            <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                            <div>{data.hint}</div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                        {optionTwo === "VA" &&
                                            <>
                                                {
                                                    props.ratioData['Valuation']['Current year'].map(data => (
                                                        <div className="MROptions">
                                                            {data.hint !== "Sector" && data.hint !== "Industry" &&
                                                                <>
                                                                    <div><input key={data.code} type="checkbox" onChange={() => handleChange(data.code)} checked={isPresent(data.code) ? true : false} /></div>
                                                                    <div>{data.hint}</div>
                                                                </>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            }
                            <div>
                                <p style={{ fontSize: "12px" }}>
                                    {selectedRatios.map(item => (
                                        <div className="dragableSection">
                                            <div style={{ display: "inline-block" }}>{getDisplayValueAndPrompt(item)[1]}</div>
                                            <div style={{ display: "inline-block", marginLeft: "10px", cursor: "pointer" }} className="removableSection" onClick={() => removeItem(item)}>&#10006;</div>
                                        </div>
                                    ))}
                                </p>
                            </div>




                        </div>
                    }{
                        props.viewLoading &&
                        <Loading />
                    }
                </>
            }{!auth.user &&
                <Locked />
            }
        </div>
    );
}
export default ManageRatios;