import React, { useState, useEffect} from "react";
import MultiRangeSlider from "multi-range-slider-react";


function MultiRange(props) {

	function getMaxValueFromFilter() {
		try {
			return props.filter[props.source]["maxValue"]
		} catch (e) {
			return props.defaultMax
        }
	}

	function getMinValueFromFilter() {
		try {
			return props.filter[props.source]["minValue"]
		} catch (e) {
			return props.defaultMin
		}
	}




	const [minValue, set_minValue] = useState(getMinValueFromFilter());
	const [maxValue, set_maxValue] = useState(getMaxValueFromFilter());
	
	//props.setInputChange(false)


	const handleInput = (e) => {
		set_minValue(e.minValue);
		set_maxValue(e.maxValue);
		props.filter[props.source]["minValue"] = e.minValue
		props.filter[props.source]["maxValue"] = e.maxValue
		//props.toggleInputChange()
		//toggleChange()
	};

	const handleManualInput = (e) => {
		if (e.target.value >= maxValue) {
			props.filter[props.source]["minValue"] = maxValue - 1
			set_minValue(maxValue - 1);
		} else {
			props.filter[props.source]["minValue"] = e.target.value
			set_minValue(e.target.value);
		}
		//props.toggleInputChange()
		//toggleChange()
	}

	const handleManualInputMax = (e) => {
		if (minValue >= e.target.value) {
			props.filter[props.source]["minValue"] = 0
			set_minValue(0);
		}
		props.filter[props.source]["maxValue"] = e.target.value
		set_maxValue(e.target.value);

		
	//	props.toggleInputChange()
	//	toggleChange()
	}

	useEffect(() => {
		//props.filter[props.source]["minValue"] = props.defaultMin
		//props.filter[props.source]["maxValue"] = props.defaultMax
	}, [])

	return (
		<div className="MultiRangeSlider" style={{ display: "flex", justifyContent: "space-between" }}>
			<div
				style={{
					border: "1px solid #B3B3B3",
					width: "150px",
					paddingTop: "5px",
					textAlign: "center",
					borderRadius: "5px",
					display: "flex",
					justifyContent: "space-between"
				}}>
				<input type="number" value={minValue} onChange={(e) => handleManualInput(e)} style={{ width: "80%", border: "none" }} />
				<div style={{ paddingRight: "10px" }}>{props.percentIndicator ? "%" : ""}</div>

			</div>
			<MultiRangeSlider
				min={props.Min}
				max={props.Max}
				step={props.Step}
				minValue={minValue}
				maxValue={maxValue}
				label="false"
				subSteps="false"
				ruler="false"
				barLeftColor="#0096FF"
				barRightColor="#0096FF"
				barInnerColor= "#0096FF"
				style={{ border: "none", boxShadow: "none", padding: "10px 15px", width: "200px"}}
				onInput={(e) => {
					handleInput(e);
				}}
				
			/>
			<div
				style={{
					border: "1px solid #B3B3B3",
					width: "150px",
					paddingTop: "5px",
					textAlign: "center",
					borderRadius: "5px",
					display: "flex",
					justifyContent: "space-between"
				}}>
				<input type="number" value={maxValue} onChange={(e) => handleManualInputMax(e)} style={{ width: "80%", border: "none" }} />
				<div style={{ paddingRight: "10px" }}>{props.percentIndicator ? "%" : ""}</div>
			</div>
		</div>
	);
}

export default MultiRange;