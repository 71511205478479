import React, { useState, useEffect } from 'react';
import HelpModal from './Modal/ManageRatioModal'
import ManageRatiosMobile from '../ManageRatios/ManageRatiosMobile'
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import useAuth from '../../hooks/useAuth';
import Loading from "../../Company/Summary/Loading"



function UserRatioMobile(props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [viewLoading, setViewLoading] = useState(false)
    const { auth, setAuth } = useAuth();
    const [restrictCount, setRestrictCount] = useState(10);
    const [disableButton, setDisableButton] = useState(true);
    const numberFormat = (value) => {
        let text = value.toString();
        let result = text.includes("%");
        if (result) {
            return value
        }
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, ratios: itemsData.ratios }))
        }
        setShow(false)
    }
    const roundValue = (data) => {
        let text = data.toString();
        let result = text.includes("%");
        if (result) {
            return data
        }
        return Math.round(data)
    }

    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }

    const getClassName = (pointer) => {
        let pointerMinusOne = pointer - 1

        if ((pointer) % 2 === 0){
            return "OddDivS"
        } else {
            return "EvenDivS"
        }
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    const getProfileData = async () => {
        setData({})
        let url = "https://api.niveshkarma.com/api/v1/add-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const response = await axiosPrivate.get(url);
        console.log(response)


        if (Object.keys(response.data).length !== 0) {
            if (auth.roles === true) {
                setRestrictCount(100)
            } else {
                setRestrictCount(10)
            }
            setData(response?.data)
        } else {
            setData({ "data": "Please select ratios" })
            setShow(false)
        }



    }



    const showManageRatios = () => {
        setShow(true)

    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (auth.user) {
                getProfileData()
            } else {
                setData({ "data": "Login to access Custom Ratios" })
            }
        }, 50)
        return () => clearTimeout(timeout)
    }, [props.compSymbol, isProfileUpdated, auth.user])

    return (
        <div className="UserRatioMobile" style={{ padding: "20px" }}>
            {(Object.keys(data).length !== 0 || !auth.ratios) &&
                <>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", marginBottom: "10px" }}>
                    <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600", paddingTop: "5px" }}>User Ratios:</div>
                    <div className="optionButtonMobile" onClick={() => showManageRatios()}> Manage&nbsp;Your&nbsp;Ratios</div>

                        <HelpModal title="Manage Your Ratios" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} disableButton={disableButton}>
                            <ManageRatiosMobile ratioData={props.ratioData} source="ratio" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                        </HelpModal>
                    </div>

                        {
                            Object.keys(data).map((item, index) => (
                                <div className={getClassName(index)}>
                                    <div className="summaryFont">
                                        <div style={{ fontSize: "13px" }}>

                                            <Popup
                                                className="popupChart"
                                                trigger={<div style={{ cursor: "pointer" }}>{getDisplayValueAndPrompt(item)[0]}</div>}
                                                content={<div className="borderedContent" style={{ padding: "5px", border: "2px solid black", background: "#fff" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                style={{ zIndex: "999" }}
                                            />

                                        </div>
                                        <div className="details">
                                            <div className="DetailsSubHeader">
                                            </div>
                                            <div style={{ fontSize: "13px" }}>
                                                {data[item]}
                                            </div>
                                            <div className="DetailsEnd">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                </>
            }

            {
                Object.keys(data).length === 0 && auth.ratios &&
                <Loading />
            }

        </div>

    );
}
export default UserRatioMobile;