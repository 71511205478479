import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Chart from "react-apexcharts";
import './ValuationChart.css'
import Loading from "./Loading"
import Locked from '../../Locked'
import useAuth from '../../hooks/useAuth';

export const options = {
    legend: { position: "none" },
    chart: {
        title: ""

    },
    tooltip: { isHtml: true, trigger: "visible" },
};

function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
}

function ValuationChart(props) {
    const [mapData, setMapData] = useState({})
    const [userData, setUserData] = useState({})
    const [userSelection, setuserSelection] = useState("Price")
    const [userDuration, setuserDuration] = useState("1M")
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const updateUserSelection = (userValue) => {
        if (userSelection !== userValue) {
            setuserSelection(userValue)
        }
    }
    const updateUserDuration = (userValue) => {
        if (userDuration !== userValue) {
            setuserDuration(userValue)
        }
    }

    const accessChart = () => {
        if (auth.roles) {
            return true
        }
        if (["HDFCBANK", "TCS", "ITC"].includes(props.compSymbol) && auth.user) {
            return true
        }
        if (userSelection === "Price") {
            return true
        }
        if (!auth.roles && ["1M", "6M", "1Y"].includes(userDuration)) {
            return true
        }
        if (!auth.user && ["1M", "6M", "1Y"].includes(userDuration)) {
            return true
        }
        return false
    }

    useEffect(() => {
        const companyDetails = async () => {
            setMapData({})
            let url = ""
            
                if (userDuration === "1M") {
                    url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration +"&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
                } else if (userDuration === "6M") {
                    url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration +"&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
                } else if (userDuration === "1Y") {
                    url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration +"&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
                } else if (userDuration === "5Y") {
                    url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration +"&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
                } else if (userDuration === "All") {
                    url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration +"&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
                }
            
            
            const cdresponse = await axiosPrivate.get(url);
            const tempData = cdresponse.data['historical_quotes']
            const peData = cdresponse.data['pe']
            const pbData = cdresponse.data['pb']
            const ptbData = cdresponse.data['ptb']
            const psData = cdresponse.data['ps']
            const pfcfData = cdresponse.data['pfcf']
            let mean = "-"
            let stdDev = "-"
            let plusDev = "-"
            let minusDev = "-"
            let coeff = "-"
            let tempLabel = []
            let tempMapData = []
            if (userSelection === "Price") {
                let interimData = []
                tempData.reverse().forEach(arr => {
                    if (arr.close_price) {
                        tempLabel.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                        interimData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.close_price])
                    }
                })
                tempMapData.push({ name: "Price", type: 'line', data: interimData})
            } else if (userSelection === "PE") {
                let interimDataOne = []
                let interimDataTwo = []
                let interimDataThree = []
                let interimDataFour = []
                let interimDataFive = []
                let interimDataSix = []
                tempData.reverse().forEach(arr => {
                    if (arr.pe) {
                        tempLabel.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                        interimDataOne.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.pe])
                        interimDataTwo.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), peData.mean])
                        interimDataThree.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), peData.median])
                        interimDataFour.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), peData.mode])
                        interimDataFive.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), peData.std_dv_plus])
                        interimDataSix.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), peData.std_dv_minus])

                    }
                })
                tempMapData.push({ name: "Price to Earning", type: "line", data: interimDataOne })
                tempMapData.push({ name: "Mean", type: "line", data: interimDataTwo })
                tempMapData.push({ name: "Median", type: "line", data: interimDataThree })
                tempMapData.push({ name: "Mode", type: "line", data: interimDataFour })
                tempMapData.push({ name: "+2 Std Deviation", type: "line", data: interimDataFive })
                tempMapData.push({ name: "-2 Std Deviation", type: "line", data: interimDataSix })
                mean = peData.mean
                stdDev = peData.std_dv
                plusDev = peData.std_dv_plus
                minusDev = peData.std_dv_minus
                coeff = peData.coeff_of_variation
            } else if (userSelection === "PB") {
                let interimDataOne = []
                let interimDataTwo = []
                let interimDataThree = []
                let interimDataFour = []
                let interimDataFive = []
                let interimDataSix = []
                tempData.reverse().forEach(arr => {
                    if (arr.pb) {
                        tempLabel.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                        interimDataOne.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.pb])
                        interimDataTwo.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pbData.mean])
                        interimDataThree.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pbData.median])
                        interimDataFour.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pbData.mode])
                        interimDataFive.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pbData.std_dv_plus])
                        interimDataSix.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pbData.std_dv_minus])

                    }
                })
                tempMapData.push({ name: "Price to Book", type: "line", data: interimDataOne })
                tempMapData.push({ name: "Mean", type: "line", data: interimDataTwo })
                tempMapData.push({ name: "Median", type: "line", data: interimDataThree })
                tempMapData.push({ name: "Mode", type: "line", data: interimDataFour })
                tempMapData.push({ name: "+2 Std Deviation", type: "line", data: interimDataFive })
                tempMapData.push({ name: "-2 Std Deviation", type: "line", data: interimDataSix })
                mean = pbData.mean
                stdDev = pbData.std_dv
                plusDev = pbData.std_dv_plus
                minusDev = pbData.std_dv_minus
                coeff = pbData.coeff_of_variation
            } else if (userSelection === "PS") {
                let interimDataOne = []
                let interimDataTwo = []
                let interimDataThree = []
                let interimDataFour = []
                let interimDataFive = []
                let interimDataSix = []
                tempData.reverse().forEach(arr => {
                    if (arr.ps) {
                        tempLabel.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                        interimDataOne.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.ps])
                        interimDataTwo.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), psData.mean])
                        interimDataThree.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), psData.median])
                        interimDataFour.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), psData.mode])
                        interimDataFive.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), psData.std_dv_plus])
                        interimDataSix.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), psData.std_dv_minus])

                    }
                })
                tempMapData.push({ name: "Price to Sales", type: "line", data: interimDataOne })
                tempMapData.push({ name: "Mean", type: "line", data: interimDataTwo })
                tempMapData.push({ name: "Median", type: "line", data: interimDataThree })
                tempMapData.push({ name: "Mode", type: "line", data: interimDataFour })
                tempMapData.push({ name: "+2 Std Deviation", type: "line", data: interimDataFive })
                tempMapData.push({ name: "-2 Std Deviation", type: "line", data: interimDataSix })
                mean = psData.mean
                stdDev = psData.std_dv
                plusDev = psData.std_dv_plus
                minusDev = psData.std_dv_minus
                coeff = psData.coeff_of_variation
            } else if (userSelection === "PF") {
                let interimDataOne = []
                let interimDataTwo = []
                let interimDataThree = []
                let interimDataFour = []
                let interimDataFive = []
                let interimDataSix = []
                tempData.reverse().forEach(arr => {
                    if (arr.pfcf) {
                        tempLabel.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                        interimDataOne.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.pfcf])
                        interimDataTwo.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pfcfData.mean])
                        interimDataThree.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pfcfData.median])
                        interimDataFour.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pfcfData.mode])
                        interimDataFive.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pfcfData.std_dv_plus])
                        interimDataSix.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), pfcfData.std_dv_minus])

                    }
                })
                tempMapData.push({ name: "Price to Free Cash Flow", type: "line", data: interimDataOne })
                tempMapData.push({ name: "Mean", type: "line", data: interimDataTwo })
                tempMapData.push({ name: "Median", type: "line", data: interimDataThree })
                tempMapData.push({ name: "Mode", type: "line", data: interimDataFour })
                tempMapData.push({ name: "+2 Std Deviation", type: "line", data: interimDataFive })
                tempMapData.push({ name: "-2 Std Deviation", type: "line", data: interimDataSix })
                mean = pfcfData.mean
                stdDev = pfcfData.std_dv
                plusDev = pfcfData.std_dv_plus
                minusDev = pfcfData.std_dv_minus
                coeff = pfcfData.coeff_of_variation
            } else if (userSelection === "PTB") {
                let interimDataOne = []
                let interimDataTwo = []
                let interimDataThree = []
                let interimDataFour = []
                let interimDataFive = []
                let interimDataSix = []
                tempData.reverse().forEach(arr => {
                    if (arr.ptb) {
                        tempLabel.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                        interimDataOne.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.ptb])
                        interimDataTwo.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), ptbData.mean])
                        interimDataThree.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), ptbData.median])
                        interimDataFour.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), ptbData.mode])
                        interimDataFive.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), ptbData.std_dv_plus])
                        interimDataSix.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), ptbData.std_dv_minus])

                    }
                })
                tempMapData.push({ name: "Price to Tangible Book", type: "line", data: interimDataOne })
                tempMapData.push({ name: "Mean", type: "line", data: interimDataTwo })
                tempMapData.push({ name: "Median", type: "line", data: interimDataThree })
                tempMapData.push({ name: "Mode", type: "line", data: interimDataFour })
                tempMapData.push({ name: "+2 Std Deviation", type: "line", data: interimDataFive })
                tempMapData.push({ name: "-2 Std Deviation", type: "line", data: interimDataSix })
                mean = ptbData.mean
                stdDev = ptbData.std_dv
                plusDev = ptbData.std_dv_plus
                minusDev = ptbData.std_dv_minus
                coeff = ptbData.coeff_of_variation
            }
            setUserData({
                mean: mean,
                stdDev: stdDev,
                plusDev: plusDev,
                minusDev: minusDev,
                coeff: coeff
            })
            

            setMapData({
                options: {
                    chart: { id: "valuation-chart" },
                    dataLabels: { enabled: false },
                    colors: ['#455EB5', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                    xaxis: {
                        type: 'datetime',
                        //  categories: labels,
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm',
                            }
                        }
                    },
                    yaxis: {
                        forceNiceScale: true
                    },
                    stroke: {
                        width: [1.5, 1.5, 1.5, 1.5, 1.5, 1.5]
                    }
                },
                series: tempMapData
            })
            
            
          /*  if (userSelection === "Price") {
                setMapData([["", "Price"]])
                tempData.reverse().forEach(arr => {
                    if (arr.Close) {
                        setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.Close]])
                    }
                })
            } else if (userSelection === "PE") {
                setMapData([["", "Price to Earning", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                tempData.reverse().forEach(arr => {
                    if (arr.PE) {
                        setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PE, arr.mean, arr.plus_dev, arr.minus_dev]])
                    }
                })
            } else if (userSelection === "PB") {
                setMapData([["", "Price to Book", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                tempData.reverse().forEach(arr => {
                    if (arr.PB) {
                        setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PB, arr.mean, arr.plus_dev, arr.minus_dev]])
                    }
                })
            } else if (userSelection === "PS") {
                setMapData([["", "Price to Sales", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                tempData.reverse().forEach(arr => {
                    if (arr.PS) {
                        setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PS, arr.mean, arr.plus_dev, arr.minus_dev]])
                    }
                })
            } else if (userSelection === "PF") {
                setMapData([["", "Price to Free Cash Flow", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                tempData.reverse().forEach(arr => {
                    if (arr.PFCF) {
                        setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PFCF, arr.mean, arr.plus_dev, arr.minus_dev]])
                    }
                })
            }*/
        }
        companyDetails()
    }, [props.compSymbol, userSelection, userDuration, auth.user])

    return (
        <div className="ValuationChart shadowBlock">
            <div className="CompanyChartMap">
                <section className="CompanyChart" id="CompanyChartGraph">
                    <div className="chartContainer">
                        <div className="chartHeader">
                            <div className="ValuationchartTopic" style={{ pointerEvents: mapData.length <= 1 ? "none" : "auto", opacity: mapData.length <= 1 ? ".3" : "1"  }}>
                                <div className="chartMenuItem" onClick={() => updateUserDuration("1M")}
                                    style={{
                                        backgroundColor: userDuration === "1M" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "1M" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                        borderTopLeftRadius: '25px',
                                        borderBottomLeftRadius: '25px',
                                        
                                    }}
                                >1M</div>
                                <div className="chartMenuItem" onClick={() => updateUserDuration("6M")}
                                    style={{
                                        backgroundColor: userDuration === "6M" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "6M" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                        
                                    }}
                                >6M</div>
                                <div className="chartMenuItem" onClick={() => updateUserDuration("1Y")}
                                    style={{
                                        backgroundColor: userDuration === "1Y" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "1Y" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >1Y</div>
                                <div className="chartMenuItem" onClick={() => updateUserDuration("5Y")}
                                    style={{
                                        backgroundColor: userDuration === "5Y" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "5Y" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >5Y</div>
                                <div className="chartMenuItem" onClick={() => updateUserDuration("All")}
                                    style={{
                                        backgroundColor: userDuration === "All" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "All" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                        borderTopRightRadius: '25px',
                                        borderBottomRightRadius: '25px'
                                    }}
                                >All</div>

                            </div>
                            <div className="chartMenu" style={{ pointerEvents: mapData.length <= 1 ? "none" : "auto", opacity: mapData.length <= 1 ? ".3" : "1" }}>
                                <div className="chartMenuItem" onClick={() => updateUserSelection("Price")}
                                    style={{
                                        backgroundColor: userSelection === "Price" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "Price" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                        borderTopLeftRadius: '25px',
                                        borderBottomLeftRadius: '25px'
                                    }}
                                >Price</div>
                                <div className="chartMenuItem" onClick={() => updateUserSelection("PE")}
                                    style={{
                                        backgroundColor: userSelection === "PE" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PE" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Earning</div>
                                <div className="chartMenuItem" onClick={() => updateUserSelection("PB")}
                                    style={{
                                        backgroundColor: userSelection === "PB" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PB" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Book</div>
                                <div className="chartMenuItem" onClick={() => updateUserSelection("PTB")}
                                    style={{
                                        backgroundColor: userSelection === "PTB" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PTB" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Tangible BV</div>
                                <div className="chartMenuItem" onClick={() => updateUserSelection("PS")}
                                    style={{
                                        backgroundColor: userSelection === "PS" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PS" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Sales</div>
                                <div className="chartMenuItem" onClick={() => updateUserSelection("PF")}
                                    style={{
                                        backgroundColor: userSelection === "PF" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PF" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                        borderTopRightRadius: '25px',
                                        borderBottomRightRadius: '25px'
                                    }}
                                >Price to FCF</div>
                            </div>
                        </div>
                        <div>
                            {Object.keys(mapData).length > 0 && 
                                <>
                                {accessChart() ?
                                    <Chart
                                        options={mapData.options}
                                        series={mapData.series}
                                        type="line"
                                        width="100%"
                                        height="500"
                                    /> :
                                    <Locked />
                                }
                                {userSelection !== "Price" &&
                                    <div style={{ width: "100%", paddingTop: "40px" }}>
                                    <table className="chartSummaryDetails">
                                        <tbody>
                                            <tr>
                                                <td className="chartSummaryDetailsTD" style={{ fontWeight: "600" }}>
                                                    Mean
                                                </td>
                                                <td className="chartSummaryDetailsTDData">
                                                    {userData.mean}
                                                </td>
                                                <td className="chartSummaryDetailsTD" style={{ fontWeight: "600" }}>
                                                    STD Dev
                                                </td>
                                                <td className="chartSummaryDetailsTDData">
                                                    {userData.stdDev}
                                                </td>
                                                <td className="chartSummaryDetailsTD" style={{ fontWeight: "600" }}>
                                                    +/- 2 STD Dev
                                                </td>
                                                <td className="chartSummaryDetailsTDData">
                                                    {userData.plusDev + "/" + userData.minusDev}
                                                </td>
                                                <td className="chartSummaryDetailsTD" style={{ fontWeight: "600" }}>
                                                    COV
                                                </td>
                                                <td className="chartSummaryDetailsTDData">
                                                    {userData.coeff}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}
                            </>
                            }
                            {Object.keys(mapData).length <= 1 &&
                                <Loading />
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ValuationChart;