import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Loading from './Loading'
import UpArrow from "../icon/UpArrow"
import DownArrow from "../icon/DownArrow"

function CommodityMainChange(props) {
    const [pageData, setpageData] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const CommodityList = async () => {
        setpageData([])
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/commodities-table/monthly_index?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        //document.title = cdresponse.data.stock_name + "- 3M Score | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes | Investing checklist. ";
        let tempData = []
        cdresponse.data.commodities.forEach((item) => {
            let tempObject = {}
            tempObject.code = item.code
            tempObject.name = item.name
            tempObject.oneM = item.pct_change[0]["change_pct"]
            tempObject.threeM = item.pct_change[1]["change_pct"]
            tempObject.sixM = item.pct_change[2]["change_pct"]
            tempObject.oneY = item.pct_change[3]["change_pct"]
            tempObject.twoY = item.pct_change[4]["change_pct"]
            tempObject.threeY = item.pct_change[5]["change_pct"]
            tempObject.fiveY = item.pct_change[6]["change_pct"]
            tempData.push(tempObject)
        })
        localStorage.setItem(
            "CommodityMainData", JSON.stringify(tempData)
        );
        setpageData(tempData)
    }

    const updateSelection = (item, name) => {
        props.setCommoditySymbol({ label: name, value: item })
        props.setUserSelection("Default")
    }

    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('CommodityMainData'))
        let tempData = itemsData
        if (finalSortData["pointer"]) {
            tempData.sort(dynamicSortAscending(pointer));
        } else {
            tempData.sort(dynamicSortDescending(pointer));
        }
        let finalData = itemsData
        finalData["peer"] = tempData
        setpageData(finalData)
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }


    useEffect(() => {
        CommodityList()
    }, [])

    return (
        <div style={{ paddingBottom: "110px" }}>
            {pageData.length >= 0 &&
                <div style={{ backgroundColor: "white", borderRadius: "10px", maxHeight: "500px", overflowY: "auto" }}>
                <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                    <tr>
                        <th style={{ fontWeight: 600, maxWidth: "200px", textAlign: "left", backgroundColor: "white", position: "sticky", top: '0' }}>
                            Commodity Name
                        </th>
                        <th onClick={() => showSorted("oneM")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>1 Month</div>{SortIndicator("oneM")[0] ? <div>{SortIndicator("oneM")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        <th onClick={() => showSorted("threeM")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>3 Month</div>{SortIndicator("threeM")[0] ? <div>{SortIndicator("threeM")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        <th onClick={() => showSorted("sixM")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>6 Month</div>{SortIndicator("sixM")[0] ? <div>{SortIndicator("sixM")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        <th onClick={() => showSorted("oneY")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>1 Year</div>{SortIndicator("oneY")[0] ? <div>{SortIndicator("oneY")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        <th onClick={() => showSorted("twoY")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>2 Year</div>{SortIndicator("twoY")[0] ? <div>{SortIndicator("twoY")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        <th onClick={() => showSorted("threeY")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>3 Year</div>{SortIndicator("threeY")[0] ? <div>{SortIndicator("threeY")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        <th onClick={() => showSorted("fiveY")} style={{ position: "sticky", top: '0', backgroundColor: "white" }}>
                            <div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }}><div>5 Year</div>{SortIndicator("fiveY")[0] ? <div>{SortIndicator("fiveY")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>} </div>
                        </th>
                        </tr>
                        {pageData.map(item => (
                            <tr>
                                <td style={{ fontWeight: 500, maxWidth: "200px", textAlign: "left" }}>
                                    <div onClick={() => updateSelection(item.code, item.name)} style={{ cursor: "pointer", color: "blue" }}>{item.name}</div>
                                </td>
                                <td>
                                    {item.oneM}
                                </td>
                                <td>
                                    {item.threeM}
                                </td>
                                <td>
                                    {item.sixM}
                                </td>
                                <td>
                                    {item.oneY}
                                </td>
                                <td>
                                    {item.twoY}
                                </td>
                                <td>
                                    {item.threeY}
                                </td>
                                <td>
                                    {item.fiveY}
                                </td>
                            </tr>
                        ))

                        }
                    </table>
                </div>

            }
        </div>
    );
}

export default CommodityMainChange                                                  