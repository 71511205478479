import axios from '../api/axios';
import useAuth from './useAuth';
const useRefreshToken = () => {
    
    const { auth, setAuth } = useAuth();
    const REFRESH_URL = process.env.REACT_APP_LOGIN_API_URL + "/api/v1/refresh-token";
    const refresh = async () => {
        let tempRefreshToken = false
        const response = await axios.post(REFRESH_URL,
            "",
            {
                headers: { 'Content-Type': 'application/json', "x-refresh-token": auth?.refreshToken, 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL, },

            });
        const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
        const user = itemsData?.user
        const accessToken = response.data.access_token;
        const refreshToken = itemsData?.refreshToken;
        const roles = itemsData?.roles;
        const ratios = itemsData?.ratios;
        const historicalRatios = itemsData?.historicalRatios;
        const peerColumns = itemsData?.peerColumns;
        const newData = { user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken }
        localStorage.setItem('NKUserProfileInfo', JSON.stringify(newData))
        setAuth(prevAuth => ({ ...prevAuth, accessToken: response.data.access_token }))
        tempRefreshToken = response.data.access_token;
        return tempRefreshToken;
    }

    return refresh;
};

export default useRefreshToken;