import React, { useEffect } from 'react'; 
import './Terms.css'


function Terms(props) {
    window.scrollTo(0, 0);
    document.title = "Nivesh Karma - Terms & Privacy";
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])
    return (
        <div className="Terms commonContainer" >
            <div className="AboutUs-container" style={{ backgroundColor:"white" }}>
            <h2>Terms & Privacy</h2>
            <p>
                Welcome to <blue><b>NiveshKarma.Com</b></blue>
            </p>
            <p>
                <b>Overview about the service</b><br/>

                Nivesh Karma provides stock fundamental data, market data, ratios, valuation template and alternative data for stock markets.

                By using our Services, you are agreeing to following terms of service. Please read them carefully.
            </p>
            <p>
                <b>Terms</b><br/>
                Nivesh Karma is not directly engaged in rendering legal, accounting, investment advising, or other professional service. Your use of the Website and of any content, information or data accessed on or through the Website is at your own risk. We do not guarantee or make any warranties about the accuracy or completeness of any content, information or data accessed on or through the Website. Some content, information or data accessed on or through the Website may be inaccurate, incomplete, or unfit for a particular purpose. By accessing the Site, you are agreeing to be bound by our terms of service, all applicable laws, and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
            </p>
            <p>    
                <b>Redistribution Rights and Personal Use</b><br />

                You hereby agree to not redistribute or share access to data or derived results from the data obtained from Nivesh Karma with anyone or any 3rd party without written approval from Nivesh Karma.  
                Permission is granted to use the materials on Site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
            <br/>
                <ul>
                <li>modify or copy the materials.</li>
                    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial)</li>
                    <li>attempt to decompile or reverse engineer any software contained on Site.</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                </ul>
                <br />
                This license shall automatically terminate if you violate any of these restrictions and may be terminated by us at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
            </p>  
            <p>
                <b>Disclaimer and Warranties</b><br />
                The materials on Site are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.

                The data feed for the website is sourced and curated using automated algorithms from various data sources. The comments and opinions are based on automated formulas. These are prone to errors of automation. We are not responsible for the accuracy, reliability, performance, completeness, comprehensiveness, correctness, functionality, continuity, timeliness of the information, data or for any content available.
     
                Though we make the best efforts for accuracy; the materials appearing on Site could include technical, typographical, or photographic errors. We do not warrant that any of the materials on the Site are accurate, complete, or current. We may make changes to the materials contained on Site at any time without notice. However, we do not make any commitment to update the materials.
   
                Further, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
            </p>
            <p>   
                <b>Liability for our Services</b><br />
                Nivesh Karma will not be responsible for lost profits, revenues or data, financial losses or indirect, special, consequential, exemplary, or punitive damages.
  
                The total liability of site for any claims under these terms, including for any implied warranties, is limited to the amount that you paid us to use the services.
 
                In all cases, we will not be liable for any loss or damage that arises from the usage of our website.
            </p>
            <p>    
                <b>Links</b><br />
                We have not reviewed all of the links present on the Site and are not responsible for the contents of any such linked website. The inclusion of any link does not imply our endorsement. Use of any such linked website is at the user's own risk.
            </p>
            <p>
                <b>Modifications</b><br />
                These terms of service along with its supplements may be updated from time to time. We will update the last updated date to alert about the changes. It is your responsibility to periodically review these changes and stay informed about the updates. By using this website, you are agreeing to be bound by the then current version of these terms of service.
            </p>
            <p>    
                <b>Governing Law</b><br />
                These terms and conditions are governed by and construed in accordance with the laws of India. 
            </p>
            <p>   
                <b>Refund Policy</b><br />
                We do not offer refund for any plans on our website. It's your duty to cancel the subscription on time. You hereby agree to not use chargeback against our company in case of a dispute without reaching out to our support first.
            </p>
            <p>    
                <b>Failure to Comply with Terms and Conditions and Termination</b><br />
                Subscription to this Software and/or Service may be terminated at any time, and without cause, by either Nivesh Karma or the Owner and/or Subscriber, upon notification by telephone or e-mail. You acknowledge and agree that we may terminate your password or account or deny you access to all or part of the Service without prior notice if you engage in any conduct or activities that we, in our sole discretion, believe violate any of these Terms and Conditions, violate the rights of Nivesh Karma or is otherwise inappropriate for continued access.
            </p>
            <p>    
                <b>Privacy</b><br />
                The Website collects some personal information from its users, which we may use to operate the Website, to evaluate and enhance performance of the Website and to study usage patterns. We will not share any of your personal information with any third party, except we may share personal information with another company if we merge with or are acquired by that company. We may send you announcements, messages, or other information, but you may choose to opt-out of these communications.
            </p>
            <p>   
                <b>Contact Us</b><br />
                To resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: NiveshKarma@gmail.com
                </p>
            </div>
        </div>
    );
}
export default Terms;