import React, { useState, useEffect } from 'react';
import HelpModal from './HelpModal'
import ManageRatios from '../../ManageRatios/ManageRatios'
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import useAuth from '../../hooks/useAuth';
import Loading from "./Loading"


function Score(props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [viewLoading, setViewLoading] = useState(false)
    const { auth, setAuth } = useAuth();
    const [restrictCount, setRestrictCount] = useState(10);
    const [disableButton, setDisableButton] = useState(true);
    const numberFormat = (value) => {
        let text = value.toString();
        let result = text.includes("%");
        if (result) {
            return value
        }
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, ratios: itemsData.ratios }))
        }
        setShow(false)
    }
    const roundValue = (data) => {
        let text = data.toString();
        let result = text.includes("%");
        if (result) {
            return data
        }
        return Math.round(data)
    }

    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }

    const getClassName = (pointer) => {
        let pointerMinusOne = pointer - 1

        if (((pointer / 2) % 2 === 0) || ((pointerMinusOne / 2) % 2 === 0)) {
            return "OddDivS"
        } else {
            return "EvenDivS"
        }
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    const getProfileData = async () => {
        setData({})
        let url = "https://api.niveshkarma.com/api/v1/add-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        
        const response = await axiosPrivate.get(url);

        if (Object.keys(response.data).length !== 0) {
            if (auth.roles === true) {
                setRestrictCount(100)
            } else {
                setRestrictCount(10)
            }
            setData(response?.data)
        } else {
            setData({ "data": "Please select ratios" })
            setShow(false)
        }



    }

    

    const showManageRatios = () => {
        setShow(true)
        
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (auth.user) {
                getProfileData()
            } else {
                setData({ "data": "Login to access Custom Ratios" })
            }
        }, 50)
        return () => clearTimeout(timeout)
    }, [props.compSymbol, isProfileUpdated, auth.user])


    return (
        <div className="finColumn">
            {(Object.keys(data).length !== 0 || !auth.ratios) &&
                <>
                <div className="optionButtonPrimary"  style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                    <h3>User Ratios:</h3>


                    <Popup
                        className="popupChart"
                        trigger={
                            <svg
                            onClick={() => showManageRatios()}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                            height="30px"
                            width="30px"
                            {...props}
                        >
                            <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                        </svg>
                        }
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                        style={{ zIndex: "999" }}
                    />
                    
                    
                    <HelpModal title="Manage Your Ratios" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} disableButton={disableButton}>
                        <ManageRatios ratioData={props.ratioData} source="ratio" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton}/>
                    </HelpModal>
                </div>
            

                <div className="finColContainer" style={{ display: "grid", gridTemplateColumns: "auto auto", justifyContent: "", marginTop: "10px" }}>
                    
                {
                    Object.keys(data).map((item, index) => (
                        <div className={getClassName(index)} style={{width: "90%"}}>
                            <div className="summaryFont">
                                <div className="detailsHeading">

                                    <Popup
                                        className="popupChart"
                                        trigger={<div style={{ cursor: "pointer" }}>{getDisplayValueAndPrompt(item)[0]}</div>}
                                        content={<div className="borderedContent" style={{ padding: "5px", border: "2px solid black", background: "#fff" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                        style={{ zIndex: "999" }}
                                    />

                                    </div>
                                <div className="details">
                                    <div className="DetailsSubHeader">
                                    </div>
                                    <div className="DetailsValue">
                                        {data[item]}
                                    </div>
                                    <div className="DetailsEnd">
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))

                }
                

                </div>
                </>
            }
            
            {
                Object.keys(data).length === 0 && auth.ratios &&
                <Loading />
            }
        </div>
    );
}

export default Score;