import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Gear from "../../icon/Gear"
import Verified from "../../icon/Verified"
import ScorePeerMobile from './ScorePeerMobile'
import useAuth from '../../hooks/useAuth';
import Locked from '../../Locked'
import { Popup } from 'semantic-ui-react'

function ScoreMobile(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/3m-score/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        document.title = cdresponse.data.stock_name + "- 3M Score | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes | Investing checklist. ";
        let tempData = cdresponse.data
        tempData["sectorUrl"] = "/Sector/" + tempData.sector
        tempData["industryUrl"] = "/Industry/" + tempData.industry
        setpageData(tempData)
    }

    function getColorBackGroundMain(score) {
        if (score !== undefined) {
            let result = score.substring(0, 1);
            console.log(result)
            if (parseFloat(result) === 1) {
                return "#009900"
            } else if (parseFloat(result) === 2) {
                return "#669900"
            } else if (parseFloat(result) === 3) {
                return "#fac904"
            } else if (parseFloat(result) === 4) {
                return "#ff6666"
            } else if (parseFloat(result) === 5) {
                return "#ff0000"
            }
        }
    }


    function getColorBackGround(score) {
        if (score !== undefined) {
            if (parseFloat(score) >= 80) {
                return "#009900"
            } else if (parseFloat(score) >= 70) {
                return "#669900"
            } else if (parseFloat(score) >= 50) {
                return "#fac904"
            } else if (parseFloat(score) >= 40) {
                return "#ff6666"
            } else if (parseFloat(score) >= 0) {
                return "#ff0000"
            }
        }
    }

    function getWidth(score) {
        return score + "%"
    }


    const accessScore = () => {

        if (auth.roles) {
            return true
        }
        if (["HDFCBANK", "TCS", "ITC"].includes(props.compSymbol) && auth.user) {
            return true
        }

        return false
    }


    useEffect(() => {
        const timeout = setTimeout(() => {
        //    if (accessScore()) {
            companyDetails()
       //     }
        }, 50)
        return () => clearTimeout(timeout)

    }, [props.compSymbol, auth.user, auth.roles])


    return (
        <div >
            {/*   {accessScore() ? */}
                <>
                    <div style={{ width: "90%", paddingTop: "40px" }}>
                    <section className="optionsFinData">
                        <div className="leftAlign growthCompanyName">
                            <div>{pageData.stock_name}</div>
                        </div>

                    </section>



                    <section style={{ paddingTop: "10px", fontSize: "16px", paddingLeft: "10px", paddingRight: "10px" }}>
                        <div className="PeersOptionMobile" style={{ display: "grid", gridTemplateColumns: "auto auto", gridColumnGap: "40px" }}>
                            <div className="peerSection">
                                <div style={{ fontSize: "13px", fontWeight: "600" }}>
                                    Sector:
                            </div>
                                <div style={{ paddingLeft: "5px", fontSize: "13px" }}>
                                    <a href={pageData.sectorUrl} target="_blank" style={{ textDecoration: "None" }}> {pageData.sector}</a>
                                </div>
                            </div>
                            <div className="peerSection">
                                <div style={{ fontSize: "13px", fontWeight: "600" }}>
                                    Industry:
                            </div>
                                <div style={{ paddingLeft: "5px", fontSize: "13px" }}>
                                    <a href={pageData.industryUrl} target="_blank" style={{ textDecoration: "None" }}>{pageData.industry}</a>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section style={{ paddingTop: "10px" }}>
                        <div className="ScoreRow ContentSectionText">
                            <div className="ScoreHalfColumn">
                                <div style={{ textAlign: "left", display: "flex", justifyContent: "left", gap: "15px" }}><div><div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Company 3M Score:</div></div><div><div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600",color: getColorBackGroundMain(pageData.relative_rank) }}>{pageData.relative_rank}</div></div>
                                    {pageData.override_score_ind ?
                                        <div><Gear ind={pageData.override_score_ind} /></div>

                                        :
                                        <div><Verified ind={pageData.override_score_ind} /></div>
                                    }</div>


                                <div>
                                    <table className="ScoreTable" style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className="centerAlignedScore">Rating</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="20%"><b>Predictability</b></td>
                                                <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "20px" }}><div style={{ width: getWidth(pageData.predictability), backgroundColor: getColorBackGround(pageData.predictability), minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}>{pageData.predictability}</div></div></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><b>Moat</b></td>
                                                <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "20px" }}><div style={{ width: getWidth(pageData.moat), backgroundColor: getColorBackGround(pageData.moat), minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}>{pageData.moat}</div></div></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><b>Management</b></td>
                                                <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "20px" }}><div style={{ width: getWidth(pageData.management), backgroundColor: getColorBackGround(pageData.management), minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}>{pageData.management}</div></div></td>
                                            </tr>
                                            <tr>
                                                <td width="20%"><b>Margin of Saftey</b></td>
                                                <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "20px" }}><div style={{ width: getWidth(pageData.margin_of_safety), backgroundColor: getColorBackGround(pageData.margin_of_safety), minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}>{pageData.margin_of_safety}</div></div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="ScoreHalfColumn">
                                <div style={{ textAlign: "left" }}><div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Investor's Checklist</div></div>
                                <div style={{ color: "hsl(207, 12%, 43%)" }}>
                                    <div>
                                        This is a reference guide to analyze company operating in specific sector. This will help you gather essential information leveraging our ChatGPT model.
                                </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", marginBottom: "10px" }}>
                                        <div className="ChecklistGridContainerStart">
                                            Products & Services
                                    </div>
                                        <div className="ChecklistGridContainer">
                                            Competitors
                                    </div>
                                        <div className="ChecklistGridContainerEnd">
                                            Competitive Advantage
                                    </div>
                                        <div className="ChecklistGridContainerStart">
                                            Business Risks
                                    </div>
                                        <div className="ChecklistGridContainer">
                                            Market Share
                                    </div>
                                        <div className="ChecklistGridContainerEnd">
                                            Industry Info
                                    </div>
                                    </div>
                                    <div>
                                        A comprehensive stock investing checklist to navigate the markets with confidence.
                                </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                    <ScorePeerMobile filter={pageData.sector} compSymbol={props.compSymbol} />
            </>{/* :
                <Locked />
            } */}
        </div>
    );
}

export default ScoreMobile;