import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"




function Tooltip(props) {
    function getBackroundColor(index) {
        if (index % 2 == 0) {
            return "#E5E4E2"
        } else {
            return "white"
        }
    }

    function getColor(data) {
        if (data < 1000) {
            return "#C41E3A"
        } else if (data < 1000) {
            return "#FAA0A0"
        } else if (data < 10000) {
            return "#C9CC3F"
        } else if (data < 100000) {
            return "#32CD32"
        } else {
            return "#355E3B"
        }
    }

    return (
        <div className="TooltipMain">
            <div style={{ padding: "10px", backgroundColor: "#36454F", color: "white", display: "flex", justifyContent: "start" }}>
                <div style={{ fontSize: "16px", fontWeight: "600" }}>{props.parentName.subData}</div><div style={{ fontSize: "14px", fontWeight: "400", paddingTop: "1px" }}> &nbsp;- {props.parentName.data}</div>
            </div>
            <div style={{ padding: "10px", backgroundColor: getColor(props.parentName.leaf.data.change), color: "white", display: "grid", gridTemplateColumns: "60% 20% 20%", fontSize: "15px" }}>
                <div><div>{props.parentName.leaf.data.code}</div><div style={{ fontSize: "12px" }}>{props.parentName.leaf.data.name}</div></div><div>₹ {props.parentName.leaf.data.price}</div><div>{props.parentName.leaf.data.change}</div>
            </div>
            {props.data.length > 0 && props.data.map((leaf, i) => (
                < div key={i + 900000} style={{ padding: "10px", backgroundColor: "white", color: "#36454F", display: "grid", gridTemplateColumns: "60% 20% 20%", fontSize: "15px", marginTop: "2px" }}>
                    <div>{leaf.code}</div><div>₹ {leaf.price}</div><div style={{ color: getColor(leaf.change)}}>{leaf.change}</div>
                </div>
            ))
            }
 
        </div>

    );
}

export default Tooltip;