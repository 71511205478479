import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import './CompanyMobile.css'
import SummaryMobile from './SummaryMobile'
import FinancialMobile from './FinancialMobile'
import RatiosMobile from './RatiosMobile'
import GrowthMobile from './GrowthMobile'
import ValuationMobile from './ValuationMobile'
import ScoreMobile from './ScoreMobile'
import PeerComparisonWrapperMobile from './PeerComparisonWrapperMobile'
import CorporateActionMobile from './CorporateActionMobile'
import PeerMobile from './PeerMobile'
import HoldingsMobile from './HoldingsMobile'

function CompanyMobile(props) {
    const { companyName } = useParams();
    const [optionSelected, setValue] = useState('Summary');
    const [companySymbol, setcompanySymbol] = useState('');
    const [MaincompanyName, setmaincompanyName] = useState("");
    if (companyName != companySymbol) {
        setcompanySymbol(companyName)
    }
  
    return (
        <div className="Company">
            <section id="mainDetails">
                <div className="CompanyNavContainer">
                    <div className="CompanyNavMobile">
                        <div className="CompanyMenuMobile" id="Summary" onClick={() => {
                            setValue('Summary')
                        }
                        } style={optionSelected === "Summary" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }} >Summary</div>
                        <div className="CompanyMenuMobile" id="Financials" onClick={() => {
                            setValue('Financials')
                        }
                        } style={optionSelected === "Financials" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Financials</div>
                        <div className="CompanyMenuMobile" id="Ratios" onClick={() => {
                            setValue('Ratios')
                        }
                        } style={optionSelected === "Ratios" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Ratios</div>
                        <div className="CompanyMenuMobile" id="Valuations" onClick={() => {
                            setValue('Growth')
                        }
                        } style={optionSelected === "Growth" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Growth</div>
                        <div className="CompanyMenuMobile" id="Valuations" onClick={() => {
                            setValue('Valuations')
                        }
                        } style={optionSelected === "Valuations" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Valuations</div>
                        <div className="CompanyMenuMobile" id="Score" onClick={() => {
                            setValue('Score')
                        }
                        } style={optionSelected === "Score" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>3M&nbsp;Score</div>
                        <div className="CompanyMenuMobile" id="PeerComp" onClick={() => {
                            setValue('PeerComp')
                        }
                        } style={optionSelected === "PeerComp" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Peer&nbsp;Comparison</div>
                        <div className="CompanyMenuMobile" id="CorporateActions" onClick={() => {
                            setValue('CorporateActions')
                        }
                        } style={optionSelected === "CorporateActions" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Corporate&nbsp;Actions</div>
                        {/*<div className="CompanyMenu" id="Charts" onClick={() => {
                            setValue('Charts')
                            togglesummary(false)
                            togglefinancials(false)
                            toggleratios(false)
                            togglevaluations(false)
                            togglescore(false)
                            togglecorporateActions(false)
                            togglecharts(true)
                            togglepeers(false)
                            toggleholdings(false)
                        }
                        } style={charts ? { fontWeight: 'bold', color: '#4169e1' } : { fontWeight: 'bold' }}>Charts</div>*/}
                        <div className="CompanyMenuMobile" id="Peers" onClick={() => {
                            setValue('Peers')
                        }
                        } style={optionSelected === "Peers" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Peers</div>
                        <div className="CompanyMenuMobile" id="Holdings" onClick={() => {
                            setValue('Holdings')
                        }
                        } style={optionSelected === "Holdings" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}>Holdings</div>
                    </div>
                </div>
                <div id="mainContentSection">
                    {optionSelected === 'Summary' &&
                        <SummaryMobile compSymbol={companySymbol} setmaincompanyName={setmaincompanyName} compName={MaincompanyName} ratioData={props.ratioData} />

                    }
                    {optionSelected === 'Financials' &&
                        <FinancialMobile compSymbol={companySymbol} />

                    }
                    {optionSelected === "Ratios" &&
                        <RatiosMobile compSymbol={companySymbol} />
                    }
                    {optionSelected === "Growth" &&
                        <GrowthMobile compSymbol={companySymbol} />
                    }
                    {optionSelected === "Valuations" &&
                        <ValuationMobile compSymbol={companySymbol} />
                    }
                    {optionSelected === "Score" &&
                        <ScoreMobile compSymbol={companySymbol} />
                    }
                    {optionSelected === "PeerComp" &&
                        <PeerComparisonWrapperMobile compSymbol={companySymbol} />
                    }
                    {optionSelected === "CorporateActions" &&
                        <CorporateActionMobile compSymbol={companySymbol} />
                    }
                    {optionSelected === "Peers" &&
                        <PeerMobile compSymbol={companySymbol} ratioData={props.ratioData}/>
                    }
                    {optionSelected === "Holdings" &&
                        <HoldingsMobile compSymbol={companySymbol} />
                    }
                </div>


                
            </section>
        </div>
    )
}


export default CompanyMobile;