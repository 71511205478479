import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import DcfMobile from './DcfMobile'
import EpsMobile from './EpsMobile'
import './ValuationMobile.css'
import ValuationChartMobile from "./ValuationChartMobile"
import Loading from "../../Company/Summary/Loading"
import HelpModal from './Modal/CommonModal'


function ValuationMobile(props) {
    const [optionSelected, setValue] = useState('relativeValuation');
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const [show, setShow] = useState(false);
    const getURL = () => {
        if (optionSelected === "relativeValuation") {
            return process.env.PUBLIC_URL + '/Relative Valuation.pdf'
        } else if (optionSelected === "dcf") {
            return process.env.PUBLIC_URL + '/DCF Valuation Model.pdf'
        } else if (optionSelected === "eps") {
            return process.env.PUBLIC_URL + '/EPS Valuation Model.pdf'
        }
    }
    const getDisplayData = (data) => {
        if (data === "-" || isNaN(data)) {
            return data
        }
        if (data) {
            return parseFloat(data).toFixed(2)
        } else {
            return "-"
        }

    }
    const isNull = (data) => {
        if (data) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        const companyDetails = async () => {
            setpageData({})
            let url = ""
            url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const cdresponse = await axiosPrivate.get(url);
            document.title = cdresponse.data.stock_name + "- Valuation | Best Stock Valuation Tools | Relative Valuation | Discounted Cash Flow Model � DCF | EPS Model | Stock Covariance | Historical price multiples | Intrinsic Value Calculator | How to calculate stock intrinsic value? ";
            setpageData(cdresponse.data)
        }
        companyDetails()
    }, [props.compSymbol, props.consolidated])
    return (
        <div className="Financials">
            <section className="ValuationSection" style={{ paddingTop: "1px;" }}>
                <div className="FinancialNavMobile">
                    <div className="FinancialMenuMobile" id="relativeValuation" onClick={() => {
                        setValue('relativeValuation')
                    }
                    } style={optionSelected === "relativeValuation" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }} >Relative Valuation</div>
                    <div className="FinancialMenuMobile" id="dcf" onClick={() => {
                        setValue('dcf')
                    }
                    } style={optionSelected === "dcf" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b'  }}  >DCF</div>
                    <div className="FinancialMenuMobile" id="eps" onClick={() => {
                        setValue('eps')
                    }
                    } style={optionSelected === "eps" ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}  >EPS</div>

                </div>
            </section>
            <section className="optionsFinData" id="companyNameValuation">
                <div className="leftAlign" style={{ paddingTop: "30px" }}>
                    {pageData.stock_name}
                </div>
                <div className="rightAlign" style={{ paddingTop: "15px" }}>
                    

                </div>
            </section>
            <section className="ValuationSection">

                <div className="finance">
                    {optionSelected === "relativeValuation" &&
                        <div className="ValuationTable">
                            <div className="ValuationRow">
                                {Object.keys(pageData).length !== 0 &&
                                    <>
                                        <section className="Ratios">
                                            <table className="finDataMobile">
                                                <tbody>
                                                    {'PriceMultiples' in pageData &&
                                                        <tr>
                                                            <td className="leftBoldMobile stikeyHeader">Price Multiples</td>
                                                            <td className="columnHeadingMobile">{pageData.PriceMultiples[0]}</td>
                                                            <td className="columnHeadingMobile">{pageData.PriceMultiples[1]}</td>
                                                            <td className="columnHeadingMobile">{pageData.PriceMultiples[2]}</td>
                                                            <td className="columnHeadingMobile">{pageData.PriceMultiples[3]}</td>
                                                            <td className="columnHeadingMobile">{pageData.PriceMultiples[4]}</td>
                                                        </tr>
                                                    }
                                                    {'PriceToEarnings' in pageData && <tr>
                                                        <td className="leftBoldMobile stikeyHeader">Price to Earning</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToEarnings[0])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToEarnings[1])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToEarnings[2])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToEarnings[3])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToEarnings[4])}</td>
                                                    </tr>}
                                                    {'PriceToBookValue' in pageData && <tr>
                                                        <td className="leftBoldMobile stikeyHeader">Price to Book Value</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToBookValue[0])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToBookValue[1])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToBookValue[2])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToBookValue[3])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToBookValue[4])}</td>
                                                    </tr>}
                                                    {'PriceToTangibleBV' in pageData && <tr>
                                                        <td className="leftBoldMobile stikeyHeader">Price to Tangible Book Value</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToTangibleBV[0])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToTangibleBV[1])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToTangibleBV[2])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToTangibleBV[3])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToTangibleBV[4])}</td>
                                                    </tr>}
                                                    {'PriceToSales' in pageData && <tr>
                                                        <td className="leftBoldMobile stikeyHeader">Price to Sale</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToSales[0])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToSales[1])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToSales[2])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToSales[3])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToSales[4])}</td>
                                                    </tr>}
                                                    {'PriceToFCF' in pageData && <tr>
                                                        <td className="leftBoldMobile stikeyHeader">Price to FCF</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToFCF[0])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToFCF[1])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToFCF[2])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToFCF[3])}</td>
                                                        <td className="tabledataMobile SubHeader">{getDisplayData(pageData.PriceToFCF[4])}</td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                </section>
                                <div className="ValuationColumnOneQuarters" id="rightWarning" style={{ color: "grey", fontSize: "12px" }}>
                                            <span>Relative valuation is a highly useful and effective tool for valuing an asset. It relies on comparing how a company is priced to historical multiples or the current price multiples of similar assets. <br /><br />

However, it is essential to remember that price multiples can sometimes be misleading due to their dependence on daily price fluctuations and potential surprises in earnings caused by temporary events. Therefore, it is crucial to exercise caution when using them to make buy decisions. </span>
                                        </div>
                                    </>
                                } {Object.keys(pageData).length === 0 &&
                                    <Loading />
                                }
                            </div>
                        {<div className="ValuationRow">

                                <ValuationChartMobile compSymbol={props.compSymbol} />
                        </div>}

                        </div>
                    }
                    {optionSelected === "dcf" &&
                        <div className="ValuationTable" style={{ fontSize: "13px" }}>
                            <DcfMobile compSymbol={props.compSymbol} />
                        </div>
                    }
                    {optionSelected === "eps" &&
                        <div className="ValuationTable" style={{ fontSize: "14px" }}>
                            <EpsMobile compSymbol={props.compSymbol} />
                        </div>
                    }

                </div>
            </section>

        </div>
    );
}

export default ValuationMobile;