import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "./hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import Loading from "./Company/Loading"
import "./WatchlistCompanies.css"
import WatchlistSearch from './WatchlistSearch';

function WatchlistCompanies(props) {
    const [pageData, setpageData] = useState({});
    const [userSelection, setUserSelection] = useState({ label: 'Search for a Company', value: 1 });
    const axiosPrivate = useAxiosPrivate();

    const getUrl = (url) => {
        return "/Company/" + url
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }
    const runQuery = async () => {
        setpageData({})
        if (props.watchlistName) {
            let url = "https://api.niveshkarma.com/api/v1/user-watchlist?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY + "&list_name=" + props.watchlistName;

            const response = await axiosPrivate.get(url);
            setpageData({ "data": response.data })
        }
    }

    const handleChange = async (stock) => {
        setpageData({})
        let tempData = []
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        if (pageData.data.peers.length > 0) {
            for (let i = 0; i < pageData.data.peers.length; i++) {
                if (pageData.data.peers[i]["stock_symbol"] !== stock) {
                    tempData.push(pageData.data.peers[i]["stock_symbol"])
                }
            }           
            const response = await axiosPrivate.put(url, { list_name: props.watchlistName, watch_list: tempData});
            runQuery()
        }
    }

    function isStockAvailable(stock) {
        if (pageData.data.peers.length > 0) {
            for (let i = 0; i < pageData.data.peers.length; i++) {
                if (pageData.data.peers[i]["stock_symbol"] === stock) {
                    return true
                }
            }
        }
        return false
    }

    const updateWatchlist = async (userInput) => {
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        let tempData = props.watchlistData
        for (let i = 0; i < tempData.length; i++) {
            if (tempData[i]["list_name"] === props.watchlistName) {
                if (!tempData[i]["watch_list"].includes(userInput)) {
                    tempData[i]["watch_list"].push(userInput)
                }
            }
        }
        const response = await axiosPrivate.post(url, tempData);
        runQuery()
    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            runQuery()
        }, 50)
        return () => clearTimeout(timeout)
    }, [props.watchlistName])

    return (
        <>

            



           
            <div className="WatchlistCompanies">

                
                {props.optionData !== undefined && props.optionData.length > 0 &&
                    <div style={{ paddingTop: "30px", paddingBottom: "25px", display: "flex", columnGap: "50px" }}>
                        <div style={{ width: "400px" }}>
                        <WatchlistSearch optionData={props.optionData} userSelection={userSelection } setUserSelection={setUserSelection}/>
                        </div>
                    <div style={{ paddingTop: "3px" }}>
                        <button style={{ padding: "4px 20px" }} onClick={() => updateWatchlist(userSelection.value)}>Add to watchlist</button>
                        </div>
                    </div>
                }

                {Object.keys(pageData).length !== 0 &&
                    <>
                    {pageData.data.peers.length === 0 &&
                        <div style={{ paddingTop: "40px", paddingBottom: "100px", textAlign: "left" }}>There is no company added in this watchlist.</div>
                    }


                    {pageData.data.peers.length !== 0 &&
                        <div className="Ratios">
                        <table className="PeersTable finData">
                            <tbody>
                                <tr>
                                    <td className="leftPeer"></td>
                                    <td className="leftPeer" style={{ fontWeight: "600", fontSize: "14px", whiteSpace: "nowrap" }}>S.&nbsp;No.</td>
                                    <td className="leftPeer" style={{ fontWeight: "600", fontSize: "14px", whiteSpace: "nowrap" }}>Company Name</td>

                                    {pageData?.data?.keys.map(item => (
                                        <td className="columnHeadingScreener">
                                            <Popup
                                                className="popupChart"
                                                trigger={<div style={{ cursor: "pointer" }}>{getDisplayValueAndPrompt(item)[0]}</div>}
                                                content={<div className="borderedContent" style={{ padding: "5px", border: "2px solid black" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                style={{ zIndex: "999" }}
                                            />
                                        </td>
                                    ))

                                    }

                                </tr>
                            </tbody>
                            <tbody>
                                {pageData?.data?.peers.map((dataKey, index) => (
                                    <tr>
                                        <td className="leftPeer" style={{cursor:"pointer"}}>
                                            <div onClick={() => handleChange(dataKey.stock_symbol)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    {...props}
                                                >
                                                    <path
                                                        d="M10 12v5M14 12v5M4 7h16M6 10v8a3 3 0 003 3h6a3 3 0 003-3v-8M9 5a2 2 0 012-2h2a2 2 0 012 2v2H9V5z"
                                                        stroke="#ee4b2b"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                           
                                        </td>
                                        <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>{index + 1}</td>
                                        <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                        {pageData?.data?.keys.map(item => (
                                            <td className="tabledataPeer SubHeader">{dataKey[item]}</td>
                                        ))
                                        }



                                    </tr>
                                ))}
                            </tbody>
                            
                        </table>
                        
                    </div>
                    } 
                    </>
                } {Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </div>
        </>
    );
}
export default WatchlistCompanies;