import React, { useState, useEffect } from 'react';
import PdfThumbnail from './PdfThumbnail'
import axios from 'axios';
import Loading from "./Company/Loading"

function InvestingBooks(props) {
    const [userSelection, setUserSelection] = useState("InvestingBooks");
    const [pageData, setPageData] = useState([]);

    const getPageDetails = async () => {
        let url = ""
        url = "https://api.niveshkarma.com/pdf/images"
        const cdresponse = await axios.get(url);
        document.title = "Best stock investing books | Best books for stock market | Best Investing books | Books, Investment, Warren Buffett";
        setPageData(cdresponse.data)
    }

    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        getPageDetails()
    }, [])
    return (
        <div className="InvestingBooks">
            <div style={{ paddingTop: "10px" }}>
                <div className="CompanyNav" style={{ justifyContent: "flex-start" }}>
                    <div className="CompanyMenu" onClick={() => setUserSelection("InvestingBooks")} style={userSelection === "InvestingBooks" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>Investing Books</div>
                    <div className="CompanyMenu" onClick={() => setUserSelection("PersonalFinance")} style={userSelection === "PersonalFinance" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>Personal Finance</div>
                </div>
                <div className="PeersMain" style={{ paddingTop: "60px" }}>
                    {pageData.length !== 0 &&
                        <div className="Watchlist">
                        <section className="Peers">
                            <div className="BookGrid">
                                {userSelection === "InvestingBooks" && pageData.investing.length !== 0 && pageData.investing.map((data, index) => (
                                        <div style={{ maxWidth: "220px" }}>
                                            <div><PdfThumbnail imageData={data.image} DocumentName={data.pdfName} url={data.pdfURL} /></div>
                                            <div style={{ fontSize: "14px", paddingTop: "5px" }}>{data.pdfName}</div>
                                        </div>
                                ))}

                                {userSelection === "PersonalFinance" && pageData['personal-finance'].length !== 0 && pageData['personal-finance'].map((data, index) => (
                                    <div style={{ maxWidth: "220px" }}>
                                        <div><PdfThumbnail imageData={data.image} DocumentName={data.pdfName} url={data.pdfURL} /></div>
                                        <div style={{ fontSize: "14px", paddingTop: "5px" }}>{data.pdfName}</div>
                                    </div>
                                ))}
                                </div>
                            </section>
                        </div>
                    } {pageData.length === 0 &&
                        <Loading />
                    }
                </div>
                
            </div> 
        </div>
    );
}

export default InvestingBooks;