import React, { useEffect } from 'react';

function Scope(props) {
	useEffect(() => {
	}, [props.ind])

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 512 512"
			style={{ fill: props.ind === "scope" ? "#FFFFFF" : "#8e7cc3" }}>
			<g>
				<path className="st0" d="M161.73,380.835c-11.152-7.313-26.811-12.469-48.046-12.455c-28.335-0.05-46.796,9.24-57.814,20.333
		c-5.518,5.525-9.185,11.427-11.488,16.735c-2.296,5.337-3.277,9.942-3.309,13.57c0.025,3.642,0.988,8.27,3.269,13.621
		c3.426,7.966,9.946,17.306,21.105,24.613c11.145,7.321,26.811,12.477,48.042,12.469c28.335,0.036,46.797-9.24,57.818-20.355
		c5.511-5.532,9.178-11.442,11.485-16.742c2.295-5.344,3.276-9.957,3.309-13.578c-0.026-3.656-0.992-8.262-3.27-13.613
		C179.409,397.483,172.885,388.149,161.73,380.835z M173.022,428.381c-2.726,6.278-7.969,13.874-17.245,19.95
		c-9.287,6.068-22.723,10.731-42.289,10.746c-26.065-0.044-41.191-8.226-50.16-17.184c-4.478-4.511-7.408-9.29-9.207-13.461
		c-1.803-4.142-2.426-7.806-2.404-9.399c-0.018-1.557,0.612-5.207,2.429-9.349c2.727-6.256,7.969-13.86,17.249-19.928
		c9.286-6.06,22.722-10.717,42.288-10.731c26.068,0.036,41.199,8.212,50.164,17.176c4.479,4.504,7.408,9.276,9.207,13.44
		c1.8,4.142,2.422,7.798,2.4,9.392C175.47,420.59,174.844,424.239,173.022,428.381z"/>
				<path className="st0" d="M446.43,380.85c-11.173-7.321-26.865-12.484-48.118-12.47c-28.306-0.05-46.728,9.211-57.713,20.319
		c-5.5,5.518-9.146,11.427-11.434,16.734c-2.282,5.352-3.248,9.957-3.27,13.599c0.029,3.635,1.018,8.248,3.31,13.592
		c3.447,7.951,10,17.292,21.185,24.613c11.169,7.321,26.861,12.492,48.118,12.484c28.306,0.036,46.728-9.225,57.713-20.326
		c5.496-5.532,9.149-11.442,11.434-16.756c2.277-5.351,3.244-9.978,3.269-13.606c-0.033-3.642-1.014-8.248-3.309-13.584
		C464.172,397.505,457.614,388.164,446.43,380.85z M457.875,428.432c-2.708,6.264-7.915,13.86-17.162,19.913
		c-9.254,6.061-22.657,10.717-42.206,10.732c-26.094-0.044-41.264-8.248-50.276-17.227c-4.5-4.504-7.447-9.29-9.258-13.469
		c-1.818-4.142-2.451-7.792-2.433-9.363c-0.022-1.579,0.6-5.235,2.404-9.377c2.701-6.257,7.915-13.845,17.158-19.899
		c9.254-6.054,22.657-10.703,42.209-10.717c26.094,0.036,41.268,8.24,50.279,17.213c4.5,4.511,7.448,9.275,9.258,13.446
		c1.818,4.142,2.447,7.792,2.426,9.356C460.301,420.626,459.678,424.29,457.875,428.432z"/>
				<path className="st0" d="M94.237,22.488l0.373-0.232c0.011-0.008,0.015-0.014,0.022-0.022L94.237,22.488z" />
				<path className="st0" d="M430.93,35c0.004,0.007,0.008,0.014,0.008,0.014l0.597,0.862L430.93,35z" />
				<path className="st0" d="M474.812,119.962c0.008,0.008,0.015,0.022,0.015,0.022l0.764,1.058L474.812,119.962z" />
				<path className="st0" d="M485.421,151.512h0.394l-1.006-6.04l-0.471-4.685h-0.315c-1.47-7.444-4.638-14.483-9.192-20.797l-0.022-0.029
		c-8.226-11.361-20.576-20.623-35.562-27.278l-3.179-50.812h-1.622c-0.923-2.389-2.027-4.714-3.508-6.85L430.927,35
		c-5.217-7.524-13.056-13.331-22.303-17.444l-0.008-0.007c-9.276-4.092-20.152-6.496-31.814-6.496h-0.004
		c-15.514,0.014-29.371,4.229-39.87,11.296h-0.007c-5.243,3.548-9.707,7.857-12.962,12.926c-3.258,5.047-5.296,10.956-5.296,17.249
		v45.982c-0.739,0.413-1.438,0.775-2.191,1.231c-9.054,5.598-16.694,12.347-22.234,20.196c-5.536,7.835-8.965,16.944-8.961,26.626
		v0.087v0.022v39.211h-58.552v-39.218v-0.008v-0.007v-0.087c0-9.682-3.429-18.791-8.968-26.633
		c-5.97-8.444-14.436-15.518-24.421-21.34V52.524c0-6.293-2.038-12.202-5.297-17.256l-0.481-0.739l0.478,0.739
		c-4.898-7.567-12.426-13.505-21.438-17.661l0.804,0.369l-0.815-0.377c-9.044-4.142-19.747-6.546-31.394-6.546
		c-15.54,0.022-29.689,4.229-40.548,11.173l-0.033,0.029C89.194,25.746,84.534,29.982,81.062,35
		c-1.477,2.15-2.585,4.475-3.516,6.872h-1.618l-3.186,50.957c-4.775,2.107-9.381,4.402-13.596,7.053h-0.004
		c-8.888,5.619-16.383,12.375-21.959,20.08c-4.562,6.314-7.738,13.367-9.211,20.826h-0.311L0,412.733l0.499,0.05
		c0.138,25.287,13.693,47.669,34.139,63.173l0.004,0.007l-0.007-0.007c0.01,0.007,0.014,0.015,0.025,0.03l1.032,0.782l-0.908-0.695
		c20.58,15.568,48.274,24.859,78.647,24.874h0.004c30.482-0.022,58.299-9.349,78.948-25.026l-1.412,1.071l1.408-1.065
		c19.171-14.518,32.325-35.033,34.114-58.335h0.232v-3.766v-1.542l0.007-5.322h-0.007v-26.141h58.552v31.311v0.152v1.542h0.054
		c0.598,24.852,14.095,46.815,34.288,62.101l1.057,0.804l-1.06-0.81c20.648,15.677,48.469,25.003,78.944,25.026h0.003
		c30.45-0.015,58.209-9.334,78.8-24.99h-0.004c20.446-15.496,33.998-37.879,34.139-63.165l0.504-0.05L485.421,151.512z
		 M90.932,57.846h0.311l0.015-5.308c0.008-2.694,0.945-5.54,2.958-8.479c2.998-4.388,8.469-8.748,15.62-11.898
		c7.143-3.165,15.913-5.142,25.355-5.142c12.582-0.007,23.476,3.512,30.946,8.567c3.74,2.52,6.604,5.409,8.468,8.32
		c1.876,2.926,2.763,5.815,2.763,8.618v44.041c-14.638-5.946-31.902-9.465-50.421-9.465c-13.737,0-26.583,1.992-38.187,5.424
		L90.932,57.846z M210.753,412.255c-0.047,19.588-10.471,37.568-28.034,50.957c-17.531,13.338-42.082,21.774-69.284,21.76
		c-27.172,0.014-51.663-8.4-69.125-21.717c-17.494-13.36-27.85-31.296-27.853-50.863v-0.109
		c0.044-19.594,10.467-37.575,28.031-50.956c17.531-13.346,42.083-21.782,69.284-21.775c27.177-0.007,51.663,8.407,69.129,21.731
		c17.495,13.353,27.85,31.289,27.854,50.848V412.255z M210.753,146.646V376.15c-5.438-8.776-12.665-16.705-21.405-23.339
		c-19.551-14.88-46.21-23.889-75.577-23.896c-29.389,0.006-56.112,9.029-75.725,23.939c-6.861,5.2-12.802,11.21-17.709,17.756
		L43.07,147.153l0.004-0.051c0.511-5.996,2.89-12.013,7.053-17.784c6.234-8.653,16.524-16.618,29.751-22.332
		c13.219-5.736,29.327-9.24,47.068-9.24c23.632-0.015,45.044,6.198,60.189,15.576c7.574,4.671,13.566,10.116,17.571,15.815
		c4.008,5.706,6.039,11.55,6.046,17.422v0.079l5.322,0.008H210.753z M290.598,364.847h-69.201v-9.588h69.201V364.847z
		 M290.598,344.622h-69.201V201.846h69.201V344.622z M334.629,52.524c0.004-2.803,0.887-5.692,2.762-8.618
		c2.796-4.366,7.872-8.668,14.686-11.788c6.807-3.136,15.294-5.098,24.726-5.098c12.592-0.007,23.986,3.534,31.919,8.646
		c3.968,2.549,7.056,5.482,9.058,8.393c2.013,2.939,2.948,5.785,2.958,8.479l0.01,5.308h0.315l2.169,34.657
		c-11.597-3.44-24.457-5.403-38.18-5.403c-18.527,0.008-35.779,3.527-50.424,9.48V52.524z M301.247,146.632v-0.073
		c0-5.873,2.031-11.716,6.043-17.422c5.996-8.552,16.51-16.503,30.04-22.202c13.524-5.706,29.994-9.189,47.724-9.189
		c23.642-0.015,44.406,6.242,59.269,15.641c7.434,4.699,13.386,10.167,17.543,15.93c4.167,5.771,6.542,11.788,7.056,17.784
		l0.007,0.036l22.73,223.472c-4.91-6.561-10.844-12.556-17.709-17.756c-19.613-14.91-46.336-23.933-75.725-23.939
		c-29.363,0.006-56.029,9.015-75.576,23.896c-8.737,6.634-15.964,14.562-21.402,23.339V146.646h-5.323L301.247,146.632z
		 M495.541,412.393c-0.004,19.566-10.362,37.502-27.854,50.863c-17.462,13.317-41.952,21.731-69.125,21.717
		c-27.198,0.014-51.752-8.422-69.284-21.76c-17.56-13.389-27.991-31.369-28.031-50.957l-5.323,0.015l5.323-0.029v-0.109
		c0-19.559,10.355-37.495,27.85-50.848c17.465-13.324,41.952-21.738,69.128-21.731c27.202-0.007,51.753,8.43,69.284,21.775
		c17.56,13.381,27.988,31.362,28.031,50.949V412.393z"/>
			</g>
        </svg>
    )
}
export default Scope;