import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "../../Company/Summary/Loading"
import HelpModal from '../../Company/HelpModal'
import { Popup } from 'semantic-ui-react'
import ManageRatiosMobile from '../ManageRatios/ManageRatiosMobile'
import { downloadExcel } from "react-export-table-to-excel";
import useAuth from '../../hooks/useAuth';


function PeerMobile(props) {
    const [pageData, setpageData] = useState({});
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [show, setShow] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const axiosPrivate = useAxiosPrivate();
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);
    const getUrl = (url) => {
        return "/Company/" + url
    }
    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShow(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]

        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))

        return returnData
    }
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const roundData = (data) => {
        if (data) {
            return Math.round(data)
        } else {
            return "-"
        }
    }

    const showManageRatios = () => {
        setShow(true)
        // if (auth.user) {
        //     setShow(true)
        // } else {
        //     login()
        //  }

    }
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/peers/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data["peers"]
        let sector = cdresponse.data["sector"]
        let industry = cdresponse.data["industry"]
        let companyName = cdresponse.data["stock_name"]
        document.title = companyName + "- Peers | Key Stats Comparison | I Industry and Sector Peers ";
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(10)
        }
        setpageData({
            "peer": userData,
            "sector": sector,
            "industry": industry,
            "sectorUrl": "/Sector/" + sector,
            "industryUrl": "/Industry/" + industry,
            "CompanyName": companyName,
            "RatioCode": cdresponse.data["keys"]
        })
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.RatioCode.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData?.peer.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.RatioCode.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Peer",
            sheet: "Peer",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, isProfileUpdated, auth.user])




    return (
        <div className="PeersMain">
            <section className="optionsFinData">
                <div className="leftAlign growthCompanyName">
                    {pageData.CompanyName}
                </div>
                <div className="rightAlign" style={{ paddingTop: "10px" }}>
                    <div className="optionButtonPrimary">
                        <div className="optionButtonMobile" onClick={() => showManageRatios()}> Manage Columns</div>
                        <HelpModal title="Manage Columns" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                            <ManageRatiosMobile ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                        </HelpModal>
                    </div>
                   
                </div>
            </section>
            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>
                        <div className="PeersOption" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <div className="peerSection">
                            <div style={{ fontSize: "13px", fontWeight: "600" }}>
                                    Sector:
                            </div>
                            <div style={{ paddingLeft: "5px", fontSize: "13px"  }}>
                                    <a href={pageData.sectorUrl} target="_blank" style={{ textDecoration: "None" }}> {pageData.sector}</a>
                                </div>
                            </div>
                            <div className="peerSection">
                            <div style={{ fontSize: "13px", fontWeight: "600"  }}>
                                    Industry:
                            </div>
                            <div  style={{ paddingLeft: "5px", fontSize: "13px"  }}>
                                    <a href={pageData.industryUrl} target="_blank" style={{ textDecoration: "None" }}>{pageData.industry}</a>
                                </div>
                            </div>
                        </div>
                        {pageData.RatioCode &&
                            <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                                <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                    <thead>
                                        <tr>
                                            <th className="leftPeer intersection" style={{ fontWeight: 600 }}>S.&nbsp;No.</th>
                                            <th className="leftPeer intersection" style={{ fontWeight: 600 }}>Company Name</th>

                                            {pageData.RatioCode.map(item => (
                                                <th className="columnHeadingScreener">
                                                    <Popup
                                                        className="popupChart"
                                                        trigger={<div style={{ cursor: "pointer" }}>{getDisplayValueAndPrompt(item)[0]}</div>}
                                                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                        style={{ zIndex: "999" }}
                                                    />
                                                </th>
                                            ))

                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pageData.peer.map((dataKey, index) => (
                                            <tr>
                                                <td className="leftPeer">{index + 1}</td>
                                                <td className="leftPeer" style={{ maxWidth: "100px"}}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                                {pageData.RatioCode.map(item => (
                                                    <td className="tabledataPeer SubHeader">{dataKey[item]}</td>
                                                ))
                                                }



                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>
        </div>
    );
}

export default PeerMobile;