import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import WatchlistCompaniesMobile from './WatchlistCompaniesMobile'
import useAuth from '../hooks/useAuth';
import ManageRatiosMobile from './ManageRatios/ManageRatiosMobile'
import HelpModal from '../Company/HelpModal'
import Locked from '../Locked'
import Loading from '../Company/Valuations/Loading';

function WatchlistMobile(props) {
    const { watchlistName } = useParams();
    const [pageData, setpageData] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false)
    const [wathlistUpdated, setWatchlistUpdated] = useState(true)
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [show, setShow] = useState(false);
    const getDefaultSelection = () => {
        if (watchlistName !== undefined) {
            return watchlistName
        } else {
            return pageData[0]
        }
    }

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShow(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const showManageRatios = () => {
        setShow(true)
    }
    const getUrl = (url) => {
        return "/Company/" + url
    }
    const [userSelection, setUserSelection] = useState(getDefaultSelection());
    const axiosPrivate = useAxiosPrivate();
    document.title = "Nivesh Karma - Watchlist | Create & view multiple stock Watchlists. ";
    console.log(userSelection)

    const toggleWatchlistUpdate = () => {
        if (wathlistUpdated) {
            setWatchlistUpdated(false)
        } else {
            setWatchlistUpdated(true)
        }
    }



    const handleDelete = async () => {
        setpageData([])
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const response = await axiosPrivate.delete(url, { data: { list_name: userSelection } });
        toggleWatchlistUpdate()

    }



    const runQuery = async () => {
        setpageData([])
        setPageLoaded(false)
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const response = await axiosPrivate.get(url);
        let tempData = []
        if (response.data.watch_lists != null) {
            for (let i = 0; i < response.data.watch_lists.length; i++) {
                tempData.push(response.data.watch_lists[i]["list_name"])
            }
            if (watchlistName !== undefined) {
                setUserSelection(watchlistName)
            } else {
                setUserSelection(tempData[0])
            }
            if (auth.roles === true) {
                setRestrictCount(30)
            } else {
                setRestrictCount(5)
            }
        }
        setPageLoaded(true)
        setpageData(tempData)
    }

    useEffect(() => {
        console.log(auth.user)
        if (auth.user) {
            const timeout = setTimeout(() => {
                runQuery()
            }, 50)
            return () => clearTimeout(timeout)
        }
    }, [wathlistUpdated, isProfileUpdated, auth.user])

    return (
        <div style={{ paddingTop: "10px" }}>
            {auth.user &&
                <>
                    <section className="optionsFinData" style={{ paddingTop: "20px"}}>
                        <div className="leftAlign growthCompanyName" >
                            &nbsp;
                        </div>
                        <div className="rightAlign">
                            <div className="optionButtonPrimary">
                                <div className="optionButtonMobile" onClick={() => showManageRatios()}> Manage Columns</div>
                                <HelpModal title="Manage Columns" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                                <ManageRatiosMobile ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                                </HelpModal>
                            </div>
                        </div>
                </section>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gridColumnGap: "5px", gridRowGap: "5px", fontSize: "13px" }}>
                        {pageData.length !== 0 &&
                            pageData.map(item => (
                                <div className="CompanyMenu" onClick={() => setUserSelection(item)} style={userSelection === item ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>{item}</div>
                            ))
                        }
                    </div>
                    <div >
                        <div className="Watchlist">
                            {pageData.length !== 0 &&
                                <section className="Peers">
                                    <WatchlistCompaniesMobile watchlistName={userSelection} ratioData={props.ratioData} />
                                    {pageData.length !== 0 && pageLoaded &&
                                <div style={{ paddingTop: "50px", marginBottom: "50px", float: "right" }}>
                                    <div className="optionButton" style={{ width: "120px", backgroundColor: "#ee4b2b", borderColor: "#ee4b2b", fontSize: "13px" }} onClick={() => handleDelete()}>
                                                Delete&nbsp;Watchlist
                            </div>
                                        </div>
                                    }
                                </section>
                            }
                            {pageData.length === 0 && pageLoaded &&
                                <div> There are no active watchlists created for your account. </div>
                            }


                            <div>
                                {!pageLoaded &&
                                    <Loading />

                                }
                            </div>

                        </div>
                    </div>

                </>
            }
            {!auth.user &&
                <Locked />
            }
        </div>
    );
}
export default WatchlistMobile;