import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import Loading from "../../../Company/Summary/Loading"
import FinData from "./FinData"
import { Chart } from "react-google-charts";


export const options = {
    title: "Cash from Operating Activity and Free cashflow",
    legend: { position: 'none' },
    bar: { groupWidth: '100%' },
    colors: ['#005073', '#1ebbd7', '#e9724d'],
};

export const optionsTwo = {
    title: "COA CIA and CFA",
    legend: { position: 'none' },
    bar: { groupWidth: '100%' },
    colors: ['#005073', '#1ebbd7', '#e9724d'],
};



function CashflowMobile(props) {
    const [pageData, setpageData] = useState({
        "data": [],
        //    "horizontal_analysis": [],
        "extended_mapping": [],
        "mapData": [],
        "mapDataTwo": [],
        "CompanyName": ""
    });
    const [viewFull, setViewFull] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    // const [collapse, setcollapse] = useState(false);
    // const [modal, setmodal] = useState(false);
    const [sort, setsort] = useState(false);
    function getDisplayValue(data, source) {
        if (data) {
            return data
        }
        if (source !== "m") {
            return source
        }
        return ""
    }
    const companyDetails = async () => {
        setpageData({
            "data": [],
            //    "horizontal_analysis": [],
            "extended_mapping": [],
            "mapData": [],
            //    "mapDataTwo": [],
            "CompanyName": ""
        })
        let url = ""
        //  let url_two = ""
        let cashflow_data = ""
        //  let profitLoss_data = ""
        let cashflow_map = ""
        let horizontal_data = ""
        let map_data = []
        let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/cash-flow/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        //   url_two = process.env.REACT_APP_DATA_API_URL + "/profitloss_consolidated/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        //   const cdresponseTwo = await axios.get(url_two, { headers });
        console.log(cdresponse)
        if (sort) {
            cashflow_data = cdresponse.data['cash_flow']
            horizontal_data = cdresponse.data['yoy_growth']
            //    horizontal_data = await cdresponse.data['horizontal_analysis']
        } else {
            cashflow_data = cdresponse.data['cash_flow'].reverse()
            horizontal_data = cdresponse.data['yoy_growth'].reverse()
            //    horizontal_data = await cdresponse.data['horizontal_analysis'].reverse()
        }
        cashflow_map = cdresponse.data['prompts']
        company_name = cdresponse.data['stock_name']

        map_data.push(["", "Cash from Operating Activity", "Free Cashflow"])
        cashflow_data.map(mapdata => (
            map_data.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['netOperatingCashFlow'], 'm')), parseFloat(getDisplayValue(mapdata['fcf'], 'm'))])
        ))

        map_data_two.push(["", "Cash from Operating Activity", "Cash from Investing Activity", "Cash from Financing Activity"])
        cashflow_data.map(mapdata => (
            map_data_two.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['netOperatingCashFlow'], 'm')), parseFloat(getDisplayValue(mapdata['netInvestingCashFlow'], 'm')), parseFloat(getDisplayValue(mapdata['netCashFinancingActivities'], 'm'))])
        ))
        document.title = company_name + "- Cash Flows Statement | Horizonal Analysis | Free Cash Flow (FCF)";
        setpageData({
            "data": cashflow_data,
            "yoyGrowth": horizontal_data,
            "extended_mapping": cashflow_map,
            "mapData": map_data,
            "mapDataTwo": map_data_two,
            "CompanyName": company_name
        })

    }


    useEffect(() => {
        companyDetails()

    }, [props.compSymbol, sort])

    return (

        <>
            {!viewFull ?
                <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    <div className="leftAlign" style={{ paddingBottom: "40px" }}>
                        {pageData.CompanyName}
                    </div>
                    {pageData.mapData.length > 0 &&
                        <>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "right" }}>
                        <div className="optionButtonMobile" onClick={() => setViewFull(true)}> View full Statement</div>
                    </div>
                        <br />
                        <br />
                        <Chart
                            chartType="Bar"
                            data={pageData.mapDataTwo}
                            options={optionsTwo}
                            loader={<div>Loading Chart</div>}

                        />
                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "20px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: "#005073" }}></div><div>&nbsp;CFO</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: "#1ebbd7" }}></div><div>&nbsp;CFI</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: "#e9724d" }}></div><div>&nbsp;CFF</div></div>
                        </div>
                        <br />
                        <br />
                        <Chart
                        chartType="Bar"
                        data={pageData.mapData}
                        options={options}
                        loader={<div>Loading Chart</div>}

                        />
                        

                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "20px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: "#005073" }}></div><div>&nbsp;Cash from Operating Activity</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: "#1ebbd7" }}></div><div>&nbsp;Free Cash Flow</div></div>
                        </div>
                        </>
                    }
                    {pageData.mapData.length === 0 &&
                        <Loading />
                    }
                </div> :
                <div>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "right" }}>
                        <div className="optionButtonMobile" onClick={() => setViewFull(false)}> View Summary Chart</div>
                    </div>
                    <FinData pageData={pageData} sort={sort} setsort={setsort} source="cashflow" horizontal={true} vertical={false} />
                </div>
            }
            </>
    );
}

export default CashflowMobile;