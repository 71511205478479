import React, {useEffect } from "react";
import './Searchbox.css';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select'
import { useNavigate } from 'react-router-dom';


function Searchbox(props) {
    const navigate = useNavigate();
    const filterCompany = (inputValue) => {
        return props.optionData.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = (inputValue) =>
        new Promise ((resolve) => {
            setTimeout(() => {
                resolve(filterCompany(inputValue));
            }, 100);
        });

    function updateHistory(newElement) {
        if (props.searchHistory.length < 4) {
            props.setSearchHistory([...props.searchHistory, newElement])
            localStorage.setItem("nkSearchHistory", JSON.stringify([...props.searchHistory, newElement]));
        } else {
            let tempArray = props.searchHistory
            tempArray.splice(0, 1);
            tempArray.push(newElement)
            props.setSearchHistory(tempArray)
            localStorage.setItem("nkSearchHistory", JSON.stringify(tempArray));
        }
    }

    const navigateCompany = (inputValue) => {
        updateHistory(inputValue.value)
        props.setShow(false)
        document.getElementById("HeaderSearchBox").value = { label: 'Search for a Company', value: 1 }
        navigate("/Company/"+inputValue.value );
        
    }



    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <SearchIcon />
                </components.DropdownIndicator>
            )
        )
    }

    const SearchIcon = () => (
        <svg
            width={props.origin === "Home" ? "35" : "25"}
            height={props.origin === "Home" ? "35" : "25"}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
            <path
                d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
                fill="currentColor"
            />
        </svg>
    )



    useEffect(() => {
    }, [props.optionData]);

    return (
        <div className="Searchbox" style={props.origin === "Home" ? { fontSize: "22px", color: "grey", height: "35px", borderRadius: "35px", paddingTop: "10px", paddingBottom: "10px" } : { fontSize: "14px", color: "grey", height: "30px" }}>
            <AsyncSelect id="HeaderSearchBox" components={{ DropdownIndicator, IndicatorSeparator: () => null}} defaultOptions loadOptions={promiseOptions} onChange={navigateCompany} value={{ label: 'Search for a Company', value: 1 }} />
        </div>
    );
}

export default Searchbox