import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import useAxiosPrivate from "./hooks/useAxiosPrivate"




export const options = {
    title: "Sector % analysis",

    legend: { position: "none" },
    bar: { groupWidth: '70%' },
    hAxis: {
        minValue: 0,
        maxValue: 100
    }
};

function InvestorSectorChart(props) {
    

    

    const [mapData, setMapData] = useState({})
    const axiosPrivate = useAxiosPrivate();

    const removePercent = (data) => {
        return data.replace("%", "")
    }
    const addMapData = async() => {

        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/investor-sector/" + props.investor + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let userData = cdresponse.data
        
        let tempMapData = []
        let label = []
        userData.investorSector.map(data => {
            tempMapData.push(parseFloat(removePercent(data.pctOfProfile)))
            label.push(data.sector)
        })

        setMapData({
            options: {
                chart: { id: "Investor Info" },
                colors: ['#455EB5', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                dataLabels: { enabled: false },
                xaxis: {
                    categories: label,
                    max: 100
                },
                yaxis: [{ forceNiceScale: true }],
                chart: {
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },

            },
            series: [{ name: "Sector", data: tempMapData }]
        })
    }
    useEffect(() => {
        addMapData()
    }, [])

    return (
        <div className="SectorChart" style={{ paddingTop: "20px" }}>
            <h2 style={{ textAlign: "left" }}>Sector Analysis</h2>
            <div style={{ backgroundColor: "white", borderRadius:"10px" }}>
            {Object.keys(mapData).length > 0 && 
                <Chart
                    options={mapData.options}
                    series={mapData.series}
                    type="bar"
                    height="400"
                    />
                }</div>
        </div>
    )

}


export default InvestorSectorChart;