import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "../../Company/Summary/Loading"
import AboutFullMobile from './AboutFullMobile'
import HelpModal from './Modal/CommonModal'

function AboutMobile(props) {
    const [pageData, setpageData] = useState({});
    const [show, setShow] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/profile/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData({
            "Sector": cdresponse.data.gsector,
            "Industry": cdresponse.data.gind,
            "Description": cdresponse.data.description,
            "sectorUrl": "/Sector/" + cdresponse.data.gsector,
            "industryUrl": "/Industry/" + cdresponse.data.gind
        })
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div style={{ overflowY: "auto", padding: "20px"}}>
            {Object.keys(pageData).length !== 0 &&
                <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600", paddingTop: "5px" }}>
                            Company Profile
                    </div>
                    <div className="optionButtonMobile" onClick={() => setShow(true)}> View More
                        <HelpModal title="Company Info" onClose={() => setShow(false)} show={show}>
                                <AboutFullMobile compSymbol={props.compSymbol} />
                            </HelpModal>
                        </div>
                </div>
                <div className="MainRow"><div className="SubRow"><div style={{ fontSize: "13px", fontWeight: "600" }}>Sector:&nbsp; </div><div style={{ fontSize: "13px" }}> <a href={pageData["sectorUrl"]} target="_blank">{pageData["Sector"]}</a></div></div><div className="SubRow"><div style={{ fontSize: "13px", fontWeight: "600"  }}>Industry:&nbsp; </div><div style={{ fontSize: "13px" }}>  <a href={pageData["industryUrl"]} target="_blank">{pageData["Industry"]}</a></div></div></div>
                <div className="MainRow"><div className="summaryFont"><div style={{ fontSize: "13px" }}></div> <div style={{ fontSize: "13px" }}><div style={{ fontSize: "13px" }}></div><div style={{ textAlign: "left" }}>{pageData["Description"]}</div><div className="DetailsEnd"></div></div></div></div>
                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}
export default AboutMobile;