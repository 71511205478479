import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "./Loading"
import Employee from './Employee'
import ExternalLink from '../../icon/ExternalLink'


function AboutFull(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/profile/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData({
            "Sector": cdresponse.data.gsector,
            "Industry": cdresponse.data.gind,
            "Description": cdresponse.data.description,
            "ReportLink": "https://www.bseindia.com/stock-share-price/equity/scripcode/" + cdresponse.data.bse_code + "/corporate-governance",
            "sectorUrl": "/Sector/" + cdresponse.data.gsector,
            "industryUrl": "/Industry/" + cdresponse.data.gind
        })
    }

    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])


    return (
        <div className="AboutFull">
            {Object.keys(pageData).length !== 0 &&
                <>
                <h3>Company Profile</h3>
                <div className="compProfile"><div className="MainRow"><div className="SubRow"><div className="AboutHeader">Sector:&nbsp; </div><div className="AboutValue"> <a href={pageData["sectorUrl"]} target="_blank">{pageData["Sector"]}</a></div></div><div className="SubRow"><div className="AboutHeader">Industry:&nbsp; </div><div className="AboutValue">  <a href={pageData["industryUrl"]} target="_blank">{pageData["Industry"]}</a></div></div></div><div className="corpGrev" style={{ display: "flex" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a href={pageData.ReportLink} target="_blank" style={{ textDecoration: "none" }}>Corporate Governance</a></div></div></div>
                <div className="OddDivSProfile"><div className="summaryFont"><div className="detailsHeading"></div> <div className="details"><div className="DetailsSubHeader"></div><div className="AboutValue">{pageData["Description"]}</div><div className="DetailsEnd"></div></div></div></div>
                <br />
                <Employee compSymbol={props.compSymbol}/>
            </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default AboutFull;