import React, { useState }  from 'react';
import PersonalDetails from "./PersonalDetailsMobile"
import CustomRatios from "./CustomRatiosMobile"
import HistoricalRatios from "./HistoricalRatiosMobile"
import PeerRatios from "./PeerRatiosMobile"
import SubscriptionDetails from "./SubscriptionDetailsMobile"


function ProfileMobile(props) {
    window.scrollTo(0, 0)
    document.title = "Nivesh Karma - User Profile";
    const [optionSelected, setOptionSelected] = useState("one");

    return (
        <>
            <div className="CompanyNavMobile">

                <div className="CompanyMenuMobile" onClick={() => setOptionSelected("one")} style={optionSelected === "one" ? { borderBottom: "2px solid #36454f", color: '#3e424b', whiteSpace: "nowrap" } : { color: '#3e424b', whiteSpace: "nowrap" }}>
                    Personal Details
                        </div>
                <div className="CompanyMenuMobile" onClick={() => setOptionSelected("two")} style={optionSelected === "two" ? { borderBottom: "2px solid #36454f", color: '#3e424b', whiteSpace: "nowrap" } : { color: '#3e424b', whiteSpace: "nowrap" }}>
                    Custom Ratios
                        </div>
                <div className="CompanyMenuMobile" onClick={() => setOptionSelected("three")} style={optionSelected === "three" ? { borderBottom: "2px solid #36454f", color: '#3e424b', whiteSpace: "nowrap" } : { color: '#3e424b', whiteSpace: "nowrap" }}>
                    Historical Ratios
                        </div>
                <div className="CompanyMenuMobile" onClick={() => setOptionSelected("four")} style={optionSelected === "four" ? { borderBottom: "2px solid #36454f", color: '#3e424b', whiteSpace: "nowrap" } : { color: '#3e424b', whiteSpace: "nowrap" }}>
                    Peers/Screener Ratios
                        </div>
                <div className="CompanyMenuMobile" onClick={() => setOptionSelected("five")} style={optionSelected === "five" ? { borderBottom: "2px solid #36454f", color: '#3e424b', whiteSpace: "nowrap" } : { color: '#3e424b', whiteSpace: "nowrap" }}>
                    Subscription Details
                        </div>
            </div>
        <div className="faq commonContainer" >

                <div className="AboutUs-container" style={{padding: "0px"}}>

                    <div style={{ padding: "10px 0px", minHeight: "300px" }}>
                        {optionSelected === "one" &&
                            <PersonalDetails />
                        }
                        {optionSelected === "two" &&
                            <CustomRatios ratioData={props.ratioData} />
                        }
                        {optionSelected === "three" &&
                            <HistoricalRatios ratioData={props.ratioData} />
                        }
                        {optionSelected === "four" &&
                            <PeerRatios ratioData={props.ratioData} />
                        }
                        {optionSelected === "five" &&
                            <SubscriptionDetails />
                        }
                    </div>
                

            </div>
            </div>
            </>
    );
}
export default ProfileMobile;