import React, { useState, useEffect }from 'react';
import "./Screener.css"
import HelpModal from './HelpModal'
import ManageScreener from './ManageScreener'
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import { getOptionLookup, getKeyLookup } from './ManageScreener'
import ExportSVG from '../icon/ExportSVG'
import UpArrow from "../icon/UpArrow"
import DownArrow from "../icon/DownArrow"
import Loading from "./Loading"
import ManageRatios from '../ManageRatios/ManageRatios'
import HelpModalTwo from '../Company/HelpModal'
import useAuth from '../hooks/useAuth';
import Locked from '../Locked'
import AddToWatchlist from './AddToWatchlist'
import { downloadExcel } from "react-export-table-to-excel";
import WatchlistModal from './WatchlistModal'
import ManageScreenerMini from './ManageScreenerMini'

function Screener(props) {

    const [pageData, setpageData] = useState({
        "stocks": [],
        "RatioCode": [],
        "defaultScreener": [],
        "pageLoaded": false,
        "defaultPage": false
    });
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState({})
    const [query, setQuery] = useState([])
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [minimize, setMinimize] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);
    const [selectedRatios, setSelectedRatios] = useState([]);
    const [checked, setChecked] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [miniOption, setMiniOption] = useState("");
    const [showCustom, setShowCustom] = useState(false);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const axiosPrivate = useAxiosPrivate();
    document.title = "Stock Screener | Best Stock Screener, research, and analysis tool | Build your own stock screener.";

    const onOpenModal = (item) => {
        if (filter[item] !== undefined && filter[item]["Source"] === "Custom") {
            setMinValue(filter[item]["minValue"])
            setMaxValue(filter[item]["maxValue"])
            setShowCustom(true)
        } 
        if (filter[item] !== undefined && filter[item]["Source"] === "PreSelected") {
            setShowCustom(false)
        } 
        setMiniOption(item)
        setShowModal(true)
    };

    const onCloseModal = () => {
        setMiniOption("")
        setShowModal(false)
    };


    const handleSelectedRatios = () => {
        let dataArr = []
        for (let k in checked) {
            if (checked[k]) {
                dataArr.push(k)
            }
            setSelectedRatios(dataArr)
        }
        console.log(selectedRatios)
    }

    const handleChange = (item) => {
        if (checked[item]) {
            checked[item] = false
        } else {
            checked[item] = true
        }
        handleSelectedRatios()
    }


    const isPresent = (key) => {
        return selectedRatios.includes(key)
    }


    const cancelActionRatio = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShowTwo(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const showManageRatios = () => {
        setShowTwo(true)
    }


    const showManageScreener = () => {
        setShow(true)
    }
    const cancelAction = () => {

        const filterData = JSON.parse(localStorage.getItem('NKScreenerFilter'))
        console.log(filterData)
        if (filterData) {
            setFilter(filterData)
        } else {
            setFilter({})
        }
        setShow(false)
    }
    const removeSelected = (code) => {
        filter[code] = { "Source": "PreSelected", "Value": "01" }
        buildQuery()
    }



    const getScreenerURL = (code) => {
        return "/PredefinedScreener/" + code
    }
    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('ScreenerData'))
        let tempData = itemsData["stocks"]
        if (finalSortData["pointer"]) {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }

        setpageData({
            "stocks": tempData,
            "RatioCode": itemsData["RatioCode"],
            "pageLoaded": true,
            "defaultPage": false
        })
    } 

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const isFilterPresent = () => {
        for (const [key, value] of Object.entries(filter)) {
            if (value["Value"] !== "01") {
                return true
            }
        }
        return false
    }

    const buildQuery = () => {
        let tempQuery = []
        console.log(filter)
        localStorage.setItem('NKScreenerFilter', JSON.stringify(filter))
        setShow(false)
        for (const [key, value] of Object.entries(filter)) {
            console.log(value);
            if (value["Source"] === "PreSelected") {
                if (value["Value"] !== "01") {
                    tempQuery.push({
                        "ratio_code": key,
                        "selection_code": value["Value"],
                        "selection_type": "Selection"

                    })
                }
            } else {
                tempQuery.push({
                    "ratio_code": key,
                    "selection_code": value["Value"],
                    "selection_type": "custom",
                    "min_value": value["minValue"],
                    "max_value": value["maxValue"]


                })
            }

        }
        console.log(tempQuery)
        setQuery(tempQuery)

    }
    const getUrl = (url) => {
        return "/Company/" + url
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    const runQuery = async () => {
        
        if (query.length > 0) {
            setpageData({
                "stocks": [],
                "RatioCode": [],
                "pageLoaded": false,
                "defaultPage": false
            })
            let url = "https://api.niveshkarma.com/api/v1/screener?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

            const response = await axiosPrivate.post(url, { "query": query });
            console.log(response)
            localStorage.setItem(
                "ScreenerData", JSON.stringify({
                    "stocks": response.data["stocks"],
                    "RatioCode": response.data["keys"]
                }));
            setShow(false)
            setpageData({
                "stocks": response.data["stocks"],
                "RatioCode": response.data["keys"],
                "pageLoaded": true,
                "defaultPage": false
            })
        } else {
            setpageData({
                "defaultScreener": [],
                "pageLoaded": false,
                "defaultPage": false
            })
            let url = "https://api.niveshkarma.com/api/v1/default-screeners?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const response = await axiosPrivate.get(url)
            console.log(response.data)
            if (auth.roles === true) {
                setRestrictCount(30)
            } else {
                setRestrictCount(5)
            }
            setpageData({
                "defaultScreener": response.data,
                "pageLoaded": false,
                "defaultPage": true
            })
        }
    }

    const getCompanyCountMessage = () => {
        try {
            let count = pageData.stocks.length
            if (count > 0) {
                return count.toString() + " companies found."
            } else {
                return "No companies found. Please adjust your filters"
            }
        } catch (e) {
            return "No companies found. Please adjust your filters"
        }
    }

    const getClassName = (data) => {
        if (data === "Sector") {
            return "Sector columnHeadingScreenerLeft"
        } else if (data === "Industry") {
            return "Industry columnHeadingScreenerLeft"
        } else {
            return "columnHeadingScreener"
        }
    }

    const getSubClassName = (data) => {
        if (data === "Sector") {
            return "Sector SubHeader"
        } else if (data === "Industry") {
            return "Industry SubHeader"
        } else {
            return "tabledataPeer SubHeader"
        }
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.RatioCode.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData?.stocks.forEach((item, index) => {
            let tempData = [index+1]
            tempData.push(item.stock_name)
            pageData.RatioCode.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Screener",
            sheet: "Screener",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }




    useEffect(() => {
        const timeout = setTimeout(() => {
            runQuery()
            localStorage.removeItem('SortDataItem');
            localStorage.removeItem('NKScreenerFilter');
        }, 50)
        return () => clearTimeout(timeout)
      //  }
    }, [query, isProfileUpdated, auth.user])

    return (
        <div className="Screener">
            {!pageData.defaultPage && pageData.pageLoaded &&
                <div style={{ cursor: "pointer", minHeight: "35px", marginTop: "12.5px", paddingLeft: "40px", paddingRight: "42.25px", backgroundColor: "rgb(248, 248, 252)"}}>
                <div style={!minimize ?
                    { width: "100%", borderTop: "2px solid #ccc", borderLeft: "2px solid #ccc", borderRight: "2px solid #ccc", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", height: "40px" }
                    : { width: "100%", border: "2px solid #ccc", borderRadius: "10px", height: "30px"}}>
                    {!minimize ?

                        <div style={{ float: "right", padding: "0px 5px", color: "blue", fontSize: "32px", paddingRight: "20px" }} onClick={() => setMinimize(true)}>
                            <svg
                                width="30px"
                                height="30px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 300 300"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                fill="#007FFF"
                                style={{ transform: "rotate(180deg)" }}
                                {...props}
                            >
                                <path
                                    d="M67.79 65.49l170.343-.697L150 176.963 67.79 65.49z"
                                    transform="matrix(1 0 0 .68815 -8 16.35)"
                                    stroke="#007FFF"
                                    strokeWidth={0.6}
                                />
                            </svg>
                        </div>

                        :
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <div style={{ paddingTop: "4px", paddingLeft: "10px", fontWeight: "600", display: "flex", justifyContent: "start" }}><div className="ScreenerResultPrompt">Screener Result:</div><div className="ScreenerResultText">{getCompanyCountMessage()}</div></div>
                            </div>
                            <div style={{fontSize: "30px", padding: "0px 5px", color: "blue", paddingRight: "20px", minHeight: "20px" }} onClick={() => setMinimize(false)}>
                            <svg
                                width="30px"
                                height="30px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 300 300"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                fill="#007FFF"
                                {...props}
                            >
                                <path
                                    d="M67.79 65.49l170.343-.697L150 176.963 67.79 65.49z"
                                    transform="matrix(1 0 0 .68815 -8 16.35)"
                                    stroke="#007FFF"
                                    strokeWidth={0.6}
                                />
                            </svg>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }
            <>
                <div style={{ backgroundColor: "rgb(248, 248, 252)" }}>
            {
                        pageData.defaultPage &&
                        <div className="ScreenerHeader" style={{ textAlign: "left", paddingTop: "15px", paddingRight: "40px", paddingLeft: "40px" }}>
                        <h2>Create your own Screener</h2>
                    </div>
            }
            {(pageData.pageLoaded || pageData.defaultPage) &&
                        <>
                            {pageData.pageLoaded && !minimize && <div className="rightAlign" style={{ paddingTop: "0px", marginLeft: "40px", marginRight: "38.5px", paddingRight: "20px" }}>
                        <Popup
                            className="popupChart"
                            trigger={
                                <div style={{ marginTop: "-8px" }} class="checkbox-wrapper-31" onClick={() => setShowThree(true)}>
                                    <input type="checkbox" checked={true} />
                                    <svg viewBox="0 0 35.6 35.6" height="25px" width="25px">
                                        <circle class="background" cx="17.8" cy="17.8" r="17.8"></circle>
                                        <circle class="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                                        <polyline class="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                                    </svg>
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Add to Watchlist.</div>}
                            style={{ zIndex: "999"}}
                        />


                        <WatchlistModal title="Add companies to your watchlist" onClose={() => setShowThree(false)} show={showThree}>
                            <AddToWatchlist watchlistList={selectedRatios} />
                        </WatchlistModal>
                        <Popup
                            className="popupChart"
                            trigger={
                                <svg
                                    onClick={() => showManageRatios()}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 256 256"
                                    height="25px"
                                    width="25px"
                                    {...props}
                                >
                                    <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                                </svg>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                            style={{ zIndex: "999" }}
                        />
                        <HelpModalTwo title="Manage Columns" onClose={() => cancelActionRatio()} show={showTwo} toggleProfile={toggleProfile} setShow={setShowTwo} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                            <ManageRatios ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                        </HelpModalTwo>

                            
                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer", paddingTop: "3px" }}>
                                    <ExportSVG big={true}/>
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Export to Excel</div>}
                            style={{ zIndex: "999" }}
                        />
                        </div>
                        }
                        <div className="optionButtonPrimary" style={pageData.defaultPage ? { paddingLeft: "40px" } : { marginTop: "-30px", marginLeft: "40px", marginRight: "38.5px", borderLeft: "2px solid #ccc", borderRight: "2px solid #ccc", paddingLeft:"20px" }}>
                        <div style={{ display: "flex", justifyContent: "start", gap: "20px" }}>
                                {pageData.defaultPage && <button style={{ width: "250px"}} onClick={() => showManageScreener()}>Stock Screener</button>}
                            {!pageData.defaultPage && !minimize && <button style={{ width: "250px" }} onClick={() => showManageScreener()}>Edit Stock Screener</button>}
                            {/* <div className="optionButton"><a style={{ textDecoration: "none", color: "#fff" }} href="/StockMap">Stock Map</a></div>*/}
                        </div>
                        <HelpModal title="Manage Your Screener Filter" onRun={() => buildQuery()} onClose={() => cancelAction()} show={show} filter={filter}>
                            <ManageScreener filter={filter}/>
                        </HelpModal>
                    {!pageData.defaultPage ?
                                <div>
                                    {!minimize && <div style={{ paddingTop: "10px", fontWeight: "600", display: "flex", justifyContent: "start" }}><div className="ScreenerResultPrompt">Screener Result:</div><div className="ScreenerResultText">{getCompanyCountMessage()}</div></div>}
                    </div>
                                : <div className="ScreenerContainer" style={{ textAlign: "left", paddingTop: "15px"}}>
                        <div>Our <b>"3M Screener"</b> is based on risk-averse Value Investing Strategies taught by Warren Buffett.</div>
                                    <div className="ScreenerBlock">
                                        <div className="ScreenerSubBlock" style={{ backgroundColor: "white" }}>
                                <h3>Moat</h3>
                                <ul>
                                    <li>Great businesses with competitive durable advantages.</li>
                                    <li>Predictable growth in Revenue, Profit, EPS, Book Value and Free Cash Flow.</li>
                                </ul>
                            </div>
                                        <div className="ScreenerSubBlock" style={{ backgroundColor: "white" }}>
                                <h3>Management</h3>
                                <ul>
                                    <li>Result-driven management with honesty, integrity and effective corporate governance.</li>
                                    <li>Predictable & high ROE, ROCE, ROA, Profit Margin and low debt.</li>
                                </ul>
                            </div>
                                        <div className="ScreenerSubBlock" style={{ backgroundColor: "white" }}>
                                <h3>Margin of Safety</h3>
                                <ul>
                                    <li>Determine Intrinsic value using valuation techniques such as relative valuation and DCF.</li>
                                    <li>Buy at great MOS to achieve superior returns over a long period.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    }
                    
                </div>
                


                </>
            }
                    {Object.keys(filter).length !== 0 && !minimize &&
                        <div style={{ borderLeft: "2px solid #ccc", borderRight: "2px solid #ccc", borderBottom: "2px solid #ccc", borderBottomLeftRadius: "10px", borderBottomRightRadius:"10px", marginLeft: "40px", marginRight: "38.5px" }}>
                            <p style={{ fontSize: "12px", margin: "0px", paddingLeft: "10px", paddingRight: "10px" }}>
                                {
                                    Object.keys(filter).map((item, index) => (
                                        filter[item]["Source"] === "PreSelected" ?
                                            <>
                                                {filter[item]["Value"] === "01" ?
                                                    <></>
                                                    :
                                                    <div className="dragableSection">
                                                        <div style={{ display: "inline-block", cursor: "pointer" }} onClick={() => onOpenModal(item)}>
                                                            <b> {getKeyLookup(item)}</b> &nbsp; {getOptionLookup(item, filter[item])}
                                                        </div>
                                                        <div style={{ display: "inline-block", marginLeft: "10px", cursor: "pointer" }} className="removableSection" onClick={() => removeSelected(item)}>
                                                            &#10006;
                                            </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className="dragableSection">
                                                <div style={{ display: "inline-block", cursor: "pointer" }} onClick={() => onOpenModal(item)}>
                                                    <b> {getKeyLookup(item)}</b> &nbsp; Custom
                                </div>
                                                <div style={{ display: "inline-block", marginLeft: "10px", cursor: "pointer" }} className="removableSection" onClick={() => removeSelected(item)}>
                                                    &#10006;
                                    </div>
                                            </div>
                                    ))
                                }
                            </p>
                    <ManageScreenerMini
                        state={showModal}
                        close={onCloseModal}
                        item={miniOption}
                        filter={filter}
                        run={() => buildQuery()}
                        show={showCustom}
                        setShow={setShowCustom}
                        minValue={minValue}
                        setMinValue={setMinValue}
                        maxValue={maxValue}
                        setMaxValue={setMaxValue}
                    />
                </div>
            }

                </div>
                <section className="PeersTwo" style={!minimize ? { marginBottom: "110px", paddingLeft: "40px", paddingRight: "40px", backgroundColor: "rgb(248, 248, 252)", paddingTop: "20px" } : { marginBottom: "110px", paddingLeft: "40px", paddingRight: "40px", backgroundColor: "rgb(248, 248, 252)", marginTop: "30px", paddingTop: "20px" }}>
                    {pageData?.RatioCode?.length !== 0 && pageData.pageLoaded &&
                        <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto", marginBottom: "60px"}}>
                            <table className="PeersTable finData ScreenerData" style={{ overflowX: "auto"}}>
                    <thead>
                            <tr>
                                    <th className="columnHeadingScreenerLeft leftPeer intersection" >Select</th>
                                    <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ textAlign: "center" }}>S.&nbsp;No.</th>
                                <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>Company Name</th>

                            {pageData?.RatioCode?.map(item => (
                                <th className="columnHeadingScreener" className={getClassName(getDisplayValueAndPrompt(item)[0])}>
                                    <Popup
                                        className="popupChart"
                                        trigger={<div style={["Sector", "Industry"].includes(item) ? { cursor: "pointer", color: "blue", fontWeight: "400", display: "flex", justifyContent: "start" } : { cursor: "pointer", color: "blue", fontWeight: "400", display: "flex", justifyContent: "center" }} onClick={() => showSorted(item)}><div>{getDisplayValueAndPrompt(item)[0]}</div><div style={{ paddingTop: "5px" }}>{SortIndicator(item)[0] ? <div>{SortIndicator(item)[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div>}
                                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                        style={{ zIndex: "999" }}
                                    />
                                </th>
                            ))

                            }

                        </tr>
                    </thead>
                    <tbody>
                    {pageData?.stocks !== null && pageData?.stocks?.map((dataKey, index) => (
                        <tr>
                            <td className="leftPeer" style={{ width: "50px" }}>
                                <div>
                                    <input key={index + 1} type="checkbox" onChange={() => handleChange(dataKey.stock_symbol)} checked={isPresent(dataKey.stock_symbol) ? true : false} />
                                </div>
                            </td>
                            <td className="leftPeer" style={{ width: "50px", textAlign: "center" }}>{index + 1}</td>
                            <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                            {pageData.RatioCode.map(item => (
                                <td className={getSubClassName(getDisplayValueAndPrompt(item)[0])}>{dataKey[item]}</td>
                                ))
                                }



                            </tr>
                        ))}
                    </tbody>
                    </table>
                    </div>
                }
                {
                    pageData.defaultPage &&
                        <>
                            <div style={{ textAlign: "left", backgroundColor: "rgb(248, 248, 252)", paddingLeft: "40px", paddingTop: "50px", paddingBottom: "5px" }}>
                                <div style={{ fontSize: "22px", fontWeight: "600" }}>Nivesh Karma Screeners</div>
                            </div>
                            <div className="predefinedScreenerOptions" style={{ backgroundColor: "rgb(248, 248, 252)", paddingLeft: "40px", paddingRight: "40px" }}>
                                {pageData?.defaultScreener?.map(defaultItem => (
                                    <div style={{ marginBottom: "20px", backgroundColor: "white", marginLeft: "10px", marginRight: "10px" }}>
                                    <div className="dragableSectionAttached upperAttached">
                                        <a href={getScreenerURL(defaultItem.screener_code)} target="_blank" style={{ textDecoration: "none", fontWeight: "500" }}>{defaultItem.screener_name}</a>
                                </div>
                                <div className="dragableSectionAttached lowerAttached">
                                        {defaultItem.description}
                                </div>
                            </div>
                            ))}
                        </div>
                    </>
                }
                {
                    !pageData.pageLoaded && !pageData?.defaultPage &&
                    <Loading />
                }
            </section>
           </>
            {/*}
            {!auth.user && 
                <Locked />
            }*/}
        </div>
    );
}

export default Screener;