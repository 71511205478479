import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import "./RatiosMobile.css"
import Loading from "../../Company/Summary/Loading"
import HelpModal from '../../Company/HelpModal'
import ManageRatiosMobile from '../ManageRatios/ManageRatiosMobile'
import useAuth from '../../hooks/useAuth';
import { downloadExcel } from "react-export-table-to-excel";


function RatiosMobile(props) {

    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [sort, setsort] = useState(false);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(3);
    const [disableButton, setDisableButton] = useState(true);
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, historicalRatios: itemsData.historicalRatios }))
        }
        setShow(false)
    }


    const toggleSort = () => {
        if (sort) {
            setsort(false)
        } else {
            setsort(true)
        }
    }
    const getURL = () => {
        return process.env.PUBLIC_URL + '/All Ratios.pdf'
    }
    const isDataPresent = (dataObject, keyArr) => {
        let dataPresent = false
        if (keyArr) {
            keyArr.forEach(item => {
                dataObject.forEach(dataKey => {
                    console.log(dataKey)
                    if (dataKey[item.field_name]) {
                        dataPresent = true
                    }
                })
            })
        }
        return dataPresent
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    function getDisplayValue(data, origin) {
        let pointer = data['display']
        let value = data[pointer]
        if (value) {
            if (parseFloat(value) === 0) {
                return "-"
            }
            if (pointer === "percentage" && origin !== "m") {
                return Math.round(value * 100) / 100 + "%"
            }
            if (origin === "date") {
                return value
            }
            return Math.round(value * 10000) / 10000
        } else {
            return "-"

        }
    }
    const roundPercentValue = (data) => {
        if (data === "-" || isNaN(data)) {
            return data
        }
        if (data) {
            let result = data.replace("%", "");
            let value = parseFloat(result);
            var intvalue = Math.ceil(value);
            return intvalue + "%"
        } else {
            return "-"
        }

    }


    const showManageRatios = () => {
        setShow(true)

    }
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        let ratioData = ""
        let prMap = ""
        let lirMap = ""
        let lerMap = ""
        let erMap = ""
        let prrMap = ""
        let userMap = ""
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        if (sort) {
            ratioData = cdresponse.data['historical_ratios']
        } else {
            ratioData = cdresponse.data['historical_ratios'].reverse()

        }
        company_name = cdresponse.data['stock_name']
        prMap = cdresponse.data['prompts']['ProfitabilityRatios']
        lirMap = cdresponse.data['prompts']['LiquidityRatios']
        lerMap = cdresponse.data['prompts']['LeverageRatios']
        erMap = cdresponse.data['prompts']['EfficiencyRatios']
        prrMap = cdresponse.data['prompts']['ProprietaryRatios']
        userMap = cdresponse.data['prompts']['UserRatios']
        document.title = company_name + "- Ratios | Historical Ratios | Profitability Ratios | Liquidity Ratios | Leverage Ratios | Efficiency Ratios | Proprietary Ratios | User Ratios";
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(3)
        }

        setpageData({
            "ratio": ratioData,
            "profitabilityRatioMapping": prMap,
            "liquidityRatioMapping": lirMap,
            "leverageRatioMapping": lerMap,
            "efficiencyRatioMapping": erMap,
            "proprietaryRatioMapping": prrMap,
            "userRatioMapping": userMap,
            "CompanyName": company_name
        })
    }


    const premiumUser = () => {
        if (auth.user && auth.roles) {
            return true
        } else {
            return false
        }

    }


    function handleDownloadExcel() {

        let header = [""]
        let body = []
        pageData.ratio.forEach(item => {
            header.push("")
        })
        let tempData = ["Profitability Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.profitabilityRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(roundPercentValue(data[item.field_name]))
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)
        tempData = ["Liquidity Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.liquidityRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)

        tempData = ["Leverage Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.leverageRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)

        tempData = ["Efficiency Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.efficiencyRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })
        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)

        tempData = ["Proprietary Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.proprietaryRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })

        tempData = [""]
        pageData.ratio.forEach(item => {
            tempData.push("")
        })
        body.push(tempData)
        tempData = ["User Ratios"]
        pageData.ratio.forEach(item => {
            tempData.push(item.date_header)
        })
        body.push(tempData)
        pageData.userRatioMapping.forEach(item => {
            tempData = []
            tempData.push(item.field_prompt)
            pageData.ratio.forEach(data => {
                tempData.push(data[item.field_name])
            })
            body.push(tempData)
        })

        downloadExcel({
            fileName: "Ratios",
            sheet: "ratios",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }




    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort, isProfileUpdated, auth.user])


    return (
        <div className="RatiosMain">
            {Object.keys(pageData).length !== 0 &&
                <>
                    <section className="optionsFinData">
                        <div className="leftAlign">
                            {pageData.CompanyName}
                        </div>
                        <div className="rightAlign">
                            <div className="optionButtonPrimary" onClick={toggleSort}>
                                <div className="optionButtonMobile"> {sort ? "Ascending View" : "Descending View"}</div>
                            </div>

                        </div>
                    </section>
                {pageData.ratio &&
                    <>
                    <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Profitability Ratios</div>
                    <section className="Ratios">
                    <table className="finDataMobile">
                        <tbody>
                            <tr>
                                <td className="leftBoldMobile stikeyHeader ratioHeaderOne"></td>
                                {pageData.profitabilityRatioMapping.map(data => (
                                    <td className="columnHeadingMobile">{data.field_prompt}</td>
                                ))}
                            </tr>
                            {
                                pageData.ratio.map(data => (
                                    <tr>
                                        <td className="leftBoldMobile SubHeader stikeyHeader">{data.date_header}</td>
                                        {pageData.profitabilityRatioMapping.map(item => (
                                            <td className="tabledataMobile SubHeader">{data[item.field_name]}</td>
                                        ))
                                        }
                                    </tr>
                                 ))

                            }
                        </tbody>
                        </table>
                    </section>
                    </>
                    }
                {pageData.ratio && isDataPresent(pageData.ratio, pageData.liquidityRatioMapping) &&

                    <>
                    <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Liquidity Ratios</div>
                    <section className="Ratios">
                    <table className="finDataMobile">
                        <tbody>
                            <tr>
                                <td className="leftBoldMobile stikeyHeader ratioHeaderOne"></td>
                                {pageData.liquidityRatioMapping.map(data => (
                                    <td className="columnHeadingMobile">{data.field_prompt}</td>
                                ))}
                            </tr>
                            {
                                pageData.ratio.map(data => (
                                    <tr>
                                        <td className="leftBoldMobile SubHeader stikeyHeader">{data.date_header}</td>
                                        {pageData.liquidityRatioMapping.map(item => (
                                            <td className="tabledataMobile SubHeader">{data[item.field_name]}</td>
                                        ))
                                        }
                                    </tr>
                                 ))

                            }
                        </tbody>
                        </table>
                    </section>
                    </>

                    }
                {pageData.ratio &&

                    <>
                    <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Leverage Ratios</div>
                    <section className="Ratios">
                    <table className="finDataMobile">
                        <tbody>
                            <tr>
                                <td className="leftBoldMobile stikeyHeader ratioHeaderOne"></td>
                                {pageData.leverageRatioMapping.map(data => (
                                    <td className="columnHeadingMobile">{data.field_prompt}</td>
                                ))}
                            </tr>
                            {
                                pageData.ratio.map(data => (
                                    <tr>
                                        <td className="leftBoldMobile SubHeader stikeyHeader">{data.date_header}</td>
                                        {pageData.leverageRatioMapping.map(item => (
                                            <td className="tabledataMobile SubHeader">{data[item.field_name]}</td>
                                        ))
                                        }
                                    </tr>
                                 ))

                            }
                        </tbody>
                        </table>
                    </section>
                    </>

                    }
                {pageData.ratio && isDataPresent(pageData.ratio, pageData.efficiencyRatioMapping) &&
                     <>
                    <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Efficiency Ratios</div>
                    <section className="Ratios">
                    <table className="finDataMobile">
                        <tbody>
                            <tr>
                                <td className="leftBoldMobile stikeyHeader ratioHeaderOne"></td>
                                {pageData.efficiencyRatioMapping.map(data => (
                                    <td className="columnHeadingMobile">{data.field_prompt}</td>
                                ))}
                            </tr>
                            {
                                pageData.ratio.map(data => (
                                    <tr>
                                        <td className="leftBoldMobile SubHeader stikeyHeader">{data.date_header}</td>
                                        {pageData.efficiencyRatioMapping.map(item => (
                                            <td className="tabledataMobile SubHeader">{data[item.field_name]}</td>
                                        ))
                                        }
                                    </tr>
                                 ))

                            }
                        </tbody>
                        </table>
                    </section>
                    </>

                    }
                {pageData.ratio &&

                    <>
                    <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Proprietary Ratios</div>
                    <section className="Ratios">
                    <table className="finDataMobile">
                        <tbody>
                            <tr>
                                <td className="leftBoldMobile stikeyHeader ratioHeaderOne"></td>
                                {pageData.proprietaryRatioMapping.map(data => (
                                    <td className="columnHeadingMobile">{data.field_prompt}</td>
                                ))}
                            </tr>
                            {
                                pageData.ratio.map(data => (
                                    <tr>
                                        <td className="leftBoldMobile SubHeader stikeyHeader">{data.date_header}</td>
                                        {pageData.proprietaryRatioMapping.map(item => (
                                            <td className="tabledataMobile SubHeader">{data[item.field_name]}</td>
                                        ))
                                        }
                                    </tr>
                                 ))

                            }
                        </tbody>
                        </table>
                    </section>
                    </>

                    }


                    {pageData.ratio &&
                        <div>
                            <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", paddingRight: "10px", marginBottom: "10px" }}>
                                <div style={{ fontWeight: "600" }}>&nbsp;</div>
                                <div className="optionButtonMobile" style={{ width: "150px" }} onClick={() => showManageRatios()}> Manage Your Ratios</div>

                                <HelpModal title="Manage Your Ratios" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} disableButton={disableButton} source="histRatios">
                                    <ManageRatiosMobile ratioData={props.ratioData} source="histRatios" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                                </HelpModal>
                            </div>

                    {pageData.userRatioMapping ?

                        <>
                            <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>User Ratios</div>
                            <section className="Ratios">
                                <table className="finDataMobile">
                                    <tbody>
                                        <tr>
                                            <td className="leftBoldMobile stikeyHeader ratioHeaderOne"></td>
                                            {pageData.userRatioMapping.map(data => (
                                                <td className="columnHeadingMobile">{data.field_prompt}</td>
                                            ))}
                                        </tr>
                                        {
                                            pageData.ratio.map(data => (
                                                <tr>
                                                    <td className="leftBoldMobile SubHeader stikeyHeader">{data.date_header}</td>
                                                    {pageData.userRatioMapping.map(item => (
                                                        <td className="tabledataMobile SubHeader">{data[item.field_name]}</td>
                                                    ))
                                                    }
                                                </tr>
                                            ))

                                        }
                                    </tbody>
                                </table>
                            </section>
                        </>
                        :
                        <div style={{ border: "2px solid #F5F5F5", padding: "20px 10px", fontSize: "14px", marginBottom: "40px", borderRadius: "10px" }}>
                            Please Login to view this Section
                        </div>

                        }









                            
                        </div>

                    }

                </>
            }
            {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>

    );
}

export default RatiosMobile;