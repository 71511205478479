import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Chart } from "react-google-charts";
import Loading from "../../Company/Summary/Loading"
import Locked from '../../Locked'
import useAuth from '../../hooks/useAuth';
import './ValuationChartMobile.css'


export const options = {
    legend: { position: "none" },
    chart: {
        title: ""

    },
    tooltip: { isHtml: true, trigger: "visible" },
};

function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
}

function ValuationChartMobile(props) {
    const [mapData, setMapData] = useState([])
    const [userData, setUserData] = useState({})
    const [userSelection, setuserSelection] = useState("Price")
    const [userDuration, setuserDuration] = useState("1M")
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const updateUserSelection = (userValue) => {
        if (userSelection !== userValue) {
            setuserSelection(userValue)
        }
    }
    const updateUserDuration = (userValue) => {
        if (userDuration !== userValue) {
            setuserDuration(userValue)
        }
    }

    const accessChart = () => {
        if (auth.roles) {
            return true
        }
        if (["HDFCBANK", "TCS", "ITC"].includes(props.compSymbol) && auth.user) {
            return true
        }
        if (userSelection === "Price") {
            return true
        }
        if (!auth.roles && ["1M", "6M", "1Y"].includes(userDuration)) {
            return true
        }
        if (!auth.user && ["1M", "6M", "1Y"].includes(userDuration)) {
            return true
        }
        return false
    }

    useEffect(() => {
        const companyDetails = async () => {
            setMapData([])
            let url = ""

            if (userDuration === "1M") {
                url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            } else if (userDuration === "6M") {
                url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            } else if (userDuration === "1Y") {
                url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            } else if (userDuration === "5Y") {
                url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            } else if (userDuration === "All") {
                url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=" + userDuration + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            }


            const cdresponse = await axiosPrivate.get(url);
            const tempData = cdresponse.data['historical_quotes']
            const peData = cdresponse.data['pe']
            const pbData = cdresponse.data['pb']
            const ptbData = cdresponse.data['ptb']
            const psData = cdresponse.data['ps']
            const pfcfData = cdresponse.data['pfcf']
            let mean = "-"
            let stdDev = "-"
            let plusDev = "-"
            let minusDev = "-"
            let coeff = "-"

            console.log(tempData)
            let tempMapData = []
            if (userSelection === "Price") {
                tempMapData.push(["", "Price"])
                tempData.forEach(arr => {
                    if (arr.close_price) {
                        tempMapData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.close_price])
                    }
                })
            } else if (userSelection === "PE") {
                tempMapData.push([
                    "",
                    "Price to Earning",
                    { role: "tooltip", type: "string" },
                    "Mean",
                    { role: "tooltip", type: "string" },
                    "Median",
                    { role: "tooltip", type: "string" },
                    "Mode",
                    { role: "tooltip", type: "string" },
                    "+2 Std Deviation",
                    { role: "tooltip", type: "string" },
                    "-2 Std Deviation",
                    { role: "tooltip", type: "string" }
                ])
                tempData.forEach(arr => {
                    if (arr.pe) {
                        tempMapData.push([
                            stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"),
                            arr.pe,
                            'Price to Earning: ' + arr.pe,
                            peData.mean,
                            'Mean: ' + peData.mean,
                            peData.median,
                            'Median: ' + peData.median,
                            peData.mode,
                            'Mode: ' + peData.mode,
                            peData.std_dv_plus,
                            '+2 Std Deviation: ' + peData.std_dv_plus,
                            peData.std_dv_minus,
                            '-2 Std. Deviation: ' + peData.std_dv_minus])
                    }
                })
                mean = peData.mean
                stdDev = peData.std_dv
                plusDev = peData.std_dv_plus
                minusDev = peData.std_dv_minus
                coeff = peData.coeff_of_variation
            } else if (userSelection === "PB") {
                tempMapData.push(["", "Price to Book", "Mean", "Median", "Mode", "+2 Std Deviation", "-2 Std Deviation"])
                tempData.forEach(arr => {
                    if (arr.pb) {
                        tempMapData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.pb, pbData.mean, pbData.median, pbData.mode, pbData.std_dv_plus, pbData.std_dv_minus])
                    }
                })
                mean = pbData.mean
                stdDev = pbData.std_dv
                plusDev = pbData.std_dv_plus
                minusDev = pbData.std_dv_minus
                coeff = pbData.coeff_of_variation
            } else if (userSelection === "PS") {
                tempMapData.push(["", "Price to Sales", "Mean", "Median", "Mode", "+2 Std Deviation", "-2 Std Deviation"])
                tempData.forEach(arr => {
                    if (arr.ps) {
                        tempMapData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.ps, psData.mean, psData.median, psData.mode, psData.std_dv_plus, psData.std_dv_minus])
                    }
                })
                mean = psData.mean
                stdDev = psData.std_dv
                plusDev = psData.std_dv_plus
                minusDev = psData.std_dv_minus
                coeff = psData.coeff_of_variation
            } else if (userSelection === "PF") {
                tempMapData.push(["", "Price to Free Cash Flow", "Mean", "Median", "Mode", "+2 Std Deviation", "-2 Std Deviation"])
                tempData.forEach(arr => {
                    if (arr.pfcf) {
                        tempMapData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.pfcf, pfcfData.mean, pfcfData.median, pfcfData.mode, pfcfData.std_dv_plus, pfcfData.std_dv_minus])
                    }
                })
                mean = pfcfData.mean
                stdDev = pfcfData.std_dv
                plusDev = pfcfData.std_dv_plus
                minusDev = pfcfData.std_dv_minus
                coeff = pfcfData.coeff_of_variation
            } else if (userSelection === "PTB") {
                tempMapData.push(["", "Price to Tangible Book", "Mean", "Median", "Mode", "+2 Std Deviation", "-2 Std Deviation"])
                tempData.forEach(arr => {
                    if (arr.ptb) {
                        tempMapData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.ptb, ptbData.mean, ptbData.median, ptbData.mode, ptbData.std_dv_plus, ptbData.std_dv_minus])
                    }
                })
                mean = ptbData.mean
                stdDev = ptbData.std_dv
                plusDev = ptbData.std_dv_plus
                minusDev = ptbData.std_dv_minus
                coeff = ptbData.coeff_of_variation
            }
            console.log(tempMapData)
            setUserData({
                mean: mean,
                stdDev: stdDev,
                plusDev: plusDev,
                minusDev: minusDev,
                coeff: coeff
            })
            setMapData(tempMapData)


            /*  if (userSelection === "Price") {
                  setMapData([["", "Price"]])
                  tempData.reverse().forEach(arr => {
                      if (arr.Close) {
                          setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.Close]])
                      }
                  })
              } else if (userSelection === "PE") {
                  setMapData([["", "Price to Earning", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                  tempData.reverse().forEach(arr => {
                      if (arr.PE) {
                          setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PE, arr.mean, arr.plus_dev, arr.minus_dev]])
                      }
                  })
              } else if (userSelection === "PB") {
                  setMapData([["", "Price to Book", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                  tempData.reverse().forEach(arr => {
                      if (arr.PB) {
                          setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PB, arr.mean, arr.plus_dev, arr.minus_dev]])
                      }
                  })
              } else if (userSelection === "PS") {
                  setMapData([["", "Price to Sales", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                  tempData.reverse().forEach(arr => {
                      if (arr.PS) {
                          setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PS, arr.mean, arr.plus_dev, arr.minus_dev]])
                      }
                  })
              } else if (userSelection === "PF") {
                  setMapData([["", "Price to Free Cash Flow", "Mean", "+2nd Deviation", "-2nd Deviation"]])
                  tempData.reverse().forEach(arr => {
                      if (arr.PFCF) {
                          setMapData(mapData => [...mapData, [stringToDate(String(arr.Date).substring(0, 10), "yyyy-mm-dd", "-"), arr.PFCF, arr.mean, arr.plus_dev, arr.minus_dev]])
                      }
                  })
              }*/
        }
        companyDetails()
    }, [props.compSymbol, userSelection, userDuration, auth.user])

    return (
        <div className="ValuationChart" style={{ marginTop: "10px" }}>
            <div className="CompanyChartMap">
                <section className="CompanyChart" id="CompanyChartGraph">
                    <div className="chartContainer">
                        <div className="chartHeader">
                            <div className="ValuationchartTopicMobile" style={{ pointerEvents: mapData.length <= 1 ? "none" : "auto", opacity: mapData.length <= 1 ? ".3" : "1" }}>
                                <div className="chartMenuItemMobile" onClick={() => updateUserDuration("1M")}
                                    style={{
                                        backgroundColor: userDuration === "1M" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "1M" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",

                                    }}
                                >1M</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserDuration("6M")}
                                    style={{
                                        backgroundColor: userDuration === "6M" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "6M" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"

                                    }}
                                >6M</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserDuration("1Y")}
                                    style={{
                                        backgroundColor: userDuration === "1Y" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "1Y" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >1Y</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserDuration("5Y")}
                                    style={{
                                        backgroundColor: userDuration === "5Y" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "5Y" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >5Y</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserDuration("All")}
                                    style={{
                                        backgroundColor: userDuration === "All" ? '#000000' : '#F8F8FC',
                                        color: userDuration === "All" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                    }}
                                >All</div>

                            </div>
                            <div className="chartMenuMobile" style={{ pointerEvents: mapData.length <= 1 ? "none" : "auto", opacity: mapData.length <= 1 ? ".3" : "1" }}>
                                <div className="chartMenuItemMobile" onClick={() => updateUserSelection("Price")}
                                    style={{
                                        backgroundColor: userSelection === "Price" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "Price" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                    }}
                                >Price</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserSelection("PE")}
                                    style={{
                                        backgroundColor: userSelection === "PE" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PE" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Earning</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserSelection("PB")}
                                    style={{
                                        backgroundColor: userSelection === "PB" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PB" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Book</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserSelection("PTB")}
                                    style={{
                                        backgroundColor: userSelection === "PTB" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PTB" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Tangible BV</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserSelection("PS")}
                                    style={{
                                        backgroundColor: userSelection === "PS" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PS" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black"
                                    }}
                                >Price to Sales</div>
                                <div className="chartMenuItemMobile" onClick={() => updateUserSelection("PF")}
                                    style={{
                                        backgroundColor: userSelection === "PF" ? '#000000' : '#F8F8FC',
                                        color: userSelection === "PF" ? '#FFFFFF' : '#000000',
                                        border: "1px solid black",
                                    }}
                                >Price to FCF</div>
                            </div>
                        </div>
                        <div>
                            {mapData.length > 1 &&
                                <>
                                    {accessChart() ?
                                        <Chart
                                            className="valuationChartChart"
                                            chartType="Line"
                                            data={mapData}
                                            options={options}

                                        /> :
                                        <Locked />
                                    }
                                    {userSelection !== "Price" &&
                                        <div style={{ width: "100%", paddingTop: "40px" }}>
                                            <table className="chartSummaryDetails">
                                            <tbody>
                                            <tr>
                                                <td className="chartSummaryDetailsTDMobile" style={{ fontWeight: "600", fontSize: "13px" }}>
                                                    Mean
                                                </td>
                                                <td className="chartSummaryDetailsTDDataMobile">
                                                    {userData.mean}
                                                </td>
                                                <td className="chartSummaryDetailsTDMobile" style={{ fontWeight: "600", fontSize: "13px"  }}>
                                                    STD Dev
                                                </td>
                                                <td className="chartSummaryDetailsTDDataMobile">
                                                    {userData.stdDev}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="chartSummaryDetails">
                                        <tbody>
                                            <tr>
                                                <td className="chartSummaryDetailsTDMobile" style={{ fontWeight: "600", fontSize: "13px"  }}>
                                                    +/-&nbsp;2&nbsp;STD&nbsp; Dev
                                                </td>
                                                <td className="chartSummaryDetailsTDDataMobile">
                                                    {userData.plusDev + "/" + userData.minusDev}
                                                </td>
                                                <td className="chartSummaryDetailsTDMobile" style={{ fontWeight: "600", fontSize: "13px"  }}>
                                                    COV
                                                </td>
                                                <td className="chartSummaryDetailsTDDataMobile">
                                                    {userData.coeff}
                                                </td>
                                            </tr>
                                            </tbody>
                                            </table>
                                        </div>}
                                </>
                            }
                            {mapData.length <= 1 &&
                                <Loading />
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ValuationChartMobile;