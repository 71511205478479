var ProfileData = (function () {

    var getProfileInfo = function (company) {
        return JSON.parse(localStorage.getItem('NKUserProfileData' + company))
    };

    var setProfileInfo = function (data, company) {
        localStorage.setItem('NKUserProfileData' + company, JSON.stringify(data))
    };

    return {
        getProfileInfo: getProfileInfo,
        setProfileInfo: setProfileInfo
    }

})();

export default ProfileData;