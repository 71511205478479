import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './Locked.css'
import useAuth from './hooks/useAuth';
import { useNavigate } from "react-router-dom";
const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL + "/google-auth/api/v1/token";



function Locked() {
    window.scrollTo(0, 0)
    const { auth, setAuth } = useAuth();
    let navigate = useNavigate();

    const handleCallbackResponse = async (dataResponse) => {
        try {
            const response = await axios.post(LOGIN_URL,
                '',
                {
                    headers: { 'Content-Type': 'application/json', 'x-token-type': 'Bearer', "x-token": dataResponse.access_token, 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL, },

                }
            );

            const user = response?.data?.user?.first_name + " " + response?.data?.user?.last_name;
            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const roles = response?.data?.user?.premium;
            const ratios = response?.data?.user?.add_ratios;
            const historicalRatios = response?.data?.user?.historical_ratios;
            const peerColumns = response?.data?.user?.peer_ratios;
            const itemsData = { user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken }
            console.log(itemsData)
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(itemsData))
            setAuth({ user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken });
            navigate("/");
        } catch (err) {
            console.log(err)
            /*if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password'); 
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }*/
        }
    }

    const login = useGoogleLogin({
        onSuccess: codeResponse => handleCallbackResponse(codeResponse),
        googleAccountConfigs: {
            client_id: "293375405749-i8qhl8aujplmiapclijjv0ffqob8enr4.apps.googleusercontent.com"
        },
    });



    const handleCallbackResponsePayment = async (dataResponse) => {
        try {
            const response = await axios.post(LOGIN_URL,
                '',
                {
                    headers: { 'Content-Type': 'application/json', 'x-token-type': 'Bearer', "x-token": dataResponse.access_token, 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL, },

                }
            );

            const user = response?.data?.user?.first_name + " " + response?.data?.user?.last_name;
            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const roles = response?.data?.user?.premium;
            const ratios = response?.data?.user?.add_ratios;
            const historicalRatios = response?.data?.user?.historical_ratios;
            const peerColumns = response?.data?.user?.peer_ratios;
            const itemsData = { user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken }
            console.log(itemsData)
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(itemsData))
            setAuth({ user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken });
            navigate("/SubscriptionDetails");
        } catch (err) {
            console.log(err)
            /*if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password'); 
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }*/
        }
    }

    const loginPayment = useGoogleLogin({
        onSuccess: codeResponse => handleCallbackResponsePayment(codeResponse),
        googleAccountConfigs: {
            client_id: "293375405749-i8qhl8aujplmiapclijjv0ffqob8enr4.apps.googleusercontent.com"
        },
    });

    const navigatePayment = () => {
        if (!auth.user) {
            loginPayment()
        } else {
            navigate("/SubscriptionDetails");
        }
    }
    return (
        <div className="ContentSectionText" style={{ textAlign: "center", marginBottom: "110px" }}>

            <div style={{ paddingTop: "100px" }}>
                Please login to view this section
            </div>
            {/* <h2>Full Access to our Research Platform</h2>
            Already a member of Nivesh Karma? Login now. <br/>
            Otherwise, choose the plan that best fits your investing style.
            <br /><br /><br />
            <div className="LockedContainer" >
                <div className="LockedContainerChild" style={{border: "2px solid #4CBB17", borderRadius: "15px", opacity: auth.user? ".5": "1" }}>
                    <div style={{ color: "#4CBB17", fontSize: "18px", fontWeight: "600", paddingTop: "10px"  }}>Beginner</div>
                    <div style={{ fontSize: "24px", fontWeight: "600", paddingTop: "15px" }}>Free</div>
                    <div style={{ fontWeight: "600" }}>Login Required</div>
                    <div style={{ padding: "10px 20px", backgroundColor: "#4CBB17", color: "white", borderRadius: "25px", marginTop: "20px", cursor: "pointer" }} onClick={auth.user ? "": () => login()}>Login</div>
                    <div style={{ textAlign: "left", marginTop: "10px", paddingTop: "15px", borderTop: "1px solid #F5F5F5" }}>
                        Basic Features:
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17"}}>&#10003;&nbsp;</span>Full access to 3 stocks: ITC, TCS and HDFC Bank.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Full access to financial data.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Limited screener access.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Limited access to historical ratios.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Limited access to valuation tools.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Select and view up to 10 custom ratios.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>10 columns for comparisons.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Create upto 2 watchlist.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#4CBB17" }}>&#10003;&nbsp;</span>Limited access to Superinvestors.<br />
                        </div>
                    </div>
                </div>
                <div className="LockedContainerChild" style={{ border: "2px solid #0096FF", borderRadius: "15px", opacity: auth.roles ? ".5" : "1"  }}>
                    <div style={{ color: "#0096FF", fontSize: "18px", fontWeight: "600", paddingTop: "10px" }}>Active investor</div>
                    <div style={{ fontSize: "24px", fontWeight: "600", paddingTop: "15px" }}>INR 300</div>
                    <div style={{ fontWeight: "600" }}>per month billed annually.</div>
                    <div onClick={auth.roles? "" : () => navigatePayment()} style={{ padding: "10px 20px", backgroundColor: "#0096FF", color: "white", borderRadius: "25px", marginTop: "20px", cursor: "pointer" }}>Select Plan</div>
                    <div style={{ textAlign: "left", marginTop: "10px", paddingTop: "15px", borderTop: "1px solid #F5F5F5" }}>
                        Basic Features, plus:
                        <div className="intendedContent" style={{fontSize: "14px" }}>
                            <span style={{ color: "#0096FF"}}>&#10003;&nbsp;</span>Ability to slice & dice financial data.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Acess to 3M Scores.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Powerful 3M Stock screener.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Compare up to 5 stocks across Sector and Industry <br/><span style={{ color: "#0096FF" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>on 150+ attributes.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>100 Custom Ratios.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>30 Columns for comparison.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Superinvestor picks.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Create upto 20 watchlist.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Access to 3M Premium Watchlist.<br />
                        </div>
                        <div className="intendedContent" style={{ fontSize: "14px" }}>
                            <span style={{ color: "#0096FF" }}>&#10003;&nbsp;</span>Download data to excel for analysis.<br />
                        </div>
                    </div>
                </div>
            </div>
                */}
        </div>
    );
}
export default Locked;