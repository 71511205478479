import React, { useState, useEffect } from "react";
import CommoditySearchbox from './CommoditySearchbox'
import CommodityChart from './CommodityChart'
import Commodities from './Commodities'
import CoreIndustries from './CoreIndustries'
import CommodityChangeWrapper from './CommodityChangeWrapper'

function Commodity(props) {
    const [commoditySymbol, setCommoditySymbol] = useState({ label: "All commodities", value: "1000000000"});
    const [userSelection, setUserSelection] = useState("Default")
    document.title = "NK - Commodity Price Trends";
   

    useEffect(() => {

    }, [props.compSymbol])

    return (
        <div style={{ paddingTop: "50px", marginLeft: "36px", marginRight: "40px", width: "95%", marginBottom: "110px" }}>
            <section className="optionsFinData">
                <div className="leftAlign">
                    Commodity Price Trends
                </div>
                <div className="rightAlign">
                    <div className="optionButtonPrimary" onClick={() => setUserSelection("All") }>
                        <button> View All Commodities</button>
                    </div>
                    <div className="optionButtonPrimary" onClick={() => setUserSelection("Core")}>
                        <button> 8 Core Industries</button>
                    </div>
                    <div className="optionButtonPrimary" onClick={() => setUserSelection("Change")}>
                        <button> View % Change</button>
                    </div>
                    {/*<div className="optionButtonPrimary" onClick={() => setUserSelection("Change")}>
                        <div className="optionButton"> % Price Change</div>
                    </div>*/}
                </div>
            </section>
            {userSelection === "Default" &&
                <section>
                    <div style={{ width: "400px", paddingTop: "10px" }}>
                        <CommoditySearchbox commodityOptions={props.commodityData} updateOption={setCommoditySymbol} />
                    </div>
                    <div>
                        <CommodityChart CommoditySymbol={commoditySymbol} />
                    </div>
                </section>
            }
            {userSelection === "All" &&
            <section>
                <Commodities setUserSelection={setUserSelection} setCommoditySymbol={setCommoditySymbol}/>
            </section>
            }
            {userSelection === "Core" &&
                <section>
                <CoreIndustries setUserSelection={setUserSelection} setCommoditySymbol={setCommoditySymbol} />
                </section>
            }
            {userSelection === "Change" &&
                <section>
                <CommodityChangeWrapper setUserSelection={setUserSelection} setCommoditySymbol={setCommoditySymbol} />
                </section>
            }
        </div>
    );
}

export default Commodity                                                