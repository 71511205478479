import React, { useState, useEffect } from 'react';
import Screener from './Screener'
import StockMap from './StockMap'

function ScreenerWrapper(props) {
    const [optionSelected, setValue] = useState('Screener');
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])
    return (
        <div className="Company">
            <section id="mainDetails">
                {/*<div className="CompanyNavContainer">
                    <div className="CompanyNav" style={{ justifyContent: "left", columnGap: "20px" }}>
                        <div className="CompanyMenu" id="Screener" onClick={() => { setValue('Screener') }} style={optionSelected === "Screener" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }} >Screener</div>
                        <div className="CompanyMenu" id="Map" onClick={() => { setValue('Map') }} style={optionSelected === "Map" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>Stock Map</div>
                    </div>
                </div>*/}
                <div id="mainContentSection" style={{paddingTop: "5px"}}>
                    {optionSelected === 'Screener' &&
                        <Screener ratioData={props.ratioData} />

                    }
                    {optionSelected === 'Map' &&
                        <StockMap />
                    }
                </div>
            </section>
        </div>
    )
}


export default ScreenerWrapper;