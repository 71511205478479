import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import FinData from "./FinData"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import Loading from "../../../Company/Summary/Loading"


export const options = {
    title: "Revenue, Net Income, and Net Income - Owner",
    legend: { position: 'none' },
    bar: { groupWidth: '100%' },
    colors: ['#005073', '#1ebbd7', '#e9724d'],
    language: 'hi_IN',
};


function QuarterlyResultMobile(props) {
    const [pageData, setpageData] = useState({
        "data": [],
        //   "horizontal_analysis": [],
        "extended_mapping": [],
        "mapData": [],
        //   "mapDataTwo": [],
        "CompanyName": ""
    });
    const [viewFull, setViewFull] = useState(false);
    //  const [modal, setmodal] = useState(false);
    //  const [modalTwo, setmodalTwo] = useState(false);
    const [sort, setsort] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    function getDisplayValue(data, source) {
        if (data) {
            return data
        }
        if (source !== "m") {
            return source
        }
        return ""
    }

    const companyDetails = async () => {
        setpageData({
            "data": [],
            //   "horizontal_analysis": [],
            "extended_mapping": [],
            "mapData": [],
            //   "mapDataTwo": [],
            "CompanyName": ""
        })
        let url = ""
        let quarter_data = ""
        let quarter_map = ""
        let horizontal_data = ""
        let vertical_data = ""
        let map_data = []
        //  let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/quarterly/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        console.log(cdresponse)
        if (sort) {
            quarter_data = cdresponse.data['quarterly']
            //  horizontal_data = cdresponse.data['horizontal_analysis']
            vertical_data = cdresponse.data['as_pct_of_income']
        } else {
            quarter_data = cdresponse.data['quarterly'].reverse()
            // horizontal_data = cdresponse.data['horizontal_analysis'].reverse()
            vertical_data = cdresponse.data['as_pct_of_income'].reverse()
        }
        quarter_map = cdresponse.data['prompts']
        company_name = cdresponse.data['stock_name']
        map_data.push(["", "Revenue", "Net Income", "Net Income - Owner"])
        quarter_data.map(mapdata => (
            map_data.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['revenue'], mapdata['bankInterestIncome'])) ,  parseFloat(getDisplayValue(mapdata['netIncomeAfterTaxes'], 'm')), parseFloat(getDisplayValue(mapdata['netIncome'], 'm'))])
         ))
        //  map_data_two.push(["", "Sales", "EBITDA", "EBIT", "Net Profit"])
        //  quarter_data.map(mapdata => (
        //      map_data_two.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')), parseFloat(getDisplayValue(mapdata['EBITDA'], "m")), parseFloat(getDisplayValue(mapdata['EBIT'], "m")), parseFloat(getDisplayValue(mapdata['Net Profit'], "m"))])
        //  ))
        document.title = company_name + "- Quarterly Results | Quarterly Profit & Loss | Vertical Analysis";
        console.log(map_data)
        setpageData({
            "data": quarter_data,
            "percentOfIncome": vertical_data,
            //    "horizontal_analysis": horizontal_data,
            "extended_mapping": quarter_map,
            "mapData": map_data,
            //    "mapDataTwo": map_data_two,
            "ReportLink": "https://www.bseindia.com/stock-share-price/equity/scripcode/" + cdresponse.data.bse_code + "/financials-results",
            "CompanyName": company_name
        })
    }



    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort])

    return (
            <>
            {!viewFull ?
                <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    <div className="leftAlign" style={{ paddingBottom: "40px" }}>
                        {pageData.CompanyName}
                    </div>
                    {pageData.mapData.length > 0 &&
                        <>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "right" }}>
                        <div className="optionButtonMobile" onClick={() => setViewFull(true)}> View full Statement</div>
                    </div>
                    
                        <Chart
                        chartType="Bar"
                        data={pageData.mapData}
                        options={options}
                        loader={<div>Loading Chart</div>}

                        />
                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "20px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#005073' }}></div><div>&nbsp;Revenue</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#1ebbd7' }}></div><div>&nbsp;Net Income</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#e9724d' }}></div><div>&nbsp;Net Income - Owner</div></div>
                        </div>
                        </>
                    }
                    {pageData.mapData.length === 0 &&
                        <Loading />
                    }
                </div> :
                <div>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "right" }}>
                        <div className="optionButtonMobile" onClick={() => setViewFull(false)}> View Summary Chart</div>
                    </div>
                    <FinData pageData={pageData} sort={sort} setsort={setsort} source="quaterly" horizontal={false} vertical={true} />
                </div>
            }
            </>
    );
}

export default QuarterlyResultMobile;