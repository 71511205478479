import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import './Holdings.css'
import Loading from "./Loading"
import { downloadExcel } from "react-export-table-to-excel";
import ExportSVG from '../icon/ExportSVG'
import { Popup } from 'semantic-ui-react'


export const options = {
    chart: {
        title: "Holding Analysis",
        subtitle: "Promoters, FIIs, and DIIs",
    },
};



function Holdings(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const setBold = (itemlength, collapseValue) => {
        if (itemlength == 0) {
            return false
        }
        if (collapseValue && itemlength != 0) {
            return true
        }
    }
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    const companyDetails = async () => {
        setpageData({})
        const url = "https://investors-api.niveshkarma.com/api/v1/ownerships/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let company_name = cdresponse.data['name']
        document.title = company_name + "- Holdings | Shareholding Pattern | Portfolio holdings";
        setpageData(cdresponse.data)

    }

    const getUrl = (url) => {
        return "/Investor/" + url
    }


    function handleDownloadExcel() {

        let header = ["S. No.", "Investors", "% of ownership", "Filing Date", "Shares", "Investment Value (Cr)", "Activity", "Change", "Change %"]
        let body = []
        pageData.investors.forEach((item, index) => {
            body.push([index + 1, item.name, item.pctOfOwnership, item.filingDate, item.share, item.investmentValue, item.activity, item.change, item.pctChange])
        })


        downloadExcel({
            fileName: "Holding",
            sheet: "Holding",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])


    return (
        <div className="PeersMain">
            <section className="optionsFinData">
                <div className="leftAlign growthCompanyName">
                    {pageData.name}
                </div>
                <div className="rightAlign">
                    <Popup
                        className="popupChart"
                        trigger={
                            <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer", paddingTop: "5px" }}>
                                <ExportSVG />
                            </div>
                        }
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Export to Excel</div>}
                        style={{ zIndex: "999" }}
                    />
                </div>
            </section>
            <section className="Peers" style={{ paddingRight: "0px" }}>
                {pageData.investors !== undefined && pageData.investors.length !== 0 &&
                    <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto", backgroundColor: "white"}}>
                    <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                        <thead>
                            <tr>
                                <th className="leftPeer intersection" style={{ fontWeight: 600, textAlign: "center" }}>S.&nbsp;No.</th>
                                <th className="leftPeer intersection" style={{ fontWeight: 600 }}>Investors</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>% of ownership</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>Filing Date</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>Shares</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>Investment Value (Cr)</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>Activity</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>Change</th>
                                <th className="columnHeadingScreener" style={{ textAlign: "center" }}>Change %</th>
                                {/*<td className="columnHeading" style={{ textAlign: "center" }}>Type</td>*/}
                                </tr>
                        </thead>
                        <tbody>
                            {pageData.investors && pageData.investors.map((dataKey, index) => (
                                <tr>
                                    <td className="leftPeer" style={{ textAlign: "center" }}>{index + 1}</td>
                                    <td className="leftPeer"><a href={getUrl(dataKey.investor_id)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.name}</a></td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.pctOfOwnership}</td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.filingDate}</td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.share}</td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.investmentValue}</td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.activity}</td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.change}</td>
                                    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.pctChange}</td>
                                    { /*    <td className="tabledata SubHeader" style={{ textAlign: "center" }}>{dataKey.type}</td>*/}
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>
        </div>
    );
}

export default Holdings;