import React, { useEffect } from 'react';

function Compare(props) {
    useEffect(() => {
    }, [props.ind])



    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 1024 1024"
            style={{ fill: props.ind === "compare" ? "#FFFFFF" : "#6d9eeb" }}>
            <path d="M192 352h96v64H192zM192 480h96v64H192zM192 608h96v64H192zM736 352h96v64h-96zM736 480h96v64h-96zM736 608h96v64h-96z" /><path d="M352 668.448V768H128V256h224v99.52a225.056 225.056 0 0 1 64-45.6V192H64v640h352v-117.952a224.416 224.416 0 0 1-64-45.6z" /><path d="M672 512a160 160 0 1 0-192 156.768V832h64v-163.264A160 160 0 0 0 672 512z m-160 96c-52.928 0-96-43.072-96-96s43.072-96 96-96 96 43.072 96 96-43.072 96-96 96z" /><path d="M608 192v117.92a225.056 225.056 0 0 1 64 45.6V256h224v512h-224v-99.552a225.152 225.152 0 0 1-64 45.632V832h352V192H608z" />
        </svg>
    )
}
export default Compare;