import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import CompareSearchbox from "./CompareSearchBox"
import Icon from "../../icon/Icon"
import useAuth from '../../hooks/useAuth';
import Locked from '../../Locked'
import './PeerComparisonMobile.css'

function PeerComparisonMobile(props) {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [pageData, setpageData] = useState(
        {
            Management: [],
            ManagementOrder: [],
            Mos: [],
            MosOrder: [],
            Predictability: [],
            PredictabilityOrder: [],
            Moat: [],
            MoatOrder: []
        });
    const [show, setShow] = useState(false);
    const [showP, setShowP] = useState("table-row");
    const [showMoat, setShowMoat] = useState("table-row");
    const [showMOS, setShowMOS] = useState("table-row");
    const [showManagement, setShowManagement] = useState("table-row");




    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/compare-company/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setShow(false)
        setpageData(
            {
                Management: [cdresponse.data.Management.data],
                ManagementOrder: cdresponse.data.Management.ratio_order,
                Mos: [cdresponse.data["Margin of Safety"].data],
                MosOrder: cdresponse.data["Margin of Safety"].ratio_order,
                Predictability: [cdresponse.data.Predictability.data],
                PredictabilityOrder: cdresponse.data.Predictability.ratio_order,
                Moat: [cdresponse.data.Moat.data],
                MoatOrder: cdresponse.data.Moat.ratio_order

            }
        )
    }


    const updateCompareData = async (symbol) => {
        let tempData = pageData
        let url = "https://api.niveshkarma.com/api/v1/compare-company/" + symbol.value + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        tempData.Management.push(cdresponse.data.Management.data)
        tempData.Mos.push(cdresponse.data["Margin of Safety"].data)
        tempData.Predictability.push(cdresponse.data.Predictability.data)
        tempData.Moat.push(cdresponse.data.Moat.data)
        console.log(tempData)
        setShow(false)
        setpageData(tempData)

    }

    const toggleShowP = () => {
        if (showP === "table-row") {
            setShowP("none")
        } else {
            setShowP("table-row")
        }
    }
    const toggleShowMoat = () => {
        if (showMoat === "table-row") {
            setShowMoat("none")
        } else {
            setShowMoat("table-row")
        }
    }
    const toggleShowManagement = () => {
        if (showManagement === "table-row") {
            setShowManagement("none")
        } else {
            setShowManagement("table-row")
        }
    }
    const toggleShowMos = () => {
        if (showMOS === "table-row") {
            setShowMOS("none")
        } else {
            setShowMOS("table-row")
        }
    }

    const accessScore = () => {

        if (auth.roles) {
            return true
        }
        if (["HDFCBANK", "TCS", "ITC"].includes(props.compSymbol) && auth.user) {
            return true
        }

        return false
    }



    useEffect(() => {
        const timeout = setTimeout(() => {
          //  if (accessScore()) {
            companyDetails()
         //   }
        }, 50)
        return () => clearTimeout(timeout)
    }, [props.compSymbol, auth.user])


    return (
        <div className="CompareContainer">
            {/*  { accessScore() ? */}
                <div style={{ textAlign: "left" }}>
                    <div style={{ paddingTop: "40px" }}>
                        <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Compare with Peers</div>
                    </div>
                    <section style={{ border: "none", marginTop: "0px", paddingTop: "0px", overflow: "auto", minHeight: "300px" }}>
                        <div className="ScoreCompare">
                            {pageData?.PredictabilityOrder !== undefined &&
                                <div className="CompareRow">
                                    <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto", paddingLeft: "10px" }}>
                                        <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                            <thead>
                                                <tr style={{ backgroundColor: "white" }}>
                                                    <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px" }}></th>
                                                    {pageData?.Predictability?.map(item => (
                                                        <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px" }}>{item["Company Name"]}</th>
                                                    ))
                                                    }
                                                    <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px" }}>
                                                        {pageData.Predictability.length < 5 &&
                                                            <div style={{ display: "flex", justifyContent: "flex-start", paddingRight: "20px" }}>
                                                        <div className="CompareDataAddonMobile" onClick={() => setShow(true)}>+</div>
                                                                {show &&
                                                                    <CompareSearchbox companyOptions={props.compList} updateCompareData={updateCompareData} compSymbol={props.compSymbol} />}
                                                            </div>
                                                        }
                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colSpan={pageData?.Predictability?.length + 2}><div style={{ fontSize: "16px", display: "flex", alignContent: "start", gap: "10px" }}><div>Predictability</div><div onClick={() => toggleShowP()}>{showP === "table-row" ? <Icon source="minus" /> : <Icon source="plus" />}</div></div></th>
                                                </tr>
                                                {pageData?.PredictabilityOrder?.map((dataDict, index) => (
                                                    <>
                                                        {Object.keys(dataDict).map(data => (
                                                            <>
                                                                <tr style={{ display: showP }}>
                                                                    <td colSpan={pageData?.Predictability?.length + 2}><div style={{ fontWeight: "600", paddingLeft: "10px" }}>{data}</div></td>
                                                                </tr>
                                                                {pageData?.PredictabilityOrder[index][data].filter(element => element != "Company Name").map(item => (
                                                                    <tr style={{ display: showP }}>
                                                                        <td><div style={{ paddingLeft: "30px", minWidth: "80px" }}>{item}</div></td>
                                                                        {pageData?.Predictability?.map(items => (
                                                                            <td style={{ textAlign: "left", paddingLeft: "20px", minWidth: "80px"  }}>{items[item]}</td>
                                                                        ))
                                                                        }
                                                                        <td></td>
                                                                    </tr>
                                                                ))


                                                                }
                                                            </>
                                                        ))
                                                        }
                                                    </>
                                                ))

                                                }


                                                <tr>
                                                    <th colSpan={pageData?.Moat?.length + 2}><div style={{ fontSize: "16px", display: "flex", alignContent: "start", gap: "10px" }}><div>Moat</div><div onClick={() => toggleShowMoat()}>{showMoat === "table-row" ? <Icon source="minus" /> : <Icon source="plus" />}</div></div></th>
                                                </tr>
                                                {pageData?.MoatOrder?.map((dataDict, index) => (
                                                    <>
                                                        {Object.keys(dataDict).map(data => (
                                                            <>
                                                                <tr style={{ display: showMoat }}>
                                                                    <td colSpan={pageData?.Moat?.length + 2}><div style={{ fontWeight: "600", paddingLeft: "10px" }}>{data}</div></td>
                                                                </tr>
                                                                {pageData?.MoatOrder[index][data].filter(element => element != "Company Name").map(item => (
                                                                    <tr style={{ display: showMoat }}>
                                                                        <td><div style={{ paddingLeft: "30px" }}>{item}</div></td>
                                                                        {pageData?.Moat?.map(items => (
                                                                            <td style={{ textAlign: "left", paddingLeft: "20px" }}>{items[item]}</td>
                                                                        ))
                                                                        }
                                                                        <td></td>
                                                                    </tr>
                                                                ))


                                                                }
                                                            </>
                                                        ))
                                                        }
                                                    </>
                                                ))

                                                }


                                                <tr>
                                                    <th colSpan={pageData?.Management?.length + 2}><div style={{ fontSize: "16px", display: "flex", alignContent: "start", gap: "10px" }}><div>Management</div><div onClick={() => toggleShowManagement()}>{showManagement === "table-row" ? <Icon source="minus" /> : <Icon source="plus" />}</div></div></th>
                                                </tr>
                                                {pageData?.ManagementOrder?.map((dataDict, index) => (
                                                    <>
                                                        {Object.keys(dataDict).map(data => (
                                                            <>
                                                                <tr style={{ display: showManagement }}>
                                                                    <td colSpan={pageData?.Management?.length + 2}><div style={{ fontWeight: "600", paddingLeft: "10px" }}>{data}</div></td>
                                                                </tr>
                                                                {pageData?.ManagementOrder[index][data].filter(element => element != "Company Name").map(item => (
                                                                    <tr style={{ display: showManagement }}>
                                                                        <td><div style={{ paddingLeft: "30px" }}>{item}</div></td>
                                                                        {pageData?.Management?.map(items => (
                                                                            <td style={{ textAlign: "left", paddingLeft: "20px" }}>{items[item]}</td>
                                                                        ))
                                                                        }
                                                                        <td></td>
                                                                    </tr>
                                                                ))


                                                                }
                                                            </>
                                                        ))
                                                        }
                                                    </>
                                                ))

                                                }


                                                <tr>
                                                    <th colSpan={pageData?.Mos?.length + 2}><div style={{ fontSize: "16px", display: "flex", alignContent: "start", gap: "10px" }}><div>Margin of Saftey</div><div onClick={() => toggleShowMos()}>{showMOS === "table-row" ? <Icon source="minus" /> : <Icon source="plus" />}</div></div></th>
                                                </tr>
                                                {pageData?.MosOrder?.map((dataDict, index) => (
                                                    <>
                                                        {Object.keys(dataDict).map(data => (
                                                            <>
                                                                <tr style={{ display: showMOS }}>
                                                                    <td colSpan={pageData?.Mos?.length + 2}><div style={{ fontWeight: "600", paddingLeft: "10px" }}>{data}</div></td>
                                                                </tr>
                                                                {pageData?.MosOrder[index][data].filter(element => element != "Company Name").map(item => (
                                                                    <tr style={{ display: showMOS }}>
                                                                        <td><div style={{ paddingLeft: "30px" }}>{item}</div></td>
                                                                        {pageData?.Mos?.map(items => (
                                                                            <td style={{ textAlign: "left", paddingLeft: "20px" }}>{items[item]}</td>
                                                                        ))
                                                                        }
                                                                        <td></td>
                                                                    </tr>
                                                                ))


                                                                }
                                                            </>
                                                        ))
                                                        }
                                                    </>
                                                ))

                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>





                    </section>




                </div>
            {/* :
                <div style={{ paddingTop: "20px" }}>
                    <Locked />
                </div> 
            }*/}
        </div>
    );
}

export default PeerComparisonMobile;