import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Loading from "./Loading"
//import Chart from "react-apexcharts";
import { Chart } from "react-google-charts";
import { Treemap } from "../util/Chart/TreeMap";

export const options = {
  //  minColor: "#f00",
  //  midColor: "#ddd",
  //  maxColor: "#0d0",
    headerHeight: 15,
    fontColor: "black",
    showScale: true,
    maxDepth: 3,
    maxPostDepth: 0,
    colorAxis: {
        colors: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00"],
        values: [0, 10000, 100000, 1000000],
    },
    tooltip: {
        isHtml: true
    }
};


const data  = {
    type: "node",
    name: "boss",
    value: 0,
    children: [
        {
            type: "node",
            name: "Team Dataviz",
            value: 0,
            children: [
                { type: "leaf", name: "Mark", value: 90 },
                { type: "leaf", name: "Robert", value: 12 },
                { type: "leaf", name: "Emily", value: 34 },
                { type: "leaf", name: "Marion", value: 53 },
            ],
        },
        {
            type: "node",
            name: "Team DevOps",
            value: 0,
            children: [
                { type: "leaf", name: "Nicolas", value: 98 },
                { type: "leaf", name: "Malki", value: 22 },
                { type: "leaf", name: "Dj�", value: 12 },
            ],
        },
        {
            type: "node",
            name: "Team Sales",
            value: 0,
            children: [
                { type: "leaf", name: "M�lanie", value: 45 },
                { type: "leaf", name: "Einstein", value: 76 },
            ],
        },
    ],
};


function StockMap() {
    const axiosPrivate = useAxiosPrivate();
    const [mapData, setMapData] = useState({})
    //const [mapData, setMapData] = useState([])
    const [query] = useState([{
        "ratio_code": "PR001",
        "selection_code": "02",
        "selection_type": "custom",
        "min_value": 0,
        "max_value": 100000
    }])


    const ChartEvents = [
        {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                google.visualization.events.addListener(chart, "onmouseover", e => {
                    const { row, column } = e;
                    console.warn("MOUSE OVER ", { row, column });
                });
                google.visualization.events.addListener(chart, "onmouseout", e => {
                    const { row, column } = e;
                    console.warn("MOUSE OUT ", { row, column });
                });
            }
        }
    ];



    function forceIntValue(data) {
        let finalData = data.replaceAll(",", "")
        return parseInt(finalData)
    }

    function addDataToList(dataObject, ResultList) {
        let ObjectFound = false
        ResultList.map(item => {
            if (item.name === dataObject.Industry) {
                console.log(forceIntValue(dataObject.PR002))
                item.data.push({ x: dataObject.stock_name, y: forceIntValue(dataObject.PR002) })
                ObjectFound = true
            }
        })
        if (!ObjectFound) {
            ResultList.push({ name: dataObject.Industry, data: [{ x: dataObject.stock_name, y: forceIntValue(dataObject.PR002) }]})
        }
    }


    function generateUniqueSectorIndustry(dataObject, SectorList, IndustryObject) {
        if (!SectorList.includes(dataObject.Sector)) {
            if (dataObject.Sector !== "Others" && dataObject.Sector !== "N/A") {
                SectorList.push(dataObject.Sector)
            }
        }
        if (!Object.keys(IndustryObject).includes(dataObject.Industry)) {
            if (dataObject.Industry !== "Others" && dataObject.Industry !== "N/A") {
                IndustryObject[dataObject.Industry] = dataObject.Sector
            }
        }

    }

    function isStockPresent(dataList, stockName) {
        dataList.map(item => {
            if (item[0] === stockName) {
                console.log(stockName)  
                return true
            }
        })
        return false
    }

    const companyDetails = async () => {
        setMapData({})
        let url = "https://api.niveshkarma.com/api/v1/screener?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const response = await axiosPrivate.post(url, { "query": query });
        let userData = response.data.stocks
        /*let tempMapData = [["Name", "Parent", "Market Cap", "Percent Change"],
            ["Indian Stock Exchange", null, 0, 0],
            ["Others", "Indian Stock Exchange", 0, 0],
            ["N/A", "Indian Stock Exchange", 0, 0]

        ]*/
        let tempMapData = {
            type: "node",
            name: "Indian Stock Exchange",
            code: "",
            value: 0,
            change: 0,
            price: 0,
            children: []
        }
        let series = [] 
        let childObject = {}
        userData.forEach(item => {
           // addDataToList(item, series)
            generateUniqueSectorIndustry(item, series, childObject)
            
        })
        console.log(series)
        console.log(childObject)

        series.forEach(data => {
            tempMapData.children.push({
                type: "node",
                name: data,
                code: "",
                value: 0,
                change: 0,
                price: 0,
                children: []})
        })
        Object.keys(childObject).map(data => {
            tempMapData.children.map(item => {
                if (item.name === childObject[data]) {
                    item.children.push({
                        type: "node",
                        name: data,
                        code: "",
                        value: 0,
                        change: 0,
                        price: 0,
                        children: []
                    })
                }
            })
        })
        userData.forEach(item => {
            if (item.stock_name !== "Tata Motors Ltd" && item.stock_name !== "Jain Irrigation Systems Ltd") {
                tempMapData.children.map(sectorItem => {
                    if (sectorItem.name === item.Sector) {
                        sectorItem.children.map(industryItem => {
                            if (industryItem.name === item.Industry) {
                                industryItem.children.push({
                                    type: "leaf",
                                    name: item.stock_name,
                                    code: item.stock_symbol,
                                    value: forceIntValue(item.PR002),
                                    price: forceIntValue(item.PR001),
                                    change: (forceIntValue(item.PR002)/100),
                                })
                            }
                        })
                    }
                })
            }
        })
        console.log(tempMapData)






       /*userData.forEach(item => {
            if (item.stock_name !== "Tata Motors Ltd" && item.stock_name !== "Jain Irrigation Systems Ltd") {
                tempMapData.push([item.stock_name, item.Industry, forceIntValue(item.PR002), forceIntValue(item.PR002)])
            }
        })
        console.log(tempMapData)
        setMapData({

            series: series,
            options: {
                legend: {
                    show: false
                },
                chart: {
                    height: 550,
                    type: 'treemap'
                },
                title: {
                    text: 'Stock Map',
                    align: 'center'
                },
                color : []
            }
        })*/
        setMapData(tempMapData)
        
    }

    

    

    useEffect(() => {
        setTimeout(function () { companyDetails(); }, 50);

    }, [])




    return (
        <div className="PeersMain">
            <section className="Peers">
                {/*Object.keys(mapData).length > 0 && 
                    <Chart options={mapData.options} series={mapData.series} type="treemap" height={750} />
                */}
                {/*mapData.length > 0 &&
                    <Chart
                        chartType="TreeMap"
                        width="100%"
                        height="650px"
                        data={mapData}
                        options={options}
                        chartEvents={ChartEvents}
                    />


                */}
                {Object.keys(mapData).length > 0 && 
                    <Treemap data={mapData} width="1400" height="670" />
                }

                
            </section>
        </div>
    );
}


export default StockMap;