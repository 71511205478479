import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import HomeContents from './HomeContents'
import PersonalizedHome from './PersonalizedHome'

function Home(props) {
    const { auth } = useAuth();
    useEffect(() => {

    }, [auth.user])
    return (
        <div className="Home" >
            {auth.user ?
                <PersonalizedHome
                    ratioData={props.ratioData}
                    optionData={props.optionData}
                    showSearch={props.showSearch}
                    searchHistory={props.searchHistory}
                    setSearchHistory={props.setSearchHistory}
                    setShowSearch={props.setShowSearch}
                />
                :
                <HomeContents
                    optionData={props.optionData}
                    setShowSearch={props.setShowSearch}
                    showSearch={props.showSearch}
                    searchHistory={props.searchHistory}
                    setSearchHistory={props.setSearchHistory}
                />
            }
        </div>
    );
}
export default Home;