import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Chart } from "react-google-charts";
import Icon from "../../icon/Icon"
import Loading from "../../Company/Summary/Loading"
import ProfileData from "../../context/ProfileData"

export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};


function EpsMobile(props) {
    let userData = ProfileData.getProfileInfo(props.compSymbol)
    console.log(userData)
    if (!userData || userData.company !== props.compSymbol) {
        userData = { avgEPS: 0, assumedGRFiveYear: 0, assumedGRFiveTenYear: 0, discountRate: 0, forwardPE: 0 }
    }
    const [pageData, setpageData] = useState({
        "price": 0,
        "mos": 0,
        "pvRatio": 0,
        "avgEPS": userData.avgEPS,
        "assumedGRFiveYear": userData.assumedGRFiveYear,
        "assumedGRFiveTenYear": userData.assumedGRFiveTenYear,
        "discountRate": userData.discountRate,
        "forwardPE": userData.forwardPE,
        "futureEPS": 0,
        "futureSharePrice": 0,
        "shareOutstanding": 0,
        "epsInput": [],
        "valueOfFirm": 0,
        "marketCap": 0,
        "ivps": 0,
        "currentYear": 0,
        "mapData": [],

    });

    const axiosPrivate = useAxiosPrivate();
    const getColor = (data) => {
        let tempData = parseFloat(data)
        if (tempData < 10.0) {
            return "#FF5733"
        } else if (tempData >= 5.0 && tempData < 30.0) {
            return "#E49B0F"
        } else {
            return "#00A36C"
        }
    }

    function getDisplayValue(data) {
        if (data) {
            return (Math.round(data * 100) / 100).toLocaleString('en-IN')
        } else {
            return ""
        }
    }
    const forceDefault = (data) => {
        if (data) {
            return data
        } else {
            return 0.0
        }
    }

    const handlePlus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedGRFiveYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedGRFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedGRFiveTenYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const handleMinus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedGRFiveYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedGRFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedGRFiveTenYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const evaluateEPS = () => {
        ProfileData.setProfileInfo(
            {
                company: props.compSymbol,
                avgEPS: pageData.avgEPS,
                assumedGRFiveYear: pageData.assumedGRFiveYear,
                assumedGRFiveTenYear: pageData.assumedGRFiveTenYear,
                discountRate: pageData.discountRate,
                forwardPE: pageData.forwardPE
            }, props.compSymbol)

        let var_price = parseFloat(pageData.price)
        let rate = 0
        let lastEPS = pageData.avgEPS
        let outputfutureEPS = 0
        let varEPSInput = [{ "year": "Current", "earning": lastEPS, "GrowthRate": "" }, { "year": "+1", "earning": "", "GrowthRate": "" }, { "year": "+2", "earning": "", "GrowthRate": "" }, { "year": "+3", "earning": "", "GrowthRate": "" }, { "year": "+4", "earning": "", "GrowthRate": "" }, { "year": "+5", "earning": "", "GrowthRate": "" }, { "year": "+6", "earning": "", "GrowthRate": "" }, { "year": "+7", "earning": "", "GrowthRate": "" }, { "year": "+8", "earning": "", "GrowthRate": "" }, { "year": "+9", "earning": "", "GrowthRate": "" }, { "year": "+10", "earning": "", "GrowthRate": "" }]
        let outputfutureSharePrice = 0
        let varMapData = [["", "EPS"], [String(2023), Math.round(lastEPS)]]
        varEPSInput.forEach((epsData, epsIndex) => {
            if (epsIndex >= 1) {
                if (epsIndex < 6) {
                    rate = pageData.assumedGRFiveYear
                } else {
                    rate = pageData.assumedGRFiveTenYear
                }
                let varEPS = lastEPS * (1 + (rate / 100))
                let finalRate = rate
                console.log(finalRate)
                lastEPS = varEPS
                varMapData.push([String(2023 + epsIndex), Math.round(lastEPS)])
                epsData.earning = lastEPS
                epsData.GrowthRate = parseFloat(finalRate).toFixed(2)

            }
        })

        let varEarning = lastEPS
        let futureSharePrice = Math.round(varEarning * pageData.forwardPE)
        let varivps = Math.round(futureSharePrice / Math.pow((1 + pageData.discountRate / 100), (10)))
        let varpvRatio = Math.round((varivps / var_price) * 100) / 100
        let mos = ""
        console.log(varivps)
        if (varivps > 0.0) {
            let tempVal = ((varivps - var_price) / varivps) * 100
            mos = tempVal.toString()
        } else {
            mos = 0
        }
        let updatedValue = {};
        updatedValue = {
            "mos": mos,
            "epsInput": varEPSInput,
            "futureEPS": varEarning,
            "futureSharePrice": futureSharePrice,
            "valueOfFirm": '',
            "ivps": varivps,
            "pvRatio": varpvRatio,
            "mapData": varMapData
        };

        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));

    }

    const handleRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedGRFiveYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleTenRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedGRFiveTenYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDiscountRateChange = e => {
        let updatedValue = {};
        updatedValue = { "discountRate": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };


    const EPSChange = e => {
        let updatedValue = {};
        updatedValue = { "avgEPS": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };

    const forwardPEChange = e => {
        let updatedValue = {};
        updatedValue = { "forwardPE": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };

    function getDefaultValue(valueOne, ValueTwo) {
        if (!userData || userData.company !== props.compSymbol) {
            return ValueTwo
        } else {
            return valueOne
        }
    }
    const getDefaultInput = async (ind) => {
        setpageData({
            "price": 0,
            "mos": 0,
            "pvRatio": 0,
            "avgEPS": 0,
            "assumedGRFiveYear": 0,
            "assumedGRFiveTenYear": 0,
            "discountRate": 0,
            "forwardPE": 0,
            "futureEPS": 0,
            "futureSharePrice": 0,
            "shareOutstanding": 0,
            "epsInput": [],
            "valueOfFirm": 0,
            "marketCap": 0,
            "ivps": 0,
            "currentYear": 0,
            "mapData": [],

        })
        if (ind === "r") {
            let url = ""
            url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const cdresponse = await axiosPrivate.get(url);
            let rate = 0
            let lastEPS = cdresponse.data.valuation_ratio.VR006
            console.log(lastEPS)
            let outputfutureEPS = 0
            let varEPSInput = [{ "year": "Current", "earning": lastEPS, "GrowthRate": "" }, { "year": "+1", "earning": "", "GrowthRate": "" }, { "year": "+2", "earning": "", "GrowthRate": "" }, { "year": "+3", "earning": "", "GrowthRate": "" }, { "year": "+4", "earning": "", "GrowthRate": "" }, { "year": "+5", "earning": "", "GrowthRate": "" }, { "year": "+6", "earning": "", "GrowthRate": "" }, { "year": "+7", "earning": "", "GrowthRate": "" }, { "year": "+8", "earning": "", "GrowthRate": "" }, { "year": "+9", "earning": "", "GrowthRate": "" }, { "year": "+10", "earning": "", "GrowthRate": "" }]
            let outputfutureSharePrice = 0
            let varMapData = [["", "FCF"], [String(2023), Math.round(lastEPS)]]
            varEPSInput.forEach((epsData, epsIndex) => {
                if (epsIndex >= 1) {
                    if (epsIndex < 6) {
                        rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR003))
                    } else {
                        rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR004))
                    }
                    let varEPS = lastEPS * (1 + (rate / 100))
                    let finalRate = rate
                    lastEPS = varEPS
                    varMapData.push([String(2023 + epsIndex), Math.round(lastEPS)])
                    epsData.earning = lastEPS
                    epsData.GrowthRate = finalRate.toFixed(2)

                }
            })
            let var_price = parseFloat(cdresponse.data.close_price)
            let varEarning = lastEPS
            let futureSharePrice = varEarning * parseFloat(cdresponse.data.valuation_ratio.VR007)
            let varivps = futureSharePrice / Math.pow((1 + parseFloat(cdresponse.data.valuation_ratio.VR010) / 100), (10))
            let varpvRatio = Math.round((varivps / var_price) * 100) / 100
            let mos = ""
            console.log(varivps)
            if (varivps > 0.0) {
                let tempVal = ((varivps - var_price) / varivps) * 100
                mos = tempVal.toString()
            } else {
                mos = 0
            }
            setpageData({
                "price": Math.round(var_price),
                "mos": mos,
                "pvRatio": varpvRatio,
                "avgEPS": cdresponse.data.valuation_ratio.VR006,
                "assumedGRFiveYear": parseFloat(cdresponse.data.valuation_ratio.VR003),
                "assumedGRFiveTenYear": parseFloat(cdresponse.data.valuation_ratio.VR004),
                "discountRate": parseFloat(cdresponse.data.valuation_ratio.VR010),
                "forwardPE": parseFloat(Math.round(cdresponse.data.valuation_ratio.VR007)),
                "futureEPS": varEarning,
                "futureSharePrice": Math.round(futureSharePrice),
                "shareOutstanding": 0,
                "epsInput": varEPSInput,
                "valueOfFirm": 0,
                "marketCap": 0,
                "ivps": Math.round(varivps),
                "currentYear": 0,
                "mapData": varMapData,

            })
        } else {
            let url = ""
            url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const cdresponse = await axiosPrivate.get(url);


            let rate = 0
            let lastEPS = getDefaultValue(userData.avgEPS, cdresponse.data.valuation_ratio.VR006)
            console.log(lastEPS)
            let outputfutureEPS = 0
            let varEPSInput = [{ "year": "Current", "earning": lastEPS, "GrowthRate": "" }, { "year": "+1", "earning": "", "GrowthRate": "" }, { "year": "+2", "earning": "", "GrowthRate": "" }, { "year": "+3", "earning": "", "GrowthRate": "" }, { "year": "+4", "earning": "", "GrowthRate": "" }, { "year": "+5", "earning": "", "GrowthRate": "" }, { "year": "+6", "earning": "", "GrowthRate": "" }, { "year": "+7", "earning": "", "GrowthRate": "" }, { "year": "+8", "earning": "", "GrowthRate": "" }, { "year": "+9", "earning": "", "GrowthRate": "" }, { "year": "+10", "earning": "", "GrowthRate": "" }]
            let outputfutureSharePrice = 0
            let varMapData = [["", "FCF"], [String(2023), Math.round(lastEPS)]]
            varEPSInput.forEach((epsData, epsIndex) => {
                if (epsIndex >= 1) {
                    if (epsIndex < 6) {
                        rate = forceDefault(parseFloat(getDefaultValue(userData.assumedGRFiveYear, cdresponse.data.valuation_ratio.VR003)))
                    } else {
                        rate = forceDefault(parseFloat(getDefaultValue(userData.assumedGRFiveTenYear, cdresponse.data.valuation_ratio.VR004)))
                    }
                    let varEPS = lastEPS * (1 + (rate / 100))
                    let finalRate = rate
                    lastEPS = varEPS
                    varMapData.push([String(2023 + epsIndex), Math.round(lastEPS)])
                    epsData.earning = lastEPS
                    epsData.GrowthRate = finalRate.toFixed(2)

                }
            })
            let var_price = parseFloat(cdresponse.data.close_price)
            let varEarning = lastEPS
            let futureSharePrice = varEarning * parseFloat(getDefaultValue(userData.forwardPE, cdresponse.data.valuation_ratio.VR007))
            let varivps = futureSharePrice / Math.pow((1 + parseFloat(getDefaultValue(userData.discountRate, cdresponse.data.valuation_ratio.VR010)) / 100), (10))
            let varpvRatio = Math.round((varivps / var_price) * 100) / 100
            let mos = ""
            console.log(varivps)
            if (varivps > 0.0) {
                let tempVal = ((varivps - var_price) / varivps) * 100
                mos = tempVal.toString()
            } else {
                mos = 0
            }
            setpageData({
                "price": Math.round(var_price),
                "mos": mos,
                "pvRatio": varpvRatio,
                "avgEPS": getDefaultValue(userData.avgEPS, cdresponse.data.valuation_ratio.VR006),
                "assumedGRFiveYear": parseFloat(getDefaultValue(userData.assumedGRFiveYear, cdresponse.data.valuation_ratio.VR003)),
                "assumedGRFiveTenYear": parseFloat(getDefaultValue(userData.assumedGRFiveTenYear, cdresponse.data.valuation_ratio.VR004)),
                "discountRate": parseFloat(getDefaultValue(userData.discountRate, cdresponse.data.valuation_ratio.VR010)),
                "forwardPE": parseFloat(Math.round(getDefaultValue(userData.forwardPE, cdresponse.data.valuation_ratio.VR007))),
                "futureEPS": varEarning,
                "futureSharePrice": Math.round(futureSharePrice),
                "shareOutstanding": 0,
                "epsInput": varEPSInput,
                "valueOfFirm": 0,
                "marketCap": 0,
                "ivps": Math.round(varivps),
                "currentYear": 0,
                "mapData": varMapData,

            })
        }
    }

    useEffect(() => {
        getDefaultInput("i")
    }, [props.compSymbol])

    return (
        <>{pageData.price !== 0 &&
            <>
            <div className="ValuationRow" style={{ marginTop: "20px" }}>

                <div className="ValuationOddDivMobile"><div className="leftIntend middle">Initial EPS:  </div><div className="userInputMobile"><div className="grey leftRoundedMobile">₹</div><div className="paddedUserInput"><input type="number" className="customInputMobile" placeholder="Base EPS" value={pageData.avgEPS} onChange={EPSChange} /></div><div className="grey rightRoundedMobile"></div></div></div>
                <div className="ValuationEvenDivMobile"><div className="leftIntend middle">Growth Rate(1-5 Yrs): </div><div className="userInputMobile"><div className="grey leftRoundedMobile">{pageData.assumedGRFiveYear}</div><div onClick={() => handleMinus("grfive")}><Icon source="minus" /></div><div className="paddedUserInput"><input type="range" className="customInputRangeMobile" min="1" max="30" step="0.5" value={pageData.assumedGRFiveYear} onChange={handleRateChange} /></div><div onClick={() => handlePlus("grfive")}><Icon source="plus" /></div><div className="grey rightRoundedMobile">%</div></div></div>
                <div className="ValuationOddDivMobile"><div className="leftIntend middle">Growth Rate(6-10 Yrs): </div><div className="userInputMobile"><div className="grey leftRoundedMobile">{pageData.assumedGRFiveTenYear}</div><div onClick={() => handleMinus("grten")}><Icon source="minus" /></div><div className="paddedUserInput"><input type="range" className="customInputRangeMobile" min="1" max="30" step="0.5" value={pageData.assumedGRFiveTenYear} onChange={handleTenRateChange} /></div><div onClick={() => handlePlus("grten")}><Icon source="plus" /></div><div className="grey rightRoundedMobile">%</div></div></div>
                <div className="ValuationEvenDivMobile"><div className="leftIntend middle">Discount Rate: </div><div className="userInputMobile"><div className="grey leftRoundedMobile">{pageData.discountRate}</div><div onClick={() => handleMinus("discRate")}><Icon source="minus" /></div><div className="paddedUserInput"><input type="range" className="customInputRangeMobile" min="1" max="30" step="0.5" value={pageData.discountRate} onChange={handleDiscountRateChange} /></div><div onClick={() => handlePlus("discRate")}><Icon source="plus" /></div><div className="grey rightRoundedMobile">%</div></div></div>
                <div className="ValuationOddDivMobile"><div className="leftIntend middle">Estimated Future PE: </div><div className="userInputMobile"><div className="grey leftRoundedMobile"></div><div className="paddedUserInput"><input type="number" className="customInputMobile" placeholder="Future PE" value={pageData.forwardPE} onChange={forwardPEChange} /></div><div className="grey rightRoundedMobile"></div></div></div>

                        <div className="optionButtonPrimary topMarginEPS">
                            <div className="optionButtonVal" onClick={evaluateEPS}> Calculate EPS</div>
                            <div className="optionButtonVal" onClick={() => getDefaultInput("r")}> Reset</div>
                        </div>
                        
                <div style={{ padding: "40px 20px 0px 20px" }}>
                    <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Valuation Result</div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Projected EPS after 10 years: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{getDisplayValue(pageData.futureEPS)}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Current Price: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px"}}>{getDisplayValue(pageData.price)}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Margin of Saftey: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{getDisplayValue(pageData.mos)}%</div><div className="DetailsEnd"></div></div></div></div>

                </div>

                <div style={{ padding: "0px 20px 20px 20px" }}>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Projected stock price after 10 years: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{getDisplayValue(pageData.futureSharePrice)}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Intrinsic Value: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{getDisplayValue(pageData.ivps)}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Price/Value: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{getDisplayValue(pageData.pvRatio)}</div><div className="DetailsEnd"></div></div></div></div>
                </div>




                        <div className="ValuationRowInnerI">
                            <div className="FullQuarter borderEnclosed">
                                <div className="headerChart" style={{ paddingLeft: "20px" }}>Future EPS Estimation</div>
                                <div className="valCharts">
                                    <Chart
                                        className="valuationChart"
                                        chartType="Bar"
                                        width="100%"
                                        height="150px"
                                        data={pageData.mapData}
                                        options={options}

                                    />
                                </div>
                            </div>
                        </div>

                </div>
                <div className="ValuationRow" style={{ paddingTop: "40px" }}>
                    <div className="FullQuarter">
                    <h3 class="leftHeading" style={{ color: "#606f7b", whiteSpace: "nowrap"  }}>EPS Model</h3>
                        <table className="finData valuationResult">
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap"  }}>
                                    Year
                            </td >
                                {
                                    pageData.epsInput.map(data => (
                                        <td className="rightIntend bold" style={{ color: "#606f7b" }}>{data["year"]}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap"  }}>
                                    Earning
                            </td>
                                {
                                    pageData.epsInput.map(data => (
                                        <td className="rightIntend">{getDisplayValue(data["earning"])}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                            <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap"  }}>
                                    Growth Rate
                            </td>
                                {
                                    pageData.epsInput.map(data => (
                                        <td className="rightIntend">{data["GrowthRate"]}</td>
                                    ))
                                }
                            </tr>
                        </table>
                    </div>

                </div>
                <div style={{ textAlign: "left", color: "grey", fontSize: "14px", paddingTop: "10px", marginBottom: "110px" }}>
                    **This data has been generated by a machine. It is necessary to adjust the required inputs for
                    business valuation based on your estimation of future growth.
            </div>
            </>
        }{
                pageData.price === 0 &&
                <Loading />
            }
        </>
    );
}

export default EpsMobile;
