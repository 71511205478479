import React, { useEffect, useState  } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./HelpModal.css";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate"

const HelpModal = props => {
    const { auth, setAuth  } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    const SaveRatios = async () => {
        props.setViewLoading(true)
        let url = "https://users.niveshkarma.com/api/v1/update-profile"
        let dataJson = {
            "add_ratios": auth.ratios,
            "historical_ratios": auth.historicalRatios,
            "peer_ratios": auth.peerColumns

        }
        const response = await axiosPrivate.post(url,
            dataJson
        );
        console.log(response)

        const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
        if (props.source === "peerColumns") {
            const updatedItemData = { ...itemsData, "peerColumns": auth.peerColumns }
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(updatedItemData))
        } else {
            const updatedItemData = { ...itemsData, "historicalRatios": auth.historicalRatios }
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(updatedItemData))
        }
        props.setViewLoading(false)
        props.setShow(false)
        props.toggleProfile()
    }


    const ResetDefaults = async () => {
        props.setViewLoading(true)
        let dataArr = ["PR001", "PR002", "PR021", "PR022", "PR024", "RM492", "RM496", "RM498", "RM106"]
        let url = "https://users.niveshkarma.com/api/v1/update-profile"
        let dataJson = {
            "add_ratios": auth.ratios,
            "historical_ratios": auth.historicalRatios,
            "peer_ratios": dataArr

        }
        setAuth(prevAuth => ({ ...prevAuth, peerColumns: dataArr }))
        const response = await axiosPrivate.post(url,
            dataJson
        );
        console.log(response)
        const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
        const updatedItemData = { ...itemsData, "peerColumns": dataArr }
        localStorage.setItem('NKUserProfileInfo', JSON.stringify(updatedItemData))
        props.setViewLoading(false)
        props.setShow(false)
        props.toggleProfile()
    }






    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className="modalMR" onClick={props.onClose}>
                <div className="modal-contentMR" onClick={e => e.stopPropagation()}>
                    <div className="modal-headerMR">
                        <h4 className="modal-titleMR">{props.title}</h4>
                    </div>
                    <div className="modal-bodyMR">{props.children}</div>
                    <div className="modal-footerMR">
                        {props.source === "peerColumns" && auth.user &&
                            <>{!props.viewLoading && <button onClick={ResetDefaults} className="button" disabled={props.disableButton ? false : true}>
                                Reset to Defaults
                        </button>}</>
                        }
                        {props.source !== "manageRatios" && auth.user &&
                            <>{!props.viewLoading && <button onClick={SaveRatios} className="button" disabled={props.disableButton ? false : true}>
                            Save Columns
                        </button>}</>
                        }
                        <button onClick={props.onClose} className="button" disabled={props.viewLoading}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>,
        document.getElementById("root")
    );
};

export default HelpModal;
