import React, { useState } from 'react';

function WatchlistIcon() {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };


    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const getIconPathData = () => {
        return "M815.2 911.7H210.8c-55.4 0-100.7-45.3-100.7-100.7V206.5c0-55.4 45.3-100.7 100.7-100.7h604.5c55.4 0 100.7 45.3 100.7 100.7V811c0 55.3-45.3 100.7-100.8 100.7zM210.8 156.1c-30.2 0-50.4 20.1-50.4 50.4V811c0 30.2 20.1 50.4 50.4 50.4h604.5c30.2 0 50.4-20.1 50.4-50.4V206.5c0-30.2-20.1-50.4-50.4-50.4H210.8z m0 0"
    }

    const getIconPathDataSecond = () => {
        return "M714.5 533.9h-403c-15.1 0-25.2-10.1-25.2-25.2s10.1-25.2 25.2-25.2h403c15.1 0 25.2 10.1 25.2 25.2s-10.1 25.2-25.2 25.2z m0 0"
    }

    const getIconPathDataThird = () => {
        return "M513 735.4c-15.1 0-25.2-10.1-25.2-25.2v-403c0-15.1 10.1-25.2 25.2-25.2s25.2 10.1 25.2 25.2v403c0 15.1-10.1 25.2-25.2 25.2z m0 0"
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            width="18"
            height="18"
            viewBox="0 0 1024 1024"
            style={{ fill: "#fff" }}>
            <path d={getIconPathData()}></path>
            <path d={getIconPathDataSecond()}></path>
            <path d={getIconPathDataThird()}></path>
        </svg>
    )
}
export default WatchlistIcon;