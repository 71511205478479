import React, { useState, useEffect }   from 'react';
import useAuth from '../hooks/useAuth';
import HelpModal from '../Company/HelpModal'
import ManageRatios from '../ManageRatios/ManageRatios'
import { Popup } from 'semantic-ui-react'


const userRatiosData = [
    { "code": "HR035", "prompt": "FCF/share", "hint": "FCF per share" },
    { "code": "HR049", "prompt": "Avg shares", "hint": "Diluted Shares Outstanding" },
    { "code": "HR031", "prompt": "CFO/NI", "hint": "CFO to Net Income" },
    { "code": "HR025", "prompt": "WC turnover", "hint": "Working Capital Turnover" },
    { "code": "HR034", "prompt": "Sales per share", "hint": "Sales/share" },
    { "code": "HR032", "prompt": "TBV/share", "hint": "Tangible BV per Share" },
    { "code": "HR033", "prompt": "BV/share", "hint": "Book Value per share" },
    { "code": "HR036", "prompt": "Debt", "hint": "Total Debt" },
    { "code": "HR037", "prompt": "Net debt", "hint": "Net Debt" },
    { "code": "HR038", "prompt": "Non-Curr assets", "hint": "Non-Current Assets" },
    { "code": "HR039", "prompt": "Non-Curr liab", "hint": "Non-Current Liabilities" },
    { "code": "HR041", "prompt": "BV", "hint": "Book Value" },
    { "code": "HR042", "prompt": "Assets", "hint": "Total Assets" },
    { "code": "HR043", "prompt": "Goodwill", "hint": "Goodwill" },
    { "code": "HR044", "prompt": "Accu dep", "hint": "Accumulated Depreciation" },
    { "code": "HR045", "prompt": "Receivables", "hint": "Total Receivables" },
    { "code": "HR046", "prompt": "Cash & Inv", "hint": "Cash & Short Term Invest" },
    { "code": "HR047", "prompt": "Net loans", "hint": "Net Loans" },
    { "code": "HR048", "prompt": "Shares", "hint": "Shares Outstanding" },
    { "code": "HR052", "prompt": "FCF", "hint": "Free Cash Flow" },
    { "code": "HR053", "prompt": "CAPEX", "hint": "CAPEX" },
    { "code": "HR054", "prompt": "CFO", "hint": "Cash from Operating Activity" },
    { "code": "HR040", "prompt": "TBV", "hint": "Tangible Book Value" },
    { "code": "HR030", "prompt": "Tax %", "hint": "Tax %" },
    { "code": "HR026", "prompt": "WC days", "hint": "Working Capital Days" },
    { "code": "HR050", "prompt": "Debt/FCF", "hint": "Total Debt to FCF" },
    { "code": "HR051", "prompt": "LTDebt/FCF", "hint": "Long Term Debt to FCF" },
    { "code": "HR055", "prompt": "CFI", "hint": "Cash from Investing Activity" },
    { "code": "HR056", "prompt": "CFF", "hint": "Cash from Financing Activity" },
    { "code": "HR059", "prompt": "Int cov", "hint": "Interest Coverage" }
]

function HistoricalRatios(props) {
    const { auth, setAuth } = useAuth();
    const [show, setShow] = useState(false);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [restrictCount, setRestrictCount] = useState(3);
    const [disableButton, setDisableButton] = useState(true);
    const [viewLoading, setViewLoading] = useState(false)

    

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        userRatiosData.map(data => {
            if (data.code === itemCode) {
                returnData = [data.prompt, data.hint]
            }
        })
        return returnData
    }
    const showManageRatios = () => {
        setShow(true)

    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, historicalRatios: itemsData.historicalRatios }))
        }
        setShow(false)
    }

    useEffect(() => {
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(3)
        }

    }, [auth.historicalRatios])

    return (
        <div style={{ paddingTop: "20px", paddingLeft: "20px" }} >
            <div style={{ fontWeight: "600", fontSize: "16px", textAlign: "left", display: "flex", justifyContent: "space-between" }}>
                <div>Custom Ratios</div>
                <Popup
                    className="popupChart"
                    trigger={
                        <svg
                            onClick={() => showManageRatios()}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                            height="25px"
                            width="25px"
                            style={{cursor:"pointer"}}
                            {...props}
                        >
                            <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                        </svg>
                    }
                    content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                    style={{ zIndex: "999"}}
                />
                <HelpModal title="Manage Your Ratios" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} disableButton={disableButton} source="histRatios">
                    <ManageRatios ratioData={props.ratioData} source="histRatios" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                </HelpModal>
            </div>
            <div style={{ paddingLeft: "40px", paddingTop: "50px" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {auth.historicalRatios.map(item => (

                        <div style={{ padding: "5px 15px", margin: "5px", display: "flex", justifyContent: "flex-start", width: "max-content", backgroundColor: "rgb(248, 248, 252)", borderRadius: "15px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto", maxWidth: "500px" }}>
                                <div style={{ paddingTop: "2px" }}>{getDisplayValueAndPrompt(item)[1]}</div>
                            </div>
                            
                        </div>

                    ))}
                </div>
            </div>
          
        </div>
    );
}
export default HistoricalRatios;