import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "./Loading"
import { Popup } from 'semantic-ui-react'

function PartThree(props) {
    const [pageData, setpageData] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "0"
        }
    }
    const roundData = (data) => {
        if (data) {
            return Math.round(data)
        } else {
            return "-"
        }

    }
    const diaplayIncome = (value) => {
        if (value) {
            return false
        } else {
            return true
        }
    }

    const roundPercentValue = (data) => {
        if (data) {
            let result = data.replace("%", "");
            let value = parseFloat(result).toFixed(2);
          //  var intvalue = Math.round(value*100)/100;
            return value + "%"
        } else {
            return "-"
        }
    }
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/summary-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div className="finColumn" style={{ marginLeft: "20px" }}>
            {Object.keys(pageData).length !== 0 &&
                <>
                <div className="finColumnContainer">
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Profitability (TTM)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Profitability - Trailing 12 Months.</div>}
                            style={{ zIndex: "999" }}
                        />
                        
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Net Margin: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["netMargin"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Net Interest Margin: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["netInterestMargin"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Return on Equity: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["returnOnEquityTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Return on Assets: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["returnOnAssetTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                    </div>
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Growth (CAGR)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Growth - Compounded Annual Growth Rate.</div>}
                            style={{ zIndex: "999" }}
                        />
                        
                        
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Net Income growth 3 yrs: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["netIncomeGrowth3Years"]}</div><div className="DetailsEnd"></div></div></div></div>
                        {diaplayIncome(pageData["netInterestIncomeGrowth3years"]) ?
                            <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Revenue growth 3 yrs: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["revenueGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                            :
                            <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Interest Income growth 3 yrs: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["netInterestIncomeGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                        }  
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Assets Growth 3 Yrs: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["assetGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Equity Growth 3 Yrs: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["bookValueGrowth3Years"]}</div><div className="DetailsEnd"></div></div></div></div>
                    </div>
                </div>
                <br />
                <div className="finColumnContainer">
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Balance Sheet (MRQ)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Balance Sheet - Most Recent Quarter.</div>}
                            style={{ zIndex: "999" }}
                        />
                        
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Total Debt: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["totalDebt"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Net Debt: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["netDebt"]}</div><div className="DetailsEnd"></div></div></div></div>
                    </div>
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Cash Flow (FY)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Cash Flow - Financial Year.</div>}
                            style={{ zIndex: "999" }}
                        />
                        
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">3 Yrs OCF: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["ocfGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">3 Yrs FCF: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["fcfGrowth3Years"]}</div><div className="DetailsEnd"></div></div></div></div>
                    </div>
                </div>
                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default PartThree;