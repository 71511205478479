import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import './Peers.css'
import Loading from "./Loading"
import HelpModal from './HelpModal'
import { Popup } from 'semantic-ui-react'
import ManageRatios from '../ManageRatios/ManageRatios'
import { downloadExcel } from "react-export-table-to-excel";
import useAuth from '../hooks/useAuth';
import UpArrow from "../icon/UpArrow"
import DownArrow from "../icon/DownArrow"
import ExportSVG from '../icon/ExportSVG'


function Peers(props) {
    const [pageData, setpageData] = useState({});
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [show, setShow] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const axiosPrivate = useAxiosPrivate();
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);
    const getUrl = (url) => {
        return "/Company/" + url
    }
    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))
            
        }
        setShow(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const roundData = (data) => {
        if (data) {
            return Math.round(data)
        } else {
            return "-"
        }
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    const showManageRatios = () => {
        setShow(true)
       // if (auth.user) {
       //     setShow(true)
       // } else {
       //     login()
      //  }

    }
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/peers/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data["peers"]
        let sector = cdresponse.data["sector"]
        let industry = cdresponse.data["industry"]
        let companyName = cdresponse.data["stock_name"]
        document.title = companyName + "- Peers | Key Stats Comparison | I Industry and Sector Peers ";
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(10)
        }
        localStorage.setItem(
            "TableData", JSON.stringify({
                "peer": userData,
                "sector": sector,
                "industry": industry,
                "sectorUrl": "/Sector/" + sector,
                "industryUrl": "/Industry/" + industry,
                "CompanyName": companyName,
                "RatioCode": cdresponse.data["keys"]
            }));
        setpageData({
            "peer": userData,
            "sector": sector,
            "industry": industry,
            "sectorUrl": "/Sector/" + sector,
            "industryUrl": "/Industry/" + industry,
            "CompanyName": companyName,
            "RatioCode": cdresponse.data["keys"]
        })
    }


    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('TableData'))
        let tempData = itemsData["peer"]
        if (finalSortData["pointer"]) {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }
        let finalData = itemsData
        finalData["peer"] = tempData
        setpageData(finalData)
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.RatioCode.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData?.peer.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.RatioCode.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Peer",
            sheet: "Peer",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, isProfileUpdated, auth.user])




    return (
        <div className="PeersMain">
            <section className="optionsFinData">
                <div className="leftAlign growthCompanyName">
                    {pageData.CompanyName}
                </div>
                <div className="rightAlign" style={{ paddingTop: "10px" }}>
                    <Popup
                        className="popupChart"
                        trigger={
                            <svg
                                onClick={() => showManageRatios()}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 256"
                                height="25px"
                                width="25px"
                                style={{cursor:"pointer"}}
                                {...props}
                            >
                                <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                            </svg>
                        }
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                        style={{ zIndex: "999" }}
                    />
                    <HelpModal title="Manage Columns" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                        <ManageRatios ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton}/>
                    </HelpModal>


                    <Popup
                        className="popupChart"
                        trigger={
                            <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer", paddingTop: "5px" }}>
                                <ExportSVG />
                            </div>
                        }
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Export to Excel</div>}
                        style={{ zIndex: "999" }}
                    />
                </div>
            </section>
                <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>
                    <div className="PeersOption">
                        <div className="peerSection">
                            <div className="peerHeader">
                                Sector:
                            </div>
                            <div className="peerContent" style={{ paddingLeft: "5px" }}>
                                <a href={pageData.sectorUrl} target="_blank" style={{ textDecoration: "None" }}> {pageData.sector}</a>
                            </div>
                        </div>
                        <div className="peerSection">
                            <div className="peerHeader">
                                Industry:
                            </div>
                            <div className="peerContent" style={{ paddingLeft: "5px" }}>
                                <a href={pageData.industryUrl} target="_blank" style={{ textDecoration: "None" }}>{pageData.industry}</a>
                            </div>
                        </div>
                    </div>
                    {pageData.RatioCode &&
                        <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto", backgroundColor: "white" }}>
                        <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                            <thead>
                                <tr>
                                    <th className="leftPeer intersection" style={{ fontWeight: 600, textAlign: "center"  }}>S.&nbsp;No.</th>
                                    <th className="leftPeer intersection" style={{ fontWeight: 600 }}>Company Name</th>

                                    {pageData.RatioCode.map(item => (
                                        <th className="columnHeadingScreener">
                                            <Popup
                                                className="popupChart"
                                                trigger={<div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted(item)}><div>{getDisplayValueAndPrompt(item)[0]}</div><div style={{ paddingTop: "5px" }}>{SortIndicator(item)[0] ? <div>{SortIndicator(item)[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div>}
                                                content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                style={{ zIndex: "999" }}
                                            />
                                        </th>
                                    ))

                                    }

                                </tr>
                            </thead>
                            <tbody>
                                {pageData.peer.map((dataKey, index) => (
                                    <tr>
                                        <td className="leftPeer" style={{ textAlign: "center" }}>{index + 1}</td>
                                        <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                        {pageData.RatioCode.map(item => (
                                            <td className="tabledataPeer SubHeader">{dataKey[item]}</td>
                                        ))
                                        }



                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    }
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
                </section>
        </div>
    );
}

export default Peers;