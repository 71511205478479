import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"


function ScorePeerMobile(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();


    const companyDetails = async () => {
        setpageData({})
        let url = ""
        console.log(encodeURIComponent(props.filter))
        url = "https://api.niveshkarma.com/api/v1/3m-score-peers/" + props.compSymbol + "?filter_by=gsector&filter_value=" + encodeURIComponent(props.filter) + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        console.log(cdresponse.data)
        setpageData(cdresponse.data)
    }

    const getUrl = (url) => {
        return "/Company/" + url
    }

    function getColorBackGround(score) {
        let result = score.substring(0, 1);
        console.log(result)
        if (parseFloat(result) === 1) {
            return "#009900"
        } else if (parseFloat(result) === 2) {
            return "#669900"
        } else if (parseFloat(result) === 3) {
            return "#fac904"
        } else if (parseFloat(result) === 4) {
            return "#ff6666"
        } else if (parseFloat(result) === 5) {
            return "#ff0000"
        }
    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, props.filter])


    return (
        <div className="ScoreMainPeer" style={{ paddingRight: "10px" }}>
            {pageData.length > 0 &&
                <>
                <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Peers</div>
                <section className="ScoreSubsectionPeer">
                    <div className="ScoreRow">
                        <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                            <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                <thead>
                                    <tr style={{ backgroundColor: "white" }}>
                                        <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px", left: "0", zIndex: "10" }}>Company Name</th>
                                        <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px" }}>Sector</th>
                                        <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px" }}>Industry</th>
                                        <th style={{ backgroundColor: "white" }}>Weighted Score</th>
                                        <th style={{ backgroundColor: "white" }}>Relative Rank</th>
                                        <th style={{ backgroundColor: "white" }}>Predictability</th>
                                        <th style={{ backgroundColor: "white" }}>Moat</th>
                                        <th style={{ backgroundColor: "white" }}>Management</th>
                                        <th style={{ backgroundColor: "white" }}>Margin of Saftey</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pageData.map(item => (
                                            <tr>
                                                <td style={{ textAlign: "left", paddingLeft: "10px", position: "sticky", left: "0", backgroundColor: "white", minWidth: "100px" }}><a href={getUrl(item.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{item.stock_name}</a></td>
                                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>{item.sector}</td>
                                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>{item.industry}</td>
                                                <td>{item.weighted_score}</td>
                                                <td style={{ color: getColorBackGround(item.relative_rank) }}>{item.relative_rank}</td>
                                                <td>{item.predictability}</td>
                                                <td>{item.moat}</td>
                                                <td>{item.management}</td>
                                                <td>{item.margin_of_safety}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                </>
            }
        </div>
    );
}

export default ScorePeerMobile;