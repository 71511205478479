import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import ExportSVG from '../icon/ExportSVG'
import Loading from "./Loading"
import { downloadExcel } from "react-export-table-to-excel";
import UpArrow from "../icon/UpArrow"
import DownArrow from "../icon/DownArrow"

function PredefinedScreener(props) {
    const { id } = useParams();
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const getUrl = (url) => {
        return "/Company/" + url
    }
    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    

    const getClassName = (data) => {
        if (data === "Sector") {
            return "Sector columnHeadingScreenerLeft"
        } else if (data === "Industry") {
            return "Industry columnHeadingScreenerLeft"
        } else {
            return "columnHeadingScreener"
        }
    }

    const getSubClassName = (data) => {
        if (data === "Sector") {
            return "Sector SubHeader"
        } else if (data === "Industry") {
            return "Industry SubHeader"
        } else {
            return "tabledataPeer SubHeader"
        }
    }

    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('TableData'))
        let tempData = itemsData["data"]["stocks"]
        if (finalSortData["pointer"]) {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }
        let finalData = itemsData
        finalData["data"]["stocks"] = tempData
        setpageData(finalData)
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    } 

    const companyDetails = async () => {
        let url = ""
        console.log(props.screener)
        url = "https://api.niveshkarma.com/api/v1/default-screener/" + id + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data
        document.title =  "NK - Screeners | Explore stock screener | Best Stock Screener in India | Popular Investing themes";
        console.log(userData)
        localStorage.setItem(
            "TableData", JSON.stringify({
                "screener": id,
                "data": userData
            }));
        setpageData({
            "screener": id,
            "data": userData
        })
    }

    const getCompanyCountMessage = () => {
        try {
            let count = pageData.data.stocks.length
            if (count > 0) {
                return count.toString() + " companies found."
            } else {
                return "No companies found. Please adjust your filters"
            }
        } catch (e) {
            return "No companies found. Please adjust your filters"
        }
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.data.keys.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData.data.stocks.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.data.keys.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: id,
            sheet: id,
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }

    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        setTimeout(function () { companyDetails(); }, 50);

    }, [props.screener])




    return (
        <div className="PeersMain" style={{ paddingTop: "40PX" }}>
            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ paddingTop: "10px", fontWeight: "600", display: "flex", justifyContent: "start" }}>
                            <div style={{ fontSize: "20px", fontWeight: "600", textAlign: "left", paddingBottom: "10px" }}>{pageData.data.screener_name + ":"}</div>
                            <div style={{ paddingTop: "4px", paddingLeft: "10px", fontWeight: "400" }}>{getCompanyCountMessage()}</div>
                        </div>
                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer", paddingTop: "5px" }}>
                                    <ExportSVG />
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Export to Excel</div>}
                            style={{ zIndex: "999" }}
                        />
                    </div>
                    <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                        <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                <thead>
                                <tr>
                                    <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ fontWeight: "600", fontSize: "14px", textAlign: "center" }}>S.&nbsp;No.</th>
                                    <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ fontWeight: "600", fontSize: "14px" }}>Company Name</th>

                                        {pageData?.data?.keys.map(item => (
                                            <th className="columnHeadingScreener" className={getClassName(getDisplayValueAndPrompt(item)[0])}>
                                                <Popup
                                                    className="popupChart"
                                                    trigger={<div style={["Sector", "Industry"].includes(item) ? { cursor: "pointer", color: "blue", fontWeight: "600", display: "flex", justifyContent: "start", paddingTop: "5px" } : { cursor: "pointer", color: "blue", fontWeight: "600", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted(item)}><div>{getDisplayValueAndPrompt(item)[0]}</div><div style={{ paddingTop: "5px" }}>{SortIndicator(item)[0] ? <div>{SortIndicator(item)[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div>}
                                                    content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                    style={{ zIndex: "999" }}
                                                />
                                            </th>
                                        ))

                                        }

                                    </tr>
                            </thead>
                                <tbody>
                                    {pageData?.data?.stocks.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                            {pageData?.data?.keys.map(item => (
                                                <td className={getSubClassName(getDisplayValueAndPrompt(item)[0])}>{dataKey[item]}</td>
                                            ))
                                            }



                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>
        </div>
    );
}


export default PredefinedScreener;