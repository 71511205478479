import React, { useEffect, useState} from 'react';
import WatchlistSection from './WatchlistSection'
import HomeContents from '../Home/HomeContents'

function PersonalizedHome(props) {
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const [watchlistPresent, setWatchlistPresent] = useState(true);



    useEffect(() => {
        props.setShowSearch(true)
    }, [isProfileUpdated])
    return (
        <>
            {watchlistPresent &&
                <div className="Home" style={{ paddingTop: "20px" }}>
                    <WatchlistSection
                        ratioData={props.ratioData}
                        optionData={props.optionData}
                        toggleProfile={toggleProfile}
                        isProfileUpdated={isProfileUpdated}
                        showSearch={props.showSearch}
                        searchHistory={props.searchHistory}
                        setSearchHistory={props.setSearchHistory}
                        setShowSearch={props.setShowSearch}
                        setWatchlistPresent={setWatchlistPresent}
                    />
                </div>
            }
            {!watchlistPresent &&
                <HomeContents
                    optionData={props.optionData}
                    setShowSearch={props.setShowSearch}
                    showSearch={props.showSearch}
                    searchHistory={props.searchHistory}
                    setSearchHistory={props.setSearchHistory}
                />
            }
        </>
    );
}
export default PersonalizedHome;