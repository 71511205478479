import React, { useMemo, useState  } from "react";
import * as d3 from "d3";
import "./TreeMap.css";
import Tooltip from './TreemapToolTip'


const colors = [
    "#C41E3A",
    "#097969"
];

export const Treemap = ({ width, height, data }) => {

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false)
    const [filterName, setFilterName] = useState({})
    const [filterData, setFilterData] = useState([])

    const handleMouseMove = (event) => {
        console.log(event.clientY)
        setCursorPosition({
            x: event.clientX,
            y: event.clientY,
        });
        setShow(true)
    };

    const handloeMouseOut = () => {
        setShow(false)
    }

    const divStyle = {
        position: "absolute",
        left: cursorPosition.x + 20,
        top: cursorPosition.y + 10,
        width: 600,
        backgroundColor: "#36454F",
        color: "white",
        border: "3px solid #848884",
        borderRadius: "5px",
        padding: "5px 0px",
        textAlign: "left"
    };

    const divStyleTwo = {
        position: "absolute",
        left: cursorPosition.x - 620,
        top: cursorPosition.y + 10,
        width: 600,
        backgroundColor: "#36454F",
        color: "white",
        border: "3px solid #848884",
        borderRadius: "5px",
        padding: "5px 0px",
        textAlign: "left"
    };

    const divStyleThree = {
        position: "absolute",
        left: cursorPosition.x + 20,
        top: cursorPosition.y - 300,
        width: 600,
        backgroundColor: "#36454F",
        color: "white",
        border: "3px solid #848884",
        borderRadius: "5px",
        padding: "5px 0px",
        textAlign: "left"
    };

    const divStyleFour = {
        position: "absolute",
        left: cursorPosition.x - 620,
        top: cursorPosition.y - 300,
        width: 600,
        backgroundColor: "#36454F",
        color: "white",
        border: "3px solid #848884",
        borderRadius: "5px",
        padding: "5px 0px",
        textAlign: "left"
    };

    const openUrlInNewTab = (stock) => {
        let url = "/Company/" + stock
        window.open(url, '_blank');
    };


    const hierarchy = useMemo(() => {
        return d3.hierarchy(data).sum((d) => d.value);
    }, [data]);

    // List of item of level 1 (just under root) & related color scale
  //  const firstLevelGroups = hierarchy?.children?.map((child) => child.data.name);
    var colorScale = d3
        .scaleOrdinal()
        .domain([1000, 10000, 100000, 1000000, 10000000])
        .range(["#C41E3A", "#FAA0A0", "#C9CC3F", "#32CD32", "#355E3B"]);

    const root = useMemo(() => {
        const treeGenerator = d3.treemap().size([width, height]).padding(1);
        return treeGenerator(hierarchy);
    }, [hierarchy, width, height]);

    function returnClassName(name) {
        return name.replaceAll(" ", "_").replaceAll(",", "_").replaceAll("&", "_");
    }

    const updateTooltip = (data, subData, leaf) => {
        const container = document.querySelectorAll("." + returnClassName(data));
        const containerTwo = document.querySelectorAll(".inintialRect");
        if (containerTwo !== undefined) {
            for (var i = 0; i < containerTwo.length; i++) {
                containerTwo[i].setAttribute("style", "filter: saturate(60%); opacity: .4;");
            }
        }
        if (container !== undefined) {
            for (var i = 0; i < container.length; i++) {
                container[i].setAttribute("style", "filter: saturate(100%); opacity: 1;");
            }   
        }
        let tempData = []
        root.leaves().filter((item) => item.parent?.data.name === data).map((item, i) => {
            if (i <= 5) {
                tempData.push(item.data)
            }
        })
        console.log(tempData)
        setFilterData(tempData)
        setFilterName({ data, subData, leaf})
    }

    const hideTooltip = () => {
        const container = document.querySelectorAll(".inintialRect");
        if (container !== undefined) {
            for (var i = 0; i < container.length; i++) {
                container[i].setAttribute("style", "filter: saturate(100%); opacity: 1;");
            }
        }

    }

    function getColor(data) {
        if (data < 1000) {
            return "#C41E3A"
        } else if (data < 1000) {
            return "#FAA0A0"
        } else if (data < 10000) {
            return "#C9CC3F"
        } else if (data < 100000) {
            return "#32CD32"
        } else {
            return "#355E3B"
        }
    }


    const allShapes = root.leaves().map((leaf, i) => {
        const parentName = leaf.parent?.data.name;
        const subParentName = leaf.parent?.parent?.data.name;
        let min = d3.min(root.leaves().map(leaf => leaf.data.change))
        let max = d3.max(root.leaves().map(leaf => leaf.data.change))
        let companyCode = leaf.data.code
        return (
            <g key={leaf.id} className={"inintialRect " + returnClassName(parentName)} onMouseOver={() => updateTooltip(parentName, subParentName, leaf)} onMouseOut={() => hideTooltip()} onClick={()=>openUrlInNewTab(companyCode)}>
  
                <rect
                    x={leaf.x0}
                    y={leaf.y0}
                    width={leaf.x1 - leaf.x0}
                    height={leaf.y1 - leaf.y0}
                    stroke="transparent"
                   // fill={colorScale(parentName)}
                    fill={getColor(leaf.data.change)}
                  //  fillOpacity={(leaf.data.change - min) / (max - min)}
                    className={"opacity-80 hover:opacity-100"}
                />

                <text
                    x={leaf.x0 + 3}
                    y={leaf.y0 + 3}
                    fontSize={12}
                    textAnchor="start"
                    alignmentBaseline="hanging"
                    fill="white"
                    className="font-bold"
                >
                    {leaf.data.code}
                </text>
                <text
                    x={leaf.x0 + 3}
                    y={leaf.y0 + 18}
                    fontSize={12}
                    textAnchor="start"
                    alignmentBaseline="hanging"
                    fill="white"
                    className="font-light"
                >
                    {leaf.data.change}
                </text>
                
                
                
            </g>
            
        );
    });

    return (
        <div style={{ cursor: "pointer", border: "5px solid #848884" }} onMouseOver={handleMouseMove} onMouseOut={handloeMouseOut} >
            <svg width={width} height={height} className="container">
                {allShapes}
            </svg>
            {show && 
                <>
                {cursorPosition.y <= 300 &&
                    <div className="Tooltip" style={cursorPosition.x <= 800 ? divStyle : divStyleTwo}>
                        <Tooltip data={filterData} parentName={filterName} />
                    </div>
                }
                {
                    cursorPosition.y > 300 &&
                    <div className="Tooltip" style={cursorPosition.x <= 800 ? divStyleThree : divStyleFour}>
                        <Tooltip data={filterData} parentName={filterName} />
                    </div>
                }
                </>
            }
        </div>
    );
};
