import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "../../Company/Summary/Loading"
import ExternalLink from '../../icon/ExternalLink'

export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};
function prepareData(data) {
    if (data) {
        return data
    } else {
        return "-"
    }

}

function DividendSummaryMobile(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const getURL = (code, source) => {
        if (source === "a") {
            return "https://www.bseindia.com/stock-share-price/equity/scripcode/" + code + "/corp-announcements"
        } else {
            return "https://www.bseindia.com/stock-share-price/equity/scripcode/" + code + "/corp-actions"
        }
    }


    const companyDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/corporate-action/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])
    return (
        <div className="DividendRowHighlights">
            <div className="DividendRowHighlightsSub">
                {Object.keys(pageData).length !== 0 &&
                   

                    <div style={{ padding: "20px" }}>
                        <div className="OddDivS"><div style={{ display: "flex", justifyContent: "end" }} ><div style={{ fontSize: "13px" }}><ExternalLink /> </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}><a href={getURL(pageData.bse_code, "a")} target="_blank" style={{ textDecoration: "none" }}>Announcements</a></div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div style={{ display: "flex", justifyContent: "end" }}><div style={{ fontSize: "13px" }}><ExternalLink /></div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}><a href={getURL(pageData.bse_code, "c")} target="_blank" style={{ textDecoration: "none" }}>Corporate Action</a></div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Dividend Yield </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{prepareData(pageData["Dividend Yield"])}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Payout Ratio </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{prepareData(pageData["Payout Ratio"])}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Average Payout - 5 Years </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{prepareData(pageData["Average Payout - 5 years"])}</div><div className="DetailsEnd"></div></div></div></div>
                </div>


                }
            </div>
            {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );



}
export default DividendSummaryMobile;