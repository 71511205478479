import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "./hooks/useAxiosPrivate"



function InvestmentHistory(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/ownership-history/" + props.investorID + "/"+ props.stock+ "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div style={{ overflowY: "auto" }}>
            {Object.keys(pageData).length !== 0 &&
                <>
                <div className="AboutHeaderTop">
                    <div style={{ fontWeight: '600', fontSize: "18px" }}>
                        {pageData.investor_name}
                    </div>
                </div>
                <div className="AboutHeaderTop">

                        <div style={{ fontWeight: '600' }}>
                        Holding activity History for {props.stock}
                        </div>
                </div>
                <table className="PeersTable finData">
                    <tbody>
                        <tr>
                            <td className="leftPeer" style={{ fontWeight: 600 }}>Sr.No.</td>
                            <td className="columnHeading">Filing Date</td>
                            <td className="columnHeading">Shares</td>
                            <td className="columnHeading">% of Portfolio</td>
                            <td className="columnHeading">Change</td>
                            <td className="columnHeading">Reported Price</td>
                        </tr>
                    </tbody>
                    <tbody>
                        {pageData.history.map((dataKey, index) => (
                            <tr>
                                <td className="leftPeer">{index + 1}</td>
                                <td className="tabledata SubHeader">{dataKey.filingDate}</td>
                                <td className="tabledata SubHeader">{dataKey.share}</td>
                                <td className="tabledata SubHeader">{dataKey.pctOfProfile}</td>
                                <td className="tabledata SubHeader">{dataKey.change}</td>
                                <td className="tabledata SubHeader">-</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                </>
            } 
        </div>
    );
}

export default InvestmentHistory;