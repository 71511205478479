import React, { useState, useEffect } from 'react';
import './CompanyMain.css'
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import WatchlistOption from './WatchlistOption'
import ExternalLink from '../icon/ExternalLink'



function CompanyMain(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    const priceChange = (data) => {
        if (data) {
            let position = data.search("-");
            if (position === -1) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }
    const displayPriceChange = (data) => {
        if (data) {
            let position = data.search("-");
            if (position === -1) {
                return "+" + data
            } else {
                return data
            }
        } else {
            return ""
        }
    }
    function getLastRefreshedDate(vdate) {
        let date = new Date(vdate);
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        let hr = date.getHours();
        let mn = date.getMinutes();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month === 1) {
            month = 'Jan'
        }
        if (month === 2) {
            month = 'Feb'
        }
        if (month === 3) {
            month = 'Mar'
        }
        if (month === 4) {
            month = 'Apr'
        }
        if (month === 5) {
            month = 'May'
        }
        if (month === 6) {
            month = 'Jun'
        }
        if (month === 7) {
            month = 'Jul'
        }
        if (month === 8) {
            month = 'Aug'
        }
        if (month === 9) {
            month = 'Sep'
        }
        if (month === 10) {
            month = 'Oct'
        }
        if (month === 11) {
            month = 'Nov'
        }
        if (month === 12) {
            month = 'Dec'
        }
        return dt + '-' + month + " " + hr + ":" + mn + " IST"
    }

    const companyDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/profile/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        document.title = cdresponse.data.name + "- Summary | Company Profile, stock summary, Stock Price & Manage your ratios | Build your own ratios. ";
        setpageData({
            "StockName": cdresponse.data.name,
            "LastRefreshed": cdresponse.data.quote_date,
            "ClosingPrice": cdresponse.data.close_price,
            "PriceChange": cdresponse.data.diff_prev_day,
            "BseSymbol": cdresponse.data.bse_code,
            "NseSymbol": cdresponse.data.nse_code,
            "NseUrl": "https://www.nseindia.com/get-quotes/equity?symbol=" + cdresponse.data.nse_code,
            "BseUrl": "https://www.bseindia.com/stock-share-price/equity/scripcode/" + cdresponse.data.bse_code,
            "AnnualReportLink": "https://www.bseindia.com/stock-share-price/equity/scripcode/" + cdresponse.data.bse_code +"/financials-annual-reports",
            "CompanyLink": cdresponse.data.weburl
        })
    }

    return (
        <div className="CompanyMain">
            {Object.keys(pageData).length !== 0 &&
                <>
            <div id="CompanyMainLeft">
                <div id="compInfo">
                    <div id="CompanyMainCompanyName">
                        {pageData.StockName}
                    </div>
                    <div id="CompanyMainCompanyEodDetails">
                        <div id="CompanyMainCompanyEodPrice">
                                &#8377; {pageData.ClosingPrice}
                        </div>
                        <div id="CompanyMainCompanyEodChange"
                            style={priceChange(pageData.PriceChange) ? { color: '#FF6666' } : { color: '#0BB747'  }}
                        >
                                {displayPriceChange(pageData.PriceChange)}
                        </div>
                        <div id="CompanyMainCompanyEodDate">
                                {getLastRefreshedDate(pageData.LastRefreshed)}
                        </div>
                    </div>
                </div>
                    <div id="exchangeInfo">
                        <Popup
                            className="popupChart"
                            trigger={<div style={{ display: "flex" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a href={pageData.CompanyLink} target="_blank">COMPANY URL</a></div></div>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white", maxWidth: "500px" }}>You can visit company website and view "Quarterly Results", "Annual reports", "Shareholder's Presentations", "Call Transcripts" and "Credit Reports" under Investor Relations.</div>}
                            style={{ zIndex: "999" }}
                        />

                        <div style={{ display: "flex" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a  href={pageData.BseUrl} target="_blank">BSE: {pageData.BseSymbol}</a></div></div>
                        <div style={{ display: "flex" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a  href={pageData.NseUrl} target="_blank">NSE: {pageData.NseSymbol}</a></div></div>
                        <div style={{ display: "flex" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a  href={pageData.AnnualReportLink} target="_blank">ANNUAL REPORTS</a></div></div>
                </div>
                </div>
                <div id="CompanyMainRight" style={{ marginRight: "10px" }}>
                    <WatchlistOption symbol={props.compSymbol}/>
                </div>
                </>
            }
            
        </div>
    );
}

export default CompanyMain;