import React, { useState, useEffect } from 'react';
import './CorporateActions.css'
import Dividend from './CorporateActions/Dividend'
import EquityHistory from './CorporateActions/EquityHistory'
import Esop from './CorporateActions/Esop'
import Bonus from './CorporateActions/Bonus'
import Split from './CorporateActions/Split'
export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};


function CorporateActions(props) {

    const [optionSelected, setValue] = useState('dividend');
    useEffect(() => {
    }, [props.compSymbol])
    return (
        <div className="CorporateActionsMain">
            {/*<section className="CorporateActionsNav">
                <div className="CANav">
                    <div className="FinancialMenu" id="dividend" onClick={() => {
                        setValue('dividend')
                    }
                    } style={optionSelected === "dividend" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }} >Dividend</div>
                    <div className="FinancialMenu" id="equityHistory" onClick={() => {
                        setValue('equityHistory')
                    }
                    } style={optionSelected === "equityHistory" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }}  >Equity History</div>
                    <div className="FinancialMenu" id="esop" onClick={() => {
                        setValue('esop')
                    }
                    } style={optionSelected === "esop" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }}  >ESOP's</div>
                    <div className="FinancialMenu" id="bonus" onClick={() => {
                        setValue('bonus')
                    }
                    } style={optionSelected === "bonus" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }}  >Bonus's</div>
                    <div className="FinancialMenu" id="split" onClick={() => {
                        setValue('split')
                    }
                    } style={optionSelected === "split" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }}  >Split's</div>
                </div>
            </section>*/}
            <section className="CorporateActions">
                {optionSelected === "dividend" && 
                    <Dividend compSymbol={props.compSymbol} />
                }
                {/*optionSelected === "equityHistory" &&
                    <div className="equityHistory">
                    <EquityHistory compSymbol={props.compSymbol}/>
                    </div>
                */}
                {/*optionSelected === "esop" &&
                    <div className="esop">
                    <Esop compSymbol={props.compSymbol} />
                    </div>
                */}
                {/*optionSelected === "bonus" &&
                    <div className="bonus">
                    <Bonus compSymbol={props.compSymbol} />
                    </div>
                */}
                {/*optionSelected === "split" &&
                    <div className="split">
                    <Split compSymbol={props.compSymbol} />
                    </div>
                */}
            </section>
            
        </div>
    );
}

export default CorporateActions;