import React, { useEffect }  from "react";
import AsyncSelect from 'react-select/async';



function CompareSearchbox(props) {
    console.log(props.companyOptions)
    const filterCompany = (inputValue) => {
        return props.companyOptions.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterCompany(inputValue));
            }, 1000);
        });

    useEffect(() => {
    }, [props.compSymbol])

    return (
        <div className="Searchbox" style={{ fontSize: "14px" }}>
                <AsyncSelect id="CompareSearchBox" cacheOptions defaultOptions loadOptions={promiseOptions} onChange={(value) => props.updateCompareData(value)} value={{ label: 'Search for a Company', value: 1 }} />
        </div>
    );
}

export default CompareSearchbox