import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from "./hooks/useAxiosPrivate"
import WatchlistCompanies from './WatchlistCompanies'
import useAuth from './hooks/useAuth';
import ManageRatios from './ManageRatios/ManageRatios'
import HelpModal from './Company/HelpModal'
import Locked from './Locked'
import Loading from './Company/Valuations/Loading';
import AddWatchlist from './AddWatchlist'
import { Popup } from 'semantic-ui-react'


function Watchlist(props) {
    const { watchlistName } = useParams();
    const [pageData, setpageData] = useState([]);
    const [watchlistData, setWatchlistData] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false)
    const [wathlistUpdated, setWatchlistUpdated] = useState(true)
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [show, setShow] = useState(false);
    const [userInput, setUserInput] = useState("")
    const [watchlistLimit, setWatchlistLimit] = useState(2)


    const getDefaultSelection = () => {
        if (watchlistName !== undefined) {
            return watchlistName
        } else {
            return pageData[0]
        }
    }

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShow(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const showManageRatios = () => {
        setShow(true)
    }
    const getUrl = (url) => {
        return "/Company/" + url
    }
    const [userSelection, setUserSelection] = useState(getDefaultSelection());
    const axiosPrivate = useAxiosPrivate();
    document.title = "Nivesh Karma - Watchlist | Create & view multiple stock Watchlists. ";
    console.log(userSelection)
    
    const toggleWatchlistUpdate = () => {
        if (wathlistUpdated) {
            setWatchlistUpdated(false)
        } else {
            setWatchlistUpdated(true)
        }
    }

    const createWatchlist = async () => {
        if (userInput) {
            let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            let tempData = []
            if (watchlistData.length !== 0) {
                tempData = watchlistData
                tempData.push({ "list_name": userInput, watch_list: [] })
            } else {
                tempData.push({ "list_name": userInput, watch_list: [] })
            }
            const response = await axiosPrivate.post(url, tempData);
            setUserInput("")
            runQuery()
        }
    }

    

    const handleDelete = async () => {
        setpageData([])
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const response = await axiosPrivate.delete(url, { data: { list_name: userSelection } });
        toggleWatchlistUpdate()

    }

    

    const runQuery = async () => {
        setpageData([])
        setPageLoaded(false)
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const response = await axiosPrivate.get(url);
        let tempData = []
        if (response.data.watch_lists != null) {
            for (let i = 0; i < response.data.watch_lists.length; i++) {
                tempData.push(response.data.watch_lists[i]["list_name"])
            }
            if (watchlistName !== undefined) {
                setUserSelection(watchlistName)
            } else {
                setUserSelection(tempData[0])
            }
            if (auth.roles === true) {
                setRestrictCount(30)
            } else {
                setRestrictCount(5)
            }
            setWatchlistData(response.data.watch_lists)
        }
        setPageLoaded(true)
        setpageData(tempData)
    }

    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        if (auth.user) {
            const timeout = setTimeout(() => {
                runQuery()
                setWatchlistLimit(20)
            }, 50)
            return () => clearTimeout(timeout)
        } 
    }, [wathlistUpdated, isProfileUpdated, auth.user])

    return (
        <div style={{ paddingTop: "10px" }}>
            {auth.user &&
                <>
                    <section className="optionsFinData" style={{ paddingTop: "20px", paddingRight: "50px" }}>
                    <div className="leftAlign growthCompanyName" >
                        &nbsp;
                        </div>
                    <div className="rightAlign">
                        <Popup
                            className="popupChart"
                            trigger={
                                <svg
                                    onClick={() => showManageRatios()}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 256 256"
                                    height="25px"
                                    width="25px"
                                    {...props}
                                >
                                    <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                                </svg>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                            style={{ zIndex: "999" }}
                        />
                            <HelpModal title="Manage Columns" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                                <ManageRatios ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                            </HelpModal>

                    </div>
                </section>
                {pageData.length > 0 &&
                    <div style={{ display: "flex", columnGap: "50px" }}>
                        <div style={{ display: "flex", columnGap: "5px", paddingTop: "20px", paddingLeft: "50px" }}>
                            <div style={{ paddingTop: "5px", fontWeight: "600" }}>Watchlist:</div>
                            <div style={{ display: "flex" }}>
                                <div>
                                    <select
                                        className="ViewSelected"
                                        value={userSelection}
                                        onChange={e => setUserSelection(e.target.value)}
                                    >
                                        {pageData.map(item => (
                                            <option value={item}>{item}</option>
                                        ))
                                        }
                                    </select>
                                </div>
                                <div style={{ marginLeft: "-30px", fontSize: "30px", paddingTop: "1px" }}>
                                    <svg
                                        width="20px"
                                        height="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 300 300"
                                        shapeRendering="geometricPrecision"
                                        textRendering="geometricPrecision"
                                        fill="#555"
                                        {...props}
                                    >
                                        <path
                                            d="M67.79 65.49l170.343-.697L150 176.963 67.79 65.49z"
                                            transform="matrix(1 0 0 .68815 -8 16.35)"
                                            stroke="#555"
                                            strokeWidth={0.6}
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {console.log(watchlistLimit)}
                        {watchlistLimit > pageData.length &&
                            <div style={{ padding: "10px 20px 10px 10px", borderBottom: "1px solid #F5F5F5", display: "flex" }}>
                                <div style={{ paddingRight: "10px", paddingTop: "10px" }}>
                                    <input style={{ height: "27px" }} className="WatchlistInput" type="text" placeholder="Create watchlist" value={userInput} onChange={(e) => setUserInput(e.target.value)} />
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <button style={{ padding: "4px 20px" }} onClick={() => createWatchlist()}>Create</button>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="PeersMain">
                    <div className="Watchlist" style={{ backgroundColor: "white", borderRadius: "10px" }}>
                    {pageData.length !== 0 &&
                        <section className="Peers">
                            <WatchlistCompanies optionData={props.optionData} watchlistName={userSelection} ratioData={props.ratioData} watchlistData={watchlistData}/>
                            {pageData.length !== 0 && pageLoaded &&
                                <div style={{ paddingTop: "50px", marginBottom: "50px", float: "right" }}>
                                    <div className="optionButton" style={{ width: "120px", backgroundColor: "#ee4b2b", borderColor: "#ee4b2b" }} onClick={() => handleDelete()}>
                                        Delete&nbsp;Watchlist
                            </div>
                                </div>
                            }
                        </section>
                    }
                        {pageData.length === 0 && pageLoaded &&
                            <div style={{padding: "100px"}}>
                                <div>
                                There are no active watchlists created for your account. Please create a watchlist.
                                </div>
                            <div style={{ marginLeft: "auto", marginRight: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <AddWatchlist toggleProfile={toggleProfile} />
                            </div>
                            </div>
                    }

                        
                        <div>
                            {!pageLoaded &&
                                <Loading/>

                            }
                        </div>
                       
                </div>
                </div>
                
                </>
            }
            {!auth.user &&
                <Locked />
            }
        </div>
    );
}
export default Watchlist;