import React, { useState, useEffect  } from 'react';
import './HomeContent.css'
import Gear from "../icon/Gear"
import Searchbox from '../Searchbox';
import Tutorial from './Tutorial'
import ExternalLink from '../icon/ExternalLink'
import ExportSVG from '../icon/ExportSVG'
import KnowMore from '../icon/KnowMore'
import Asc from '../icon/Asc'
import Desc from '../icon/Desc'
import Chart from "react-apexcharts";
import AsyncSelect from 'react-select/async';

function HomeContents(props) {
    const [isHovering, setIsHovering] = useState("");
    const [showMobileOption, setShowMobileOption] = useState(false);
    const handleMouseOver = (ind) => {
        setIsHovering(ind);
    };

    const mapData = {
        "options": {
            "chart": { "id": "dcf-chart" },
            "colors": ["#9cb2cf", "#C9D8E9"],
            "dataLabels": {
                "enabled": false,
                
            },
            "xaxis": {
                        "categories": [
                            "2024",
                            "2025",
                            "2026",
                            "2027",
                            "2028",
                            "2029",
                            "2030",
                            "2031",
                            "2032",
                            "2033",
                            "2034",
                            "2035"
                ],
                "labels": {
                    "style": {
                        "fontSize": "8px"
                    }
                }
                    },
            "yaxis": [{"forceNiceScale": true}]
        },
        "series": [
            {
                "name": "DCF Projection",
                "data": [
                    14462,
                    17933,
                    22237,
                    27574,
                    34191,
                    42397,
                    50029,
                    59034,
                    69660,
                    82199,
                    96994,
                    100389
                ]
            }
        ]
    }

    const commodity = {
        "options": {
            "chart": {
                "id": "commodity-chart"
            },
            "dataLabels": {
                "enabled": false
            },
            "colors": [
                "#0070f3",
                "#2E93fA",
                "#66DA26",
                "#546E7A",
                "#E91E63",
                "#FF9800"
            ],
            "xaxis": {
                "type": "datetime",
                "labels": {
                    "datetimeFormatter": {
                        "year": "yyyy",
                        "month": "MMM 'yy",
                        "day": "dd MMM",
                        "hour": "HH:mm"
                    }
                }
            },
            "yaxis": {
                "forceNiceScale": true,
                "min": 104.7,
                "max": 155.4
            },
            "stroke": {
                "width": 1.5
            }
        },
        "series": [
            {
                "name": "Commodity Price",
                "data": [
                    [
                        "2012-04-24T18:30:00.000Z",
                        104.7
                    ],
                    [
                        "2012-05-24T18:30:00.000Z",
                        105.3
                    ],
                    [
                        "2012-06-24T18:30:00.000Z",
                        105.3
                    ],
                    [
                        "2012-07-24T18:30:00.000Z",
                        106.2
                    ],
                    [
                        "2012-08-24T18:30:00.000Z",
                        106.9
                    ],
                    [
                        "2012-09-24T18:30:00.000Z",
                        107.6
                    ],
                    [
                        "2012-10-24T18:30:00.000Z",
                        107.4
                    ],
                    [
                        "2012-11-24T18:30:00.000Z",
                        107.3
                    ],
                    [
                        "2012-12-24T18:30:00.000Z",
                        107.1
                    ],
                    [
                        "2013-01-24T18:30:00.000Z",
                        108
                    ],
                    [
                        "2013-02-24T18:30:00.000Z",
                        108.4
                    ],
                    [
                        "2013-03-24T18:30:00.000Z",
                        108.6
                    ],
                    [
                        "2013-04-24T18:30:00.000Z",
                        108.6
                    ],
                    [
                        "2013-05-24T18:30:00.000Z",
                        108.6
                    ],
                    [
                        "2013-06-24T18:30:00.000Z",
                        110.1
                    ],
                    [
                        "2013-07-24T18:30:00.000Z",
                        111.2
                    ],
                    [
                        "2013-08-24T18:30:00.000Z",
                        112.9
                    ],
                    [
                        "2013-09-24T18:30:00.000Z",
                        114.3
                    ],
                    [
                        "2013-10-24T18:30:00.000Z",
                        114.6
                    ],
                    [
                        "2013-11-24T18:30:00.000Z",
                        114.3
                    ],
                    [
                        "2013-12-24T18:30:00.000Z",
                        113.4
                    ],
                    [
                        "2014-01-24T18:30:00.000Z",
                        113.6
                    ],
                    [
                        "2014-02-24T18:30:00.000Z",
                        113.6
                    ],
                    [
                        "2014-03-24T18:30:00.000Z",
                        114.3
                    ],
                    [
                        "2014-04-24T18:30:00.000Z",
                        114.1
                    ],
                    [
                        "2014-05-24T18:30:00.000Z",
                        114.8
                    ],
                    [
                        "2014-06-24T18:30:00.000Z",
                        115.2
                    ],
                    [
                        "2014-07-24T18:30:00.000Z",
                        116.7
                    ],
                    [
                        "2014-08-24T18:30:00.000Z",
                        117.2
                    ],
                    [
                        "2014-09-24T18:30:00.000Z",
                        116.4
                    ],
                    [
                        "2014-10-24T18:30:00.000Z",
                        115.6
                    ],
                    [
                        "2014-11-24T18:30:00.000Z",
                        114.1
                    ],
                    [
                        "2014-12-24T18:30:00.000Z",
                        112.1
                    ],
                    [
                        "2015-01-24T18:30:00.000Z",
                        110.8
                    ],
                    [
                        "2015-02-24T18:30:00.000Z",
                        109.6
                    ],
                    [
                        "2015-03-24T18:30:00.000Z",
                        109.9
                    ],
                    [
                        "2015-04-24T18:30:00.000Z",
                        110.2
                    ],
                    [
                        "2015-05-24T18:30:00.000Z",
                        111.4
                    ],
                    [
                        "2015-06-24T18:30:00.000Z",
                        111.8
                    ],
                    [
                        "2015-07-24T18:30:00.000Z",
                        111.1
                    ],
                    [
                        "2015-08-24T18:30:00.000Z",
                        110
                    ],
                    [
                        "2015-09-24T18:30:00.000Z",
                        109.9
                    ],
                    [
                        "2015-10-24T18:30:00.000Z",
                        110.1
                    ],
                    [
                        "2015-11-24T18:30:00.000Z",
                        109.9
                    ],
                    [
                        "2015-12-24T18:30:00.000Z",
                        109.4
                    ],
                    [
                        "2016-01-24T18:30:00.000Z",
                        108
                    ],
                    [
                        "2016-02-24T18:30:00.000Z",
                        107.1
                    ],
                    [
                        "2016-03-24T18:30:00.000Z",
                        107.7
                    ],
                    [
                        "2016-04-24T18:30:00.000Z",
                        109
                    ],
                    [
                        "2016-05-24T18:30:00.000Z",
                        110.4
                    ],
                    [
                        "2016-06-24T18:30:00.000Z",
                        111.7
                    ],
                    [
                        "2016-07-24T18:30:00.000Z",
                        111.8
                    ],
                    [
                        "2016-08-24T18:30:00.000Z",
                        111.2
                    ],
                    [
                        "2016-09-24T18:30:00.000Z",
                        111.4
                    ],
                    [
                        "2016-10-24T18:30:00.000Z",
                        111.5
                    ],
                    [
                        "2016-11-24T18:30:00.000Z",
                        111.9
                    ],
                    [
                        "2016-12-24T18:30:00.000Z",
                        111.7
                    ],
                    [
                        "2017-01-24T18:30:00.000Z",
                        112.6
                    ],
                    [
                        "2017-02-24T18:30:00.000Z",
                        113
                    ],
                    [
                        "2017-03-24T18:30:00.000Z",
                        113.2
                    ],
                    [
                        "2017-04-24T18:30:00.000Z",
                        113.2
                    ],
                    [
                        "2017-05-24T18:30:00.000Z",
                        112.9
                    ],
                    [
                        "2017-06-24T18:30:00.000Z",
                        112.7
                    ],
                    [
                        "2017-07-24T18:30:00.000Z",
                        113.9
                    ],
                    [
                        "2017-08-24T18:30:00.000Z",
                        114.8
                    ],
                    [
                        "2017-09-24T18:30:00.000Z",
                        114.9
                    ],
                    [
                        "2017-10-24T18:30:00.000Z",
                        115.6
                    ],
                    [
                        "2017-11-24T18:30:00.000Z",
                        116.4
                    ],
                    [
                        "2017-12-24T18:30:00.000Z",
                        115.7
                    ],
                    [
                        "2018-01-24T18:30:00.000Z",
                        116
                    ],
                    [
                        "2018-02-24T18:30:00.000Z",
                        116.1
                    ],
                    [
                        "2018-03-24T18:30:00.000Z",
                        116.3
                    ],
                    [
                        "2018-04-24T18:30:00.000Z",
                        117.3
                    ],
                    [
                        "2018-05-24T18:30:00.000Z",
                        118.3
                    ],
                    [
                        "2018-06-24T18:30:00.000Z",
                        119.1
                    ],
                    [
                        "2018-07-24T18:30:00.000Z",
                        119.9
                    ],
                    [
                        "2018-08-24T18:30:00.000Z",
                        120.1
                    ],
                    [
                        "2018-09-24T18:30:00.000Z",
                        120.9
                    ],
                    [
                        "2018-10-24T18:30:00.000Z",
                        122
                    ],
                    [
                        "2018-11-24T18:30:00.000Z",
                        121.6
                    ],
                    [
                        "2018-12-24T18:30:00.000Z",
                        119.7
                    ],
                    [
                        "2019-01-24T18:30:00.000Z",
                        119.2
                    ],
                    [
                        "2019-02-24T18:30:00.000Z",
                        119.5
                    ],
                    [
                        "2019-03-24T18:30:00.000Z",
                        119.9
                    ],
                    [
                        "2019-04-24T18:30:00.000Z",
                        121.1
                    ],
                    [
                        "2019-05-24T18:30:00.000Z",
                        121.6
                    ],
                    [
                        "2019-06-24T18:30:00.000Z",
                        121.5
                    ],
                    [
                        "2019-07-24T18:30:00.000Z",
                        121.3
                    ],
                    [
                        "2019-08-24T18:30:00.000Z",
                        121.5
                    ],
                    [
                        "2019-09-24T18:30:00.000Z",
                        121.3
                    ],
                    [
                        "2019-10-24T18:30:00.000Z",
                        122
                    ],
                    [
                        "2019-11-24T18:30:00.000Z",
                        122.3
                    ],
                    [
                        "2019-12-24T18:30:00.000Z",
                        123
                    ],
                    [
                        "2020-01-24T18:30:00.000Z",
                        123.4
                    ],
                    [
                        "2020-02-24T18:30:00.000Z",
                        122.2
                    ],
                    [
                        "2020-03-24T18:30:00.000Z",
                        120.4
                    ],
                    [
                        "2020-04-24T18:30:00.000Z",
                        119.2
                    ],
                    [
                        "2020-05-24T18:30:00.000Z",
                        117.5
                    ],
                    [
                        "2020-06-24T18:30:00.000Z",
                        119.3
                    ],
                    [
                        "2020-07-24T18:30:00.000Z",
                        121
                    ],
                    [
                        "2020-08-24T18:30:00.000Z",
                        122
                    ],
                    [
                        "2020-09-24T18:30:00.000Z",
                        122.9
                    ],
                    [
                        "2020-10-24T18:30:00.000Z",
                        123.6
                    ],
                    [
                        "2020-11-24T18:30:00.000Z",
                        125.1
                    ],
                    [
                        "2020-12-24T18:30:00.000Z",
                        125.4
                    ],
                    [
                        "2021-01-24T18:30:00.000Z",
                        126.5
                    ],
                    [
                        "2021-02-24T18:30:00.000Z",
                        128.1
                    ],
                    [
                        "2021-03-24T18:30:00.000Z",
                        129.9
                    ],
                    [
                        "2021-04-24T18:30:00.000Z",
                        132
                    ],
                    [
                        "2021-05-24T18:30:00.000Z",
                        132.9
                    ],
                    [
                        "2021-06-24T18:30:00.000Z",
                        133.7
                    ],
                    [
                        "2021-07-24T18:30:00.000Z",
                        135
                    ],
                    [
                        "2021-08-24T18:30:00.000Z",
                        136.2
                    ],
                    [
                        "2021-09-24T18:30:00.000Z",
                        137.4
                    ],
                    [
                        "2021-10-24T18:30:00.000Z",
                        140.7
                    ],
                    [
                        "2021-11-24T18:30:00.000Z",
                        143.7
                    ],
                    [
                        "2021-12-24T18:30:00.000Z",
                        143.3
                    ],
                    [
                        "2022-01-24T18:30:00.000Z",
                        143.8
                    ],
                    [
                        "2022-02-24T18:30:00.000Z",
                        145.3
                    ],
                    [
                        "2022-03-24T18:30:00.000Z",
                        148.9
                    ],
                    [
                        "2022-04-24T18:30:00.000Z",
                        152.3
                    ],
                    [
                        "2022-05-24T18:30:00.000Z",
                        155
                    ],
                    [
                        "2022-06-24T18:30:00.000Z",
                        155.4
                    ],
                    [
                        "2022-07-24T18:30:00.000Z",
                        154
                    ],
                    [
                        "2022-08-24T18:30:00.000Z",
                        153.2
                    ],
                    [
                        "2022-09-24T18:30:00.000Z",
                        151.9
                    ],
                    [
                        "2022-10-24T18:30:00.000Z",
                        152.9
                    ],
                    [
                        "2022-11-24T18:30:00.000Z",
                        152.5
                    ],
                    [
                        "2022-12-24T18:30:00.000Z",
                        150.5
                    ],
                    [
                        "2023-01-24T18:30:00.000Z",
                        150.7
                    ],
                    [
                        "2023-02-24T18:30:00.000Z",
                        150.9
                    ],
                    [
                        "2023-03-24T18:30:00.000Z",
                        151
                    ],
                    [
                        "2023-04-24T18:30:00.000Z",
                        151.1
                    ],
                    [
                        "2023-05-24T18:30:00.000Z",
                        149.4
                    ],
                    [
                        "2023-06-24T18:30:00.000Z",
                        148.9
                    ],
                    [
                        "2023-07-24T18:30:00.000Z",
                        152.1
                    ],
                    [
                        "2023-08-24T18:30:00.000Z",
                        152.5
                    ],
                    [
                        "2023-09-24T18:30:00.000Z",
                        151.8
                    ],
                    [
                        "2023-10-24T18:30:00.000Z",
                        152.5
                    ],
                    [
                        "2023-11-24T18:30:00.000Z",
                        153.1
                    ],
                    [
                        "2023-12-24T18:30:00.000Z",
                        151.6
                    ],
                    [
                        "2024-01-24T18:30:00.000Z",
                        151.1
                    ]
                ]
            }
        ]
    }

    const handleMouseOut = () => {
        setIsHovering("");
    };
    document.title = "Nivesh Karma - Stock Market research & Analysis tools for investors | Fundamental Analysis Tools | Best Stock Screener | Stock Screener in India | Long Term Investing Platform"

    useEffect(() => {
        var classesNodeList = document.querySelectorAll(".css-6j8wv5-Input");
        if (classesNodeList[1] !== undefined) {
            classesNodeList[1].style.paddingTop = "15px"
        }
        if (props.showSearch) {
            props.setShowSearch(false)
        }
    }, [props.optionData]);


    return (
        <>
            <div className="HomeContentMain" >
                <div className="shadowBlock" style={{ backgroundColor: "White", paddingBottom: "30px", paddingTop: "100px" }}>
                <div  style={{ paddingTop: "50px"}}>
                    <span id="nkSpan">Research Platform for Long Term Investment<br /></span>
                </div>
                    {/* <div id="MainTextBottom" style={{ paddingTop: "10px", color: "#606f7b"}}>
                    Our <b>"Research Platform"</b> is simple yet effective guide to investing process that can help to pick fundamentally strong business with long term growth potential.
                </div>*/}

                    <div className="HomeContentSectionSub" style={{ textAlign: "center", paddingTop: "20px"}}>
                    <div style={{ width: "600px", margin: "auto"}}>
                            {props.optionData !== undefined && props.optionData.length > 0 &&
                                <>
                                <Searchbox setShow={setShowMobileOption} optionData={props.optionData} origin="Home" searchHistory={props.searchHistory} setSearchHistory={props.setSearchHistory}/>

                                <p>
                                    <div style={{ display: "inline-block", paddingLeft:"20px" }}>
                                        or continue with
                                    </div>
                                    {props.searchHistory.toReversed().map(item => (

                                        <div style={{ display: "inline-block", padding: "2px 10px", borderRadius: "15px", backgroundColor: "rgb(248, 248, 252)", marginLeft: "5px" }}>
                                            <a href={"/Company/" + item} style={{ textDecoration: "none", color: "black", fontSize:"12px" }}>{item}</a>
                                    </div>
                                    ))
                                     

                                }
                                </p>
                                </>

                            }
                            
                    </div>
                </div>
                
                

                <div className="HomeContentSection" >
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("search")} onMouseOut={handleMouseOut}>
                            Search over 3,000 businesses
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock"  onMouseOver={() => handleMouseOver("document")} onMouseOut={handleMouseOut}>
                            10 Years of financials data
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock"  onMouseOver={() => handleMouseOver("filter")} onMouseOut={handleMouseOut}>
                            Fundamental analysis tools
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("checklist")} onMouseOut={handleMouseOut}>
                            650+ attributes for analysis
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("meter")} onMouseOut={handleMouseOut}>
                            Business valuation tools
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("gears")} onMouseOut={handleMouseOut}>
                            3M Scoring Model & Ranking
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("eye")} onMouseOut={handleMouseOut}>
                            Commodity price trends
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("scope")} onMouseOut={handleMouseOut}>
                            Powerful 3M stock screener
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock" onMouseOver={() => handleMouseOver("compare")} onMouseOut={handleMouseOut}>
                            Compare stocks on 150+ attributes
                        </div>
                    </div>
                    <div>
                        <div className="ContentBlock"  onMouseOver={() => handleMouseOver("user")} onMouseOut={handleMouseOut}>
                            Superinvestor Portfolio
                        </div>
                    </div>
                    </div>
                </div>



                {/*<div>
                    <Tutorial />
                </div>
                <div id="MarkerText">
                    <b>INVEST IN YOUR FUTURE</b>
                </div>*/}
            </div>
            <section className="ContentSection" style={{ paddingTop: "60px" }}>
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>
                <div style={{ marginLeft: "auto", marginRight: "auto", paddingLeft: "10px" }}>
                    <div>
                        <div className="RoundHeader">
                            1
                        </div>
                    </div>
                    <h2 style={{ textAlign: "left", color: "#36454f" }}>
                        Our "3M Score" - Moat, Management & Margin of Safety
                    </h2>
                    <div className="ContentSectionText" style={{ textAlign: "left" }}>
                        Our "3M Score" is based on the long-term investment strategy taught by legendary investor Warren Buffett.<br/> We use 100+ attributes for quality checks to identify predictable high-quality companies.
                    </div>
                </div>
                <div className="ContentSectionContainerOne" style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
                    <div className="leftHalf ContentSectionLeftOne">

                        <br />
                        <div style={{ textAlign: "left" }}>
                            <h3>PREDICTABILITY</h3>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Consistent capital appreciation over past years<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Operating in a predictable industry<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Considered relatively stable<br />
                            </div>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <h3>MOAT</h3>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Strong balance sheet<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Track record of revenue and earnings growth<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>High and consistent free cash flows<br />
                            </div>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <h3>MANAGEMENT</h3>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>High, consistent and growing margin<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>High & consistent Return on capital<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Low or manageable debt level<br />
                            </div>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <h3>MARGIN OF SAFETY</h3>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Certainty of future cash flows<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Historically lower valuation<br />
                            </div>
                        </div>
                        <br />
                        <div style={{ textAlign: "left" }}>
                            And much more.
                        </div>
                    </div>
                    <div className="rightHalf ContentSectionRightOne" style={{ paddingTop: "50px" }}>
                        <div>
                            <div style={{ textAlign: "left", display: "flex", justifyContent: "left", gap: "15px" }}><div><h3>Company 3M Score:</h3></div><div><h3 style={{ color: "#669900" }}>2 - Good</h3></div><Gear ind="true" /></div>
                            <div style={{ border: "1px solid #000", padding: "10px", borderRadius: "15px" }}>
                                <table className="ScoreTable" style={{ width: "100%", height: "250px", borderCollapse: "collapse" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="centerAlignedScore"><h3>Rating</h3></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td width="20%"><h3>Predictability</h3></td>
                                            <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "25px" }}><div style={{ width: "66%", backgroundColor: "#fac904", minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}><h3>66</h3></div></div></td>
                                        </tr>
                                        <tr>
                                            <td width="20%"><h3>Moat</h3></td>
                                                <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "25px" }}><div style={{ width: "92%", backgroundColor: "#009900", minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}><h3>92</h3></div></div></td>
                                        </tr>
                                        <tr>
                                            <td width="20%"><h3>Management</h3></td>
                                                    <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "25px" }}><div style={{ width: "70%", backgroundColor: "#669900", minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}><h3>70</h3></div></div></td>
                                        </tr>
                                        <tr>
                                            <td width="20%"><h3>Margin of Safety</h3></td>
                                            <td width="80%"><div style={{ display: "flex", justifyContent: "left", gap: "5px" }}><div style={{ width: "90%", backgroundColor: "#F8F8FC", borderRadius: "10px", minHeight: "25px" }}><div style={{ width: "75%", backgroundColor: "#669900", minHeight: "20px", borderRadius: "10px" }}></div></div><div style={{ width: "10%", textAlign: "center", paddingTop: "5px", fontWeight: "600" }}><h3>75</h3></div></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div className="ContentSectionText" style={{ textAlign: "left" }}>
                                Scoring model is constructed using historical financial performance, company or industry predictability, future growth potential & more to identify the best stocks to consider for investment decisions.

                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="ContentSection">
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>
                <div className="ContentSectionContainerTwo" style={{ paddingBottom: "20px", display: "grid", gridTemplateColumns: "50% 50%" }}>

                    <div className="leftHalf ContentSectionLeftTwo" style={{ marginTop: "150px", marginLeft: "20px", marginRight: "20px", border: "1px solid grey", borderRadius: "25px" }}>
                        <div style={{ color: "#36454f", fontWeight: "600", fontSize: "20px", paddingTop: "10px" }}><h3>Uncover the Best Stocks</h3></div>
                        <br />
                        <div className="ContentSectionText" style={{ textAlign: "left", padding: "10px 50px" }}>
                            The 3M Score is a good predictor of stock's long-term performance. It ranges from 0 to 100, with 100 being the highest.<br /><br />
                            These individual Scores for Predictability, Moat, Management and Margin of Safety (MOS) are assigned to each stock.<br /><br />
                            This enables you to narrow down the list to the stocks that best fits your own investing style.<br /><br />
                            You can also find stocks with the best scores across Moat, Management and Margin of Safety (MOS).<br /><br />
                            The Stock Ranking is a calibrated weighted score of each individual score.<br /><br />
                        </div>

                    </div>




                    <div className="rightHalf ContentSectionRightTwo" >

                        <div>
                            <div className="RoundHeader">
                                2
                        </div>
                        </div>
                        <div style={{ marginLeft: "auto", marginRight: "auto", paddingLeft: "10px" }}>
                            <h2 style={{ textAlign: "left", color: "#36454f" }}>
                                Discover quality businesses quickly with "3M Score"
                            </h2>
                            <div className="ContentSectionText" style={{ textAlign: "left", padding: "10px 40px 10px 0px"}}>
                                Good investors rely on powerful tools and research to ignore the noise.<br /><br />
                            Our goal is to improve the performance of your portfolio by discovering top stocks that fits your investing style.
                        </div>
                        </div>


                        <div style={{ border: "1px solid rgba(0, 0, 0, 0.5)", backgroundColor: "rgb(248, 248, 252)", borderRadius: "25px", marginTop: "20px" }}>


                            <div style={{ paddingTop: "20px", paddingLeft: "10px", paddingBottom: "10px", fontWeight: "600", textAlign: "left" }}>
                                <h3>The "3M" score is interpreted as below:</h3>
                        </div>
                            <div style={{ padding: "10px 20px 10px 20px" }}>
                                <table style={{ width: "100%", border: "1px solid rgba(0, 0, 0, 0.05)" }}>
                                    <tbody>
                                        <tr>
                                            <th width="10%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", backgroundColor: "" }}></th>
                                            <th width="20%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}><h3>Score</h3></th>
                                            <th width="70%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}><h3>Meaning</h3></th>
                                        </tr>
                                        <tr>
                                            <td width="10%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", backgroundColor: "#009900" }}></td>
                                            <td width="20%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>80-100</td>
                                            <td width="70%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>Highest outperformance potential</td>
                                        </tr>
                                        <tr>
                                            <td width="10%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", backgroundColor: "#669900" }}></td>
                                            <td width="20%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>70-80</td>
                                            <td width="70%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>Good outperformance potential</td>
                                        </tr>
                                        <tr>
                                            <td width="10%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", backgroundColor: "#fac904" }}></td>
                                            <td width="20%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>50-70</td>
                                            <td width="70%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>Likely to have average performance</td>
                                        </tr>
                                        <tr>
                                            <td width="10%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", backgroundColor: "#ff6666" }}></td>
                                            <td width="20%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>40-50</td>
                                            <td width="70%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>Poor future performance potential</td>
                                        </tr>
                                        <tr>
                                            <td width="10%" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", backgroundColor: "#ff0000" }}></td>
                                            <td width="20%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>0-40</td>
                                            <td width="70%" className="ContentSectionText" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>Worst performance, or not enough data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            
                        </div>
                    </div>
                    
                    </div>
                </div>
            </section>
            <section className="ContentSection ContentSectionThree" style={{ backgroundColor: "#f8f8fc" }} >
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 540px 40px" }}>
                <div className="ContentSectionContainerThree" style={{ display: "grid", gridTemplateColumns: "45% 55%" }}>
                    <div className="leftHalf ContentSectionLeftThree">
                        <div style={{ paddingLeft: "10px" }}>
                            <div className="RoundHeader">
                                3
                        </div>
                            </div>
                            <h2 style={{ textAlign: "left", color: "#36454f", paddingLeft: "10px" }}>
                                Fundamental analysis tools
                             </h2>
                        <div className="ContentSectionText" style={{ textAlign: "left", padding: "10px 40px" }}>
                            Fundamental analysis is a robust approach to evaluate a company's financial health and investment potential.<br /><br />
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Get Access to 10 years of financial data and trends<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Dissects financial statements using Vertical and Horizontal analysis<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>650+ predefined attributes for analysis<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>Ability to add your own ratios<br />
                            </div>
                            <div className="intendedContent">
                                <span>&#10003;&nbsp;</span>50+ historical ratios<br />
                            </div>
                            <br />
                            Combining these analytical tools helps you make informed investment decisions.

                        </div>

                    </div>
                    <div className="rightHalf ContentSectionRightThree">
                            <div style={{ textAlign: "left", padding: "10px 10px 10px 80px" }}>
                                <div style={{ border: "1px solid #dadce0", borderRadius: "10px", padding: "10px", marginTop: "80px" }}>
                                <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                                    <h3>User Ratios:</h3>
                                        <button style={{pointerEvents: "none"}}> Manage&nbsp;Your&nbsp;Ratios</button>
                                    
                                </div>

                                <div className="finColContainer" style={{ display: "grid", gridTemplateColumns: "auto auto", justifyContent: "", marginTop: "10px" }}>
                                    <div className="OddDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                Cash due banks
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    2,27,360
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="OddDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                                <div className="detailsHeading">
                                                    Cash
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    5,27,360
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="EvenDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                Current Ratio
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    1.07
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="EvenDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                Quick Ratio
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    0.72
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="OddDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                LT Debt/Eq
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    0.28
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="OddDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                BVPS 3y Back
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    708.44
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="EvenDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                    BVPS 10y Back
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    334.77
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="EvenDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                ROE 5y avg
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    9.11%
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="OddDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                ROCE 5y avg
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    7.70%
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="OddDivS" style={{ width: "90%" }}>
                                        <div className="summaryFont">
                                            <div className="detailsHeading">
                                                ROA 5y avg
                                            </div>
                                            <div className="details">
                                                <div className="DetailsSubHeader">
                                                </div>
                                                <div className="DetailsValue">
                                                    4.09%
                                                </div>
                                                <div className="DetailsEnd">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
                        <div className="HomeSectionThreeImageOne">
                            <section className="Ratios shadowBlock" style={{overflow: "hidden"}}>
                                <table className="finData">
                                    <tbody>
                                        <tr>
                                            <td className="leftBold ratioWidth stikeyHeader ratioHeaderOne">Profitability Ratios</td>
                                            <td className="columnHeading">Mar 2015</td>
                                            <td className="columnHeading">Mar 2016</td>
                                            <td className="columnHeading">Mar 2017</td>
                                            <td className="columnHeading">Mar 2018</td>
                                            <td className="columnHeading">Mar 2019</td>
                                            <td className="columnHeading">Mar 2020</td>
                                            <td className="columnHeading">Mar 2021</td>
                                            <td className="columnHeading">Mar 2022</td>
                                            <td className="columnHeading">Mar 2023</td>
                                            <td className="columnHeading">Mar 2024</td>
                                            <td className="columnHeading">TTM</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                    <div>Gross Margin %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png"/></div>
                                                    </div>
            </td>
                                            <td className="tabledata SubHeader"> 45.53%</td>
                                            <td className="tabledata SubHeader"> 43.64%</td>
                                            <td className="tabledata SubHeader"> 43.30%</td>
                                            <td className="tabledata SubHeader"> 49.31%</td>
                                            <td className="tabledata SubHeader"> 50.47%</td>
                                            <td className="tabledata SubHeader"> 51.37%</td>
                                            <td className="tabledata SubHeader"> 50.34%</td>
                                            <td className="tabledata SubHeader"> 48.35%</td>
                                            <td className="tabledata SubHeader"> 45.36%</td>
                                            <td className="tabledata SubHeader"> 51.92%</td>
                                            <td className="tabledata SubHeader"> 51.92%</td>
         </tr>
                                            <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                        <div>Operating Margin %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                        </div>
            </td>
                                            <td className="tabledata SubHeader"> 15.92%</td>
                                            <td className="tabledata SubHeader"> 16.37%</td>
                                            <td className="tabledata SubHeader"> 16.52%</td>
                                            <td className="tabledata SubHeader"> 19.26%</td>
                                            <td className="tabledata SubHeader"> 21.15%</td>
                                            <td className="tabledata SubHeader"> 22.27%</td>
                                            <td className="tabledata SubHeader"> 22.44%</td>
                                            <td className="tabledata SubHeader"> 22.43%</td>
                                            <td className="tabledata SubHeader"> 21.48%</td>
                                            <td className="tabledata SubHeader"> 21.73%</td>
                                            <td className="tabledata SubHeader"> 21.73%</td>
         </tr>
                                                <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                            <div>Pre Tax Margin %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
            </td>
                                            <td className="tabledata SubHeader"> 19.77%</td>
                                            <td className="tabledata SubHeader"> 17.43%</td>
                                            <td className="tabledata SubHeader"> 18.12%</td>
                                            <td className="tabledata SubHeader"> 20.16%</td>
                                            <td className="tabledata SubHeader"> 21.89%</td>
                                            <td className="tabledata SubHeader"> 23.06%</td>
                                            <td className="tabledata SubHeader"> 22.55%</td>
                                            <td className="tabledata SubHeader"> 22.64%</td>
                                            <td className="tabledata SubHeader"> 22.03%</td>
                                            <td className="tabledata SubHeader"> 22.50%</td>
                                            <td className="tabledata SubHeader"> 22.50%</td>
         </tr>
                                                    <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                <div>Effective Tax Rate %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                </div>
            </td>
                                            <td className="tabledata SubHeader"> 31%</td>
                                            <td className="tabledata SubHeader"> 31%</td>
                                            <td className="tabledata SubHeader"> 31%</td>
                                            <td className="tabledata SubHeader"> 28%</td>
                                            <td className="tabledata SubHeader"> 30%</td>
                                            <td className="tabledata SubHeader"> 26%</td>
                                            <td className="tabledata SubHeader"> 25%</td>
                                            <td className="tabledata SubHeader"> 25%</td>
                                            <td className="tabledata SubHeader"> 24%</td>
                                            <td className="tabledata SubHeader"> 26%</td>
                                            <td className="tabledata SubHeader"> 26%</td>
         </tr>
                                                        <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                    <div>Net Margin %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                    </div>
            </td>
                                            <td className="tabledata SubHeader"> 13.69%</td>
                                                                <td className="tabledata SubHeader"> 12.01%</td>
                                                                <td className="tabledata SubHeader"> 12.59%</td>
                                                                <td className="tabledata SubHeader"> 14.42%</td>
                                                                <td className="tabledata SubHeader"> 15.42%</td>
                                                                <td className="tabledata SubHeader"> 17%</td>
                                                                <td className="tabledata SubHeader"> 17.01%</td>
                                                                <td className="tabledata SubHeader"> 16.95%</td>
                                                                <td className="tabledata SubHeader"> 16.74%</td>
                                                                <td className="tabledata SubHeader"> 16.61%</td>
                                                                <td className="tabledata SubHeader"> 16.61%</td>
         </tr>
                                                            <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                        <div>Dividend Payout %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                        </div>
            </td>
                                            <td className="tabledata SubHeader"> 30%</td>
                                                                    <td className="tabledata SubHeader"> 81%</td>
                                                                    <td className="tabledata SubHeader"> 80%</td>
                                                                    <td className="tabledata SubHeader"> 75%</td>
                                                                    <td className="tabledata SubHeader"> 75%</td>
                                                                    <td className="tabledata SubHeader"> 77%</td>
                                                                    <td className="tabledata SubHeader"> 110%</td>
                                                                    <td className="tabledata SubHeader"> 85%</td>
                                                                    <td className="tabledata SubHeader"> 84%</td>
                                                                    <td className="tabledata SubHeader"> 91%</td>
                                                                    <td className="tabledata SubHeader"> -</td>
         </tr>
                                                                <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                            <div>Return on Equity %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                            </div>
            </td>
                                            <td className="tabledata SubHeader"> 67.80%</td>
                                                                        <td className="tabledata SubHeader"> 63.26%</td>
                                                                        <td className="tabledata SubHeader"> 66.76%</td>
                                                                        <td className="tabledata SubHeader"> 71.76%</td>
                                                                        <td className="tabledata SubHeader"> 77.03%</td>
                                                                        <td className="tabledata SubHeader"> 82.20%</td>
                                                                        <td className="tabledata SubHeader"> 16.78%</td>
                                                                        <td className="tabledata SubHeader"> 18.11%</td>
                                                                        <td className="tabledata SubHeader"> 20.17%</td>
                                                                        <td className="tabledata SubHeader"> 20.07%</td>
                                                                        <td className="tabledata SubHeader"> 20.07%</td>
         </tr>
                                                                    <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                                <div>Return on Capital %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                                </div>
            </td>
                                            <td className="tabledata SubHeader"> 69.03%</td>
                                            <td className="tabledata SubHeader"> 73.34%</td>
                                            <td className="tabledata SubHeader"> 73.92%</td>
                                            <td className="tabledata SubHeader"> 77.76%</td>
                                            <td className="tabledata SubHeader"> 83.47%</td>
                                            <td className="tabledata SubHeader"> 81.76%</td>
                                            <td className="tabledata SubHeader"> 18.30%</td>
                                            <td className="tabledata SubHeader"> 19.86%</td>
                                            <td className="tabledata SubHeader"> 21.31%</td>
                                            <td className="tabledata SubHeader"> 20.49%</td>
                                            <td className="tabledata SubHeader"> 20.49%</td>
         </tr>
                                                                        <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                                    <div>Return on Assets %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                                    </div>
            </td>
                                            <td className="tabledata SubHeader"> 31.18%</td>
                                            <td className="tabledata SubHeader"> 28.11%</td>
                                            <td className="tabledata SubHeader"> 28.66%</td>
                                            <td className="tabledata SubHeader"> 29.25%</td>
                                            <td className="tabledata SubHeader"> 32.53%</td>
                                            <td className="tabledata SubHeader"> 33.56%</td>
                                            <td className="tabledata SubHeader"> 11.64%</td>
                                            <td className="tabledata SubHeader"> 12.60%</td>
                                            <td className="tabledata SubHeader"> 13.88%</td>
                                            <td className="tabledata SubHeader"> 13.10%</td>
                                            <td className="tabledata SubHeader"> 13.10%</td>
         </tr>
                                                                            <tr>
                                            <td className="leftBold SubHeader ratioWidth stikeyHeader">
                                                <div className="headingWithPopup">
                                                                                        <div>Return on Tangible Assets %</div>
                                                    <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                                                        </div>
            </td>
                                            <td className="tabledata SubHeader"> 31.42%</td>
                                            <td className="tabledata SubHeader"> 28.28%</td>
                                            <td className="tabledata SubHeader"> 29.51%</td>
                                            <td className="tabledata SubHeader"> 30%</td>
                                            <td className="tabledata SubHeader"> 33.47%</td>
                                            <td className="tabledata SubHeader"> 34.45%</td>
                                            <td className="tabledata SubHeader"> 34.14%</td>
                                            <td className="tabledata SubHeader"> 35.25%</td>
                                            <td className="tabledata SubHeader"> 37.08%</td>
                                            <td className="tabledata SubHeader"> 31.36%</td>
                                            <td className="tabledata SubHeader"> 31.36%</td>
         </tr>
      </tbody>
   </table>
</section>
                        </div>
                        <div className="HomeSectionThreeImageTwo" >


                            <div className="finance" style={{ backgroundColor: "white", borderRadius: "10px", padding: "10px", border: "2px solid #F5F5F5"}}>
                                <div className="BalanceSheetMain">
                                    <section className="optionsFinData">
                                        <div className="leftAlign">
                                            Reliance Industries Ltd
            </div>
                                        <div className="rightAlign">
                                            <div style={{ display: "flex", paddingRight: "15px" }}>
                                                <div>
                                                    <ExternalLink />
                                                </div>
                                                <div style={{ paddingTop: "3px" }}><a href="#" target="_blank" style={{ textDecoration: "none", pointerEvents: "none" }}>Financial Result</a>
                                                </div>
                                            </div>
                                            <label>View: &nbsp;
                                            <select
                                                    className="ViewSelected"
                                                    value="fin"
                                                    onChange={() => console.log("")}
                                                    style={{ pointerEvents: "none" }}
                                                >
                                                    <option value="fin">Financial Data</option>
                                                </select>
                                            </label>
                                            <div className="optionButtonPrimary">
                                                <Asc />
                                            </div>
                                            <div className="optionButtonPrimary">
                                                <KnowMore />
                                            </div>
                                            <div className="optionButtonPrimary">
                                                <ExportSVG />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="BalanceSheet shadowBlock" style={{ marginBottom: "0px", overflow: "hidden" }}>
                                        <div className="PeerSectionTable" style={{ maxHeight: "350px", overflow: "hidden" }}>
                                            <table className="PeersTable finData">
                                                <thead>
                                                    <tr>
                                                        <th className="leftBold stikeyHeader intersection leftPeer"><i className="small ">Figures in Rs Crores.</i></th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Sep 2021</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Dec 2021</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Mar 2022</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Jun 2022</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Sep 2022</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Dec 2022</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Mar 2023</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Jun 2023</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Sep 2023</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Dec 2023</th>
                                                        <th className="finHead columnHeading columnHeadingScreener">Mar 2024</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Revenue</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,74,104</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,91,271</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,07,888</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,23,113</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,32,863</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,20,592</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,14,743</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,10,831</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,34,956</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,27,970</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,40,715</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Cost Of Goods Sold</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,19,922</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,32,413</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,47,601</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,50,678</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,62,379</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,49,165</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,39,737</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,34,940</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,55,779</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,50,386</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 1,57,646</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Gross Income</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 54,182</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 58,858</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 60,287</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 72,435</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 70,484</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 71,427</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 75,006</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 75,891</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 79,177</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 77,584</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 83,069</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Total Operating Expense</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 35,392</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 36,835</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 38,921</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 43,384</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 48,990</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 46,367</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 48,752</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 49,573</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 50,794</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 49,831</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 54,122</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Selling, General &amp; Administrative</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,580</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,660</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 5,261</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,003</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,153</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,267</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,449</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,601</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 5,947</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,313</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,818</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Depreciation &amp; Amortization</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 7,230</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 7,683</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 7,986</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 8,946</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 9,730</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 10,187</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 11,440</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 11,775</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 12,585</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 12,903</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 13,569</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Other Operating Expenses Total</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 23,582</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 24,492</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 25,674</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 28,435</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 33,107</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 29,913</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 30,863</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 31,197</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 32,262</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 30,615</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 33,735</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>EBIT</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 18,790</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 22,023</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 21,366</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 29,051</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 21,494</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 25,060</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 26,254</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 26,318</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 28,383</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 27,753</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 28,947</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Interest Income Expense</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -3,780</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -3,679</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -3,396</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -4,062</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -4,629</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -5,135</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -5,721</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -5,761</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -5,673</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -5,636</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -5,661</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Non-Recurring Items</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,836</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Total Other Income Expense Net</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,224</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,047</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,453</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,247</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 3,514</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 3,147</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,826</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 3,813</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 3,841</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 3,869</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,534</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Income before Tax</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 19,234</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 25,227</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 20,423</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 27,236</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 20,379</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 23,072</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 23,359</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 24,370</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 26,551</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 25,986</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 27,820</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Provision for Income Taxes</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 3,755</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,688</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,063</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 7,793</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 4,867</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 5,266</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 2,450</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,112</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,673</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,345</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 6,577</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Net Income</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 15,479</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 20,539</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 16,360</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 19,443</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 15,512</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 17,806</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 20,909</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 18,258</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 19,878</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 19,641</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 21,243</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Minority Interest</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -1,799</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -1,990</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -1,818</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -1,488</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -1,856</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -2,014</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -2,028</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -2,247</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -2,484</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -2,376</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> -2,292</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Equity Earnings Affiliates</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 0</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Net Income - Owner</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 13,680</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 18,549</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 16,203</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 17,955</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 13,656</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 15,792</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 19,299</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 16,011</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 17,394</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 17,265</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 18,951</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Diluted Shares Outstanding</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 664</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 668</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 679</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 692</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)" }}> 677</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}>
                                                            <div className="headingWithPopup">
                                                                <div>Diluted EPS</div>
                                                                <div><img className="chartIcon" src="/chart_Icon.png" /></div>
                                                            </div>
                                                        </td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 20.60</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 27.76</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 23.86</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 26.54</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 20.18</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 23.34</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 27.90</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 23.66</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 25.71</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 25.52</td>
                                                        <td className="tabledata SubHeader" style={{ color: "rgb(0, 0, 0)", fontWeight: "600" }}> 28.01</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <section className="ContentSection">
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>


                <div className="ContentSectionContainerFour" style={{ marginLeft: "auto", marginRight: "auto", display: "grid", gridTemplateColumns: "70% 30%", paddingBottom: "50px" }}>
                    <div className="leftHalf ContentSectionLeftFour" style={{}} >
                        <div style={{ textAlign: "left", padding: "10px 10px", margin: "0px 50px", border: "1px solid rgba(0, 0, 0, 0.5)", borderRadius: "10px", backgroundColor: "white" }}>
                                <>

                                    <section class="optionsFinData" id="companyNameValuation" style={{paddingTop: "2px"}}>
                                        <div class="leftAlign" style={{paddingTop: "5px"}}>
                                            ITC Ltd
                                        </div>
                                        <div class="rightAlign" style={{paddingTop: "0px"}}>
                                            <div class="optionButtonPrimary">
                                                <button style={{cursorEvents: "none"}}> 
                                                    Know More
                                                </button>
                                            </div>
                                        </div>
                                    </section>








                                    <div className="ValuationRow">
                                        <div
                                            className="halfQuarter shadowBlock"
                                            style={{ padding: "5px 10px 5px 10px", borderRadius: 10}}
                                        >
                                            <div className="ValuationOddDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                                <div className="leftIntend middle" style={{fontSize: "10px"}}>Initial Free Cash Flow (FCF):</div>
                                                <div className="userInput" style={{width: "60px"}}>
                                                    <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>₹</div>
                                                    <div className="paddedUserInput">
                                                        <input
                                                            name="avg_fcf"
                                                            type="number"
                                                            className="customInput"
                                                            placeholder="Average FCF per year"
                                                            defaultValue={14462}
                                                            style={{fontSize: "10px", width: "60px"}}
                                                        />
                                                    </div>
                                                    <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px"  }}>Cr</div>
                                                </div>
                                            </div>
                                            <div className="ValuationEvenDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                                <div className="leftIntend middle" style={{ fontSize: "10px" }}>Growth Rate (1 - 5 years): </div>
                                                <div className="userInput" style={{ width: "100px" }}>
                                                    <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>24</div>
                                                    <div className="userButton">
                                                        {/* <icon source="minus"></icon> */}
                                                    </div>
                                                    <div className="paddedUserInput">
                                                        <input
                                                            name="gr_five"
                                                            type="range"
                                                            className="customInputRange"
                                                            min={1}
                                                            max={30}
                                                            step="0.5"
                                                            defaultValue={24}
                                                            style={{width: "60px"}}
                                                        />
                                                    </div>
                                                    <div className="userButton">
                                                        {/*<icon source="plus"></icon>*/}
                                                    </div>
                                                    <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>%</div>
                                                </div>
                                            </div>
                                            <div className="ValuationOddDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                                <div className="leftIntend middle" style={{ fontSize: "10px" }}>Growth Rate (6 - 10 years): </div>
                                                <div className="userInput" style={{ width: "100px" }}>
                                                    <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>18</div>
                                                    <div className="userButton">
                                                        {/* <icon source="minus"></icon> */}
                                                    </div>
                                                    <div className="paddedUserInput">
                                                        <input
                                                            name="gr_ten"
                                                            type="range"
                                                            className="customInputRange"
                                                            min={1}
                                                            max={30}
                                                            step="0.5"
                                                            defaultValue={18}
                                                            style={{ width: "60px" }}
                                                        />
                                                    </div>
                                                    <div className="userButton">
                                                        {/*<icon source="plus"></icon>*/}
                                                    </div>
                                                    <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>%</div>
                                                </div>
                                                
                                            </div>
                                        
                                            <div className="ValuationEvenDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                            <div className="leftIntend middle" style={{ fontSize: "10px" }}>Terminal Growth Rate: </div>
                                            <div className="userInput" style={{ width: "100px" }}>
                                                <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>3.5</div>
                                                <div className="userButton">
                                                    {/* <icon source="minus"></icon> */}
                                                </div>
                                                <div className="paddedUserInput">
                                                    <input
                                                        name="gr_ter"
                                                        type="range"
                                                        className="customInputRange"
                                                        min={1}
                                                        max={10}
                                                        step="0.5"
                                                            defaultValue="3.5"
                                                            style={{ width: "60px" }}
                                                    />
                                                </div>
                                                <div className="userButton">
                                                    {/*<icon source="plus"></icon>*/}
                                                    </div>
                                                    <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>%</div>
                                            </div>
                                            
                                        </div>
                                    
                                            <div className="ValuationOddDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Discount Rate: </div>
                                        <div className="userInput" style={{ width: "60px" }}>
                                            <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>10</div>
                                            <div className="userButton">
                                                {/* <icon source="minus"></icon> */}
                                            </div>
                                            <div className="paddedUserInput">
                                                <input
                                                    name="disc_rate"
                                                    type="range"
                                                    className="customInputRange"
                                                    min={1}
                                                    max={30}
                                                    step="0.5"
                                                            defaultValue={10}
                                                            style={{ width: "60px" }}
                                                />
                                            </div>
                                            <div className="userButton">
                                                {/*<icon source="plus"></icon>*/}
                                                    </div>
                                                    <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>%</div>
                                        </div>
                                        
                                    </div>
                                            <div className="ValuationEvenDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Total Debt: </div>
                                        <div className="userInput" style={{ width: "60px" }}>
                                            <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>₹</div>
                                            <div className="paddedUserInput">
                                                <input
                                                    name="debt"
                                                    type="number"
                                                    className="customInput"
                                                    placeholder="Debt"
                                                    defaultValue={303}
                                                    style={{ fontSize: "10px", width: "60px" }}
                                                />
                                            </div>
                                            <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>Cr</div>
                                        </div>
                                    </div>
                                            <div className="ValuationOddDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Cash and Cash Equivalents: </div>
                                        <div className="userInput" style={{ width: "60px" }}>
                                            <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>₹</div>
                                            <div className="paddedUserInput">
                                                <input
                                                    name="cash"
                                                    type="number"
                                                    className="customInput"
                                                    placeholder="Cash and Cash Equivalent"
                                                    defaultValue={21344}
                                                    style={{ fontSize: "10px", width: "60px" }}
                                                />
                                            </div>
                                            <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>Cr</div>
                                        </div>
                                    </div>
                                            <div className="ValuationEvenDiv" style={{ width: "100%", display: "grid", gridTemplateColumns: "50% 50%", columnGap: "10px", justifyContent: "normal" }}>
                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Shares Outstanding: </div>
                                        <div className="userInput" style={{ width: "60px" }}>
                                            <div className="paddedInput grey leftRounded" style={{ fontSize: "10px", minWidth: "15px" }}>₹</div>
                                            <div className="paddedUserInput">
                                                <input
                                                    name="share"
                                                    type="number"
                                                    className="customInput"
                                                    placeholder="Share Outstanding"
                                                    defaultValue={1248}
                                                    style={{ fontSize: "10px", width: "60px" }}
                                                />
                                            </div>
                                            <div className="paddedInput grey rightRounded" style={{ fontSize: "10px", minWidth: "15px" }}>Cr</div>
                                        </div>
                                            </div>
                                            <div className="optionButtonPrimary topMarginVal">
                                                <div className="optionButtonVal" style={{fontSize: "12px", width: "40%", textAlign: "center"}}> Calculate DCF</div>
                                                <div className="optionButtonVal" style={{ fontSize: "12px", width: "40%", textAlign: "center" }}> Reset</div>
                                            </div>
                                        </div>
                                        <div className="halfQuarter">
                                            <div className="ValuationRowInner">
                                                <div
                                                    className="halfQuarter shadowBlock"
                                                    style={{ padding: "10px 0px 20px", borderRadius: 10 }}
                                                >
                                                    <div className="ValuationOddDivS" style={{ width: "94%", display: "grid", gridTemplateColumns: "65% 30%", columnGap: "10px", justifyContent: "normal", padding:  "5px 10px 5px 0px"}}>
                                                        <div className="leftIntend middle" style={{fontSize: "10px"}}>Market Cap: </div>
                                                        <div className="paddedUserOutput" style={{ fontSize: "10px" }}>
                                                            <b>6,12,135</b>
                                                        </div>
                                                    </div>
                                                    <div className="ValuationEvenDivS" style={{ width: "94%", display: "grid", gridTemplateColumns: "65% 30%", columnGap: "10px", justifyContent: "normal", padding: "5px 10px 5px 0px" }}>
                                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Value of Firm:</div>
                                                        <div
                                                            className="paddedUserOutput"
                                                            style={{ color: "rgb(0, 163, 108)", fontSize: "10px" }}
                                                        >
                                                            <b>8,84,854</b>
                                                        </div>
                                                    </div>
                                                    <div className="ValuationOddDivS" style={{ width: "94%", display: "grid", gridTemplateColumns: "65% 30%", columnGap: "10px", justifyContent: "normal", padding: "5px 10px 5px 0px" }}>
                                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Margin of Saftey:</div>
                                                        <div
                                                            className="paddedUserOutput"
                                                            style={{ color: "rgb(0, 163, 108)", fontSize: "10px" }}
                                                        >
                                                            <b>31.03%</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="halfQuarter shadowBlock"
                                                    style={{ padding: "10px 0px 20px", borderRadius: 10 }}
                                                >
                                                    <div className="ValuationOddDivS" style={{ width: "94%", display: "grid", gridTemplateColumns: "65% 30%", columnGap: "10px", justifyContent: "normal", padding: "5px 10px 5px 0px" }}>
                                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Current Price: </div>
                                                        <div className="paddedUserOutput" style={{ fontSize: "10px" }}>
                                                            <b>489</b>
                                                        </div>
                                                    </div>
                                                    <div className="ValuationEvenDivS" style={{ width: "94%", display: "grid", gridTemplateColumns: "65% 30%", columnGap: "10px", justifyContent: "normal", padding: "5px 10px 5px 0px" }}>
                                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Intrinsic Value </div>
                                                        <div
                                                            className="paddedUserOutput"
                                                            style={{ color: "rgb(0, 163, 108)", fontSize: "10px" }}
                                                        >
                                                            <b>709</b>
                                                        </div>
                                                    </div>
                                                    <div className="ValuationOddDivS" style={{ width: "94%", display: "grid", gridTemplateColumns: "65% 30%", columnGap: "10px", justifyContent: "normal", padding: "5px 10px 5px 0px" }}>
                                                        <div className="leftIntend middle" style={{ fontSize: "10px" }}>Price/Value: </div>
                                                        <div
                                                            className="paddedUserOutput"
                                                            style={{ color: "rgb(0, 163, 108)", fontSize: "10px" }}
                                                        >
                                                            <b>0.69</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ValuationRowInnerI" style={{ paddingTop: 30 }}>
                                                <div
                                                    className="FullQuarter borderEnclosed shadowBlock"
                                                    style={{ borderRadius: 10, marginBottom: 0 }}
                                                >
                                                    <div className="headerChart" style={{ paddingLeft: 20 }}>
                                                        Future FCF Estimation:
                                                    </div>
                                             <Chart
                                                        options={mapData.options}
                                                        series={mapData.series}
                                                        type="bar"
                                                        width="100%"
                                                        height="150"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                        </div>
                    </div>
                    <div className="rightHalf ContentSectionRightFour">
                        <div>
                            <div className="RoundHeader">
                                4
                            </div>
                        </div>
                        <h2 className= "FourTextAlign" style={{color: "#36454f", paddingTop: "20px"}}>
                                Valuation or Intrinsic Value Calculator
                        </h2>
                            <div className="ContentSectionText" style={{ textAlign: "left", paddingLeft: "20px", lineHeight: "1.5" }}>
                                Effective valuation is crucial for identifying buying opportunities when stock prices fall below their intrinsic value. Utilize our highly effective and useful tools for valuing assets.<br /><br />
                                <div className="intendedContent" style={{ lineHeight: "2"}}>
                                <span>&#10003;&nbsp;</span>Relative Valuation<br />
                            </div>
                                <div className="intendedContent" style={{ lineHeight: "2" }}>
                                <span>&#10003;&nbsp;</span>DCF Model<br />
                            </div>
                                <div className="intendedContent" style={{ lineHeight: "2" }}>
                                <span>&#10003;&nbsp;</span>EPS Model<br />
                            </div>


                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="ContentSection" style={{ backgroundColor: "#f8f8fc" }}>
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>
                <div className="ContentSectionContainerFive" style={{ marginLeft: "auto", marginRight: "auto", display: "grid", gridTemplateColumns: "30% 70%" }}>
                    <div className="leftHalf ContentSectionLeftFive">
                        <div style={{ paddingLeft: "10px" }}>
                            <div className="RoundHeader">
                                5
                            </div>
                        </div>
                        <h2 style={{ textAlign: "left", color: "#36454f", paddingLeft: "10px" }}>
                            Powerful Stock Screener
                        </h2>
                        <div className="ContentSectionText ContentSectionFiveText" style={{ textAlign: "left" }}>
                            Our "3M Screener" is built upon the risk-averse value investing strategies taught by Warren Buffett.<br /><br />
                            Combining these analytical tools helps you make informed investment decisions.<br /><br />

                        </div>

                    </div>
                    <div className="rightHalf ContentSectionRightFive">
                        <div className= "HomeSectionFiveImageContainer" style={{ textAlign: "left", border: "1px solid rgba(0, 0, 0, 0.5)", borderRadius: "25px", backgroundColor: "white" }}>
                                <div className="HomeSectionFiveImage" style={{overflow: "hidden"}}>

                                    <div className="Screener">
                                        <div style={{ textAlign: "right", width: "100%", cursor: "pointer", minHeight: "15px"}}>
                                            <div style={{ color: "blue", fontSize: "18px" }}><div style={{float: "right", padding: "0px 8px", color: "blue"}}>-</div></div>
                                        </div>
                                        <div className="rightAlign">
                                            <div className="optionButtonPrimary">
                                                <button style={{fontSize: "12px"}}> Manage Columns</button>
                                            </div>
                                            <div className="optionButtonPrimary">
                                                <button style={{ fontSize: "12px" }}> Add to Watchlist</button>
                                            </div>
                                            <div className="optionButtonPrimary" style={{paddingTop: "1px"}}>
                                                <svg
                                                    fill="#000000"
                                                    height="20px"
                                                    width="20px"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Z" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            className="optionButtonPrimary"
                                            style={{ marginTop: "-30px", paddingLeft: 15 }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "start", gap: 5 }}>
                                                <button style={{fontSize: "12px"}}>Edit Stock Screener</button>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        paddingTop: 10,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "start"
                                                    }}
                                                >
                                                    <div className="ScreenerResultPrompt" style={{ fontSize: "12px" }}>Screener Result:</div>
                                                    <div className="ScreenerResultText" style={{ fontSize: "11px", paddingTop: "1px" }}>6 companies found.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: 12 }} />
                                            <div className="dragableSection" style={{fontSize: "9px"}}>
                                                <div style={{ display: "inline-block", cursor: "pointer" }}>
                                                    <b> Market Cap</b> &nbsp; Large Cap(20,000 cr &amp; above)
      </div>
                                                <div
                                                    className="removableSection"
                                                    style={{ display: "inline-block", marginLeft: 10, cursor: "pointer" }}
                                                >
                                                    ✖
      </div>
                                            </div>
                                            <div className="dragableSection" style={{ fontSize: "9px" }}>
                                                <div style={{ display: "inline-block", cursor: "pointer" }}>
                                                    <b> Returns 5y cagr</b> &nbsp; Over 20%
      </div>
                                                <div
                                                    className="removableSection"
                                                    style={{ display: "inline-block", marginLeft: 10, cursor: "pointer" }}
                                                >
                                                    ✖
      </div>
                                            </div>
                                            <div className="dragableSection" style={{ fontSize: "9px" }}>
                                                <div style={{ display: "inline-block", cursor: "pointer" }}>
                                                    <b> Returns 10y cagr</b> &nbsp; Over 20%
      </div>
                                                <div
                                                    className="removableSection"
                                                    style={{ display: "inline-block", marginLeft: 10, cursor: "pointer" }}
                                                >
                                                    ✖
      </div>
                                            </div>
                                            <div className="dragableSection" style={{ fontSize: "9px" }}>
                                                <div style={{ display: "inline-block", cursor: "pointer" }}>
                                                    <b> Sector</b> &nbsp; Materials
      </div>
                                                <div
                                                    className="removableSection"
                                                    style={{ display: "inline-block", marginLeft: 10, cursor: "pointer" }}
                                                >
                                                    ✖
      </div>
                                            </div>
                                            <p />
                                        </div>
                                        <section className="PeersTwo" style={{paddingLeft: "0px"}}>
                                            <div
                                                className="PeerSectionTable"
                                                style={{ maxHeight: 550, overflow: "hidden" }}
                                            >
                                                <table className="PeersTable finData" style={{ overflow: "hidden", fontSize: "10px"}}>
                                                    <thead>
                                                        <tr>
                                                            <th className="columnHeadingScreenerLeft leftPeer intersection">
                                                                Select
            </th>
                                                            <th className="columnHeadingScreenerLeft leftPeer intersection">
                                                                S.&nbsp;No.
            </th>
                                                            <th
                                                                className="columnHeadingScreenerLeft leftPeer intersection"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                Company Name
            </th>
                                                            <th className="Sector columnHeadingScreenerLeft">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "start"
                                                                    }}
                                                                >
                                                                    <div>Sector</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="Industry columnHeadingScreenerLeft">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "start"
                                                                    }}
                                                                >
                                                                    <div>Industry</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>Price</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>Mar cap</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>PE ttm</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>PBV ttm</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>PS ttm</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>NPM %</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>ROA</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>Price 3y cagr</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>Price 5y cagr</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="columnHeadingScreener">
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "blue",
                                                                        fontWeight: 400,
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <div>Price 10y cagr</div>
                                                                    <div style={{ paddingTop: 5 }}>
                                                                        <div style={{ minHeight: 25 }}>&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="leftPeer" style={{ width: 50 }}>
                                                                <div>
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </td>
                                                            <td className="leftPeer" style={{ width: 50, textAlign: "left" }}>
                                                                1
            </td>
                                                            <td
                                                                className="leftPeer"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                <a
                                                                    href="/Company/JSWSTEEL"
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    JSW Steel Ltd
              </a>
                                                            </td>
                                                            <td className="Sector SubHeader">Materials</td>
                                                            <td className="Industry SubHeader">Metals &amp; Mining</td>
                                                            <td className="tabledataPeer SubHeader">941</td>
                                                            <td className="tabledataPeer SubHeader">2,29,275</td>
                                                            <td className="tabledataPeer SubHeader">26.02</td>
                                                            <td className="tabledataPeer SubHeader">2.95</td>
                                                            <td className="tabledataPeer SubHeader">1.31</td>
                                                            <td className="tabledataPeer SubHeader">5.13%</td>
                                                            <td className="tabledataPeer SubHeader">3.93%</td>
                                                            <td className="tabledataPeer SubHeader">10.92%</td>
                                                            <td className="tabledataPeer SubHeader">27.68%</td>
                                                            <td className="tabledataPeer SubHeader">22.55%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftPeer" style={{ width: 50 }}>
                                                                <div>
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </td>
                                                            <td className="leftPeer" style={{ width: 50, textAlign: "left" }}>
                                                                2
            </td>
                                                            <td
                                                                className="leftPeer"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                <a
                                                                    href="/Company/PIDILITIND"
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    Pidilite Industries Ltd
              </a>
                                                            </td>
                                                            <td className="Sector SubHeader">Materials</td>
                                                            <td className="Industry SubHeader">Chemicals</td>
                                                            <td className="tabledataPeer SubHeader">3,145</td>
                                                            <td className="tabledataPeer SubHeader">1,59,958</td>
                                                            <td className="tabledataPeer SubHeader">92.55</td>
                                                            <td className="tabledataPeer SubHeader">19.02</td>
                                                            <td className="tabledataPeer SubHeader">12.93</td>
                                                            <td className="tabledataPeer SubHeader">14.11%</td>
                                                            <td className="tabledataPeer SubHeader">14.44%</td>
                                                            <td className="tabledataPeer SubHeader">13.80%</td>
                                                            <td className="tabledataPeer SubHeader">20.79%</td>
                                                            <td className="tabledataPeer SubHeader">25.64%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftPeer" style={{ width: 50 }}>
                                                                <div>
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </td>
                                                            <td className="leftPeer" style={{ width: 50, textAlign: "left" }}>
                                                                3
            </td>
                                                            <td
                                                                className="leftPeer"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                <a
                                                                    href="/Company/PIIND"
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    PI Industries Ltd
              </a>
                                                            </td>
                                                            <td className="Sector SubHeader">Materials</td>
                                                            <td className="Industry SubHeader">Chemicals</td>
                                                            <td className="tabledataPeer SubHeader">3,829.10</td>
                                                            <td className="tabledataPeer SubHeader">58,003</td>
                                                            <td className="tabledataPeer SubHeader">36.47</td>
                                                            <td className="tabledataPeer SubHeader">7.28</td>
                                                            <td className="tabledataPeer SubHeader">7.75</td>
                                                            <td className="tabledataPeer SubHeader">18.94%</td>
                                                            <td className="tabledataPeer SubHeader">14.50%</td>
                                                            <td className="tabledataPeer SubHeader">9.71%</td>
                                                            <td className="tabledataPeer SubHeader">26.81%</td>
                                                            <td className="tabledataPeer SubHeader">27.82%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftPeer" style={{ width: 50 }}>
                                                                <div>
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </td>
                                                            <td className="leftPeer" style={{ width: 50, textAlign: "left" }}>
                                                                4
            </td>
                                                            <td
                                                                className="leftPeer"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                <a
                                                                    href="/Company/JKCEMENT"
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    J K Cement Ltd
              </a>
                                                            </td>
                                                            <td className="Sector SubHeader">Materials</td>
                                                            <td className="Industry SubHeader">Construction Materials</td>
                                                            <td className="tabledataPeer SubHeader">4,535</td>
                                                            <td className="tabledataPeer SubHeader">34,969</td>
                                                            <td className="tabledataPeer SubHeader">44.31</td>
                                                            <td className="tabledataPeer SubHeader">6.53</td>
                                                            <td className="tabledataPeer SubHeader">3.03</td>
                                                            <td className="tabledataPeer SubHeader">6.84%</td>
                                                            <td className="tabledataPeer SubHeader">5.34%</td>
                                                            <td className="tabledataPeer SubHeader">16.68%</td>
                                                            <td className="tabledataPeer SubHeader">35.02%</td>
                                                            <td className="tabledataPeer SubHeader">28.10%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftPeer" style={{ width: 50 }}>
                                                                <div>
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </td>
                                                            <td className="leftPeer" style={{ width: 50, textAlign: "left" }}>
                                                                5
            </td>
                                                            <td
                                                                className="leftPeer"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                <a
                                                                    href="/Company/DEEPAKNTR"
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    Deepak Nitrite Ltd
              </a>
                                                            </td>
                                                            <td className="Sector SubHeader">Materials</td>
                                                            <td className="Industry SubHeader">Chemicals</td>
                                                            <td className="tabledataPeer SubHeader">2,511</td>
                                                            <td className="tabledataPeer SubHeader">34,248</td>
                                                            <td className="tabledataPeer SubHeader">43.31</td>
                                                            <td className="tabledataPeer SubHeader">7.89</td>
                                                            <td className="tabledataPeer SubHeader">4.56</td>
                                                            <td className="tabledataPeer SubHeader">10.69%</td>
                                                            <td className="tabledataPeer SubHeader">16.61%</td>
                                                            <td className="tabledataPeer SubHeader">12.04%</td>
                                                            <td className="tabledataPeer SubHeader">54.29%</td>
                                                            <td className="tabledataPeer SubHeader">40.39%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="leftPeer" style={{ width: 50 }}>
                                                                <div>
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </td>
                                                            <td className="leftPeer" style={{ width: 50, textAlign: "left" }}>
                                                                6
            </td>
                                                            <td
                                                                className="leftPeer"
                                                                style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
                                                            >
                                                                <a
                                                                    href="/Company/TATACHEM"
                                                                    target="_blank"
                                                                    style={{ textDecoration: "none" }}
                                                                >
                                                                    Tata Chemicals Ltd
              </a>
                                                            </td>
                                                            <td className="Sector SubHeader">Materials</td>
                                                            <td className="Industry SubHeader">Chemicals</td>
                                                            <td className="tabledataPeer SubHeader">1,092</td>
                                                            <td className="tabledataPeer SubHeader">27,793</td>
                                                            <td className="tabledataPeer SubHeader">103.80</td>
                                                            <td className="tabledataPeer SubHeader">1.25</td>
                                                            <td className="tabledataPeer SubHeader">1.80</td>
                                                            <td className="tabledataPeer SubHeader">2.91%</td>
                                                            <td className="tabledataPeer SubHeader">1.22%</td>
                                                            <td className="tabledataPeer SubHeader">15.11%</td>
                                                            <td className="tabledataPeer SubHeader">31.03%</td>
                                                            <td className="tabledataPeer SubHeader">22.44%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    </div>


                                </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="ContentSection">
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>
                <div className="ContentSectionContainerSix" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div className="leftHalf ContentSectionLeftSix" style= {{paddingTop: "50px"}}>

                        <div style={{ textAlign: "left", padding: "10px", border: "1px solid rgba(0, 0, 0, 0.5)", borderRadius: "25px", backgroundColor: "white" }}>
                                <>
                                    <div className="AboutHeaderTop" style={{ paddingLeft: 0 , paddingTop: 0, paddingBottom: 0}}>
                                        <h3>Superinvestors</h3>
                                    </div>
                                    <div className="AboutHeaderTop" style={{ paddingLeft: 0, paddingTop: 0 }}>
                                        <div className="SuperHeader" style={{fontSize: "12px"}}>Individuals</div>
                                    </div>
                                    <div
                                        style={{ overflowX: "hidden", backgroundColor: "white", borderRadius: 10 }}
                                    >
                                        <table className="PeersTable finData" style={{ fontSize: "10px", marginBottom: "0px" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="leftPeer" style={{ fontWeight: 600 }}>
                                                        S.&nbsp;No.
          </td>
                                                    <td
                                                        className="columnHeading"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        Superinvestors
          </td>
                                                    <td
                                                        className="columnHeading"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        No of Stocks
          </td>
                                                    <td
                                                        className="columnHeading"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        Investment Value (Cr)
          </td>
                                                    <td
                                                        className="columnHeading"
                                                        style={{ textAlign: "left", paddingLeft: 10 }}
                                                    >
                                                        Top 10 Holdings (left to right)
          </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td className="leftPeer">1</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/1436"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Damani (Radhakishan Shivkishan)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        3
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 72,243
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/DMART"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            DMART &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/VSTIND"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            VSTIND &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/BFUTILITIE"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            BFUTILITIE &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">2</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/1434"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Jhunjhunwala (Rekha Rakesh)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        31
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 40,743
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/TITAN"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TITAN &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/TATAMOTORS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TATAMOTORS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/TATAMTRDVR"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TATAMTRDVR &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/NCC"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            NCC &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/INDHOTEL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            INDHOTEL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/CRISIL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            CRISIL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/FORTIS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            FORTIS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/TATACOMM"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TATACOMM &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/STARHEALTH"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            STARHEALTH &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/ESCORTS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            ESCORTS &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">3</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/1613"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Azim Premji Trust
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        3
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 27,584
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/WIPRO"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            WIPRO &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/TIINDIA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TIINDIA &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/BALRAMCHIN"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            BALRAMCHIN &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">4</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/7451"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Jhunjhunwala (Rekha)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        9
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 6,858
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/METROBRAND"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            METROBRAND &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/FEDERALBNK"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            FEDERALBNK &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/CANBK"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            CANBK &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/APTECHT"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            APTECHT &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/RALLIS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            RALLIS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/SPARC"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            SPARC &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/539837"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            539837 &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/505729"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            505729 &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/AUTOIND"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            AUTOIND &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">5</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/1724"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Radheshyam (Jhunjhunwala Rakesh)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        4
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 6,594
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/TATAMOTORS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TATAMOTORS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/TATAMTRDVR"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TATAMTRDVR &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/NAZARA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            NAZARA &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/WOCKPHARMA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            WOCKPHARMA &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">6</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/8160"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Jhunjhunwala (Rakesh Radheshyam)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        8
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 6,480
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/STARHEALTH"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            STARHEALTH &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/ESCORTS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            ESCORTS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/KARURVYSYA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            KARURVYSYA &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/FEDERALBNK"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            FEDERALBNK &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/JUBLINGREA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            JUBLINGREA &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/DCAL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            DCAL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/ATFL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            ATFL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/APTECHT"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            APTECHT &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">7</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/7367"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Bhanshali (Akash)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        19
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 5,291
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/FLUOROCHEM"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            FLUOROCHEM &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/SCHNEIDER"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            SCHNEIDER &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/IDFC"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            IDFC &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/RKFORGE"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            RKFORGE &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/ARVINDFASN"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            ARVINDFASN &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/IWEL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            IWEL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/LAURUSLABS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            LAURUSLABS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/WELSPUNLIV"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            WELSPUNLIV &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/AMBER"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            AMBER &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/NATCOPHARM"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            NATCOPHARM &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">8</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/6793"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Dhawan (Ashish)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        14
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 4,388
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/GLENMARK"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            GLENMARK &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/IDFC"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            IDFC &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/M&MFIN"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            M&amp;MFIN &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/EQUITASBNK"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            EQUITASBNK &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/ARVINDFASN"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            ARVINDFASN &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/QUESS"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            QUESS &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/GREENLAM"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            GREENLAM &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/AGI"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            AGI &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/KARURVYSYA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            KARURVYSYA &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/BSOFT"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            BSOFT &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftPeer">9</td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            paddingLeft: 10
                                                        }}
                                                    >
                                                        <a
                                                            href="/Investor/12677"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Kacholia (Ashish)
            </a>
                                                    </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        49
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{
                                                            maxWidth: "100%",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ₹ 3,118
          </td>
                                                    <td
                                                        className="tabledata SubHeader"
                                                        style={{ textAlign: "left", paddingLeft: 10, whiteSpace: "nowrap" }}
                                                    >
                                                        <a
                                                            href="/Company/GRAVITA"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            GRAVITA &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/GRWRHITECH"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            GRWRHITECH &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/SAFARI"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            SAFARI &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/PCBL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            PCBL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/RAINBOW"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            RAINBOW &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/TARC"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            TARC &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/FCL"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            FCL &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/540205"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            540205 &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/AMIORG"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            AMIORG &nbsp; &nbsp;
            </a>
                                                        <a
                                                            href="/Company/YASHO"
                                                            target="_blank"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            YASHO &nbsp; &nbsp;
            </a>
                                                    </td>
                                                </tr>
                                                

                                            </tbody>
                                        </table>
                                    </div>
                                </>


                            </div>
                    </div>
                    <div className="rightHalf ContentSectionRightSix">
                            <div className="sixHeader" style={{paddingTop: 30}}>
                            <div className="RoundHeader">
                                6
                            </div>
                        </div>
                        <h2 className="sixHeader" style={{ textAlign: "left", color: "#36454f"}}>
                            Superinvestors
                        </h2>
                        <div className="ContentSectionText ContentSectionTextSix" style={{ textAlign: "left", lineHeight: 1.5}}>
                            Follow and Clone Superinvestors stock picks. <br /><br />
                            Pick the most skilled player in the game, study why he is so good, and then meticulously replicate his strategy with buying companies with strong MOAT, Management and MOS i.e., below intrinsic value.
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="ContentSection" style={{ backgroundColor: "#f8f8fc" }}>
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>
                <div className="ContentSectionContainerSix" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div className="leftHalf ContentSectionRightSix">
                        <div style={{ paddingLeft: "10px" }}>
                            <div className="RoundHeader">
                                7
                            </div>
                        </div>
                        <h2 style={{ textAlign: "left", color: "#36454f", paddingLeft: "10px" }}>
                            Compare stocks on 150+ attributes
                        </h2>
                        <div className="ContentSectionText ContentSectionTextSix" style={{ textAlign: "left"}}>
                            Dive deep into the stock market's treasure trove of information, where 150+ attributes become the map to compare these stocks, helping you make informed decisions that can shape your financial future. <br /><br />

                        </div>

                    </div>
                        <div className="rightHalf ContentSectionLeftSix" style={{paddingTop: "20px"}}>
                            <div style={{ textAlign: "left", border: "1px solid rgba(0, 0, 0, 0.5)", borderRadius: "25px", backgroundColor: "white", overflow: "hidden" }}>
                                <div className="HomeSectionSixImage">
                                    <div style={{ textAlign: "left" }}>
                                        <div style={{ paddingLeft: 30, paddingTop: 5 }}>
                                            <h3>Compare with Peers</h3>
                                        </div>
                                        <section
                                            className="Score"
                                            style={{
                                                border: "none",
                                                paddingLeft: 10,
                                                paddingRight: 20,
                                                marginTop: 0,
                                                paddingTop: 0
                                            }}
                                        >
                                            <div className="ScoreCompare">
                                                <div className="CompareRow">
                                                    <div
                                                        className="PeerSectionTable"
                                                        style={{
                                                            maxHeight: 550,
                                                            overflowY: "hidden",
                                                            paddingLeft: 10,
                                                            backgroundColor: "white"
                                                        }}
                                                    >
                                                        <table className="PeersTable finData" style={{ overflowX: "hidden", fontSize: "12px", lineHeight:1 }}>
                                                            <thead>
                                                                <tr style={{ backgroundColor: "white" }}>
                                                                    <th
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            textAlign: "left",
                                                                            paddingLeft: 10
                                                                        }}
                                                                    />
                                                                    <th
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            textAlign: "left",
                                                                            paddingLeft: 10
                                                                        }}
                                                                    >
                                                                        Tata Consultancy Services Ltd
                </th>
                                                                    <th
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            textAlign: "left",
                                                                            paddingLeft: 10
                                                                        }}
                                                                    >
                                                                        Infosys Ltd
                </th>
                                                                    <th
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            textAlign: "left",
                                                                            paddingLeft: 10
                                                                        }}
                                                                    >
                                                                        HCL Technologies Ltd
                </th>
                                                                    <th
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            textAlign: "left",
                                                                            paddingLeft: 10
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-start",
                                                                                paddingRight: 20
                                                                            }}
                                                                        >
                                                                            <div className="CompareDataAddon">+</div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th colSpan={5}>
                                                                        <div
                                                                            style={{
                                                                                fontSize: 13,
                                                                                display: "flex",
                                                                                alignContent: "start",
                                                                                gap: 10
                                                                            }}
                                                                        >
                                                                            <div>Performance</div>
                                                                            <div>
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={10}
                                                                                    height={10}
                                                                                    viewBox="0 0 122.88 119.8"
                                                                                    style={{ fill: "rgb(100, 149, 237)" }}
                                                                                >
                                                                                    <path d="M23.59,0h75.7a23.63,23.63,0,0,1,23.59,23.59V96.21A23.64,23.64,0,0,1,99.29,119.8H23.59a23.53,23.53,0,0,1-16.67-6.93l-.38-.42A23.49,23.49,0,0,1,0,96.21V23.59A23.63,23.63,0,0,1,23.59,0Zm59.7,53.51a6.39,6.39,0,1,1,0,12.77H39.59a6.39,6.39,0,1,1,0-12.77Zm16-40.74H23.59A10.86,10.86,0,0,0,12.77,23.59V96.21a10.77,10.77,0,0,0,2.9,7.37l.28.26A10.76,10.76,0,0,0,23.59,107h75.7a10.87,10.87,0,0,0,10.82-10.82V23.59A10.86,10.86,0,0,0,99.29,12.77Z" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td colSpan={5}>
                                                                        <div style={{ fontWeight: 600, paddingLeft: 10 }}>
                                                                            Company Profile
                  </div>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Sector</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        Information Technology
                </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        Information Technology
                </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        Information Technology
                </td>
                                                                    <td />
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Industry</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        IT Services
                </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        IT Services
                </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        IT Services
                </td>
                                                                    <td />
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Market CAP</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        13,94,970
                </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>6,38,197</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>3,90,492</td>
                                                                    <td />
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Current price</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>3,853.30</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>1,540.15</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>1,442</td>
                                                                    <td />
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Enterprise Value</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>
                                                                        13,59,534
                </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>6,19,200</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>3,67,828</td>
                                                                    <td />
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td colSpan={5}>
                                                                        <div style={{ fontWeight: 600, paddingLeft: 10 }}>
                                                                            Returns (CAGR)
                  </div>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Stock Price CAGR 1year</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>19.89%</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>21.68%</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>23.46%</td>
                                                                    <td />
                                                                </tr>
                                                                <tr style={{ display: "table-row" }}>
                                                                    <td>
                                                                        <div style={{ paddingLeft: 30 }}>Stock Price CAGR 3year</div>
                                                                    </td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>4.46%</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>-0.73%</td>
                                                                    <td style={{ textAlign: "left", paddingLeft: 20 }}>13.14%</td>
                                                                    <td />
                                                                </tr>
                                                                

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>


                                </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="ContentSection" style={{ paddingBottom: "90px" }}>
                <div className="shadowBlock" style={{ backgroundColor: "White", marginLeft: "100px", marginRight: "100px", borderRadius: "25px", padding: "30px 40px 30px 40px" }}>
                <div className="ContentSectionContainerSix" style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <div className="leftHalf ContentSectionLeftSix" style={{paddingTop: "20px"}}>

                        <div style={{ textAlign: "left", padding: "10px", border: "1px solid rgba(0, 0, 0, 0.5)", borderRadius: "25px", backgroundColor: "white" }}>
                                <div style={{width: "100%", height: "370px"}}>
                                    <div
                                        style={{
                                            paddingTop: 5,
                                            marginLeft: 36,
                                            marginRight: 40,
                                            width: "95%",
                                            marginBottom: 0
                                        }}
                                    >
                                        <section className="optionsFinData">
                                            <div className="leftAlign" style={{ fontSize: "14px", fontWeight:"600" }}>Commodity Price Trends</div>
                                            <div className="rightAlign">
                                                <div className="optionButtonPrimary">
                                                    <button style={{ fontSize: "12px"}}> View All Commodities</button>
                                                </div>
                                                <div className="optionButtonPrimary">
                                                    <button style={{ fontSize: "12px" }}> 8 Core Industries</button>
                                                </div>
                                                <div className="optionButtonPrimary">
                                                    <button style={{ fontSize: "12px" }}> View % Change</button>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div style={{ width: "250px", paddingTop: "10px" }}>
                                                <div className="Searchbox" style={{ fontSize: "12px" }}>
                                                    <AsyncSelect id="CompareSearchBoxHome" value={{ label: 'Search for a Commodity', value: 1 }} />
                                                </div>
                                            </div>
                                            <div>
                                                <Chart
                                                    options={commodity.options}
                                                    series={commodity.series}
                                                    type="bar"
                                                    width="100%"
                                                    height="300"

                                                />
                                            </div>
                                        </section>
                                    </div>


                                </div>
                        </div>
                    </div>
                    <div className="rightHalf ContentSectionRightSix">
                        <div className="sixHeader">
                            <div className="RoundHeader">
                                8
                            </div>
                        </div>
                        <h2 className="sixHeader" style={{ textAlign: "left", color: "#36454f" }}>
                                Commodity Price Trends
                        </h2>
                            <div className="ContentSectionText ContentSectionTextSix" style={{ textAlign: "left", lineHeight: 1.5 }}>
                            Explore the dynamic relationship between commodity price charts and stock price charts to optimize your equity positions and capitalize on emerging trends. 
                            <br /><br />
                            Commodity prices hold a profound influence on companies that produce or consume commodity, making it essential for investors to delve into this correlation for informed decision-making.
                        </div>
                    </div>
                    </div>
                    </div>
            </section>
        </>


    );
}

export default HomeContents;