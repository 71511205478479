import React, { useEffect } from 'react'; 
import './Terms.css'


function SomethingWrong(props) {
    window.scrollTo(0, 0)
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])
    return (
        <div className="SomethingWrong" style={{ paddingTop: "110px", textAlign: "center", paddingLeft: "300px", paddingRight: "300px", paddingBottom: "110px" }}>
            <div className="AboutUs-container" style={{ minHeight: "200px", color: "#888", paddingTop: "120px" }}>
                <h1>Oops, Something went Wrong. Please try again.</h1>
            </div>
        </div>
    );
}
export default SomethingWrong;