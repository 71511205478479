import styled from "tachyons-components";
import React from "react";
import ReactLoading from "react-loading";


const Section = styled('div')`
flex flex-wrap content-center justify-center w-100 h-100`;

const Article = styled('div')`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;


const list = [
    {
        prop: "spinningBubbles",
        name: "SpinningBubbles"
    }
];

const Loading = () => (
    <Section>
        {list.map(l => (
            <Article key={l.prop}>
                <ReactLoading type={l.prop} color="#999" />

            </Article>
        ))}
    </Section>
);

export default Loading;