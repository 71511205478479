import React, { useEffect } from "react";
import AsyncSelect from 'react-select/async';
import { components } from 'react-select'


function CommoditySearchbox(props) {
    console.log(props.commodityOptions)
    const filterCompany = (inputValue) => {
        return props.commodityOptions.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterCompany(inputValue));
            }, 1000);
        });

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <SearchIcon />
                </components.DropdownIndicator>
            )
        )
    }

    const SearchIcon = () => (
        <svg
            width={props.origin === "Home" ? "35" : "25"}
            height={props.origin === "Home" ? "35" : "25"}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
            <path
                d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
                fill="currentColor"
            />
        </svg>
    )



    useEffect(() => {
    }, [props.commodityOptions])

    return (
        <div className="Searchbox" style={{ fontSize: "14px"}}>
            <AsyncSelect id="CompareSearchBox" components={{ DropdownIndicator, IndicatorSeparator: () => null }} cacheOptions defaultOptions loadOptions={promiseOptions} onChange={(value) => props.updateOption(value) } value={{ label: 'Search for a Commodity', value: 1 }} />
        </div>
    );
}

export default CommoditySearchbox