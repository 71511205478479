import React, { useState, useEffect }  from 'react';
import useAuth from '../../hooks/useAuth';
import HelpModal from '../../Company/Summary/HelpModal'
import ManageRatios from '../ManageRatios/ManageRatiosMobile'



function CustomRatios(props) {
    const { auth, setAuth } = useAuth();
    const [show, setShow] = useState(false);
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [restrictCount, setRestrictCount] = useState(10);
    const [disableButton, setDisableButton] = useState(true);
    const [viewLoading, setViewLoading] = useState(false)


    

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, ratios: itemsData.ratios }))
        }
        setShow(false)
    }

    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }
    const showManageRatios = () => {
        setShow(true)

    }

    useEffect(() => {
        if (auth.roles === true) {
            setRestrictCount(100)
        } else {
            setRestrictCount(10)
        }

    }, [auth.ratios])


    return (
        <div style={{ paddingTop: "10px", paddingLeft: "10px" }} >
            <div style={{ fontWeight: "600", fontSize: "14px", textAlign: "left", display: "flex", justifyContent: "space-between" }}>
                <div style={{ paddingTop: "5px" }}>Custom Ratios</div>
                <div className="optionButtonMobile" onClick={() => showManageRatios()}> Manage&nbsp;Your&nbsp;Ratios</div>
                <HelpModal title="Manage Your Ratios" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} disableButton={disableButton}>
                    <ManageRatios ratioData={props.ratioData} source="ratio" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton}/>
                </HelpModal>
            </div>
            <div style={{ paddingTop: "50px", fontSize: "13px" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {auth.ratios.map(item => (

                <div style={{ padding: "5px 15px", margin: "5px", display: "flex", justifyContent: "flex-start", width: "max-content", backgroundColor: "rgb(248, 248, 252)", borderRadius: "15px" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "auto", maxWidth: "500px" }}>
                            <div style={{ paddingTop: "2px" }}>{getDisplayValueAndPrompt(item)[1]}</div>
                    </div>
                        {/* <div style={{ width: "max-content", marginLeft: "10px", padding: "0px 5px", cursor: "pointer" }}>
                            &#10006;
                    </div>*/}
                </div>
                    
                ))}
                </div>
            </div>
        </div>
    );
}
export default CustomRatios;