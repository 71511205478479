import React from 'react';

function Verified(props) {

    const colorIndicator = (ind) => {
        if (ind === "false") {
            return false
        } else {
            return true
        }
    }
    const getIconPathData = () => {
        return "M182.8,46.4c5.4,3.6,10.4,7.8,14.9,12.3l16.9-9.5c8,6.5,13.5,14.2,16.5,23l-14.8,12.5c1.4,2.8,2.6,5.7,3.7,8.6c1.2,3.1,2.2,6.2,3,9.4l19.3,0.2c3.7,9.6,4.6,19,2.8,28.2l-19,3.5c-0.4,6.4-1.5,12.7-3.2,19l16.6,9.9c-1.6,10.1-5.5,18.7-11.7,25.8l-18.2-6.5c-3.6,5.4-7.7,10.4-12.3,14.9l9.5,16.8c-6.5,8-14.2,13.5-23,16.5l-12.5-14.8c-2.8,1.4-5.7,2.6-8.6,3.7c-3.1,1.2-6.2,2.2-9.4,3l-0.2,19.4c-9.6,3.7-19,4.6-28.2,2.8l-3.5-19c-6.3-0.4-12.7-1.5-19-3.2l-9.8,16.6c-10.2-1.6-18.8-5.5-25.8-11.7l6.5-18.2c-5.4-3.6-10.4-7.7-14.9-12.3l-16.9,9.5c-8-6.5-13.5-14.2-16.5-23l14.8-12.5c-1.4-2.8-2.6-5.7-3.7-8.6c-1.2-3.1-2.2-6.3-3-9.4L13.7,153c-3.7-9.6-4.6-19-2.8-28.1l19-3.5c0.4-6.3,1.5-12.7,3.2-19l-16.6-9.9c1.6-10.2,5.5-18.7,11.7-25.8l18.2,6.5c3.6-5.4,7.8-10.4,12.3-14.9l-9.5-16.9c6.5-8,14.2-13.5,23-16.5l12.5,14.8c2.8-1.4,5.7-2.6,8.6-3.7c3.1-1.2,6.2-2.2,9.4-3l0.2-19.3c9.6-3.7,19-4.6,28.1-2.8l3.5,19c6.4,0.4,12.7,1.5,19,3.2l9.9-16.6c10.1,1.6,18.7,5.5,25.8,11.7L182.8,46.4L182.8,46.4z M188.8,105.1c-5.9-15.6-17.7-28.9-34-36.3c-16.3-7.4-34.1-7.5-49.7-1.6c-15.5,5.9-28.8,17.6-36.2,34c-7.4,16.3-7.5,34.1-1.6,49.7c5.9,15.5,17.6,28.8,34,36.2c16.3,7.4,34.1,7.5,49.7,1.6c15.6-5.8,28.9-17.6,36.2-34C194.5,138.4,194.6,120.6,188.8,105.1z"
       
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 256 256"
            style={{ fill: colorIndicator(props.ind) ? "#0000FF" : "#000000" }}>
            <path d={getIconPathData()}></path>
        </svg>
    )
}
export default Verified;