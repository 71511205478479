import React from 'react';


function DisclaimerMobile() {
    window.scrollTo(0, 0);
    document.title = "Nivesh Karma - Disclaimer";
    return (
        <div className="Disclaimer commonContainer">
            <div className="AboutUs-container">
                <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Disclaimer</div>
                <p style={{ fontSize: "13px" }}>Although the material contained in this website was prepared based on information from public
                and private sources that Nivesh Karma believes to be reliable, no representation,
                warranty or undertaking, stated or implied, is given as to the accuracy of the information contained herein,
                and Nivesh Karma expressly disclaims any liability for the accuracy and completeness of
            information contained in this website.</p>
                <p style={{ fontSize: "13px" }}>
                    This website is distributed for general informational and educational purposes only
                    and is not intended to constitute legal, tax, accounting or investment advice.
                    The information, opinions and views contained herein have not been tailored to
                    the investment objectives of any one individual, are current only as of the date
                    hereof and may be subject to change at any time without prior notice.
                    Nivesh Karma does not have any obligation to provide revised opinions in the
                    event of changed circumstances. All investment strategies and investments involve risk of loss.
                    Nothing contained in this website should be construed as investment advice.
                    Any reference to an investment's past or potential performance is not, and should
                    not be construed as, a recommendation or as a guarantee of any specific outcome or profit.
            </p>
            </div>
        </div>
    );
}
export default DisclaimerMobile;