import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './Company.css'
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import CompanySummary from './CompanySummary'
import CorporateActions from './CorporateActions'
import Financials from './Financials'
import Holdings from './Holdings'
import Peers from './Peers'
import Ratios from './Ratios'
import Score from './Score' 
import Valuations from './Valuations'
import Charts from './Charts'
import Growth from './Growth'
import ScoreCompareWrapper from "./ScoreCompareWrapper"

function Company(props) {
    const [pageData, setpageData] = useState({});
    const { companyName } = useParams();
    const [optionSelected, setValue] = useState('Summary');
    const [companySymbol, setcompanySymbol] = useState('');
    const [MaincompanyName, setmaincompanyName] = useState("");
    if (companyName != companySymbol) {
        setcompanySymbol(companyName)
    }
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/compare-company-list/" + companySymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
      //  document.title = cdresponse.data.stock_name + "- 3M Score | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes | Investing checklist. ";
        setpageData(cdresponse.data)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            companyDetails()
            if (!props.showSearch) {
                props.setShowSearch(true)
            }
        }, 50)
        return () => clearTimeout(timeout)
    }, [companySymbol])

    return (
        <div className="Company" style={{ backgroundColor: "hsl(245, 40%, 98%)" }}>
            <section id="mainDetails" style={{ backgroundColor: "hsl(245, 40%, 98%)" }}>
                <div className="CompanyNavContainer" style={{ backgroundColor: "hsl(245, 40%, 98%)", width: "100%"}}>
                    <div className="CompanyNav">
                        <div className="CompanyMenu" id="Summary" onClick={() => {
                            setValue('Summary')
                        }
                        } style={optionSelected === "Summary" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }} >Summary</div>
                        <div className="CompanyMenu" id="Financials" onClick={() => {
                            setValue('Financials')
                        }
                        } style={optionSelected === "Financials" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b'  }}>Financials</div>
                        <div className="CompanyMenu" id="Ratios" onClick={() => {
                            setValue('Ratios')
                        }
                        } style={optionSelected === "Ratios" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b'  }}>Ratios</div>
                        <div className="CompanyMenu" id="Valuations" onClick={() => {
                            setValue('Growth')
                        }
                        } style={optionSelected === "Growth" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b'  }}>Growth</div>
                        <div className="CompanyMenu" id="Valuations" onClick={() => {
                            setValue('Valuations')
                        }
                        } style={optionSelected === "Valuations" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b'  }}>Valuations</div>
                        <div className="CompanyMenu" id="Score" onClick={() => {
                            setValue('Score')
                        }
                        } style={optionSelected === "Score" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>3M&nbsp;Score</div>
                        <div className="CompanyMenu" id="PeerComp" onClick={() => {
                            setValue('PeerComp')
                        }
                        } style={optionSelected === "PeerComp" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>Peer&nbsp;Comparison</div>
                        <div className="CompanyMenu" id="CorporateActions" onClick={() => {
                            setValue('CorporateActions')
                        }
                        } style={optionSelected === "CorporateActions" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>Corporate&nbsp;Actions</div>
                        {/*<div className="CompanyMenu" id="Charts" onClick={() => {
                            setValue('Charts')
                            togglesummary(false)
                            togglefinancials(false)
                            toggleratios(false)
                            togglevaluations(false)
                            togglescore(false)
                            togglecorporateActions(false)
                            togglecharts(true)
                            togglepeers(false)
                            toggleholdings(false)
                        }
                        } style={charts ? { fontWeight: 'bold', color: '#4169e1' } : { fontWeight: 'bold' }}>Charts</div>*/}
                        <div className="CompanyMenu" id="Peers" onClick={() => {
                            setValue('Peers')
                        }
                        } style={optionSelected === "Peers" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b'  }}>Peers</div>
                        <div className="CompanyMenu" id="Holdings" onClick={() => {
                            setValue('Holdings')
                        }
                        } style={optionSelected === "Holdings" ? { backgroundColor: '#000', color: '#fff' } : { color: '#3e424b' }}>Holdings</div>
                        <div>&nbsp;</div>
                    </div>
                </div>

                

                <div id="mainContentSection">
                    {optionSelected === 'Summary' &&
                        <CompanySummary compSymbol={companySymbol} setmaincompanyName={setmaincompanyName} compName={MaincompanyName} ratioData={props.ratioData}/>
                    
                    }
                    {optionSelected === 'Financials' &&
                        <Financials compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'Ratios' &&
                        <Ratios compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'Valuations' &&
                        <Valuations compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'Score' &&
                        <Score compSymbol={companySymbol}/>
                    }
                    {optionSelected === "PeerComp" &&
                        <ScoreCompareWrapper compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'CorporateActions' &&
                        <CorporateActions compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'Charts' &&
                        <Charts compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'Peers' &&
                        <Peers compSymbol={companySymbol} ratioData={props.ratioData}/>
                    }
                    {optionSelected === 'Holdings' &&
                        <Holdings compSymbol={companySymbol}/>
                    }
                    {optionSelected === 'Growth' &&
                        <Growth compSymbol={companySymbol} />
                    }
                </div>
            </section>
        </div>
    )
}


export default Company;