import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Loading from './Loading'

function Commodities(props) {
    const [pageData, setpageData] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const CommodityList = async () => {
        setpageData([])
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/commodities-list?category=monthly_index&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        //document.title = cdresponse.data.stock_name + "- 3M Score | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes | Investing checklist. ";
        setpageData(cdresponse.data.data)
    }

    const updateSelection = (item, name) => {
        props.setCommoditySymbol({ label: name, value: item })
        props.setUserSelection("Default")
    }

    useEffect(() => {
        CommodityList()
    }, [])

    return (
        <div style={{ paddingBottom: "110px" }}>
            {pageData.length > 0 &&
                <p>
                {pageData.map(item => (
                    <div className="CommodityElement" onClick={() => updateSelection(item.code, item.name) }>
                        {item.name}
                    </div>
                ))
                }
                </p>
            }
            {pageData.length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default Commodities                                                  