import React, { useState, useEffect } from 'react';
import "./eps.css"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Chart from "react-apexcharts";
import Icon from "../../icon/Icon"
import Loading from "./Loading"
import ProfileData from "../../context/ProfileData"

export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};


function Eps(props) {
    let userData = ProfileData.getProfileInfo(props.compSymbol)
    if (!userData || userData.company !== props.compSymbol) {
        userData = { avgEPS: 0, assumedGRFiveYear: 0, assumedGRFiveTenYear: 0, discountRate: 0, forwardPE: 0}
    }
    const [pageData, setpageData] = useState({
        "price": 0,
        "mos": 0,
        "pvRatio": 0,
        "avgEPS": userData.avgEPS,
        "assumedGRFiveYear": userData.assumedGRFiveYear,
        "assumedGRFiveTenYear": userData.assumedGRFiveTenYear,
        "discountRate": userData.discountRate,
        "forwardPE": userData.forwardPE,
        "futureEPS": 0,
        "futureSharePrice": 0,
        "shareOutstanding": 0,
        "epsInput": [],
        "valueOfFirm": 0,
        "marketCap": 0,
        "ivps": 0,
        "currentYear": 0,
        "mapData": [],

    });
    
    const axiosPrivate = useAxiosPrivate();
    const getColor = (data) => {
        let tempData = parseFloat(data)
        if (tempData < 10.0) {
            return "#FF5733"
        } else if (tempData >= 5.0 && tempData < 30.0) {
            return "#E49B0F"
        } else {
            return "#00A36C"
        }
    }

    function getDisplayValue(data) {
        if (data) {
            return (Math.round(data * 100) / 100).toLocaleString('en-IN')
        } else {
            return ""
        }
    }
    const forceDefault = (data) => {
        if (data) {
            return data
        } else {
            return 0.0
        }
    }

    const handlePlus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedGRFiveYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedGRFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedGRFiveTenYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const handleMinus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedGRFiveYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedGRFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedGRFiveTenYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const evaluateEPS = () => {
        ProfileData.setProfileInfo(
            {
                company: props.compSymbol,
                avgEPS: pageData.avgEPS,
                assumedGRFiveYear: pageData.assumedGRFiveYear,
                assumedGRFiveTenYear: pageData.assumedGRFiveTenYear,
                discountRate: pageData.discountRate,
                forwardPE: pageData.forwardPE
            }, props.compSymbol)

        let var_price = parseFloat(pageData.price)
        let rate = 0
        let lastEPS = pageData.avgEPS
        let outputfutureEPS = 0
        let varEPSInput = [{ "year": "Current", "earning": lastEPS, "GrowthRate": "" }, { "year": "+1", "earning": "", "GrowthRate": "" }, { "year": "+2", "earning": "", "GrowthRate": "" }, { "year": "+3", "earning": "", "GrowthRate": "" }, { "year": "+4", "earning": "", "GrowthRate": "" }, { "year": "+5", "earning": "", "GrowthRate": "" }, { "year": "+6", "earning": "", "GrowthRate": "" }, { "year": "+7", "earning": "", "GrowthRate": "" }, { "year": "+8", "earning": "", "GrowthRate": "" }, { "year": "+9", "earning": "", "GrowthRate": "" }, { "year": "+10", "earning": "", "GrowthRate": "" }]
        let outputfutureSharePrice = 0
        var currentTime = new Date()
        let var_current_year = currentTime.getFullYear()
        let varMapData = [Math.round(lastEPS)]
        let varLabel = [String(var_current_year)]
        varEPSInput.forEach((epsData, epsIndex) => {
            if (epsIndex >= 1) {
                if (epsIndex < 6) {
                    rate = pageData.assumedGRFiveYear
                } else {
                    rate = pageData.assumedGRFiveTenYear
                }               
                let varEPS = lastEPS * (1 + (rate / 100))
                let finalRate = rate
                lastEPS = varEPS
                varMapData.push(Math.round(lastEPS))
                varLabel.push(String(var_current_year + epsIndex))
                epsData.earning = lastEPS
                epsData.GrowthRate = parseFloat(finalRate).toFixed(2)

            }
        })

        let varEarning = lastEPS
        let futureSharePrice = Math.round(varEarning * pageData.forwardPE)
        let varivps = Math.round(futureSharePrice / Math.pow((1 + pageData.discountRate / 100), (10)))
        let varpvRatio = Math.round((varivps / var_price) * 100) / 100
        let mos = ""
        console.log(varivps)
        if (varivps > 0.0) {
            let tempVal = ((varivps - var_price) / varivps) * 100
            mos = tempVal.toString()
        } else {
            mos = 0
        }
        let updatedValue = {};
        updatedValue = {
            "mos": mos,
            "epsInput": varEPSInput,
            "futureEPS": varEarning,
            "futureSharePrice": futureSharePrice,
            "valueOfFirm": '',
            "ivps": varivps,
            "pvRatio": varpvRatio,
            "mapData": {
                options: {
                    chart: { id: "eps-chart" },
                    colors: ['#9cb2cf', '#C9D8E9'],
                    dataLabels: { enabled: false },
                    xaxis: { categories: varLabel },
                    yaxis: [{ forceNiceScale: true }]

                },
                series: [{ name: "Future EPS Projection", data: varMapData }]
            }
        };
        
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));

    }

    const handleRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedGRFiveYear": e.target.value};
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleTenRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedGRFiveTenYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDiscountRateChange = e => {
        let updatedValue = {};
        updatedValue = { "discountRate": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };


    const EPSChange = e => {
        let updatedValue = {};
        updatedValue = { "avgEPS": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };

    const forwardPEChange = e => {
        let updatedValue = {};
        updatedValue = { "forwardPE": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };

    function getDefaultValue(valueOne, ValueTwo) {
        if (!userData || userData.company !== props.compSymbol) {
            return ValueTwo
        } else {
            return valueOne
        }
    }
    const getDefaultInput = async (ind) => {
        setpageData({
            "price": 0,
            "mos": 0,
            "pvRatio": 0,
            "avgEPS": 0,
            "assumedGRFiveYear": 0,
            "assumedGRFiveTenYear": 0,
            "discountRate": 0,
            "forwardPE": 0,
            "futureEPS": 0,
            "futureSharePrice": 0,
            "shareOutstanding": 0,
            "epsInput": [],
            "valueOfFirm": 0,
            "marketCap": 0,
            "ivps": 0,
            "currentYear": 0,
            "mapData": [],

        })
        if (ind === "r") {
            let url = ""
            url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const cdresponse = await axiosPrivate.get(url);
            let rate = 0
            let lastEPS = cdresponse.data.valuation_ratio.VR006
            let outputfutureEPS = 0
            let varEPSInput = [{ "year": "Current", "earning": lastEPS, "GrowthRate": "" }, { "year": "+1", "earning": "", "GrowthRate": "" }, { "year": "+2", "earning": "", "GrowthRate": "" }, { "year": "+3", "earning": "", "GrowthRate": "" }, { "year": "+4", "earning": "", "GrowthRate": "" }, { "year": "+5", "earning": "", "GrowthRate": "" }, { "year": "+6", "earning": "", "GrowthRate": "" }, { "year": "+7", "earning": "", "GrowthRate": "" }, { "year": "+8", "earning": "", "GrowthRate": "" }, { "year": "+9", "earning": "", "GrowthRate": "" }, { "year": "+10", "earning": "", "GrowthRate": "" }]
            let outputfutureSharePrice = 0
            var currentTime = new Date()
            let var_current_year = currentTime.getFullYear()
            let varMapData = [Math.round(lastEPS)]
            let varLabel = [String(var_current_year)]
            varEPSInput.forEach((epsData, epsIndex) => {
                if (epsIndex >= 1) {
                    if (epsIndex < 6) {
                        rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR003))
                    } else {
                        rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR004))
                    }
                    let varEPS = lastEPS * (1 + (rate / 100))
                    let finalRate = rate
                    lastEPS = varEPS
                    varMapData.push(Math.round(lastEPS))
                    varLabel.push(String(var_current_year + epsIndex))
                    epsData.earning = lastEPS
                    epsData.GrowthRate = finalRate.toFixed(2)

                }
            })
            let var_price = parseFloat(cdresponse.data.close_price)
            let varEarning = lastEPS
            let futureSharePrice = varEarning * parseFloat(cdresponse.data.valuation_ratio.VR007)
            let varivps = futureSharePrice / Math.pow((1 + parseFloat(cdresponse.data.valuation_ratio.VR010) / 100), (10))
            let varpvRatio = Math.round((varivps / var_price) * 100) / 100
            let mos = ""
            if (varivps > 0.0) {
                let tempVal = ((varivps - var_price) / varivps) * 100
                mos = tempVal.toString()
            } else {
                mos = 0
            }
            setpageData({
                "price": Math.round(var_price),
                "mos": mos,
                "pvRatio": varpvRatio,
                "avgEPS": cdresponse.data.valuation_ratio.VR006,
                "assumedGRFiveYear": parseFloat(cdresponse.data.valuation_ratio.VR003),
                "assumedGRFiveTenYear": parseFloat(cdresponse.data.valuation_ratio.VR004),
                "discountRate": parseFloat(cdresponse.data.valuation_ratio.VR010),
                "forwardPE": parseFloat(Math.round(cdresponse.data.valuation_ratio.VR007)),
                "futureEPS": varEarning,
                "futureSharePrice": Math.round(futureSharePrice),
                "shareOutstanding": 0,
                "epsInput": varEPSInput,
                "valueOfFirm": 0,
                "marketCap": 0,
                "ivps": Math.round(varivps),
                "currentYear": 0,
                "mapData": {
                    options: {
                        chart: { id: "eps-chart" },
                        colors: ['#9cb2cf', '#C9D8E9'],
                        dataLabels: { enabled: false },
                        xaxis: { categories: varLabel },
                        yaxis: [{ forceNiceScale: true }]

                    },
                    series: [{ name: "Future EPS Projection", data: varMapData }]
                }

            })
        } else {
            let url = ""
            url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const cdresponse = await axiosPrivate.get(url);


            let rate = 0
            let lastEPS = getDefaultValue(userData.avgEPS, cdresponse.data.valuation_ratio.VR006)
            let outputfutureEPS = 0
            let varEPSInput = [{ "year": "Current", "earning": lastEPS, "GrowthRate": "" }, { "year": "+1", "earning": "", "GrowthRate": "" }, { "year": "+2", "earning": "", "GrowthRate": "" }, { "year": "+3", "earning": "", "GrowthRate": "" }, { "year": "+4", "earning": "", "GrowthRate": "" }, { "year": "+5", "earning": "", "GrowthRate": "" }, { "year": "+6", "earning": "", "GrowthRate": "" }, { "year": "+7", "earning": "", "GrowthRate": "" }, { "year": "+8", "earning": "", "GrowthRate": "" }, { "year": "+9", "earning": "", "GrowthRate": "" }, { "year": "+10", "earning": "", "GrowthRate": "" }]
            let outputfutureSharePrice = 0
            var currentTime = new Date()
            let var_current_year = currentTime.getFullYear()
            let varMapData = [Math.round(lastEPS)]
            let varLabel = [String(var_current_year)]
            varEPSInput.forEach((epsData, epsIndex) => {
                if (epsIndex >= 1) {
                    if (epsIndex < 6) {
                        rate = forceDefault(parseFloat(getDefaultValue(userData.assumedGRFiveYear,cdresponse.data.valuation_ratio.VR003)))
                    } else {
                        rate = forceDefault(parseFloat(getDefaultValue(userData.assumedGRFiveTenYear,cdresponse.data.valuation_ratio.VR004)))
                    }
                    let varEPS = lastEPS * (1 + (rate / 100))
                    let finalRate = rate
                    lastEPS = varEPS
                    varMapData.push(Math.round(lastEPS))
                    varLabel.push(String(var_current_year + epsIndex))
                    epsData.earning = lastEPS
                    epsData.GrowthRate = finalRate.toFixed(2)

                }
            })
            let var_price = parseFloat(cdresponse.data.close_price)
            let varEarning = lastEPS
            let futureSharePrice = varEarning * parseFloat(getDefaultValue(userData.forwardPE,cdresponse.data.valuation_ratio.VR007))
            let varivps = futureSharePrice / Math.pow((1 + parseFloat(getDefaultValue(userData.discountRate,cdresponse.data.valuation_ratio.VR010)) / 100), (10))
            let varpvRatio = Math.round((varivps / var_price) * 100) / 100
            let mos = ""
            if (varivps > 0.0) {
                let tempVal = ((varivps - var_price) / varivps) * 100
                mos = tempVal.toString()
            } else {
                mos = 0
            }
            setpageData({
                "price": Math.round(var_price),
                "mos": mos,
                "pvRatio": varpvRatio,
                "avgEPS": getDefaultValue(userData.avgEPS,cdresponse.data.valuation_ratio.VR006),
                    "assumedGRFiveYear": parseFloat(getDefaultValue(userData.assumedGRFiveYear,cdresponse.data.valuation_ratio.VR003)),
                        "assumedGRFiveTenYear": parseFloat(getDefaultValue(userData.assumedGRFiveTenYear,cdresponse.data.valuation_ratio.VR004)),
                            "discountRate": parseFloat(getDefaultValue(userData.discountRate,cdresponse.data.valuation_ratio.VR010)),
                                "forwardPE": parseFloat(Math.round(getDefaultValue(userData.forwardPE,cdresponse.data.valuation_ratio.VR007))),
                "futureEPS": varEarning,
                "futureSharePrice": Math.round(futureSharePrice),
                "shareOutstanding": 0,
                "epsInput": varEPSInput,
                "valueOfFirm": 0,
                "marketCap": 0,
                "ivps": Math.round(varivps),
                "currentYear": 0,
                "mapData": {
                    options: {
                        chart: { id: "eps-chart" },
                        colors: ['#9cb2cf', '#C9D8E9'],
                        dataLabels: { enabled: false },
                        xaxis: { categories: varLabel},
                        yaxis: [{forceNiceScale: true}]
                        
                    },
                    series: [{ name: "Future EPS Projection", data: varMapData}]
                }

            })
        }
    }

    useEffect(() => {
        getDefaultInput("i")
    }, [props.compSymbol])

    return (
        <>{pageData.price !== 0 &&
            <>
            <div className="ValuationRow" style={{ marginTop: "20px" }}>
                <div className="halfQuarterOne shadowBlock" style={{ padding: "20px 10px", borderRadius: "10px", marginBottom: "110px" }}>
                        <div className="ValuationOddDivEPS"><div className="leftIntend middle">Initial Earnings per Share (EPS):  </div><div className="userInput"><div className="paddedInput grey leftRounded">₹</div><div className="paddedUserInput"><input type="number" className="customInput" placeholder="Base EPS" value={pageData.avgEPS} onChange={EPSChange} /></div><div className="paddedInput grey rightRounded"></div></div></div>
                        <div className="ValuationEvenDivEPS"><div className="leftIntend middle">Growth Rate (1 - 5 Years): </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.assumedGRFiveYear}</div><div className="userButton" onClick={() => handleMinus("grfive")}><Icon source="minus" /></div><div className="paddedUserInput"><input type="range" className="customInput" min="1" max="30" step="0.5" value={pageData.assumedGRFiveYear} onChange={handleRateChange} /></div><div className="userButton" onClick={() => handlePlus("grfive")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                        <div className="ValuationOddDivEPS"><div className="leftIntend middle">Growth Rate (6 - 10 Years): </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.assumedGRFiveTenYear}</div><div className="userButton" onClick={() => handleMinus("grten")}><Icon source="minus" /></div><div className="paddedUserInput"><input type="range" className="customInput" min="1" max="30" step="0.5" value={pageData.assumedGRFiveTenYear} onChange={handleTenRateChange} /></div><div className="userButton" onClick={() => handlePlus("grten")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                        <div className="ValuationEvenDivEPS"><div className="leftIntend middle">Discount Rate: </div><div className="userInput"><div className="paddedInput grey leftRounded">{pageData.discountRate}</div><div className="userButton" onClick={() => handleMinus("discRate")}><Icon source="minus" /></div><div className="paddedUserInput"><input type="range" className="customInput" min="1" max="30" step="0.5" value={pageData.discountRate} onChange={handleDiscountRateChange} /></div><div className="userButton" onClick={() => handlePlus("discRate")}><Icon source="plus" /></div><div className="paddedInput grey rightRounded">%</div></div></div>
                        <div className="ValuationOddDivEPS"><div className="leftIntend middle">Estimated Future PE: </div><div className="userInput"><div className="paddedInput grey leftRounded"></div><div className="paddedUserInput"><input type="number" className="customInput" placeholder="Future PE" value={pageData.forwardPE} onChange={forwardPEChange} /></div><div className="paddedInput grey rightRounded"></div></div></div>

                        <div className="optionButtonPrimary topMarginEPS">
                            <div className="optionButtonVal" onClick={evaluateEPS}> Calculate EPS</div>
                            <div className="optionButtonVal" onClick={() => getDefaultInput("r")}> Reset</div>
                        </div>
                    </div>
                    <div className="halfQuarterTwo">
                        <div className="ValuationRowInner">
                        <div className="halfQuarter shadowBlock" style={{ padding: "10px 0px", borderRadius: "10px" }}>
                                <div className="ValuationOddDivS"><div className="leftIntend middle">Projected EPS after 10 years:  </div><div className="paddedUserOutput"><b>{getDisplayValue(pageData.futureEPS)}</b></div></div>
                                <div className="ValuationEvenDivS"><div className="leftIntend middle"> Current Price: </div><div className="paddedUserOutput"><b>{getDisplayValue(pageData.price)}</b></div></div>
                                <div className="ValuationOddDivS"><div className="leftIntend middle">Margin of Saftey:  </div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos) }}><b>{getDisplayValue(pageData.mos)}%</b></div></div>
                            </div>
                        <div className="halfQuarter shadowBlock" style={{ padding: "10px 0px", borderRadius: "10px" }}>
                                <div className="ValuationOddDivS"><div className="leftIntend middle">Projected stock price after 10 years:  </div><div className="paddedUserOutput"><b>{getDisplayValue(pageData.futureSharePrice)}</b></div></div>
                            <div className="ValuationEvenDivS"><div className="leftIntend middle">Intrinsic Value: </div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos) }}><b>{getDisplayValue(pageData.ivps)}</b></div></div>
                                <div className="ValuationOddDivS"><div className="leftIntend middle">Price/Value: </div><div className="paddedUserOutput" style={{ color: getColor(pageData.mos) }}><b>{getDisplayValue(pageData.pvRatio)}</b></div></div>
                            </div>
                        </div>
                        <div className="ValuationRowInnerI">
                        <div className="FullQuarter borderEnclosed shadowBlock" style={{ borderRadius: "10px" }}>
                                <div className="headerChart" style={{ paddingLeft: "20px" }}>Future EPS Estimation</div>
                                <div className="valCharts">
                                   
                                <Chart
                                    options={pageData.mapData.options}
                                    series={pageData.mapData.series}
                                    type="bar"
                                    width="100%"
                                    height="150"
                                /> 
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            <div className="ValuationRow" style={{ paddingTop: "20px", marginTop: "-100px"  }}>
                <div className="FullQuarter shadowBlock" style={{ backgroundColor: "white", borderRadius: "10px", padding: '5px 10px'}}>
                        <h3 class="leftHeading" style={{ color: "#606f7b" }}>EPS Model</h3>
                        <table className="finData valuationResult">
                            <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Year
                            </td >
                                {
                                    pageData.epsInput.map(data => (
                                        <td className="rightIntend bold" style={{ color: "#606f7b" }}>{data["year"]}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Earning
                            </td>
                                {
                                    pageData.epsInput.map(data => (
                                        <td className="rightIntend">{getDisplayValue(data["earning"])}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b" }}>
                                    Growth Rate
                            </td>
                                {
                                    pageData.epsInput.map(data => (
                                        <td className="rightIntend">{data["GrowthRate"]}</td>
                                    ))
                                }
                            </tr>
                        </table>
                </div>
                
            </div>
            <div style={{ textAlign: "left", color: "grey", fontSize: "14px", paddingTop: "10px", marginBottom: "110px"  }}>
                **This data has been generated by a machine. It is necessary to adjust the required inputs for
                business valuation based on your estimation of future growth.
            </div>
            </>
        }{
                pageData.price === 0 &&
                <Loading />
            }
        </>
    );
}

export default Eps;
