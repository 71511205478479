import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import Loading from "../Company/Loading"


function WatchlistCompaniesMobile(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();

    const getUrl = (url) => {
        return "/Company/" + url
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }
    const runQuery = async () => {
        setpageData({})
        if (props.watchlistName) {
            let url = "https://api.niveshkarma.com/api/v1/user-watchlist?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY + "&list_name=" + props.watchlistName;

            const response = await axiosPrivate.get(url);
            console.log(response.data)
            setpageData({ "data": response.data })
        }
    }

    const handleChange = async (event, stock) => {
        console.log(stock)
        setpageData({})
        let tempData = []
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        if (pageData.data.peers.length > 0) {
            for (let i = 0; i < pageData.data.peers.length; i++) {
                if (pageData.data.peers[i]["stock_symbol"] !== stock) {
                    tempData.push(pageData.data.peers[i]["stock_symbol"])
                }
            }
            const response = await axiosPrivate.put(url, { list_name: props.watchlistName, watch_list: tempData });
            runQuery()
        }
    }






    function isStockAvailable(stock) {
        console.log(stock)
        if (pageData.data.peers.length > 0) {
            for (let i = 0; i < pageData.data.peers.length; i++) {
                if (pageData.data.peers[i]["stock_symbol"] === stock) {
                    return true
                }
            }
        }
        return false
    }



    useEffect(() => {
        const timeout = setTimeout(() => {
            runQuery()
        }, 50)
        return () => clearTimeout(timeout)
    }, [props.watchlistName])

    return (
        <>

            <div className="WatchlistCompanies">
                {Object.keys(pageData).length !== 0 &&
                    <>
                        {pageData.data.peers.length !== 0 &&
                            <div className="Ratios">
                                <table className="PeersTable finData">
                                    <tbody>
                                        <tr>
                                            <td className="leftPeer"></td>
                                            <td className="leftPeer" style={{ fontWeight: "600", fontSize: "13px", whiteSpace: "nowrap" }}>S.&nbsp;No.</td>
                                            <td className="leftPeer" style={{ fontWeight: "600", fontSize: "13px", whiteSpace: "nowrap" }}>Company Name</td>

                                            {pageData?.data?.keys.map(item => (
                                                <td className="columnHeadingScreener">
                                                    <Popup
                                                        className="popupChart"
                                                        trigger={<div style={{ cursor: "pointer" }}>{getDisplayValueAndPrompt(item)[0]}</div>}
                                                        content={<div className="borderedContent" style={{ padding: "5px", border: "2px solid black" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                        style={{ zIndex: "999" }}
                                                    />
                                                </td>
                                            ))

                                            }

                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {pageData?.data?.peers.map((dataKey, index) => (
                                            <tr>
                                                <td className="leftPeer">
                                                    <label className="switchWatchlist">
                                                        <input type="checkbox" defaultChecked={isStockAvailable(dataKey.stock_symbol)} onChange={(e) => handleChange(e.target.checked, dataKey.stock_symbol)} />
                                                        <span className="sliderWatchlist roundWatchlist"></span>
                                                    </label>
                                                </td>
                                                <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>{index + 1}</td>
                                                <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                                {pageData?.data?.keys.map(item => (
                                                    <td className="tabledataPeer SubHeader">{dataKey[item]}</td>
                                                ))
                                                }



                                            </tr>
                                        ))}
                                    </tbody>

                                </table>

                            </div>
                        } {pageData.data.peers.length === 0 &&
                            <div style={{ paddingTop: "40px" }}>You can search for a company and add to the watchlist from the Summary page.</div>
                        }
                    </>
                } {Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </div>
        </>
    );
}
export default WatchlistCompaniesMobile;