import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    let navigate = useNavigate();
    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                
                if (!config.headers['Authorization']) {
                    if (auth.accessToken) {
                        config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                    } else {
                        config.headers['Authorization'] = ""
                    }
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                try {
                    if (error?.response?.status === 404) {
                        navigate("/PageNotFound")
                        return Promise.reject(error);
                    }


                    if (error?.response?.status === 401 && !prevRequest?.sent) {
                        prevRequest.refreshOrdered = true;
                        prevRequest.sent = true;
                        const newAccessToken = await refresh();
                        console.log("test")
                        console.log(newAccessToken)
                        prevRequest.sent = true;
                        prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                        return axiosPrivate({
                            ...prevRequest,
                            ...{
                                headers: prevRequest.headers.toJSON(),
                            },
                        });

                    }
                }
                catch (e) {
                    navigate("/SessionExpired")
                }
                
                return Promise.reject(error);
                
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;