import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import './GrowthMobile.css'
import Loading from "../../Company/Summary/Loading"



function GrowthMobile(props) {

    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();

    const roundPercentValue = (data) => {
        let result = data.replace("%", "");
        let value = parseFloat(result);
        var intvalue = Math.ceil(value);
        return intvalue + "%"

    }


    const companyDetails = async () => {
        setpageData({})
        let url = ""
        let ratioData = ""
        let plMap = ""
        let bsMap = ""
        let cfMap = ""
        let pMap = ""
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/growth-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        ratioData = cdresponse.data
        company_name = cdresponse.data['stock_name']
        plMap = cdresponse.data['prompts']['Revenue & Profit (CARG)']
        bsMap = cdresponse.data['prompts']['Balance Sheet (CAGR)']
        cfMap = cdresponse.data['prompts']['Cash Flows (CAGR)']
        pMap = cdresponse.data['prompts']['Profitability & Returns (CAGR)']
        document.title = company_name + "- Growth | Growth (CAGR) for Revenue, Profit, Assets, Book Value, Free Cash Flow, ROE, ROCE, and Stock Price ";
        setpageData({
            "ratio": ratioData,
            "profitLossMap": plMap,
            "balanceSheetMap": bsMap,
            "cashFlowMap": cfMap,
            "priceMap": pMap,
            "CompanyName": company_name
        })
    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])


    return (
        <div className="GrowthMain" style={{ paddingBottom: "110px" }}>
            {Object.keys(pageData).length !== 0 &&
                <>
                    <section className="optionsFinData">
                        <div className="leftAlign growthCompanyName">
                            {pageData.CompanyName}
                        </div>
                        <div className="rightAlign">

                        </div>
                    </section>
                    <div className="GrowthWrapper">
                    {pageData.ratio &&
                        <>
                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Revenue & Profit (CAGR)</div>
                            <section className="Growth">
                                <table className="finDataMobile">
                                    <tbody>
                                        <tr>
                                        <td className="leftBoldMobile leftBoldGrowthHead stikeyHeader"></td>
                                            {pageData.ratio.header.map(data => (
                                                <td className="columnHeadingMobile">{data}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {pageData.profitLossMap.map(key => (
                                            <>
                                                <tr>
                                                    <td className="leftBoldMobile SubHeader stikeyHeader">{key.field_prompt}</td>

                                                    {pageData.ratio[key.field_name].map(dataKey => (

                                                        <td className="tabledataMobile SubHeader"> {dataKey}</td>

                                                    ))}
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </section>
                        </>
                        }
                    {pageData.ratio &&
                        <>
                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Balance Sheet (CAGR)</div>
                            <section className="Growth">
                            <table className="finDataMobile">
                                    <tbody>
                                        <tr>
                                        <td className="leftBoldMobile leftBoldGrowthHead stikeyHeader" ></td>
                                            {pageData.ratio.header.map(data => (
                                                <td className="columnHeadingMobile" style={{ fontWeight: 600 }}>{data}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {pageData.balanceSheetMap.map(key => (
                                            <>
                                                <tr>
                                                    <td className="leftBoldMobile SubHeader stikeyHeader">{key.field_prompt}</td>


                                                    {pageData.ratio[key.field_name].map(dataKey => (

                                                        <td className="tabledataMobile SubHeader"> {dataKey}</td>

                                                    ))}
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </section>
                        </>
                        }
                    </div>
                    <div className="GrowthWrapper">
                    {pageData.ratio &&
                        <>
                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Cash Flow (CAGR)</div>
                            <section className="Growth">
                            <table className="finDataMobile">
                                    <tbody>
                                        <tr>
                                        <td className="leftBoldMobile leftBoldGrowthHead stikeyHeader" ></td>
                                            {pageData.ratio.header.map(data => (
                                                <td className="columnHeadingMobile" style={{ fontWeight: 600 }}>{data}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {pageData.cashFlowMap.map(key => (
                                            <>
                                                <tr>
                                                    <td className="leftBoldMobile SubHeader stikeyHeader">{key.field_prompt}</td>


                                                    {pageData.ratio[key.field_name].map(dataKey => (

                                                        <td className="tabledataMobile SubHeader"> {dataKey}</td>

                                                    ))}
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                        </section>
                        </>
                        }
                        {pageData.ratio &&
                            <>
                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Profitability & Returns (CAGR)</div>
                            <section className="Growth">
                            <table className="finDataMobile">
                                    <tbody>
                                        <tr>
                                        <td className="leftBoldMobile leftBoldGrowthHead stikeyHeader"></td>
                                            {pageData.ratio.header.map(data => (
                                                <td className="columnHeadingMobile" style={{ fontWeight: 600 }}>{data}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {pageData.priceMap.map(key => (
                                            <>
                                                <tr>
                                                    <td className="leftBoldMobile SubHeader stikeyHeader">{key.field_prompt}</td>


                                                    {pageData.ratio[key.field_name].map(dataKey => (

                                                        <td className="tabledataMobile SubHeader"> {dataKey}</td>

                                                    ))}
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                        </section>
                        </>
                        }

                    </div>
                </>
            }
            {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>

    );
}

export default GrowthMobile;