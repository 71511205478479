import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "../../Company/Summary/Loading"
import { Popup } from 'semantic-ui-react'

function SummaryTwoMobile(props) {
    const [pageData, setpageData] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "0"
        }
    }
    const roundData = (data) => {
        if (data) {
            return Math.round(data)
        } else {
            return "-"
        }

    }
    const diaplayIncome = (value) => {
        if (value) {
            return false
        } else {
            return true
        }
    }

    const roundPercentValue = (data) => {
        if (data) {
            let result = data.replace("%", "");
            let value = parseFloat(result).toFixed(2);
            //  var intvalue = Math.round(value*100)/100;
            return value + "%"
        } else {
            return "-"
        }
    }
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/summary-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])



    return (
        <div className="SummaryTwoMobile">
            {Object.keys(pageData).length !== 0 &&
                <>
                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Profitability (TTM)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Profitability - Trailing 12 Months.</div>}
                        style={{ zIndex: "999" }}
                    />

                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Net Margin: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["netMargin"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Net Interest Margin: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["netInterestMargin"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Return on Equity: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["returnOnEquityTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Return on Assets: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["returnOnAssetTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>
                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Growth (CAGR)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Growth - Compounded Annual Growth Rate.</div>}
                        style={{ zIndex: "999" }}
                    />


                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Net Income growth 3 yrs: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["netIncomeGrowth3Years"]}</div><div className="DetailsEnd"></div></div></div></div>
                    {diaplayIncome(pageData["netInterestIncomeGrowth3years"]) ?
                        <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Revenue growth 3 yrs: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["revenueGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                        :
                        <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Interest Income growth 3 yrs: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["netInterestIncomeGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                    }
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Assets Growth 3 Yrs: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["assetGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Equity Growth 3 Yrs: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["bookValueGrowth3Years"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>


                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Balance Sheet (MRQ)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Balance Sheet - Most Recent Quarter.</div>}
                        style={{ zIndex: "999" }}
                    />

                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Total Debt: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["totalDebt"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Net Debt: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["netDebt"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>
                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Cash Flow (FY)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Cash Flow - Financial Year.</div>}
                        style={{ zIndex: "999" }}
                    />

                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>3 Yrs OCF: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["ocfGrowth3years"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>3 Yrs FCF: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["fcfGrowth3Years"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>

                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }

        </div>

    );
}
export default SummaryTwoMobile;