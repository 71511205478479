import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "./Loading"
import './Employee.css'




function Employee(props) {

    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const employeeDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/executives/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData({
            "Employees": cdresponse.data.executive
        })
    }

    const getDisplayValue = (data) => {
        if (!data || data === 0 || data === "") {
            return "-"
        } else {
            return data
        }

    }

    useEffect(() => {
        employeeDetails()
    }, [props.compSymbol])



    return (
        <div className="Employee">
            <h3 style={{ textAlign: "left" }}>Executive Summary</h3>
            {Object.keys(pageData).length !== 0 &&
                <table className="EmployeeTable finData">
                <tbody>
                    <tr>
                        <th className="leftEmployee" style={{ fontWeight: 800, fontSize: 18 }}>
                            Name
                        </th>
                        <th className="columnHeadingEmployee">
                            Position
                        </th>
                        <th className="columnHeadingEmployeeCenter">
                            Age
                        </th>
                        <th className="columnHeadingEmployeeCenter">
                            Compensation
                        </th>
                        <th className="columnHeadingEmployeeCenter">
                            Since
                        </th>
                    </tr>
                    {pageData.Employees.map(dataKey => (
                        <tr>
                            <td className="leftEmployee">{dataKey.name}</td>
                            <td className="tabledataEmployeeLeft">{dataKey.position}</td>
                            <td className="tabledataEmployee">{getDisplayValue(dataKey.age)}</td>
                            <td className="tabledataEmployee">{getDisplayValue(dataKey.compensation)}</td>
                            <td className="tabledataEmployee">{getDisplayValue(dataKey.since)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default Employee;