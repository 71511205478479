import React, { useEffect } from 'react'; 
import Locked from './Locked'


function Coffee(props) {
    window.scrollTo(0, 0)
    document.title = "Nivesh Karma - Pricing";
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])

    return (
        <div className="Disclaimer commonContainer">
            <div className="AboutUs-container">
                <Locked />
            </div>
        </div>
    );
}
export default Coffee;