import React, { useEffect } from 'react';

function Document(props) {
	useEffect(() => {
	}, [props.ind])


    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 224.33 224.33"
			style={{ fill: props.ind === "document" ? "#FFFFFF" : "#ffd966" }}>
			<g>
				<path d="M187.744,119.241c0.67-31.958-24.781-58.503-56.731-59.179c-31.956-0.675-58.506,24.773-59.182,56.727
		c-0.01,0.538,0.041,1.035,0.046,1.574h-0.005c0,0.162,0.02,0.324,0.02,0.497c0.021,1.635,0.089,3.28,0.251,4.885
		c0.076,0.854,0.218,1.696,0.333,2.539c0.134,0.944,0.249,1.879,0.432,2.793c0.061,0.33,0.086,0.66,0.15,0.98
		c0.025,0,0.051,0,0.083,0c0.681,3.199,1.569,6.316,2.755,9.303c-0.036,0-0.079,0-0.114,0c1.627,4.164,3.75,8.079,6.246,11.71
		c0.083,0,0.16,0,0.249,0c5.543,7.937,13.01,14.426,21.762,18.783c-0.223,0-0.452,0-0.675,0c6.962,3.585,14.731,5.799,22.988,6.286
		c0.011-0.102,0-0.233,0-0.34c0.741,0.046,1.463,0.143,2.214,0.162c6.957,0.138,13.619-1,19.855-3.133v0.346
		c5.017-1.706,9.739-4.062,14.035-7.019v-0.365C177.353,155.604,187.327,138.644,187.744,119.241z M128.859,161.414
		c-20.439-0.427-37.275-15.011-41.457-34.154c-0.135-0.65-0.294-1.29-0.399-1.94c-0.114-0.7-0.193-1.411-0.276-2.112
		c-0.165-1.513-0.302-3.036-0.308-4.601c-0.005-0.508-0.056-0.995-0.045-1.513c0.041-2.021,0.269-4.002,0.582-5.952
		c0.02,0,0.038,0,0.058,0c0.696-4.131,1.965-8.056,3.738-11.702c-0.056,0-0.12,0-0.175,0c7.117-14.965,22.503-25.202,40.111-24.829
		c6.348,0.135,12.329,1.666,17.712,4.233v0.345c5.393,2.577,10.156,6.233,14.046,10.685V89.53
		c6.86,7.868,10.963,18.169,10.729,29.401C172.671,142.864,152.787,161.926,128.859,161.414z M224.33,206.115l-13.086,12.543
		l-43.579-45.463l13.081-12.543L224.33,206.115z M66.354,62.162c-10.087,0-20.175,0-30.267,0c-7.274,0-7.274-11.705,0-11.705
		c10.092,0,20.18,0,30.267,0C73.633,50.457,73.633,62.162,66.354,62.162z M36.087,81.085c-7.274,0-7.274-11.705,0-11.705
		c10.092,0,20.18,0,30.267,0c7.274,0,7.274,11.705,0,11.705C56.267,81.085,46.184,81.085,36.087,81.085z M162.455,98.629v39.374
		c-3.442,5.668-8.287,10.37-14.04,13.63V85.025C154.198,88.27,159.027,92.983,162.455,98.629z M148.415,178.678
		c4.971-1.554,9.663-3.772,14.04-6.448v44.401H0V5.672h132.271l30.184,29.27v29.432c-4.418-2.684-9.12-4.827-14.04-6.345V42.007
		h-23.38V19.715H14.043v182.87h134.371V178.678z M120.958,151.07c-1.889,0-5.748,0-10.841,0c-4.898-2.95-9.084-6.957-12.225-11.71
		c10.96,0,19.857,0,23.071,0C128.229,139.365,128.229,151.07,120.958,151.07z M34.505,139.365c4.385,0,19.375,0,35.749,0
		c1.435,4.083,3.28,8.003,5.548,11.71c-18.428,0-36.419,0-41.297,0C27.236,151.07,27.236,139.365,34.505,139.365z M120.958,130.067
		c-3.626,0-14.559,0-27.518,0c-1.196-3.696-1.846-7.627-1.846-11.71c13.759,0,25.555,0,29.358,0
		C128.229,118.357,128.229,130.067,120.958,130.067z M34.505,118.357c4.062,0,17.222,0,32.156,0
		c-0.005,3.966,0.338,7.887,1.056,11.71c-15.366,0-29.059,0-33.212,0C27.236,130.067,27.236,118.357,34.505,118.357z
		 M120.958,111.152c-3.747,0-15.208,0-28.66,0c0.81-4.182,2.293-8.128,4.357-11.708c11.522,0,20.972,0,24.303,0
		C128.229,99.444,128.229,111.152,120.958,111.152z M34.505,99.444c4.322,0,18.964,0,35.074,0c-1.201,3.77-2.064,7.68-2.531,11.708
		c-15.097,0-28.444,0-32.542,0C27.236,111.152,27.236,99.444,34.505,99.444z M90.69,181.573c-7.274,0-7.274-11.699,0-11.699
		c0.858,0,1.716,0,2.574,0c8.77,6.256,18.951,10.115,29.754,11.263c-0.604,0.244-1.279,0.437-2.057,0.437
		C110.865,181.573,100.783,181.573,90.69,181.573z"/>
			</g>
        </svg>
    )
}
export default Document;