import React from 'react';

function Gear(props) {
    const colorIndicator = (ind) => {
        if (ind === "false") {
            return true
        } else {
            return false
        }
    }
    const getIconPathData = () => {
        return "M211.5,44.6C165.4-1.5,90.7-1.5,44.6,44.6c-46.1,46.1-46.1,120.8,0,166.9c46.1,46.1,120.8,46.1,166.9,0C257.5,165.3,257.5,90.7,211.5,44.6L211.5,44.6z M128,234.9C68.9,234.9,21.1,187,21.1,128S68.9,21.1,128,21.1C187.1,21.1,235,69,235,128S187.1,234.9,128,234.9L128,234.9z M168,86.4l-61.1,63.4l-19-19.7c-3.5-3.6-9.2-3.6-12.7,0c-3.5,3.6-3.5,9.5,0,13.1l25.3,26.2c3.5,3.6,9.2,3.6,12.7,0c0.2-0.2,0.5-0.5,0.7-0.7l66.8-69.2c3.5-3.6,3.5-9.5,0-13.1C177.2,82.8,171.5,82.8,168,86.4L168,86.4z"

    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 256 256"
            style={{ fill: colorIndicator(props.ind) ? "#0000FF" : "#000000" }}>
            <path d={getIconPathData()}></path>
        </svg>
    )
}
export default Gear;