import React, { useState, useEffect }  from 'react';
import './Profile.css';
import PersonalDetails from "./PersonalDetails"
import CustomRatios from "./CustomRatios"
import HistoricalRatios from "./HistoricalRatios"
import PeerRatios from "./PeerRatios"
import SubscriptionDetails from "./SubscriptionDetails"


function Profile(props) {
    window.scrollTo(0, 0)
    document.title = "Nivesh Karma - User Profile";
    const [optionSelected, setOptionSelected] = useState("one");
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])

    return (
        <div className="faq commonContainer" >
            <div className="container">
                <div className="ProfileContainer">
                    <div className="leftOptionProfile">
                        <div className="ProfileNav" onClick={() => setOptionSelected("one")} style={optionSelected === "one" ? { backgroundColor: "black", color: "white" } : {}}>
                            Personal Details
                        </div>
                        <div className="ProfileNav" onClick={() => setOptionSelected("two")} style={optionSelected === "two" ? { backgroundColor: "black", color: "white" } : {}}>
                            Custom Ratios
                        </div>
                        <div className="ProfileNav" onClick={() => setOptionSelected("three")} style={optionSelected === "three" ? { backgroundColor: "black", color: "white" } : {}}>
                            Historical Ratios
                        </div>
                        <div className="ProfileNav" onClick={() => setOptionSelected("four")} style={optionSelected === "four" ? { backgroundColor: "black", color: "white" } : {}}>
                            Peers/Screener Ratios
                        </div>
                        <div className="ProfileNav" onClick={() => setOptionSelected("five")} style={optionSelected === "five" ? { backgroundColor: "black", color: "white" } : {}}>
                            Subscription Details
                        </div>
                    </div>
                    <div className="RightDetailsProfile" style={{ paddingRight: "10px" }}>
                        {optionSelected === "one" &&
                            <PersonalDetails />
                        }
                        {optionSelected === "two" &&
                            <CustomRatios ratioData={props.ratioData} />
                        }
                        {optionSelected === "three" &&
                            <HistoricalRatios ratioData={props.ratioData} />
                        }
                        {optionSelected === "four" &&
                            <PeerRatios ratioData={props.ratioData} />
                        }
                        {optionSelected === "five" &&
                            <SubscriptionDetails />
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}
export default Profile;