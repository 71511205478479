import React, { useState } from 'react';
import './HomeMobile.css'
import SearchIcon from "../../icon/HomeIcon"
import Document from "../../icon/Document"
import Filter from "../../icon/Filter"
import Checklist from "../../icon/Checklist"
import Meter from "../../icon/Meter"
import Gears from "../../icon/Gears"
import Eye from "../../icon/Eye"
import Scope from "../../icon/Scope"
import Compare from "../../icon/Compare"
import User from "../../icon/User"
import Gear from "../../icon/Gear"

function HomeMobile() {
    const [isHovering, setIsHovering] = useState("");
    const handleMouseOver = (ind) => {
        setIsHovering(ind);
    };

    const handleMouseOut = () => {
        setIsHovering("");
    };
    document.title = "Nivesh Karma - Stock Market research & Analysis tools for investors | Fundamental Analysis Tools | Best Stock Screener | Stock Screener in India | Long Term Investing Platform"
    return (
 
        <div className="HomeContentMain">
            <span id="nkSpan">Research Platform for Long Term Investment<br /></span>
            <div className="HomeContentSection">
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("search")} onMouseOut={handleMouseOut}><div><SearchIcon ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Search over 3,000 businesses</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("document")} onMouseOut={handleMouseOut}><div><Document ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>10 Years & 10 quarters of financials</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("filter")} onMouseOut={handleMouseOut}><div><Filter ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Fundamental analysis tools</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("checklist")} onMouseOut={handleMouseOut}><div><Checklist ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>650+ attributes for analysis</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("meter")} onMouseOut={handleMouseOut}><div><Meter ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Business valuation tools</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("gears")} onMouseOut={handleMouseOut}><div><Gears ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>3M Scoring Model & Ranking</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("eye")} onMouseOut={handleMouseOut}><div><Eye ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Popular pre-defined stock screens</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("scope")} onMouseOut={handleMouseOut}><div><Scope ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Powerful 3M stock screener</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("compare")} onMouseOut={handleMouseOut}><div><Compare ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Compare stocks on 150+ attributes</div></div>
                <div><div className="ContentBlock" onMouseOver={() => handleMouseOver("user")} onMouseOut={handleMouseOut}><div><User ind={isHovering} /></div></div><div className="contentText" style={{ paddingTop: "10px", fontWeight: "500" }}>Superinvestor Portfolio</div></div>
            </div>
            <div id="MainTextBottom" style={{ paddingTop: "10px" }}>
                Our <b>"Research Platform"</b> is simple yet effective guide to investing process that can help to pick fundamentally strong business with long term growth potential.
            </div>
            <div id="MarkerText">
                <b>INVEST IN YOUR FUTURE</b>
            </div>
        </div>

    );
}

export default HomeMobile;