import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Popup } from 'semantic-ui-react'
//import './Peers.css'
import Loading from "./Company/Loading"
import useAxiosPrivate from "./hooks/useAxiosPrivate"
import useAuth from './hooks/useAuth';
import ManageRatios from './ManageRatios/ManageRatios'
import HelpModal from './Company/HelpModal'
import { downloadExcel } from "react-export-table-to-excel";
import UpArrow from "./icon/UpArrow"
import DownArrow from "./icon/DownArrow"
import ExportSVG from './icon/ExportSVG'

function Sector(props) {
    const { sector } = useParams();
    const [pageData, setpageData] = useState({});
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [show, setShow] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShow(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const showManageRatios = () => {
        setShow(true)
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    const getUrl = (url) => {
        return "/Company/" + url
    }
    const axiosPrivate = useAxiosPrivate();
    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {       
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
                ))
            
        ))
        return returnData
    }

    const companyDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/all-peers/?filter_by=gsector&filter_value=" + encodeURIComponent(sector) + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data
        document.title = sector + "- Companies";
        console.log(userData)
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(5)
        }
        localStorage.setItem(
            "TableData", JSON.stringify({
                "sector": sector,
                "data": userData
            }));
        setpageData({
            "sector": sector,
            "data": userData
        })
    }

    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('TableData'))
        let tempData = itemsData["data"]["peers"]
        if (finalSortData["pointer"]) {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }
        let finalData = itemsData
        finalData["data"]["peers"] = tempData
        setpageData(finalData)
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.data.keys.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData?.data.peers.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.data.keys.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Sector",
            sheet: "Sector",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }


    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        setTimeout(function () { companyDetails(); }, 50);
        
    }, [sector, isProfileUpdated, auth.user])




    return (
        <div className="PeersMain">
            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>

                    <section className="optionsFinData">
                        <div className="leftAlign growthCompanyName" style={{ paddingLeft: "0px" }}>
                            {sector} Companies
                        </div>
                        <div className="rightAlign">

                            <Popup
                                className="popupChart"
                                trigger={
                                    <svg
                                        onClick={() => showManageRatios()}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 256 256"
                                        height="25px"
                                        width="25px"
                                        {...props}
                                    >
                                        <path d="M220.6 142.7l25.4-15.9-11.2-49.3-29.8-3.3-7.6-9.5 3.4-29.8L155.2 13l-21.1 21.2h-12.2L100.8 13 55.2 34.9l3.4 29.7-7.6 9.6-29.8 3.3L10 126.7l25.4 15.9 2.7 11.8-16 25.3 31.5 39.5 28.3-9.9 11 5.3 9.8 28.3h50.6l9.9-28.3 11-5.3 28.3 9.9 31.5-39.5-16-25.3 2.6-11.7zM196.2 198l-23.2-8.2-24.5 11.8-8.1 23.2h-24.8l-8.1-23.2L83 189.9 59.8 198l-15.5-19.4 13.1-20.8-6.1-26.5-20.9-13.1L36 94l24.5-2.7 17-21.3-2.8-24.4L97 34.9l17.4 17.4h27.2L159 34.9l22.4 10.8-2.8 24.4 17 21.3L220 94l5.5 24.2-20.9 13.1-6.1 26.5 13.1 20.8-15.4 19.4zm-68-116.1c-24.8 0-44.8 20.1-44.8 44.8 0 24.8 20.1 44.8 44.8 44.8 24.8 0 44.8-20.1 44.8-44.8 0-24.7-20.1-44.8-44.8-44.8zm0 73.6c-15.9 0-28.8-12.9-28.8-28.8S112.3 98 128.2 98s28.8 12.9 28.8 28.8-12.9 28.7-28.8 28.7z" />
                                    </svg>
                                }
                                content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Manage Your Ratios.</div>}
                                style={{ zIndex: "999" }}
                            />
                            <HelpModal title="Manage Columns" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                                <ManageRatios ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                            </HelpModal>
                            <Popup
                                className="popupChart"
                                trigger={
                                    <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer", paddingTop: "5px" }}>
                                        <ExportSVG />
                                    </div>
                                }
                                content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Export to Excel</div>}
                                style={{ zIndex: "999" }}
                            />
                        </div>
                    </section>
                    <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                        <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                            <thead>
                            <tr>
                                    <th className="leftPeer intersection" style={{ fontWeight: "600", textAlign: "center"  }}>S.&nbsp;No.</th>
                                <th className="leftPeer intersection" style={{ fontWeight: "600" }}>Company Name</th>

                                {pageData?.data?.keys.map(item => (
                                    <th className="columnHeadingScreener">
                                        <Popup
                                            className="popupChart"
                                            trigger={<div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted(item)}><div>{getDisplayValueAndPrompt(item)[0]}</div><div style={{ paddingTop: "5px" }}>{SortIndicator(item)[0] ? <div>{SortIndicator(item)[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div>}
                                            content={<div className="borderedContent" style={{ padding: "5px", border: "2px solid black" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                            style={{ zIndex: "999" }}
                                        />
                                    </th>
                                ))

                                }

                            </tr>
                            </thead>
                        <tbody>
                            {pageData?.data?.peers.map((dataKey, index) => (
                                <tr>
                                    <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{index+1}</td>
                                    <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                    {pageData?.data?.keys.map(item => (
                                        <td className="tabledataPeer SubHeader">{dataKey[item]}</td>
                                    ))
                                    }



                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>
        </div>
    );
}

export default Sector;