import React, { useState } from 'react';

function ExternalLink() {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };


    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const getIconPathData = () => {
        return "M65.92,17.62l-3.55-3.55a10.86,10.86,0,0,0-15.33,0L34.13,27l.79-.15.37-.05A13.1,13.1,0,0,1,37,26.68c.36,0,.73,0,1.12.05a12.83,12.83,0,0,1,5.7,1.93l8.93-8.93a2.91,2.91,0,0,1,4,0l2.49,2.49a4.34,4.34,0,0,1,0,6.14L45.07,42.49a2.75,2.75,0,0,1-.36.29,3.34,3.34,0,0,1-.45.25h0a2.85,2.85,0,0,1-3.19-.55l-3.55-3.55-.08-.1a.7.7,0,0,0-.45-.17,1,1,0,0,0-.4.1l-.15.08-.09.07-5,5c.18.21.35.44.55.63l3.55,3.55c.2.2.41.38.62.56l1.66,1.17a10.63,10.63,0,0,0,6.74,1.34l.32,0a11.17,11.17,0,0,0,1.17-.24l.15,0h0l.19-.06a11.13,11.13,0,0,0,1.16-.44l.13-.06a10.67,10.67,0,0,0,1.29-.69,10.91,10.91,0,0,0,1.86-1.49L65.92,33a10.86,10.86,0,0,0,0-15.33Z"
    }

    const getIconPathDataSecond = () => {
        return "M44.67,53.21a12.63,12.63,0,0,1-8-1.59l-.08,0-.08-.05-.28-.2L28.36,59.2a4.34,4.34,0,0,1-6.14,0L20.8,57.78a4.35,4.35,0,0,1,0-6.14l7.78-7.78.53-.53,5.83-5.83.06-.06L35,37.4l.25-.19h0l0,0,0,0a3.64,3.64,0,0,1,.39-.21h0a3.09,3.09,0,0,1,.55-.19l.15,0a2.8,2.8,0,0,1,.46-.05H37l.26,0,.17,0a2.77,2.77,0,0,1,1.32.64l0,0h0l.14.12,3.55,3.55.06.08a.82.82,0,0,0,.49.17.88.88,0,0,0,.37-.08l.15-.08.09-.07,5-5c-.18-.21-.35-.44-.55-.63L44.6,31.85a10.75,10.75,0,0,0-1.55-1.28l-.34-.22a11,11,0,0,0-2.31-1.1h0A10.81,10.81,0,0,0,39.29,29l-.23-.05a10.47,10.47,0,0,0-1.16-.17h0c-.3,0-.58,0-.85,0h-.14a11.08,11.08,0,0,0-1.35.09h0l-.24,0h-.08l-.68.13h0l-.53.13-.12,0a10.69,10.69,0,0,0-1.37.51l-.11,0a10.86,10.86,0,0,0-3.15,2.18L14.08,47a10.86,10.86,0,0,0,0,15.33l3.55,3.55a10.85,10.85,0,0,0,15.33,0L45.87,53l-.86.16Z"
    }



    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            width="20"
            height="20"
            viewBox="0 0 80 80"
            style={{ fill: "#606f7b" }}>
            <path d={getIconPathData()}></path>
            <path d={getIconPathDataSecond()}></path>
        </svg>
    )
}
export default ExternalLink;