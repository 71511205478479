import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import ScoreCompare from './ScoreCompare'

function ScoreCompareWrapper(props) {
    const [pageData, setpageData] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    document.title = props.compSymbol + "- Peer Compare | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes. ";
    const CompareOptions = async () => {
        setpageData([])
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/compare-company-list/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        //document.title = cdresponse.data.stock_name + "- 3M Score | Stock Scoring Model | Moat, Management & MOS score | Compare Stocks on key financial attributes | Investing checklist. ";
        let tempData = []
        cdresponse.data.peer_companies.forEach(item => (
            tempData.push({ label: item.stock_name, value: item.stock_symbol })
        ))
        setpageData(tempData)
    }

   

    useEffect(() => {
        CompareOptions()
    }, [props.compSymbol])

    return (
        <div className="ScoreCompareWrapper" style={{ fontSize: "14px", paddingBottom: "110px" }}>
            <ScoreCompare compList={pageData} compSymbol={props.compSymbol}/>
        </div>
    );
}

export default ScoreCompareWrapper