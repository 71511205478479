import React, { useState, useEffect } from 'react';
import "./ScreenerMobile.css"
import HelpModal from './Screener/ScreenerModalMobile'
import ManageScreenerMobile from './Screener/ManageScreenerMobile'
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
import { getOptionLookup, getKeyLookup } from './Screener/ManageScreenerMobile'
import Icon from "../icon/Icon"
import UpArrow from "../icon/UpArrow"
import DownArrow from "../icon/DownArrow"
import Loading from "../Company/Loading"
import ManageRatios from './ManageRatios/ManageRatiosMobile'
import HelpModalTwo from '../Company/HelpModal'
import useAuth from '../hooks/useAuth';
import Locked from '../Locked'
import AddToWatchlistMobile from './Screener/AddToWatchlistMobile'
import { downloadExcel } from "react-export-table-to-excel";
import WatchlistModal from './Screener/WatchlistModalMobile'

function ScreenerMobile(props) {

    const [pageData, setpageData] = useState({
        "stocks": [],
        "RatioCode": [],
        "defaultScreener": [],
        "pageLoaded": false,
        "defaultPage": false
    });
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState({})
    const [query, setQuery] = useState([])
    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);
    const [selectedRatios, setSelectedRatios] = useState([]);
    const [checked, setChecked] = useState({});

    const axiosPrivate = useAxiosPrivate();
    document.title = "Stock Screener | Best Stock Screener, research, and analysis tool | Build your own stock screener.";




    const handleSelectedRatios = () => {
        let dataArr = []
        for (let k in checked) {
            if (checked[k]) {
                dataArr.push(k)
            }
            setSelectedRatios(dataArr)
        }
        console.log(selectedRatios)
    }

    const handleChange = (item) => {
        if (checked[item]) {
            checked[item] = false
        } else {
            checked[item] = true
        }
        handleSelectedRatios()
    }


    const isPresent = (key) => {
        return selectedRatios.includes(key)
    }


    const cancelActionRatio = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShowTwo(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const showManageRatios = () => {
        setShowTwo(true)
    }


    const showManageScreener = () => {
        setShow(true)
    }
    const cancelAction = () => {

        const filterData = JSON.parse(localStorage.getItem('NKUserFilter'))
        if (filterData) {
            setFilter(filterData)
        } else {
            setFilter({})
        }
        setShow(false)
    }
    const removeSelected = (code) => {
        filter[code] = { "Source": "PreSelected", "Value": "01" }
        buildQuery()
    }



    const getScreenerURL = (code) => {
        return "/PredefinedScreener/" + code
    }
    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('ScreenerData'))
        let tempData = itemsData["stocks"]
        if (finalSortData["pointer"]) {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['Sector', 'Industry'].includes(pointer)) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }

        setpageData({
            "stocks": tempData,
            "RatioCode": itemsData["RatioCode"],
            "pageLoaded": true,
            "defaultPage": false
        })
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const isFilterPresent = () => {
        for (const [key, value] of Object.entries(filter)) {
            if (value["Value"] !== "01") {
                return true
            }
        }
        return false
    }

    const buildQuery = () => {
        let tempQuery = []
        localStorage.setItem('NKUserFilter', JSON.stringify(filter))
        setShow(false)
        for (const [key, value] of Object.entries(filter)) {
            console.log(value);
            if (value["Source"] === "PreSelected") {
                if (value["Value"] !== "01") {
                    tempQuery.push({
                        "ratio_code": key,
                        "selection_code": value["Value"],
                        "selection_type": "Selection"

                    })
                }
            } else {
                tempQuery.push({
                    "ratio_code": key,
                    "selection_code": value["Value"],
                    "selection_type": "custom",
                    "min_value": value["minValue"],
                    "max_value": value["maxValue"]


                })
            }

        }
        console.log(tempQuery)
        setQuery(tempQuery)

    }
    const getUrl = (url) => {
        return "/Company/" + url
    }

    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }

    const runQuery = async () => {

        if (query.length > 0) {
            setpageData({
                "stocks": [],
                "RatioCode": [],
                "pageLoaded": false,
                "defaultPage": false
            })
            let url = "https://api.niveshkarma.com/api/v1/screener?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

            const response = await axiosPrivate.post(url, { "query": query });
            console.log(response)
            localStorage.setItem(
                "ScreenerData", JSON.stringify({
                    "stocks": response.data["stocks"],
                    "RatioCode": response.data["keys"]
                }));
            setShow(false)
            setpageData({
                "stocks": response.data["stocks"],
                "RatioCode": response.data["keys"],
                "pageLoaded": true,
                "defaultPage": false
            })
        } else {
            setpageData({
                "defaultScreener": [],
                "pageLoaded": false,
                "defaultPage": false
            })
            let url = "https://api.niveshkarma.com/api/v1/default-screeners?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const response = await axiosPrivate.get(url)
            console.log(response.data)
            if (auth.roles === true) {
                setRestrictCount(30)
            } else {
                setRestrictCount(5)
            }
            setpageData({
                "defaultScreener": response.data,
                "pageLoaded": false,
                "defaultPage": true
            })
        }
    }

    const getCompanyCountMessage = () => {
        try {
            let count = pageData.stocks.length
            if (count > 0) {
                return count.toString() + " companies found."
            } else {
                return "No companies found. Please adjust your filters"
            }
        } catch (e) {
            return "No companies found. Please adjust your filters"
        }
    }

    const getClassName = (data) => {
        if (data === "Sector") {
            return "SectorMobile columnHeadingScreenerLeft"
        } else if (data === "Industry") {
            return "IndustryMobile columnHeadingScreenerLeft"
        } else {
            return "columnHeadingScreener"
        }
    }

    const getSubClassName = (data) => {
        if (data === "Sector") {
            return "Sector SubHeader"
        } else if (data === "Industry") {
            return "Industry SubHeader"
        } else {
            return "tabledataPeer SubHeader"
        }
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.RatioCode.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData?.stocks.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.RatioCode.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Screener",
            sheet: "Screener",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }




    useEffect(() => {
       // if (auth.user) {
        const timeout = setTimeout(() => {
            runQuery()
            localStorage.removeItem('SortDataItem');
            localStorage.removeItem('NKUserFilter');
        }, 50)
        return () => clearTimeout(timeout)
      //  }
    }, [query, isProfileUpdated, auth.user])

    return (
        <div className="Screener">
            {/* {auth.user && */}
                <>
                    {
                        pageData.defaultPage &&
                        <div className="ScreenerHeader" style={{ textAlign: "left", paddingLeft: "20px", paddingTop: "15px" }}>
                        <div style={{ textAlign: "left", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Create your own Screener</div>
                        </div>
                    }
                    {(pageData.pageLoaded || pageData.defaultPage) &&
                        <>
                            {pageData.pageLoaded && <div className="rightAlign">
                                <div className="optionButtonPrimary">
                            <div className="optionButtonMobile" onClick={() => showManageRatios()}> Manage Columns</div>
                                    <HelpModalTwo title="Manage Columns" onClose={() => cancelActionRatio()} show={showTwo} toggleProfile={toggleProfile} setShow={setShowTwo} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                                        <ManageRatios ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                                    </HelpModalTwo>
                                </div>

                                <div className="optionButtonPrimary">
                            <div className="optionButtonMobile" onClick={() => setShowThree(true)}> Add to Watchlist</div>
                                    <WatchlistModal title="Add companies to your watchlist" onClose={() => setShowThree(false)} show={showThree}>
                                        <AddToWatchlistMobile watchlistList={selectedRatios} />
                                    </WatchlistModal>
                                </div>
                                
                            </div>
                            }
                            <div className="optionButtonPrimary" style={{ marginTop: "15px", paddingLeft: "15px" }}>

                                <div className="optionButtonMobile" style={{ width: "150px" }} onClick={() => showManageScreener()}>{pageData.defaultPage ? "Stock Screener" : "Edit Stock Screener"}</div>

                                <HelpModal title="Manage Your Screener Filter" onRun={() => buildQuery()} onClose={() => cancelAction()} show={show} filter={filter}>
                                    <ManageScreenerMobile filter={filter} />
                                </HelpModal>
                                {!pageData.defaultPage ?
                                    <div>
                                        <div style={{ paddingTop: "10px", fontWeight: "600", display: "flex", justifyContent: "start" }}><div className="ScreenerResultPrompt">Screener Result:</div><div className="ScreenerResultText">{getCompanyCountMessage()}</div></div>
                                        {/*<Popup
                            className="popupChart"
                            trigger={ }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>
                                Screener Results: <br />
                                The Screener displays columns in the results according to the following criteria: <br />
                                <ul>
                                    <li>Predefined columns by Nivesh Karma. </li>
                                    <li>Plus - Columns based on query selection criteria.</li>
                                    <li>Plus - Go to Peers & click on Manage Columns to add your custom columns for analysis.</li>
                                </ul>
                            </div>}
                            style={{ zIndex: "999" }}
                        />*/}
                            </div>
                            : <div style={{ textAlign: "left", paddingTop: "15px", fontSize: "13px" }}>
                                        <div>Our <b>"3M Screener"</b> is based on risk-averse Value Investing Strategies taught by Warren Buffett.</div>
                                        <div className="ScreenerBlock">
                                            <div className="ScreenerSubBlock">
                                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Moat</div>
                                                <ul>
                                                    <li>Great businesses with competitive durable advantages.</li>
                                                    <li>Predictable growth in Revenue, Profit, EPS, Book Value and Free Cash Flow.</li>
                                                </ul>
                                            </div>
                                            <div className="ScreenerSubBlock">
                                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Management</div>
                                                <ul>
                                                    <li>Result-driven management with honesty, integrity and effective corporate governance.</li>
                                                    <li>Predictable & high ROE, ROCE, ROA, Profit Margin and low debt.</li>
                                                </ul>
                                            </div>
                                            <div className="ScreenerSubBlock">
                                        <div style={{ textAlign: "left", paddingLeft: "10px", fontSize: "13px", fontWeight: "600", paddingTop: "5px" }}>Margin of Safety</div>
                                                <ul>
                                                    <li>Determine Intrinsic value using valuation techniques such as relative valuation and DCF.</li>
                                                    <li>Buy at great MOS to achieve superior returns over a long period.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>



                        </>
                    }
                {Object.keys(filter).length !== 0 &&
                    <div className="selectedFilters">
                            {
                                Object.keys(filter).map((item, index) => (
                                    filter[item]["Source"] === "PreSelected" ?
                                        <>
                                            {filter[item]["Value"] === "01" ?
                                                <></>
                                                :
                                                <div className="dragableSection" style={{ marginLeft: "0px", marginRight: "0px" }}>
                                                    <div>
                                                        <b> {getKeyLookup(item)}</b> &nbsp; {getOptionLookup(item, filter[item])}
                                                    </div>
                                                    <div className="removableSection" onClick={() => removeSelected(item)}>
                                                        <Icon source="minus" />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        <div className="dragableSection">
                                            <div>
                                                <b> {getKeyLookup(item)}</b> &nbsp; Custom
                                </div>
                                            <div className="removableSection" onClick={() => removeSelected(item)}>
                                                <Icon source="minus" />
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    }


                    <section className="PeersTwo">
                        {pageData?.RatioCode?.length !== 0 && pageData.pageLoaded &&
                            <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                                <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                    <thead>
                                        <tr>
                                            <th className="columnHeadingScreenerLeft leftPeer intersection" >Select</th>
                                            <th className="columnHeadingScreenerLeft leftPeer intersection" >S.&nbsp;No.</th>
                                            <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ maxWidth: "100px"}}>Company Name</th>

                                            {pageData?.RatioCode?.map(item => (
                                                <th className="columnHeadingScreener" className={getClassName(getDisplayValueAndPrompt(item)[0])}>
                                                    <Popup
                                                        className="popupChart"
                                                        trigger={<div style={{ cursor: "pointer", color: "blue", fontWeight: "400", display: "flex", justifyContent: "start" }} onClick={() => showSorted(item)}><div>{getDisplayValueAndPrompt(item)[0]}</div><div style={{ paddingTop: "5px" }}>{SortIndicator(item)[0] ? <div>{SortIndicator(item)[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div>}
                                                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                        style={{ zIndex: "999" }}
                                                    />
                                                </th>
                                            ))

                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pageData?.stocks !== null && pageData?.stocks?.map((dataKey, index) => (
                                            <tr>
                                                <td className="leftPeer" style={{ width: "50px" }}>
                                                    <div>
                                                        <input key={index + 1} type="checkbox" onChange={() => handleChange(dataKey.stock_symbol)} checked={isPresent(dataKey.stock_symbol) ? true : false} />
                                                    </div>
                                                </td>
                                                <td className="leftPeer" style={{ width: "50px", textAlign: "left" }}>{index + 1}</td>
                                                <td className="leftPeer" style={{ maxWidth: "100px"}}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                                {pageData.RatioCode.map(item => (
                                                    <td className={getSubClassName(getDisplayValueAndPrompt(item)[0])}>{dataKey[item]}</td>
                                                ))
                                                }



                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {
                            pageData.defaultPage &&
                            <>
                                <div style={{ textAlign: "left" }}>
                                    <h2>Nivesh Karma Screeners</h2>
                                </div>
                                <div className="predefinedScreenerOptions">
                                    {pageData?.defaultScreener?.map(defaultItem => (
                                        <div style={{ marginBottom: "20px" }}>
                                            <div className="dragableSectionAttached upperAttached">
                                                <a href={getScreenerURL(defaultItem.screener_code)} target="_blank" style={{ textDecoration: "none", fontWeight: "500" }}>{defaultItem.screener_name}</a>
                                            </div>
                                            <div className="dragableSectionAttached lowerAttached">
                                                {defaultItem.description}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                        {
                            !pageData.pageLoaded && !pageData?.defaultPage &&
                            <Loading />
                        }
                    </section>
                </>
            {/* }
            {!auth.user &&
                <Locked />
            }*/}
        </div>
    );
}

export default ScreenerMobile;