import React, { useState, useRef, useEffect }from 'react';
import './Content.css';
import DropdownArrow from "./util/DropdownArrow";
import FixedBanner from './FixedBanner'

const Icon = () => {
    return (
        <svg height="20" width="20" viewBox="0 0 20 20" fill="#606f7b">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg >
    );
}

function HomeContent(props) {
    const [showTool, setShowTool] = useState(false);
    const ref = useRef();
    const options = [
        { value: "/InvestingFramework", label: "3M Investing Framework" },
        { value: "/InvestingBooks", label: "Investing Books" }
        
    ];

    const toggleTool = () => {
        if (showTool) {
            setShowTool(false)
        } else {
            setShowTool(true)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowTool(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <>
            <div className="HomeContent">
                <div className="FixedBanner">
                    <div className="HeaderAnchor">
                        <div className="textHeader">
                            <a className="textHeaderAnchor" style={{ textDecoration: "none" }} href="/Screener"><div className="FixedBannerMember" style={{ fontSize: "17px", }}>Screener</ div></a>

                        </div>
                    </div>
                    <div className="HeaderAnchor">
                        <div className="textHeader">
                            <a className="textHeaderAnchor" style={{ textDecoration: "none" }} href="/Superinvestor"><div className="FixedBannerMember" style={{ fontSize: "17px"}}>Superinvestors</ div></a>

                        </div>
                    </div>
                    <div className="HeaderAnchor">
                        <div className="textHeader">
                            <a className="textHeaderAnchor" style={{ textDecoration: "none" }} href="/Commodities"><div className="FixedBannerMember" style={{ fontSize: "17px" }}>Commodities</ div></a>

                        </div>
                    </div>
                    <div className="HeaderAnchor">
                        <div className="FixedBannerMember" style={{ display: "grid", gridTemplateColumns: "95% 5%" }}>
                            <div style={{ textAlign: "right" }} onClick={() => toggleTool()}>Tools</div> <div onClick={() => toggleTool()}><Icon /></div>
                            {showTool && <div className="tool-menu" ref={ref}>
                                <div>
                                    <a href="/Screener" style={{ textDecoration: "none" }}>
                                        <div className= "ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div style={{ paddingTop: "10px" }}>
                                                <img id="ScreenerLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/screener.svg'} alt="Screener Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Screener</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Run queries on 10 years of fundamental data and 100+ pre-defined custom filters.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/Commodities" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div>
                                                <img id="commodityLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/truck.svg'} alt="Commodity Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Commodity Price Trends</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Unlock investmentopportunities with insights from commodity price trends.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/Superinvestor" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div>
                                                <img id="SupperinvestorLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/superinvestor.svg'} alt="Superinvestor Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Search Superinvestors</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Pick the most skilled player in the game.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/InvestingFramework" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div>
                                                <img id="frameworkLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/Framework.svg'} alt="framework Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>3M Investing Framework</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Long-term investment stratergy taught by legendry investor Warren Buffet.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/InvestingBooks" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div>
                                                <img id="bookLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/book.svg'} alt="book Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Investing Books</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Achieve financial success by incorporating wisdom from tip investment books.</div>
                                            </div>
                                        </div>
                                    </a>
                                    {/*<a href="#" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div>
                                                <img id="calculatorLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/calculator.svg'} alt="calculator Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Financial Calculators</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Financial Calculators.</div>
                                            </div>
                                        </div>
                                    </a>*/}
                                    <a href="https://www.linkedin.com/company/nivesh-karma" target="_blank" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px" }}>
                                            <div>
                                                <img id="LinkLogo" width="40" height="40" src={process.env.PUBLIC_URL + '/link.svg'} alt="Link Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>LinkedIn Connect</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}>Follow our investing insights on LinkedIn for valuable financial knowledge.</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            }
                        </div>
                        
                     {/*<DropdownArrow
                            placeHolder="Tools "
                            options={options}
                            onChange={(value) => console.log(value)}
                        />*/}
                    </div>
                    <div className="HeaderAnchor">
                        &nbsp;
                    </div>
                    {/*<FixedBanner />*/}
                </div>
                
                
            </div>
            <div className="HomeContentMobile">
                <div style={{ height: "30px", color: "black", display: "flex", justifyContent: "right", padding: "10px", marginRight: "20px" }}>
                    <div style={{ padding: "5px 10px", border: "1px solid grey" }} onClick={() => props.setShow(false)}>
                        X
                    </div>
                </div>
                <div className="FixedBannerMobile">
                    <div className="HeaderAnchorMobile">
                        <div className="textHeader">
                            <a className="textHeaderAnchor" style={{ textDecoration: "none" }} href="/Screener"><div className="FixedBannerMember" style={{ fontSize: "17px", }}>Screener</ div></a>

                        </div>
                    </div>
                    <div className="HeaderAnchorMobile">
                        <div className="textHeader">
                            <a className="textHeaderAnchor" style={{ textDecoration: "none" }} href="/Superinvestor"><div className="FixedBannerMember" style={{ fontSize: "17px" }}>Superinvestors</ div></a>

                        </div>
                    </div>
                    <div className="HeaderAnchorMobile">
                        <DropdownArrow
                            placeHolder="Tools "
                            options={options}
                            onChange={() => props.setShow(false)}
                        />
                    </div>
                    {/*<FixedBanner />*/}
                </div>
            </div>
        </>
    );
}

export default HomeContent;