import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Chart from "react-apexcharts";

export const options = {
    legend: { position: "none" },
    chart: {
        title: ""
    },
    tooltip: { isHtml: true, trigger: "visible" },
    
};

function getMonthNum(month) {
    if (month === "Jan") {
        return 0
    } else if (month === "Feb") {
        return 1
    } else if (month === "Mar") {
        return 2
    } else if (month === "Apr") {
        return 3
    } else if (month === "May") {
        return 4
    } else if (month === "Jun") {
        return 5
    } else if (month === "Jul") {
        return 6
    } else if (month === "Aug") {
        return 7
    } else if (month === "Sep") {
        return 8
    } else if (month === "Oct") {
        return 9
    } else if (month === "Nov") {
        return 10
    } else if (month === "Dec") {
        return 11
    }
}

function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mmm");
  //  var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(getMonthNum(dateItems[monthIndex]));
    console.log(dateItems[monthIndex])
    var formatedDate = new Date(dateItems[yearIndex], month, '25');
    return formatedDate;
}

function CommodityChart(props) {
    const [mapData, setMapData] = useState({ mapData: {}, pctChange: [] })
    const axiosPrivate = useAxiosPrivate();
    const CommodityChartData = async () => {
        setMapData({ mapData: {}, pctChange: [] })
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/commodities/" + props.CommoditySymbol.value + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let labels = []
        let tempData = []
        let returnData = cdresponse.data.indexes.reverse()
        returnData.forEach(item => {
            labels.push(parseFloat(item.value))
            tempData.push([stringToDate(item.date_header, "mmm-yyyy", '-'), parseFloat(item.value)])
        })
        let minVal = Math.min.apply(null, labels.filter(Boolean))
        let maxVal = Math.max(...labels)
        setMapData({
            //mapData: { labels: labels, datasets: [{ values: tempData}]},
            mapData: {
                options: {
                    chart: { id: "commodity-chart" },
                    dataLabels: { enabled: false },
                    colors: ['#0070f3', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                    xaxis: {
                        type: 'datetime',
                      //  categories: labels,
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm',
                            }
                        }
                    },
                    yaxis: {
                        forceNiceScale: true,
                        min: minVal,
                        max: maxVal
                    },
                    stroke: {
                        width: 1.5
                    }
                },
                series: [{ name: "Commodity Price", data: tempData }]
                }, 
            pctChange: cdresponse.data.pct_change
        })
    }

    useEffect(() => {
        CommodityChartData()
    }, [props.CommoditySymbol])

    return (
        <div className="CommodityChart" style={{ paddingTop: "20px", paddingBottom: "100px" }}>
            
            <div style={{ display: "flex", justifyContent: "start", gap: "20px" }}>
                <div style={{ width: "75%", paddingTop: "30px", backgroundColor: "white", borderRadius: "10px" }}>
                    <div style={{ fontWeight: "600", fontSize: "16px" }}>
                        {props.CommoditySymbol.label}
                    </div>
                    {/*mapData.mapData.length > 1 &&
                    <Chart
                    className="CommodityChart"
                    chartType="Line"
                    data={mapData.mapData}
                    options={options}
                    width="100%"
                    height="450px"
                    loader="Loading Chart"
                    />
                    }
                    {Object.keys(mapData.mapData).length > 0 && < ReactFrappeChart
                        type="bar"
                        colors={["#21ba45"]}
                        axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
                        height={450}
                        width="100%"
                        data={ mapData.mapData }
                    />
                        */}
                    {Object.keys(mapData.mapData).length > 0 && 
                        <Chart
                        options={mapData.mapData.options}
                        series={mapData.mapData.series}
                        type="bar"
                        width="100%"
                        height="400"
                            
                        />

                    }
                </div>
                <div style={{ width: "25%" }}>
                    {mapData.pctChange.length > 0 &&
                        <div style={{ height: "450px", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gridColumnGap: "20px", gridRowGap: "20px" }}>
                        {mapData.pctChange.filter((element) => element.index !== "2 years").map((item, index) => (
                            <div style={{ backgroundColor: "white", borderRadius: "15px" }}>
                                <div style={{ fontWeight: "600", paddingTop: "50px" }}>
                                    {item.index}
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    {item.change_pct}
                                </div>
                            </div>

                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CommodityChart;