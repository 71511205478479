import React, { useState, useEffect } from 'react';
import './QuaterlyResults.css'
import { Chart } from "react-google-charts";
import FinancialData from "./FinancialData"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

export const options = {
    legend: { position: "bottom" },
    title: "Sales, Total Expense, and Net Profit",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

export const optionsTwo = {
    legend: { position: "bottom" },
    title: "Sales, EBITDA, EBIT, and Net Profit",
    curveType: "function",
    titleTextStyle: { fontSize: 20,}
};

function QuaterlyResults(props) {
    const [pageData, setpageData] = useState({
        "data": [],
     //   "horizontal_analysis": [],
        "extended_mapping": [],
     //   "mapData": [],
     //   "mapDataTwo": [],
        "CompanyName":"Company Name Placeholder"
    });
  //  const [collapse, setcollapse] = useState(false);
  //  const [modal, setmodal] = useState(false);
  //  const [modalTwo, setmodalTwo] = useState(false);
    const [sort, setsort] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        setpageData({
            "data": [],
            //   "horizontal_analysis": [],
            "extended_mapping": [],
            //   "mapData": [],
            //   "mapDataTwo": [],
            "CompanyName": "Company Name Placeholder"
        })
        let url = ""
        let quarter_data = ""
        let quarter_map = ""
        let horizontal_data = ""
        let vertical_data = ""
      //  let map_data = []
      //  let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/quarterly/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        if (sort) {
            quarter_data = cdresponse.data['quarterly']
          //  horizontal_data = cdresponse.data['horizontal_analysis']
            vertical_data = cdresponse.data['as_pct_of_income']
        } else {
            quarter_data = cdresponse.data['quarterly'].reverse()
           // horizontal_data = cdresponse.data['horizontal_analysis'].reverse()
            vertical_data = cdresponse.data['as_pct_of_income'].reverse()
        }
        quarter_map =  cdresponse.data['prompts']
        company_name = cdresponse.data['stock_name']
       // map_data.push(["", "Sales", "Expenses", "Net Profit"])
       // quarter_data.map(mapdata => (
       //     map_data.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')) - parseFloat(getDisplayValue(mapdata['Net Profit'], 'm')), parseFloat(getDisplayValue(mapdata['Net Profit'], 'm'))])
       // ))
      //  map_data_two.push(["", "Sales", "EBITDA", "EBIT", "Net Profit"])
      //  quarter_data.map(mapdata => (
      //      map_data_two.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')), parseFloat(getDisplayValue(mapdata['EBITDA'], "m")), parseFloat(getDisplayValue(mapdata['EBIT'], "m")), parseFloat(getDisplayValue(mapdata['Net Profit'], "m"))])
      //  ))
        document.title = company_name + "- Quarterly Results | Quarterly Profit & Loss | Vertical Analysis";

        setpageData({
            "data": quarter_data,
            "percentOfIncome": vertical_data,
        //    "horizontal_analysis": horizontal_data,
            "extended_mapping": quarter_map,
        //    "mapData": map_data,
        //    "mapDataTwo": map_data_two,
            "ReportLink": "https://www.bseindia.com/stock-share-price/equity/scripcode/" + cdresponse.data.bse_code + "/financials-results",
            "CompanyName": company_name
        })
    }
    


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort])

    return (
        <>
            <FinancialData pageData={pageData} sort={sort} setsort={setsort} source="quaterly" horizontal={ false } vertical={ true }/>
        </>
    );
}

export default QuaterlyResults;