import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "./Loading"


function PriceSummary(props) {
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return ""
        }
    }

    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        let url = process.env.REACT_APP_DATA_API_URL + "/price_summary/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }

    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div className="summary">
            {Object.keys(pageData).length !== 0 &&
                <>
                <h3>Price Summary</h3>
                <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Market Cap : </div> <div className="details"><div className="DetailsSubHeader">₹&nbsp;</div><div className="DetailsValue">{numberFormat(pageData.mrkt_cap)}</div><div className="DetailsEnd">&nbsp;cr.</div></div></div></div>
                <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Current Price: </div><div className="details"><div className="DetailsSubHeader">₹&nbsp;</div><div className="DetailsValue">{numberFormat(pageData.current_price)}</div><div className="DetailsEnd"></div></div></div></div>
                <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">52 Week High: </div><div className="details"><div className="DetailsSubHeader">₹&nbsp;</div><div className="DetailsValue">{numberFormat(pageData.fifty_two_high)}</div><div className="DetailsEnd"></div></div></div></div>
                <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">52 Week Low: </div><div className="details"><div className="DetailsSubHeader">₹&nbsp;</div><div className="DetailsValue">{numberFormat(pageData.fifty_two_low)}</div><div className="DetailsEnd"></div></div></div></div>
                <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Volume: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData.volume}</div><div className="DetailsEnd"></div></div></div></div>
                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default PriceSummary;