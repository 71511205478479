import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Commodity from './Commodity'
import Loading from './Loading'
import './Commodity.css'

function CommodityWrapper(props) {
    const [pageData, setpageData] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const CommodityList = async () => {
        setpageData([])
        let url = ""
        url = "https://api.niveshkarma.com/api/v1/commodities-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let tempData = []
        cdresponse.data.data.forEach(item => (
            tempData.push({ label: item.name, value: item.code })
        ))
        setpageData(tempData)
    }



    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        CommodityList()
    }, [])

    return (
        <div >
            {pageData.length > 0 && 
                <Commodity commodityData={pageData} />
            }
            {pageData.length === 0 &&
                <div style={{ paddingTop: "100px" }}>
                <Loading />
            </div>
            }
        </div>
    );
}

export default CommodityWrapper                                                  