import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Dcf from './Valuations/dcf'
import Eps from './Valuations/eps'
import './Valuation.css'
import ValuationChart from "./Valuations/ValuationChart"
import ChartWrapper from "./Valuations/ChartWrapper"
import Loading from "./Loading"
import HelpModal from './Financials/HelpModal'
import KnowMore from '../icon/KnowMore'
import { Popup } from 'semantic-ui-react'

function Valuations(props) {
    const [optionSelected, setValue] = useState('relativeValuation');
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const [show, setShow] = useState(false);
    const getURL = () => {
        if (optionSelected === "relativeValuation") {
            return process.env.PUBLIC_URL + '/Relative Valuation.pdf'
        } else if (optionSelected === "dcf") {
            return process.env.PUBLIC_URL + '/DCF Valuation Model.pdf'
        } else if (optionSelected === "eps") {
            return process.env.PUBLIC_URL + '/EPS Valuation Model.pdf'
        }
    }
    const getDisplayData = (data) => {
        if (data === "-" || isNaN(data)) {
            return data
        }
        if (data) {
            return parseFloat(data).toFixed(2)
        } else {
            return "-"
        }

    }
    const isNull = (data) => {
        if (data) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        const companyDetails = async () => {
            setpageData({})
            let url = ""
            url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            const cdresponse = await axiosPrivate.get(url);
            document.title = cdresponse.data.stock_name + "- Valuation | Best Stock Valuation Tools | Relative Valuation | Discounted Cash Flow Model - DCF | EPS Model | Stock Covariance | Historical price multiples | Intrinsic Value Calculator | How to calculate stock intrinsic value? ";
            setpageData(cdresponse.data)
        }
        companyDetails()
    }, [props.compSymbol, props.consolidated])
    return (
        <div className="Valuations">
            <section className="ValuationSection" style={{ paddingTop: "1px;" }}>
                <div className="ValuationNav">
                    <div className="FinancialMenu" id="relativeValuation" onClick={() => {
                        setValue('relativeValuation')
                    }
                    } style={optionSelected === "relativeValuation" ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }} >Relative Valuation</div>
                    <div className="FinancialMenu" id="Charting" onClick={() => {
                        setValue('Charting')
                    }
                    } style={optionSelected === "Charting" ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }} >Charting</div>
                    <div className="FinancialMenu" id="dcf" onClick={() => {
                        setValue('dcf')
                    }
                    } style={optionSelected === "dcf" ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }}  >DCF</div>
                    <div className="FinancialMenu" id="eps" onClick={() => {
                        setValue('eps')
                    }
                    } style={optionSelected === "eps" ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }}  >EPS</div>

                </div>
            </section>
            {optionSelected !== "Charting" && <section className="optionsFinData" id="companyNameValuation">
            <div className="leftAlign" style={{ paddingTop: "30px" }}>
                {pageData.stock_name}
            </div>
                <div className="rightAlign" style={{ paddingTop: "15px" }}>

                    <Popup
                        className="popupChart"
                        trigger={
                            <div className="optionButtonPrimary" onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
                                <KnowMore />
                                <HelpModal title="User Manual" onClose={() => setShow(false)} show={show}>
                                    <embed src={getURL()} width="100%" height="100%" />
                                </HelpModal>
                            </div>
                        }
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Know More.</div>}
                        style={{ zIndex: "999" }}
                    />

            </div>
            </section>}
            <section className="ValuationSection">
                
                <div className="finance">
                    {optionSelected === "relativeValuation" &&
                        <div className="ValuationTable" style={{ fontSize: "14px" }}>
                        <div className="ValuationRow">
                            {Object.keys(pageData).length !== 0 &&
                                <>
                                <div className="ValuationColumnThreeQuarters shadowBlock">
                                    <table className="finData relativeValuation" style={{ marginBottom: "0px" }}>
                                        <tbody>
                                            {'PriceMultiples' in pageData &&
                                                <tr>
                                                    <td className="finBold">Price Multiples</td>
                                                    <td className="finRight finRightBold">{pageData.PriceMultiples[0]}</td>
                                                    <td className="finRight finRightBold">{pageData.PriceMultiples[1]}</td>
                                                    <td className="finRight finRightBold">{pageData.PriceMultiples[2]}</td>
                                                    <td className="finRight finRightBold">{pageData.PriceMultiples[3]}</td>
                                                    <td className="finRight finRightBold">{pageData.PriceMultiples[4]}</td>
                                                </tr>
                                            }
                                            {'PriceToEarnings' in pageData && <tr>
                                                <td className="finLeft">Price to Earning</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToEarnings[0])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToEarnings[1])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToEarnings[2])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToEarnings[3])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToEarnings[4])}</td>
                                            </tr>}
                                            {'PriceToBookValue' in pageData && <tr>
                                                <td className="finLeft">Price to Book Value</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToBookValue[0])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToBookValue[1])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToBookValue[2])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToBookValue[3])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToBookValue[4])}</td>
                                            </tr>}
                                            {'PriceToTangibleBV' in pageData && <tr>
                                                <td className="finLeft">Price to Tangible Book Value</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToTangibleBV[0])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToTangibleBV[1])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToTangibleBV[2])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToTangibleBV[3])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToTangibleBV[4])}</td>
                                            </tr>}
                                            {'PriceToSales' in pageData && <tr>
                                                <td className="finLeft">Price to Sale</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToSales[0])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToSales[1])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToSales[2])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToSales[3])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToSales[4])}</td>
                                            </tr>}
                                            {'PriceToFCF' in pageData && <tr>
                                                <td className="finLeft">Price to FCF</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToFCF[0])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToFCF[1])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToFCF[2])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToFCF[3])}</td>
                                                <td className="finRight">{getDisplayData(pageData.PriceToFCF[4])}</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ValuationColumnOneQuarters shadowBlock" id="rightWarning" style={{color: "grey"}}>
                                    <span>Relative valuation is a highly useful and effective tool for valuing an asset. It relies on comparing how a company is priced to historical multiples or the current price multiples of similar assets. <br /><br />

However, it is essential to remember that price multiples can sometimes be misleading due to their dependence on daily price fluctuations and potential surprises in earnings caused by temporary events. Therefore, it is crucial to exercise caution when using them to make buy decisions. </span>
                                </div>
                                </>
                                } {Object.keys(pageData).length === 0 &&
                                    <Loading />
                                }
                        </div>
                        <div className="ValuationRow">
                            <div className="FullQuarter" style={{ padding: "10px", marginTop: "20px"}}>
                                <ValuationChart compSymbol={props.compSymbol}/>
                                </div>
                            </div>
                            
                        </div>
                    }
                    {optionSelected === "dcf" &&
                        <div className="ValuationTable" style={{ fontSize: "14px" }}>
                            <Dcf compSymbol={props.compSymbol}/>
                        </div>
                    }
                    {optionSelected === "eps" &&
                        <div className="ValuationTable" style={{ fontSize: "14px" }}>
                            <Eps compSymbol={props.compSymbol}/>
                        </div>
                    }
                    {optionSelected === "Charting" &&
                        <div className="ValuationTable" style={{ fontSize: "14px",paddingTop: "20px" }}>
                        <ChartWrapper compSymbol={props.compSymbol} compName={pageData.stock_name}/>
                        </div>
                    }

                </div>
            </section>
           
        </div>
    );
}

export default Valuations;