import React from 'react';
import Locked from '../Locked'

function PricingMobile() {
    return (
        <div className="Pricing">
            <div className="AboutUs-container">
                <Locked />
                <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>FAQ</div>
                <p style={{ fontSize: "13px" }}><b>1. How is premium subscription useful? </b><br />
                    A Premium subscription unlocks all Nivesh Karma platform features, providing access to numerous investing attributes and tools for making informed decisions based on a company's fundamental attributes. When used effectively, you can identify undervalued stocks and growth potential, enhancing your long-term investing success.
                </p>
                <p style={{ fontSize: "13px" }} ><b>2.	Do you provide a full refund in case of subscription cancellation?   </b><br />
                At present, we do not offer refunds for subscription cancellations. However, we recognize the need to improve our customer service and are actively working on it.
                </p>
                <p style={{ fontSize: "13px" }} >
                    <b>3.	What happens if I don't renew the subscription after 1 year? </b><br />
                Your account will remain active; however, you won't have access to premium features. You may continue to access certain premium features, such as multiple watchlists, custom ratios, and comparison columns, for a period of 30 days. After 30 days, your account will be reset to the beginner profile.
                </p>
                <p style={{ fontSize: "13px" }}>
                    <b>4.	How can I contact the support group for assistance with any issues? </b><br />
                You can visit the 'Feedback/Questions' page in the footer. Choose the appropriate category for your query or issues and send us a message. Our support team will respond within 48 hours."
                </p>

            </div>
        </div>
    );
}
export default PricingMobile;