import React, { useEffect } from 'react';
import useAuth from './hooks/useAuth';

function ExpiredSession(props) {
    const { auth, setAuth } = useAuth();
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        if (auth.user) {
            localStorage.clear()
            setAuth({})
        }

    }, [])
    return (
        <div className="ExpiredSession">
            <div>
                <div className="commonContainer" >
                    <div className="AboutUs-container" style={{ textAlign: "center", marginTop: "100px" }}>
                        Your current session expired. Please login again.                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExpiredSession;