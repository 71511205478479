import React from 'react';


function AboutUsMobile() {
    window.scrollTo(0, 0)
    document.title = "Our Story - Why Value Investing? | Invest Like Warren Buffett | How to get rich";
    return (
        <div className="AboutUs commonContainer" >
            <div className="AboutUs-container">
                <div style={{ textAlign: "left" }}><div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Our Story: </div></div>
                <p style={{ fontSize: "13px" }}>Market Fear is one of the strongest emotions everyone faces in investing. </p>
                <p style={{ fontSize: "13px" }}>When you first start investing, you may face several problems:</p>
                <ul>
                    <li style={{ fontSize: "13px" }}>Don't know what type of companies to invest.</li>
                    <li style={{ fontSize: "13px" }}>Don't know what risk you are carrying.</li>
                    <li style={{ fontSize: "13px" }}>Don't know how much to pay for a company stock.</li>
                    <li style={{ fontSize: "13px" }}>Afraid to commit large sum of money. </li>
                    <li style={{ fontSize: "13px" }}>You might chase trends and buy at too high price because of FOMO.</li>
                    <li style={{ fontSize: "13px" }}>When the price drops, you might panic and sell at a loss.</li>
                    <li style={{ fontSize: "13px" }}>When you have a small gain, you might sell for a small profit instead of letting winners run. This meant you might miss out on massive gains. </li>
                    <li style={{ fontSize: "13px" }}>You might spend too much time researching stock prices but not really learning anything about core business.</li>
                    <li style={{ fontSize: "13px" }}>You might waste hours learning about things you absolutely don't need, such as day trading. </li>
                </ul>
                <p style={{ fontWeight: "800", fontSize: "14px" }}>Sounds familiar. You might relate?</p>
                <p style={{ fontWeight: "600", fontSize: "13px"  }}>I faced all such problems until I discovered Value Investing. </p>
                <p style={{ fontWeight: "600", color: "#004F98", fontSize: "14px"  }}>Why Value Investing in The Current Times Make Sense? </p>
                <p style={{ fontSize: "13px" }}>Value Investing is a simple investing strategy. It's all about buying stocks for less than what they are worth. If you think a stock is worth INR 100, you only buy it when its selling for INR 50.</p>
                <p style={{ fontSize: "13px" }}>It seems simple but it's a concept many struggles with and needs extreme discipline & patience.</p>
                <p style={{ fontSize: "13px" }}>Value Investing is a proven strategy used by many of the most successful investors such as:</p>
                <ul>
                    <li style={{ fontSize: "13px" }}>Warren Buffett - net worth of $112 Billion</li>
                    <li style={{ fontSize: "13px" }}>Charlie Munger - $2.5 Billion</li>
                    <li style={{ fontSize: "13px" }}>Seth Klarman - $1.3 Billion</li>
                    <li style={{ fontSize: "13px" }}>Howard Marks - $2.2 Billion</li>
                    <li style={{ fontSize: "13px" }}>Carl Icahn - $9 Billion</li>
                    <li style={{ fontSize: "13px" }}>Rakesh Jhunjhunwala - $5.8 Billion </li>
                    <li style={{ fontSize: "13px" }}>Raamdeo Agrawal - $1 Billion</li>
                </ul>
                <p style={{ fontSize: "13px" }}>Once I discovered greatest value investors, I began to read books by <b>Peter Lynch, Joel Greenblatt, Mohanish Pabrai, Pat Dorsey, Guy Spier, Howard Mark, Raamdeo Agrawal wealth creation study</b> and others. I tried to learn everything about the most successful investors such as Warren Buffett and his mentor Benjamin Graham.</p>
                <ul>
                    <li style={{ fontSize: "13px" }}>I learned about the types of companies I should be investing in. Warren Buffett calls them Wonderful Companies with strong economic MOAT.</li>
                    <li style={{ fontSize: "13px" }}>I learned how to identify them.</li>
                    <li style={{ fontSize: "13px" }}>I learned how to value a company.</li>
                    <li style={{ fontSize: "13px" }}>I learned how to buy companies at bargain prices.</li>
                    <li style={{ fontSize: "13px" }}>And I learned when to sell a company.</li>
                </ul>
                <p style={{ fontWeight: "600" }}>I now have the confidence to:</p>
                <ul>
                    <li style={{ fontSize: "13px" }}>Find high quality stocks.</li>
                    <li style={{ fontSize: "13px" }}>Quickly judge it based on its fundamentals.</li>
                    <li style={{ fontSize: "13px" }}>Decide whether it is a good company or not.</li>
                    <li style={{ fontSize: "13px" }}>Value It.</li>
                    <li style={{ fontSize: "13px" }}>Determine a good price to pay.</li>
                    <li style={{ fontSize: "13px" }}>And when to sell it.</li>
                </ul>

                <p style={{ fontSize: "13px" }}> However, the difficulty I faced was the right platform which can make my job easy for business analysis. </p>
                <p style={{ fontSize: "13px", textAlign: "left" }}> This was a seed for starting <span style={{ fontSize: "18px", fontWeight: "800", color: "#26B467" }}>"Nivesh&nbsp;Karma"</span>.</p>

                <p style={{ fontSize: "13px" }}> Now, we want people across the world to leverage our platform, learn stock investing and create their own pipeline for passive income.</p>

                <p style={{ fontSize: "13px" }}> Our <b>"Research Platform"</b> is simple yet effective guide to investing process that can help to pick fundamentally strong business with long term growth potential.</p>

                <p style={{ fontSize: "13px" }}> Together, let's embark on a journey that will redefine your approach to investing and pave the way for your financial success. Your future as a confident, knowledgeable investor begins now. </p>

                <div style={{ fontSize: "15px", fontWeight: "600" }}> Nivesh Karma</div>


            </div>

        </div>
    );
}
export default AboutUsMobile;