import React, { useState, useEffect } from 'react';
import FinData from "./FinData"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import { Chart } from "react-google-charts";
import Loading from "../../../Company/Summary/Loading"


export const options = {
    title: "Total Liabilities, Total Assets and Total Equity",
    legend: { position: 'none' },
    bar: { groupWidth: '100%' },
    colors: ['#005073', '#1ebbd7', '#e9724d'],
};

export const optionsTwo = {
    title: "Accumulated Decreciation, Goodwill and Net Debt",
    legend: { position: 'none' },
    bar: { groupWidth: '100%' },
    colors: ['#005073', '#1ebbd7', '#e9724d'],
};


function BalanceSheetMobile(props) {
    const [pageData, setpageData] = useState({
        "data": [],
        //"horizontal_analysis": [],
        "extended_mapping": [],
        "mapData": [],
        "mapDataTwo": [],
        "CompanyName": ""
    });
    const [viewFull, setViewFull] = useState(false);
    // const [collapse, setcollapse] = useState(false);
    // const [modal, setmodal] = useState(false);
    // const [modalTwo, setmodalTwo] = useState(false);
    const [sort, setsort] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    function getDisplayValue(data, source) {
        if (data) {
            return data
        }
        if (source !== "map") {
            return source
        }
        return ""
    }
    const companyDetails = async () => {
        setpageData({
            "data": [],
            //"horizontal_analysis": [],
            "extended_mapping": [],
            "mapData": [],
            "mapDataTwo": [],
            "CompanyName": ""
        })
        const headers = {
            'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL,
            "accept": "application/json"
        };
        let url = ""
        let urlTwo = ""
        let balancesheet_data = ""
        let balancesheet_map = ""
        let horizontal_data = ""
        let vertical_data = ""
        let balancesheet_data_two = ""
        let balancesheet_map_two = ""
        let horizontal_data_two = ""
        let vertical_data_two = ""
        let map_data = []
        let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/balance-sheet/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        urlTwo = process.env.REACT_APP_DATA_API_URL + "/api/v1/balance-sheet-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url, { headers });
        const cdresponseTwo = await axiosPrivate.get(urlTwo, { headers });

        if (sort) {
            balancesheet_data = cdresponse.data['balance_sheet']
            horizontal_data = cdresponse.data['yoy_growth']
            vertical_data = cdresponse.data['as_pct_of_assets_or_liabilities']
            balancesheet_data_two = cdresponseTwo.data['balance_sheet']
            horizontal_data_two = cdresponseTwo.data['yoy_growth']
            vertical_data_two = cdresponseTwo.data['as_pct_of_assets_or_liabilities']
        } else {
            balancesheet_data = cdresponse.data['balance_sheet'].reverse()
            horizontal_data = cdresponse.data['yoy_growth'].reverse()
            vertical_data = cdresponse.data['as_pct_of_assets_or_liabilities'].reverse()
            balancesheet_data_two = cdresponseTwo.data['balance_sheet'].reverse()
            horizontal_data_two = cdresponseTwo.data['yoy_growth'].reverse()
            vertical_data_two = cdresponseTwo.data['as_pct_of_assets_or_liabilities'].reverse()
        }
        balancesheet_map = cdresponse.data['prompts']
        balancesheet_map_two = cdresponseTwo.data['prompts']
        company_name = cdresponse.data['stock_name']
        map_data.push(["", "Total Liabilities", "Total Assets", "Total Equity"]) 
        balancesheet_data.filter(mapItem => mapItem.date_header.data !== "TTM").map(mapdata => (
            map_data.push([getDisplayValue(mapdata['date_header'], 'm'), parseFloat(getDisplayValue(mapdata['totalLiabilities'], 'm')), parseFloat(getDisplayValue(mapdata['totalAssets'], 'm')), parseFloat(getDisplayValue(mapdata['totalEquity'], 'm'))])
        ))
        map_data_two.push(["", "Accumulated Depreciation", "Net Debt"])
        balancesheet_data.filter(mapItem => mapItem.date_header.data !== "TTM").map(mapdata => (
            map_data_two.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['accumulatedDepreciation'], 'm')), parseFloat(getDisplayValue(mapdata['netDebt'], 'm'))])
        ))
        document.title = company_name + "- Balance Sheet | Vertical & Horizontal analysis";
        setpageData({
            "data": balancesheet_data,
            "percentOfIncome": vertical_data,
            "yoyGrowth": horizontal_data,
            "dataTwo": balancesheet_data_two,
            "percentOfIncomeTwo": vertical_data_two,
            "yoyGrowthTwo": horizontal_data_two,
            "extended_mapping": balancesheet_map,
            "extended_mapping_two": balancesheet_map_two,
            "mapData": map_data,
            "mapDataTwo": map_data_two,
            "CompanyName": company_name
        })

    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort])

    return (
        <>
            {!viewFull ?
                <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    <div className="leftAlign" style={{ paddingBottom: "40px" }}>
                        {pageData.CompanyName}
                    </div>
                    {pageData.mapData.length > 0 &&
                        <>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "right" }}>
                        <div className="optionButtonMobile" onClick={() => setViewFull(true)}> View full Statement</div>
                    </div>
                    
                        <Chart
                        chartType="Bar"
                        data={pageData.mapData}
                        options={options}
                        loader={<div>Loading Chart</div>}

                        />
                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "20px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#005073' }}></div><div>&nbsp;Total&nbsp;Liabilities</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#1ebbd7' }}></div><div>&nbsp;Total&nbsp;Assets</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#e9724d' }}></div><div>&nbsp;Total&nbsp;Equity</div></div>
                        </div>

                        <Chart
                            chartType="Bar"
                            data={pageData.mapDataTwo}
                            options={optionsTwo}
                            loader={<div>Loading Chart</div>}

                        />
                        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "20px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#005073' }}></div><div>&nbsp;Accumulated&nbsp;Depreciation</div></div>
                            <div style={{ display: "flex", justifyContent: "start" }}><div style={{ minWidth: "12px", minHeight: "10px", backgroundColor: '#1ebbd7' }}></div><div>&nbsp;Net&nbsp;Debt</div></div>
                        </div>
                        </>
                    }
                    {pageData.mapData.length === 0 &&
                        <Loading />
					}
                </div> :
                <div>
                    <div className="optionButtonPrimary" style={{ display: "flex", justifyContent: "right" }}>
                        <div className="optionButtonMobile" onClick={() => setViewFull(false)}> View Summary Chart</div>
                    </div>
                    <FinData pageData={pageData} sort={sort} setsort={setsort} source="balancesheet" horizontal={true} vertical={true} />
                </div>
            }
            </>
    );
}

export default BalanceSheetMobile;