import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
//import './Peers.css'
import Loading from "../Company/Loading"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import InvestorSectorChartMobile from "./InvestorSectorChartMobile"
import InvestmentHistoryMobile from "./InvestorHistoryMobile"
import HelpModal from './Company/Modal/CommonModal'
import useAuth from '../hooks/useAuth';

function InvestorMobile() {
    const { investor } = useParams();
    const [pageData, setpageData] = useState({});
    const [stock, setStock] = useState();
    const [show, setShow] = useState(false);
    const { auth } = useAuth();
    const getUrl = (url) => {
        return "/Company/" + url
    }

    const viewHelper = (symbol) => {
        setStock(symbol)
        setShow(true)
    }

    const axiosPrivate = useAxiosPrivate();

    const companyDetails = async () => {
        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/investors/" + investor + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data

        console.log(userData)
        document.title = cdresponse.data.name + "- Superinvestor Portfolio | Shareholdings & investment value";
        setpageData(userData)
    }

    const getSectorURL = (url) => {
        return "/Sector/" + url
    }

    const getIndustryURL = (url) => {
        return "/Industry/" + url
    }

    useEffect(() => {
        setTimeout(function () { companyDetails(); }, 50);
    }, [auth.user])




    return (
        <div className="PeersMain">
            {auth.user &&
                <section className="Peers">
                    {Object.keys(pageData).length !== 0 &&
                        <>
                            <div className="leftHeader"><h2>{pageData.name} </h2></div>

                            <HelpModal title="Investment History" onClose={() => setShow(false)} show={show}>
                                <InvestmentHistoryMobile investorID={investor} stock={stock} />
                            </HelpModal>
                            <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                                <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                    <thead style={{ backgroundColor: "white", zIndex: "20" }}>
                                        <tr>
                                            <th className="PeerColumnHeading leftPeer intersection" style={{ fontWeight: 600, }}>S.&nbsp;No.</th>
                                            <th className="PeerColumnHeading" style={{ whiteSpace: "nowrap" }}>Stock Symbol</th>
                                            <th className="PeerColumnHeading">Name</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">% of Portfolio</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Activity</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Change</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Change%</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Shares</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Current Price</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Investment Value (Cr)</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">52W Low</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">52W High</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">% Above 52W low</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Industry</th>
                                            <th className="PeerColumnHeading PeerTabledataCenter">Sector</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pageData.investments.map((dataKey, index) => (
                                            <tr>
                                                <td className="leftPeer">{index + 1}</td>
                                                <td className="PeerTabledataLeft SubHeader"><div className="StockLink" onClick={() => viewHelper(dataKey.stock_symbol)}>{dataKey.stock_symbol}</div></td>
                                                <td className="PeerTabledataLeft SubHeader">{dataKey.name}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.pctOfProfile}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.activity}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.change}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.pctChange}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.share}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.currentPrice}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.investmentValue}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.low52}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.high52}</td>
                                                <td className="PeerTabledataCenter SubHeader" style={{ minWidth: "80px" }}>{dataKey.pctAbove52WLow}</td>
                                                <td className="PeerTabledataLeft SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getIndustryURL(dataKey.industry)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.industry}</a></td>
                                                <td className="PeerTabledataLeft SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}><a href={getSectorURL(dataKey.sector)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.sector}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <InvestorSectorChartMobile investor={investor} />
                            </div>
                        </>
                    }{
                        Object.keys(pageData).length === 0 &&
                        <Loading />
                    }
                </section>
            }
            {!auth.user &&
                <section className="Peers">
                    Please Login to view this section.
                </section>
            }
        </div>
    );
}

export default InvestorMobile;