import React, { useState, useEffect, useRef } from 'react';
import Loading from "../../../Company/Summary/Loading"
import { Popup } from 'semantic-ui-react'
import HelpModal from '../Modal/CommonModal'
import ExternalLink from '../../../icon/ExternalLink'
import useAuth from '../../../hooks/useAuth';
import Locked from '../../../Locked'
import { downloadExcel } from "react-export-table-to-excel";

export const options = {
    legend: { position: "bottom" },
    title: "Equity, Total Liabilities and Total Assets",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

export const optionsTwo = {
    legend: { position: "bottom" },
    title: "Gross Block, Accumulated Decreciation and Net Block",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};



function FinData(props) {
    const [show, setShow] = useState(false);
    const [collapse, setcollapse] = useState(false);
    const [modal, setmodal] = useState(false);
    const [modalTwo, setmodalTwo] = useState(false);
    const { auth } = useAuth();
    const tableRef = useRef(null);
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const toggleSort = () => {
        if (props.sort) {
            props.setsort(false)
        } else {
            props.setsort(true)
        }
    }
    const setBold = (data) => {
        if (data === "true") {
            return true
        } else {
            return false
        }
    }
    function getDisplayValue(data, key) {
        if (data) {
            if (key === "dilutedEPS") {
                return parseFloat(Math.round(data * 100) / 100).toFixed(2)
            } else {
                return numberFormat(Math.round(data / 10))
            }
        } else {
            return 0
        }
    }
    function getDisplayDate(data, ttmInd, ytdInd) {
        console.log(ttmInd)
        if (ttmInd) {
            return "TTM"
        } else if (ytdInd) {
            return "YTD"
        } else {
            return data
        }
    }

    const getHeaderDisplay = (source) => {
        if (source === "quaterly" || source === "profitloss") {
            return "% of Income"
        } else {
            return "% of Assets/Total Liabilities"
        }

    }


    const toggleCollapse = () => {
        if (collapse) {
            setcollapse(false)
        } else {
            setcollapse(true)
        }
    }
    const toggleModal = () => {
        if (modal) {
            setmodal(false)
        } else {
            setmodal(true)
        }
    }
    const toggleModalTwo = () => {
        if (modalTwo) {
            setmodalTwo(false)
        } else {
            setmodalTwo(true)
        }
    }
    function getTableClass(index) {
        if (index % 2 === 0) {
            return "EvenTable"
        } else {
            return "OddTable"
        }
    }
    const getURL = (source) => {
        let url = ""
        if (source === "balancesheet") {
            url = process.env.PUBLIC_URL + '/Balance Sheet.pdf'
        } else if (source === "cashflow") {
            url = process.env.PUBLIC_URL + '/Cash Flow.pdf'
        } else if (source === "profitloss") {
            url = process.env.PUBLIC_URL + '/Income Statement.pdf'
        } else if (source === "quaterly") {
            url = process.env.PUBLIC_URL + '/Income Statement.pdf'
        }
        return url
    }

    const openCompanies = () => {
        if (["ITC Ltd", "HDFC Bank Ltd", "Tata Consultancy Services Ltd"].includes(props.pageData.CompanyName)) {
            return true
        } else {
            return false
        }
    }

    const lockedUser = () => {
        if ((!modal && !modalTwo) || (auth.user && auth.roles) || (auth.user && openCompanies())) {
            return true
        } else {
            return false
        }
    }


    function handleDownloadExcel() {

        let header = ["Figures in Rs Crores."]
        let body = []
        props.pageData.data.forEach(item => {
            header.push(item.date_header)
        })
        props.pageData.extended_mapping.forEach(item => {
            let tempData = []
            tempData.push(item.field_prompt)
            console.log(modal)
            console.log(modalTwo)
            if (!modal && !modalTwo) {
                props.pageData.data.forEach(data => {
                    tempData.push(getDisplayValue(data[item.field_name], item.field_name))
                })
            } else if (modal) {
                props.pageData.yoyGrowth.forEach(data => {
                    tempData.push(data[item.field_name])
                })
            } else if (modalTwo) {
                props.pageData.percentOfIncome.forEach(data => {
                    tempData.push(data[item.field_name])
                })
            }
            body.push(tempData)
        })

        downloadExcel({
            fileName: props.source,
            sheet: props.source,
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }






    useEffect(() => {
    }, [props.pageData, props.sort])

    return (
        <div className="BalanceSheetMain">
            {props.pageData.data.length > 1 &&
                <>
                    <section className="optionsFinData">
                        <div className="leftAlign">
                            {props.pageData.CompanyName}
                    </div>
                    <div style={{ paddingTop: "5px", display: "grid", gridTemplateColumns: "auto auto auto", gridColumnGap: "20px", gridRowGap: "5px" }}>

                        {(props.source === "quaterly" || props.source === "profitloss") &&
                            <div style={{ display: "flex", paddingRight: "15px" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a href={props.pageData.ReportLink} target="_blank" style={{ textDecoration: "none", fontSize: "13px" }}>Financial Result</a></div></div>
                            }
                            {!modalTwo && props.horizontal && <div className="optionButtonPrimary">
                                <div className="optionButton" onClick={toggleModal}> {modal ? "View Financial Data" : "YOY Growth"}</div>
                            </div>}
                            {!modal && props.vertical && <div className="optionButtonPrimary">
                                <div className="optionButton" onClick={toggleModalTwo}> {modalTwo ? "View Financial Data" : getHeaderDisplay(props.source)}</div>
                            </div>}
                            <div className="optionButtonPrimary" onClick={toggleSort}>
                                <div className="optionButton"> {props.sort ? "Ascending View" : "Descending View"}</div>
                            </div>
                            

                        </div>
                    </section>
                    {lockedUser() &&
                        <>
                            <section className="BalanceSheet" style={{ marginBottom: props.source === "balancesheet" ? "40px" : "110px" }}>
                                <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                                    <table className="PeersTable finData">
                                        <thead>
                                            <tr>
                                                <th className="leftBold stikeyHeader intersection leftPeer">
                                                    <i className="small ">Figures in Rs Crores.</i>
                                                </th>

                                                {props.pageData.data.map(data => (
                                                    <th className="finHead columnHeading columnHeadingScreener">{data.date_header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.pageData.extended_mapping.map(item => (
                                                <>
                                                    <tr
                                                        style={{}}
                                                    >
                                                        <td className="leftBold SubHeader stikeyHeader leftPeer"
                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        >
                                                            <div className="headingWithPopup">
                                                                <div>{item.field_prompt}</div>
                                                            </div>
                                                        </td>

                                                        {!modal && !modalTwo && props.pageData.data.map(dataitem => (

                                                            <td className="tabledata SubHeader"

                                                                style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                            > {getDisplayValue(dataitem[item.field_name], item.field_name)}</td>

                                                        ))}
                                                        {modal && props.pageData.yoyGrowth.map(dataitem => (

                                                            <td className="tabledata SubHeader"

                                                                style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                            > {dataitem[item.field_name]}</td>

                                                        ))}

                                                        {modalTwo && props.pageData.percentOfIncome.map(dataitem => (

                                                            <td className="tabledata SubHeader"

                                                                style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                            > {dataitem[item.field_name]}</td>

                                                        ))}
                                                    </tr>

                                                </>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                            {props.source === "balancesheet" &&
                                <section className="BalanceSheet">
                                    <div style={{ textAlign: "left", paddingTop: "10px", paddingLeft: "10px", fontWeight: "600" }}>
                                        Debt, Intangibles and Depreciation Schedule
                    </div>
                                    <table className="finData" ref={tableRef}>
                                        <tbody>
                                            <tr>
                                                <td className="leftBold stikeyHeader">
                                                    <i className="small">Figures in Rs Crores.</i>
                                                </td>

                                                {props.pageData.dataTwo.map(data => (
                                                    <td className="columnHeading">{data.date_header}</td>
                                                ))}
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            {props.pageData.extended_mapping_two.map(item => (
                                                <>
                                                    <tr
                                                        style={{}}
                                                    >
                                                        <td className="leftBold SubHeader stikeyHeader"
                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        >
                                                            <div className="headingWithPopup">
                                                                <div>{item.field_prompt}</div> 
                                                            </div>
                                                        </td>

                                                        {!modal && !modalTwo && props.pageData.dataTwo.map(dataitem => (

                                                            <td className="tabledata SubHeader"

                                                                style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                            > {getDisplayValue(dataitem[item.field_name], item.field_name)}</td>

                                                        ))}
                                                        {modal && props.pageData.yoyGrowthTwo.map(dataitem => (

                                                            <td className="tabledata SubHeader"

                                                                style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                            > {dataitem[item.field_name]}</td>

                                                        ))}
                                                        {modalTwo && props.pageData.percentOfIncomeTwo.map(dataitem => (

                                                            <td className="tabledata SubHeader"

                                                                style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                            > {dataitem[item.field_name]}</td>

                                                        ))}
                                                    </tr>

                                                </>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </section>

                            }
                        </>
                    }
                    { !lockedUser() &&
                        <Locked />

                    }

                </>}
            {props.pageData.data.length <= 1 &&
                <Loading />
            }
        </div>
    );
}

export default FinData;