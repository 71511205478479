import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomeHeader from './Header'
import MobileHeader from './Mobile/MobileHeader'
import { BrowserView, MobileView } from 'react-device-detect';


function HeaderWrapper(props) {
    
    const getOption = async () => {
        let urlTwo = ""
        urlTwo = "https://api.niveshkarma.com/api/v1/stocks-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axios.get(urlTwo);
        props.setOptionData(cdresponse.data)
    }


    useEffect(() => {
        getOption()

    }, [props.showSearch]);
    return (

        <div className="HomeHeaderWrapper" style={{ backgroundColor: "hsl(245, 40%, 98%)", width: "100%"}}>
            <BrowserView style={{ backgroundColor: "hsl(245, 40%, 98%)" }}>
                <HomeHeader optionData={props.optionData} showSearch={props.showSearch} searchHistory={props.searchHistory} setSearchHistory={props.setSearchHistory}/>
            </BrowserView>
            <MobileView>
                <MobileHeader optionData={props.optionData} />
            </MobileView>
        </div>
    );
}

export default HeaderWrapper;