import React, { useEffect } from "react";
import useAuth from './hooks/useAuth';
import './Loginbutton.css';
import { Link} from 'react-router-dom';
import { useGoogleOneTapLogin } from '@react-oauth/google';
//import axios from 'axios';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from './api/axios';
const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL + "/google-auth/api/v1/token";
//const LOGIN_URL = "https://nk-auth.onrender.com" + "/google-auth/api/v1/token";
function LoginButton(props) {   
    const { setAuth } = useAuth();
    let navigate = useNavigate();
    useEffect(() => {
    }, [])

    const handleCallbackResponse = async (e) => {
        try {
            const response = await axios.post(LOGIN_URL,
                '',
                {
                    headers: { 'Content-Type': 'application/json', 'x-token-type': 'One-tap', "x-token": e.credential, 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL,},

                }
            );

            //console.log(JSON.stringify(response));
            const email = response?.data?.user?.email
            const user = response?.data?.user?.first_name + " " + response?.data?.user?.last_name;
            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const roles = response?.data?.user?.premium;
            const ratios = response?.data?.user?.add_ratios;
            const historicalRatios = response?.data?.user?.historical_ratios;
            const peerColumns = response?.data?.user?.peer_ratios;
            const itemsData = { user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken, email }
            console.log(itemsData)
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(itemsData))
            setAuth({ user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken, email});
            navigate("/");
        } catch (err) { 
            console.log(err)
            /*if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password'); 
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }*/
        }
    }

    useGoogleOneTapLogin({
        onError: error => console.log(error),
        onSuccess: response => handleCallbackResponse(response),
        googleAccountConfigs: {
            client_id: "293375405749-i8qhl8aujplmiapclijjv0ffqob8enr4.apps.googleusercontent.com"
        },
    })



    return (
        <Link className="LoginButton" key="Login_Button" >
            <div className="Login" onClick={props.onClick}>
                LOGIN
            </div>
            
        </Link>
    );
}

export default LoginButton;