import React, { useState, useEffect, useRef } from 'react';
import Loading from "./Loading"
import './Balancesheet.css'
import { Popup } from 'semantic-ui-react'
import RowChart from './RowChart'
import HelpModal from './HelpModal'
import ExternalLink from '../../icon/ExternalLink'
import ExportSVG from '../../icon/ExportSVG'
import KnowMore from '../../icon/KnowMore'
import Asc from '../../icon/Asc'
import Desc from '../../icon/Desc'
import useAuth from '../../hooks/useAuth';
import Locked from '../../Locked'
import { downloadExcel } from "react-export-table-to-excel";


export const options = {
    legend: { position: "bottom" },
    title: "Equity, Total Liabilities and Total Assets",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

export const optionsTwo = {
    legend: { position: "bottom" },
    title: "Gross Block, Accumulated Decreciation and Net Block",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};



function FinancialData(props) {
    const [show, setShow] = useState(false);
    const [collapse, setcollapse] = useState(false);
    const [selectedView, setSelectedView] = useState("fin");
    const [modal, setmodal] = useState(false);
    const [modalTwo, setmodalTwo] = useState(false);
    const { auth } = useAuth();
    const tableRef = useRef(null);
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const toggleSort = () => {
        if (props.sort) {
            props.setsort(false)
        } else {
            props.setsort(true)
        }
    }
    const setBold = (data) => {
        if (data === "true") {
            return true
        } else {
            return false
        }
    }
    function getDisplayValue(data, key) {
        if (data) {
            if (key === "dilutedEPS") {
                return parseFloat(Math.round(data * 100) / 100).toFixed(2)
            } else {
                return numberFormat(Math.round(data/10))
            }
        } else {
            return 0
        }
    }
    function getDisplayDate(data, ttmInd, ytdInd) {
        console.log(ttmInd)
        if (ttmInd) {
            return "TTM"
        } else if (ytdInd) {
            return "YTD"
        } else {
            return data
        }
    }

    const getHeaderDisplay = (source) => {
        if (source === "quaterly" || source === "profitloss") {
            return "As % of Income"
        } else {
            return "As % of Assets or Total Liabilities"
        }

    }


    const toggleCollapse = () => {
        if (collapse) {
            setcollapse(false)
        } else {
            setcollapse(true)
        }
    }
    const toggleModal = () => {
        if (modal) {
            setmodal(false)
        } else {
            setmodal(true)
        }
    }
    const toggleModalTwo = () => {
        if (modalTwo) {
            setmodalTwo(false)
        } else {
            setmodalTwo(true)
        }
    }
    function getTableClass(index) {
        if (index % 2 === 0) {
            return "EvenTable"
        } else {
            return "OddTable"
        }
    }
    const getURL = (source) => {
        let url = ""
        if (source === "balancesheet") {
            url = process.env.PUBLIC_URL + '/Balance Sheet.pdf'
        } else if (source === "cashflow") {
            url = process.env.PUBLIC_URL + '/Cash Flow.pdf'
        } else if (source === "profitloss") {
            url = process.env.PUBLIC_URL + '/Income Statement.pdf'
        } else if (source === "quaterly") {
            url = process.env.PUBLIC_URL + '/Income Statement.pdf'
        }
        return url
    }

    const openCompanies = () => {
        if (["ITC Ltd", "HDFC Bank Ltd", "Tata Consultancy Services Ltd"].includes(props.pageData.CompanyName)) {
            return true
        } else {
            return false
        }
    }

    const lockedUser = () => {
        if ((!modal && !modalTwo) || (auth.user && auth.roles) || (auth.user && openCompanies())) {
            return true
        } else {
            return false
        }
    }


    function handleDownloadExcel() {

        let header = ["Figures in Rs Crores."]
        let body = []
        props.pageData.data.forEach(item => {
            header.push(item.date_header)
        })
        props.pageData.extended_mapping.forEach(item => {
            let tempData = []
            tempData.push(item.field_prompt)
            console.log(modal)
            console.log(modalTwo)
            if (!modal && !modalTwo) {
                props.pageData.data.forEach(data => {
                    tempData.push(getDisplayValue(data[item.field_name], item.field_name))
                })
            } else if (modal) {
                props.pageData.yoyGrowth.forEach(data => {
                    tempData.push(data[item.field_name])
                })
            } else if (modalTwo) {
                props.pageData.percentOfIncome.forEach(data => {
                    tempData.push(data[item.field_name])
                })
            }
            body.push(tempData)
        })

        downloadExcel({
            fileName: props.source,
            sheet: props.source,
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }

 
    useEffect(() => {
    }, [props.pageData, props.sort])

    return (
        <div className="BalanceSheetMain">
            {props.pageData.data.length > 1 &&
            <>
            <section className="optionsFinData">
                <div className="leftAlign">
                    {props.pageData.CompanyName}
                </div>
                <div className="rightAlign">
                   
                        {(props.source === "quaterly" || props.source === "profitloss") &&
                            <div style={{ display: "flex", paddingRight: "15px" }}><div><ExternalLink /></div><div style={{ paddingTop: "3px" }}><a href={props.pageData.ReportLink} target="_blank" style={{ textDecoration: "none", color:"#606f7b"}}>Financial Result</a></div></div>
                        }

                        <div style={{ display: "flex", columnGap: "5px" }}>
                            <div style={{ paddingTop: "5px" }}>View:</div>
                            <div style={{ display:"flex" }}>
                                <div>
                                    <select
                                        className="ViewSelected"
                                        value={selectedView}
                                        onChange={e => setSelectedView(e.target.value)}
                                    >
                                    <option value="fin">Financial Data</option>
                                    {props.source === "quaterly" && <option value="percent">As % of Income</option>}
                                    {props.source === "profitloss" && <option value="percent">As % of Income</option>}
                                    {props.source === "balancesheet" && <option value="percent">As % of Assets or Liabilities</option>}
                                    {props.source !== "quaterly" && <option value="yoy">YOY Growth</option>}
                                    </select>
                                </div>
                                <div style={{ marginLeft: "-30px", fontSize: "30px", paddingTop: "1px" }}>
                                    <svg
                                        width="20px"
                                        height="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 300 300"
                                        shapeRendering="geometricPrecision"
                                        textRendering="geometricPrecision"
                                        fill="#555"
                                        {...props}
                                    >
                                        <path
                                            d="M67.79 65.49l170.343-.697L150 176.963 67.79 65.49z"
                                            transform="matrix(1 0 0 .68815 -8 16.35)"
                                            stroke="#555"
                                            strokeWidth={0.6}
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={toggleSort} style={{ cursor: "pointer" }}>
                                {props.sort ? <Asc /> : <Desc />}
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Sort.</div>}
                            style={{ zIndex: "999" }}
                        />

                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
                                    <KnowMore />
                                    <HelpModal title="User Manual" onClose={() => setShow(false)} show={show}>
                                        <embed src={getURL(props.source)} width="100%" height="100%" />
                                    </HelpModal>
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>Know More.</div>}
                            style={{ zIndex: "999" }}
                        />

                        <Popup
                            className="popupChart"
                            trigger={
                                <div className="optionButtonPrimary" onClick={handleDownloadExcel} style={{ cursor: "pointer" }}>
                                    <ExportSVG />
                                </div>
                            }
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white"  }}>Export to Excel</div>}
                            style={{ zIndex: "999" }}
                        />
                            
                        
                        
                </div>
                </section>
                {lockedUser() &&
                    <>
                    <section className="BalanceSheet shadowBlock" style={{ marginBottom: props.source === "balancesheet" ? "40px" : "110px" }}>
                            <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                                <table className="PeersTable finData">
                                    <thead>
                                        <tr>
                                            <th className="leftBold stikeyHeader intersection leftPeer">
                                                <i className="small ">Figures in Rs Crores.</i>
                                            </th>

                                            {props.pageData.data.map(data => (
                                                <th className="finHead columnHeading columnHeadingScreener">{data.date_header}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.pageData.extended_mapping.map(item => (
                                            <>
                                                <tr
                                                    style={{}}
                                                >
                                                    <td className="leftBold SubHeader stikeyHeader leftPeer"
                                                        style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                    >
                                                        <div className="headingWithPopup">
                                                            <div>{item.field_prompt}</div>
                                                            <div>
                                                                <Popup
                                                                    className="popupChart"
                                                                    trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                    content={<RowChart mapkey={item.field_name} mapData={props.pageData.data} />}
                                                                    style={{ zIndex: "999" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {selectedView==="fin" && props.pageData.data.map(dataitem => (

                                                        <td className="tabledata SubHeader"

                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        > {getDisplayValue(dataitem[item.field_name], item.field_name)}</td>

                                                    ))}
                                                    {selectedView === "yoy" && props.pageData.yoyGrowth.map(dataitem => (

                                                        <td className="tabledata SubHeader"

                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        > {dataitem[item.field_name]}</td>

                                                    ))}

                                                    {selectedView === "percent" && props.pageData.percentOfIncome.map(dataitem => (

                                                        <td className="tabledata SubHeader"

                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        > {dataitem[item.field_name]}</td>

                                                    ))}
                                                </tr>

                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        {props.source === "balancesheet" &&
                            <section className="BalanceSheet">
                                <div style={{ textAlign: "left", paddingTop: "10px", paddingLeft: "10px", fontWeight: "600" }}>
                                    Debt, Intangibles and Depreciation Schedule
                    </div>
                            <table className="finData" ref={tableRef}>
                                    <tbody>
                                        <tr>
                                            <td className="leftBold stikeyHeader">
                                                <i className="small">Figures in Rs Crores.</i>
                                            </td>

                                            {props.pageData.dataTwo.map(data => (
                                                <td className="columnHeading">{data.date_header}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {props.pageData.extended_mapping_two.map(item => (
                                            <>
                                                <tr
                                                    style={{}}
                                                >
                                                    <td className="leftBold SubHeader stikeyHeader"
                                                        style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                    >
                                                        <div className="headingWithPopup">
                                                            <div>{item.field_prompt}</div>
                                                            <div>
                                                                <Popup
                                                                    className="popupChart"
                                                                    trigger={<img className="chartIcon" src={process.env.PUBLIC_URL + '/chart_Icon.png'} />}
                                                                    content={<RowChart mapkey={item.field_name} mapData={props.pageData.dataTwo} />}
                                                                    style={{ zIndex: "999" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {selectedView === "fin" && props.pageData.dataTwo.map(dataitem => (

                                                        <td className="tabledata SubHeader"

                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        > {getDisplayValue(dataitem[item.field_name], item.field_name)}</td>

                                                    ))}
                                                    {selectedView === "yoy" && props.pageData.yoyGrowthTwo.map(dataitem => (

                                                        <td className="tabledata SubHeader"

                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        > {dataitem[item.field_name]}</td>

                                                    ))}
                                                    {selectedView === "percent" && props.pageData.percentOfIncomeTwo.map(dataitem => (

                                                        <td className="tabledata SubHeader"

                                                            style={setBold(item.header_indicator) ? { color: '#000', fontWeight: '600' } : { color: '#000' }}
                                                        > {dataitem[item.field_name]}</td>

                                                    ))}
                                                </tr>

                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </section>

                        }
                    </>
                }
                { !lockedUser() &&
                    <Locked />

                }
            
            </>}
            {props.pageData.data.length <= 1 &&
                <Loading />
            }
        </div>
    );
}

export default FinancialData;