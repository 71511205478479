import React from 'react';
import useAuth from '../hooks/useAuth';


function SubscriptionDetails() {
    const { auth } = useAuth();
    return (
        <div style={{ paddingTop: "20px", paddingLeft: "20px" }} >
            <div style={{ fontWeight: "600", fontSize: "16px", textAlign: "left" }}>
                Subscription Details
            </div>
            <div style={{ paddingLeft: "40px", paddingTop: "50px" }}>
                <div style={{ display: "grid", gridTemplateColumns: "auto", maxWidth: "500px" }}>
                    {auth.roles?
                        <div style={{ textAlign: "left" }}>You are suscribed to our Premium Membership</div>
                        :
                         <div style={{ textAlign: "left" }}>You are not suscribed to our Premium Membership</div>
                    }
                    
                </div>
            </div>
        </div>
    );
}
export default SubscriptionDetails;