import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"

function WatchlistActionOption(props) {
    const [check, setCheck] = useState(false);
    const axiosPrivate = useAxiosPrivate();



    const handleChange = async (event, watchlist) => {
        let tempData = props.watchlistData
        for (let i = 0; i < tempData.watch_lists.length; i++) {
            if (tempData.watch_lists[i]["list_name"] === watchlist) {
                if (event) {
                    tempData.watch_lists[i]["watch_list"].push(props.compSymbol)
                } else {
                    const index = tempData.watch_lists[i]["watch_list"].indexOf(props.compSymbol);
                    if (index > -1) { // only splice array when item is found
                        tempData.watch_lists[i]["watch_list"].splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
                
            }
        }
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const response = await axiosPrivate.post(url, tempData.watch_lists);
        props.toggleVariableChange()
    }




    function getDefaultCheck() {
        if (props.watchlistData.watch_lists != null) {
            for (let i = 0; i < props.watchlistData.watch_lists.length; i++) {
                if (props.watchlistData.watch_lists[i]["list_name"] === props.name) {
                    for (let j = 0; j < props.watchlistData.watch_lists[i]["watch_list"].length; j++) {
                        if (props.watchlistData.watch_lists[i]["watch_list"][j] === props.compSymbol) {
                            return true
                        }

                    }
                }
            }
        } else {
            return false
        }
        return false




        if (props.watchlistData.companiesList.includes(props.compSymbol)) {
            return true
        } else {
            return false
        }
    }
   

    useEffect(() => {
        setCheck(getDefaultCheck())
    }, [])
    return (
        <>
            <div style={{ padding: "10px 20px 10px 10px", borderBottom: "1px solid #F5F5F5", display: "flex" }}>
                <div style={{ paddingRight: "10px" }}>
                    <input type="checkbox" defaultChecked={getDefaultCheck()} onChange={(e) => handleChange(e.target.checked, props.name)} style={{ width: "18px", height: "18px" }} />
                </div>
                <div style={{ paddingTop: "3px" }}>
                    {props.name}
                </div>

            </div>
            
        </>
    );
}
export default WatchlistActionOption;
