import React from 'react';
import PriceSummaryMobile from './PriceSummaryMobile'
import SummaryOneMobile from './SummaryOneMobile'
import SummaryTwoMobile from './SummaryTwoMobile'
import UserRatioMobile from './UserRatioMobile'
import AboutMobile from './AboutMobile'

function SummaryMobile(props) {
    return (
        <div className="CompanySummaryMainMobile">
            <PriceSummaryMobile compSymbol={props.compSymbol} setCompName={props.setmaincompanyName} compName={props.compName} />
            <SummaryOneMobile compSymbol={props.compSymbol} />
            <SummaryTwoMobile compSymbol={props.compSymbol} />
            <UserRatioMobile compSymbol={props.compSymbol} ratioData={props.ratioData} />
            <AboutMobile compSymbol={props.compSymbol} />
            {/* <div className="finSummary">
                <div className="finSum">
                    <PartOne compSymbol={props.compSymbol}  />
                    <PartThree compSymbol={props.compSymbol} consolidated={props.consolidated} />

                </div>
            </div>
            <div className="finSummary">
                <div className="finSum">
                    <Score compSymbol={props.compSymbol} ratioData={props.ratioData} />

                    <About compSymbol={props.compSymbol} />

                </div>
            </div>*/}


            
        </div>

    );
}
export default SummaryMobile;