import React, { useEffect, useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css';
import './Mobile.css';
import Home from './Home/Home'
import Blogs from './Blogs/Blogs'
import ScreenerWrapper from './Screener/ScreenerWrapper'
import Company from './Company/Company'
import HeaderWrapper from './HeaderWrapper'
import Login from "./Login"
import ForgotPassword from "./ForgotPassword"
import Register from "./Register"
import Footer from './Footer'
import AboutUs from './Aboutus'
import Pricing from './Pricing'
import Terms from './Terms'
import Faq from './Faq'
import Coffee from './Coffee'
import Feedback from './Feedback'
import Disclaimer from './Disclaimer'
import InvestingFramework from './InvestingFramework'
import Sector from './Sector'
import Logout from './Logout'
import Watchlist from './Watchlist'
import Industry from './Industry'
import Investor from './Investor'
import InvestingBooks from './InvestingBooks'
import Superinvestor from './Superinvestor'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthProvider'
import axios from './api/axios';
import PredefinedScreener from './Screener/PredefinedScreener'
import SomethingWrong from './SomethingWrong'
import Payment from './Payment'
import PaymentConfirmation from './PaymentConfirmation'
import Profile from './Profile/Profile'
import ReactGA from "react-ga4";
import ExpiredSession from "./ExpiredSession"
import PageNotFound from "./PageNotFound"
import { BrowserView, MobileView } from 'react-device-detect';

import HomeMobile from './Mobile/Home/HomeMobile';
import CompanyMobile from './Mobile/Company/CompanyMobile';
import FooterMobile from './Mobile/FooterMobile'
import PricingMobile from './Mobile/PricingMobile'
import FaqMobile from './Mobile/FaqMobile'
import FeedbackMobile from './Mobile/FeedbackMobile'
import AboutUsMobile from './Mobile/AboutUsMobile'
import TermsMobile from './Mobile/TermsMobile'
import DisclaimerMobile from './Mobile/DisclaimerMobile'
import WatchlistMobile from './Mobile/WatchlistMobile'
import InvestingBooksMobile from './Mobile/InvestingBooksMobile'
import InvestingFrameworkMobile from './Mobile/InvestingFrameworkMobile'
import SuperInvestorMobile from './Mobile/SuperInvestorMobile'
import InvestorMobile from './Mobile/InvestorMobile'
import SectorMobile from './Mobile/SectorMobile'
import IndustryMobile from './Mobile/IndustryMobile'
import ScreenerMobile from './Mobile/ScreenerMobile'
import PredefinedScreenerMobile from './Mobile/Screener/PredefinedScreenerMobile'
import PaymentMobile from './Mobile/PaymentMobile'
import PaymentConfirmationMobile from './Mobile/PaymentConfirmationMobile'
import ProfileMobile from './Mobile/Profile/ProfileMobile'
import CommodityWrapper from './Commodity/CommodityWrapper'

function App() {
    const [ratioData, setRatioData] = useState({});
    const [optionData, setOptionData] = useState([]);
    const [showSearch, setShowSearch] = useState(false);

    function getDefaultSearchHistory() {
        if (JSON.parse(localStorage.getItem("nkSearchHistory")) && JSON.parse(localStorage.getItem("nkSearchHistory")).length > 0) {
            return JSON.parse((localStorage.getItem("nkSearchHistory")))
        } else {
            return ["HDFCBANK", "ITC", "SBIN"]
        }
    }


    const [searchHistory, setSearchHistory] = useState(getDefaultSearchHistory);
    ReactGA.initialize("G-6JYFVGNCJC");

    const preLoader = async () => {
        let URL = "https://api.niveshkarma.com/api/v1/ratio-variables?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY
        const response = await axios.get(URL,
            '',
            {
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL, },

            }
        );
        
        let tempData = response?.data
        tempData.Valuation["Current year"].push({ code: "Sector", hint: "Sector", prompt: "Sector" })
        tempData.Valuation["Current year"].push({ code: "Industry", hint: "Industry", prompt: "Industry" })
        setRatioData(tempData)
    }


    useEffect(() => {
        preLoader()
      //  window.addEventListener('resize', function (event) {
      //      refresh()
     //   });
    }, [])
    return (
        <div className="App" style={{ backgroundColor: "hsl(245, 40%, 98%)" }}>
            <AuthProvider>
                <HeaderWrapper optionData={optionData} setOptionData={setOptionData} showSearch={showSearch} searchHistory={searchHistory} setSearchHistory={setSearchHistory}/>

                { /*<HomeContent />*/}
                <section id="HomeSection">
                    <div style={{ display: "none" }}>
                        Test Nivesh Karma
                        Stock investing
                        Stock screener
                        Stock market analysis
                        Stock research tools
                        Stock screening criteria
                        Best stock screener
                        Top stock investing websites
                        Stock analysis software
                        Stock market screening filters
                        Stock screening strategies
                        Stock screeners for beginners
                        Free stock screeners
                        Stock picking tools
                        Stock market data analysis
                        Technical analysis tools
                        Fundamental analysis software
                        Stock screening metrics
                        Dividend stock screeners
                        Growth stock screeners
                        Value investing screeners
                        Stock research platform
                        Stock market research tools
                        Investment research platform
                        Stock research websites
                        Equity research platform
                        Stock analysis platform
                        Top stock research tools
                        Best stock research websites
                        Fundamental analysis tools
                        Technical analysis platform
                        Stock market analytics
                        Research reports for stocks
                        Real-time stock data platform
                        Stock screening and analysis
                        Stock market insights
                        Financial data research platform
                        Stock charts and graphs
                        Company financials and ratios
                        Stock valuations 
                        Stock valuation
                        Business valuation
                        How to find value of the stock? 
                        How to find stock intrinsic value? 
                        Superinvestors stocks 
                        Superinvestor's picks
                        Superinvestor picks  
                        Stock screening website
                        Stock screener tool
                        Stock filter platform
                        Best stock screening tool
                        Stock screening software
                        Top stock screener website
                        Free stock screener
                        Advanced stock screener
                        Customizable stock screeners
                        Real-time stock screener
                        Stock scanner tool
                        Sector-specific stock screener
                        Value stock screener
                        Growth stock screener
                        Dividend stock screener
                        Technical stock screener
                        Fundamental stock screener
                        Penny stock screener
                        Stock screening API
                        Nivesh Karma
                        Karma
                        Nivehs
                        Invest in stocks, mutual funds, fixed deposits 
                        Screener
                        Economic Times Market
                        Best Stock Research and Analysis Tool in India
                        Best Stock Screener for Indian Stocks
                        Best Stock Screener in India 
                        Best Stock Screener
                        Indian stock screener
                        Top 5 Websites For Stock Market
                        Best Stock Market Research Websites in India
                        Fundamental Stock Analysis
                        Best platform for Live Stock screeners and analysis
                        Best Sites for Indian Stock Market Analysis
                        Stock Analysis & Best Financial Tools 
                        Top Investing Websites
                        Must Know Websites for Indian Stock Market Investors
                        Best Trading Apps of India
                        Most Useful Websites for Indian Traders
                        Intraday Screener
                        Best stocks to buy 
                        Bets stocks in India 
                        Best stock screener for fundamental analysis
                        Stock screener list 
                        Free stock screener 
                        Top value investors in India
                        Good Day trading Stocks
                        Zacks investment research 
                        Nivesh Mitra
                        Screener.in
                        Seeking alpha 
                        Finology 
                        Tikr 
                        NSE India
                        BSE India
                        Trade Brains Portal
                        Money Control
                        Investing.com

                        Investment management
                        Loan
                        Mortgage
                        Financial services
                        Mortgage calculator
                        Investment
                        Financial service company
                        Mortgage rate
                        Quicken loans
                        Life insurance
                        Mortgage payment calculator
                        Payday loans
                        Investment planning
                        Personal loan
                        Finance
                        Investment plan
                        Retirement accounts
                        Finance calculator
                        Return on investment
                        Investment banker
                        Google finance
                        Nerd wallet
                        ROI
                        Investment banking
                        Investment planning
                        Financial independence
                        Student loans
                        Foreign Investment
                        Foreign direct investment
                        Yahoo finance
                        Financial advisor
                        Mutual funds
                        Toyota financials
                        Insurance
                        FPA
                        Wealth management
                        Financial institutions
                        Investing
                        Refinance
                        E-finance
                        Google finance world
                        Stock quotes
                        Ministry of finance
                        Finance yahoo com
                        Google shares
                        Shares
                        Stock exchange
                        Finanzen.
                        Invest
                        Buy
                        Sell
                        Purchase
                        Wall street
                        Finance sector
                        Budget
                        Minister of finance
                        Treasury
                        Bonds
                        Commodity prices
                        Search shareholders
                        Create stock screener. 
                        HDFC Bank stock 
                        HDFC Bank share price  
                    </div>
                <BrowserView>
                    <Routes>

                            <Route path="/" element={<Home optionData={optionData} ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch} searchHistory={searchHistory} setSearchHistory={setSearchHistory}/>} />
                            {/*<Route path="/Login" element={<Login />}  />
                            <Route path="/ForgotPassword" element={<ForgotPassword />} loader={() => setShowSearch(true)}/>
                            <Route path="/Register" element={<Register />} loader={() => setShowSearch(true)}/>*/}
                            <Route path="/Company/:companyName" element={<Company ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>}/>
                            <Route path="/Screener" element={<ScreenerWrapper ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            {/*  <Route path="/Learning" element={<Learning />} />
                            <Route path="/Resources" element={<Resources />} /> 
                            <Route path="/StockIdeas" element={<StockIdeas />} />
                            <Route path="/StockMap" element={<StockMap />} />
                            <Route path="/StockWatchlist" element={<StockWatchlist />} /> 
                            <Route path="/Blogs" element={<Blogs />}/>*/}
                            <Route path="/OurStory" element={<AboutUs setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Pricing" element={<Pricing setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/FAQ" element={<Faq setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Terms" element={<Terms setShowSearch={setShowSearch} showSearch={showSearch}/>}/>
                            <Route path="/Disclaimer" element={<Disclaimer setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Sector/:sector" element={<Sector ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Industry/:industry" element={<Industry ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Investor/:investor" element={<Investor setShowSearch={setShowSearch} showSearch={showSearch}/>}/>
                            <Route path="/Superinvestor" element={<Superinvestor setShowSearch={setShowSearch} showSearch={showSearch}/>}/>
                            <Route path="/PredefinedScreener/:id" element={<PredefinedScreener ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Pricing" element={<Coffee setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Feedback" element={<Feedback setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Logout" element={<Logout />} />
                            <Route path="/SomethingWrong" element={<SomethingWrong setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Watchlist" element={<Watchlist optionData={optionData} ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/InvestingBooks" element={<InvestingBooks setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/InvestingFramework" element={<InvestingFramework setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/SubscriptionDetails" element={<Payment setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Confirmation" element={<PaymentConfirmation setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Profile" element={<Profile ratioData={ratioData} setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/Commodities" element={<CommodityWrapper setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="/SessionExpired" element={<ExpiredSession setShowSearch={setShowSearch} showSearch={showSearch}/>} />
                            <Route path="*" element={<PageNotFound setShowSearch={setShowSearch} showSearch={showSearch}/>} />

                            
                    
                        </Routes>
                    </BrowserView>
                    <MobileView>
                        <Routes>
                            <Route path="/" element={<HomeMobile />} />
                            <Route path="/Company/:companyName" element={<CompanyMobile ratioData={ratioData} />} />
                            <Route path="/OurStory" element={<AboutUsMobile />} />
                            <Route path="/Pricing" element={<PricingMobile />} />
                            <Route path="/FAQ" element={<FaqMobile />} />
                            <Route path="/Feedback" element={<FeedbackMobile />} />
                            <Route path="/Terms" element={<TermsMobile />} />
                            <Route path="/Disclaimer" element={<DisclaimerMobile />} />
                            <Route path="/Watchlist" element={<WatchlistMobile ratioData={ratioData} />} />
                            <Route path="/InvestingBooks" element={<InvestingBooksMobile />} />
                            <Route path="/InvestingFramework" element={<InvestingFrameworkMobile />} />
                            <Route path="/Superinvestor" element={<SuperInvestorMobile />} />
                            <Route path="/Investor/:investor" element={<InvestorMobile />} />
                            <Route path="/Sector/:sector" element={<SectorMobile ratioData={ratioData} />} />
                            <Route path="/Industry/:industry" element={<IndustryMobile ratioData={ratioData} />} />
                            <Route path="/Screener" element={<ScreenerMobile ratioData={ratioData} />} />
                            <Route path="/PredefinedScreener/:id" element={<PredefinedScreenerMobile ratioData={ratioData} />} />
                            <Route path="/SubscriptionDetails" element={<PaymentMobile />} />
                            <Route path="/Confirmation" element={<PaymentConfirmationMobile />} />
                            <Route path="/Profile" element={<ProfileMobile ratioData={ratioData} />} />
                            <Route path="/SessionExpired" element={<ExpiredSession />} />
                            <Route path="*" element={<PageNotFound />} />
                            
                        </Routes>
                    </MobileView>
                </section>
                <BrowserView>
                    <Footer />
                </BrowserView>
                <MobileView>
                    <FooterMobile />
                </MobileView>
            </AuthProvider>
        </div>
    )
}
const AppName = () => ( 
    <BrowserRouter>
        <GoogleOAuthProvider clientId="293375405749-i8qhl8aujplmiapclijjv0ffqob8enr4.apps.googleusercontent.com">
            <App />
        </GoogleOAuthProvider>
    </BrowserRouter>
)

export default AppName