import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import './ProfitLoss.css'
import { Chart } from "react-google-charts";
import FinancialData from "./FinancialData"


export const options = {
    legend: { position: "bottom" },
    title: "Sales, Total Expense, and Net Profit",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

export const optionsTwo = {
    legend: { position: "bottom" },
    title: "Sales, EBITDA, EBIT, and Net Profit",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

function ProfitLoss(props) {
    const [pageData, setpageData] = useState({
        "data": [],
     //   "horizontal_analysis": [],
        "extended_mapping": [],
     //   "mapData": [],
     //   "mapDataTwo": [],
        "CompanyName": "Company Name Placeholder"
    });
    const axiosPrivate = useAxiosPrivate();

   // const [collapse, setcollapse] = useState(false);
   // const [modal, setmodal] = useState(false);
   // const [modalTwo, setmodalTwo] = useState(false);
    const [sort, setsort] = useState(false);
    

    const companyDetails = async () => {
        setpageData({
            "data": [],
            //   "horizontal_analysis": [],
            "extended_mapping": [],
            //   "mapData": [],
            //   "mapDataTwo": [],
            "CompanyName": "Company Name Placeholder"
        })
        let url = ""
        let profitloss_data = ""
        let profitloss_map = ""
        let horizontal_data = ""
        let vertical_data = ""
       // let map_data = []
       // let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/profit-loss/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        if (sort) {
            profitloss_data = cdresponse.data['profit_loss']
            horizontal_data = cdresponse.data['yoy_growth']
            vertical_data = cdresponse.data['as_pct_of_income']
        } else {
            profitloss_data = cdresponse.data['profit_loss'].reverse()
            horizontal_data = cdresponse.data['yoy_growth'].reverse()
            vertical_data = cdresponse.data['as_pct_of_income'].reverse()
        }
        profitloss_map = cdresponse.data['prompts']
        company_name = cdresponse.data['stock_name']
    //    map_data.push(["", "Total Sales", "Total Expenses", "Net Profit"])
    //    profitloss_data.filter(mapItem => mapItem.date_header.data !== "TTM").map(mapdata => (
    //        map_data.push([getDisplayValue(mapdata['date_header'], 'm'), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')) - parseFloat(getDisplayValue(mapdata['Net Profit'], 'm')), parseFloat(getDisplayValue(mapdata['Net Profit'], 'm'))])
   //     ))
   //     map_data_two.push(["", "Total Sales", "EBITDA", "EBIT", "Net Profit"])
   //     profitloss_data.filter(mapItem => mapItem.date_header.data !== "TTM").map(mapdata => (
    //        map_data_two.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['Sales'], 'm')) + parseFloat(getDisplayValue(mapdata['Other Income'], 'm')), parseFloat(getDisplayValue(mapdata['EBITDA'], "m")), parseFloat(getDisplayValue(mapdata['EBIT'], "m")), parseFloat(getDisplayValue(mapdata['Net Profit'], "m"))])
   //     ))
        document.title = company_name + "- Profit & Loss Statement | Income Statement | Vertical & Horizontal analysis";
        setpageData({
            "data": profitloss_data,
            "percentOfIncome": vertical_data,
            "yoyGrowth": horizontal_data,
            "extended_mapping": profitloss_map,
     //       "mapData": map_data,
     //       "mapDataTwo": map_data_two,
            "ReportLink": "https://www.bseindia.com/stock-share-price/equity/scripcode/" + cdresponse.data.bse_code + "/financials-results",
            "CompanyName": company_name
        })


    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort])

    return (
        <>
            <FinancialData pageData={pageData} sort={sort} setsort={setsort} source="profitloss" horizontal={ true } vertical={ true }/>
        </>
    );
}

export default ProfitLoss;