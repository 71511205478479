import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "../../Company/Summary/Loading"




function EmployeeMobile(props) {

    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const employeeDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/executives/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData({
            "Employees": cdresponse.data.executive
        })
    }

    const getDisplayValue = (data) => {
        if (!data || data === 0 || data === "") {
            return "-"
        } else {
            return data
        }

    }

    useEffect(() => {
        employeeDetails()
    }, [props.compSymbol])



    return (
        <div className="Employee" style={{ overflow: "auto" }}>
            <h3 style={{ textAlign: "left" }}>Executive Summary</h3>
            {Object.keys(pageData).length !== 0 &&
                <table className="EmployeeTable finData">
                    <tbody>
                        <tr>
                            <th className="leftEmployee" style={{ fontWeight: "600", fontSize: "12px" }}>
                                Name
                        </th>
                        <th className="columnHeadingEmployee" style={{ fontWeight: "600", fontSize: "12px" }}>
                                Position
                        </th>
                        <th className="columnHeadingEmployeeCenter" style={{ fontWeight: "600", fontSize: "12px" }}>
                                Age
                        </th>
                        <th className="columnHeadingEmployeeCenter" style={{ fontWeight: "600", fontSize: "12px" }}>
                                Compensation
                        </th>
                        <th className="columnHeadingEmployeeCenter" style={{ fontWeight: "600", fontSize: "12px" }}>
                                Since
                        </th>
                        </tr>
                        {pageData.Employees.map(dataKey => (
                            <tr>
                                <td style={{ fontSize: "11px" }}>{dataKey.name}</td>
                                <td style={{ fontSize: "11px" }}>{dataKey.position}</td>
                                <td style={{ fontSize: "11px" }}>{getDisplayValue(dataKey.age)}</td>
                                <td style={{ fontSize: "11px" }}>{getDisplayValue(dataKey.compensation)}</td>
                                <td style={{ fontSize: "11px" }}>{getDisplayValue(dataKey.since)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default EmployeeMobile;