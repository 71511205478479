import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Chart } from "react-google-charts";
import Icon from "../../icon/Icon"
import Loading from "../../Company/Summary/Loading"
import './DcfMobile.css'


export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};


function DcfMobile(props) {
    const [pageData, setpageData] = useState({
        "price": 0,
        "mos": 0,
        "pvRatio": 0,
        "avgFCFPerYear": 0,
        "assumedFCFGRNextFiveYear": 0,
        "assumedFCFGRFiveTenYear": 0,
        "terminalGrowthRate": 0,
        "discountRate": 0,
        "debt": 0,
        "cashEquivalent": 0,
        "shareOutstanding": 0,
        "dcfInput": [],
        "discountedFCF": 0,
        "discountedTerminal": 0,
        "valueOfFirm": 0,
        "marketCap": 0,
        "ivps": 0,
        "currentYear": 0,
        "mapData": [],

    });
    const axiosPrivate = useAxiosPrivate();
    const [readyToDisplay, setreadyToDisplay] = useState(false)
    function getPercentValue(data) {
        if (data) {
            let finalData = data.replace("%", "");
            return parseFloat(finalData) / 100
        } else {
            return data
        }

    }
    const forceDefault = (data) => {
        if (data) {
            return data
        } else {
            return parseFloat(0.0)
        }
    }

    function getDisplayValue(data) {
        if (data) {
            return (Math.round(data * 100) / 100).toLocaleString('en-IN')
        } else {
            return ""
        }
    }

    const geMOS = (data) => {
        if (data === 0) {
            return "-"
        } else {
            let tempMOS = Math.round(data * 100) / 100
            return tempMOS.toString() + "%"
        }

    }
    const getColor = (data) => {
        let tempData = parseFloat(data)
        if (tempData < 10.0) {
            return "#FF5733"
        } else if (tempData >= 10.0 && tempData < 30.0) {
            return "#E49B0F"
        } else {
            return "#00A36C"
        }
    }
    function evaluateDCF() {
        let var_price = pageData.price
        let rate = 0
        let lastFCF = pageData.avgFCFPerYear
        let outputDiscountedFCF = 0
        let var_dcf_input = [{ "year": "Current", "fcf": lastFCF, "GrowthRate": "", "FCFPV": "" }, { "year": "+1", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+2", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+3", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+4", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+5", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+6", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+7", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+8", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+9", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+10", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "Terminal", "fcf": "", "GrowthRate": "", "FCFPV": "" }]
        let outputDiscountedTerminal = 0
        let varMapData = [["", "FCF"], [String(pageData.currentYear), Math.round(pageData.avgFCFPerYear)]]
        var_dcf_input.map((fcfData, fcfIndex) => {
            if (fcfIndex >= 1) {
                if (fcfIndex < 6) {
                    rate = pageData.assumedFCFGRNextFiveYear / 100
                } else if (fcfIndex < 11) {
                    rate = pageData.assumedFCFGRFiveTenYear / 100
                } else {
                    rate = pageData.terminalGrowthRate / 100
                }
                let varFCF = lastFCF * (1 + rate)
                let finalRate = rate
                lastFCF = varFCF
                let varFCFPV = 0
                if (fcfIndex < 11) {
                    varFCFPV = lastFCF / Math.pow((1 + pageData.discountRate / 100), (fcfIndex))
                    outputDiscountedFCF = outputDiscountedFCF + varFCFPV
                } else {
                    varFCFPV = lastFCF / (pageData.discountRate / 100 - rate)
                    outputDiscountedTerminal = varFCFPV / Math.pow((1 + pageData.discountRate / 100), (10))
                }
                let finalFCFPV = 0
                if (fcfIndex < 11) {
                    finalFCFPV = varFCFPV
                } else {
                    finalFCFPV = outputDiscountedTerminal
                }
                varMapData.push([String(pageData.currentYear + fcfIndex), Math.round(lastFCF)])
                fcfData.fcf = Math.round(lastFCF)
                fcfData.GrowthRate = (finalRate * 100).toFixed(2)
                fcfData.FCFPV = Math.round(finalFCFPV)
            }
        })
        let firmValue = outputDiscountedFCF + outputDiscountedTerminal + parseFloat(pageData.cashEquivalent) - parseFloat(pageData.debt)
        let firmIvps = firmValue / pageData.shareOutstanding
        let pv = pageData.price / firmIvps
        let mos = ""
        console.log(firmIvps)
        if (firmIvps > 0.0) {
            let tempVal = ((firmIvps - var_price) / firmIvps) * 100
            mos = tempVal.toString()
        } else {
            mos = 0
        }
        let updatedValue = {};
        updatedValue = {
            "mos": mos,
            "dcfInput": var_dcf_input,
            "discountedFCF": Math.round(outputDiscountedFCF),
            "discountedTerminal": Math.round(outputDiscountedTerminal),
            "valueOfFirm": Math.round(firmValue),
            "ivps": Math.round(firmIvps),
            "pvRatio": Math.round(pv * 100) / 100,
            "mapData": varMapData
        };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));



    }
    const handlePlus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedFCFGRNextFiveYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedFCFGRNextFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedFCFGRFiveTenYear)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "assumedFCFGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "grterm") {
            let value = parseFloat(pageData.terminalGrowthRate)
            value = value + 0.5
            if (value >= 10) {
                value = 10
            }
            updatedValue = { "terminalGrowthRate": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value + 0.5
            if (value >= 30) {
                value = 30
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const handleMinus = (fieldId) => {
        let updatedValue = {};
        if (fieldId === "grfive") {
            let value = parseFloat(pageData.assumedFCFGRNextFiveYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedFCFGRNextFiveYear": value.toFixed(2) };
        } else if (fieldId === "grten") {
            let value = parseFloat(pageData.assumedFCFGRFiveTenYear)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "assumedFCFGRFiveTenYear": value.toFixed(2) };
        } else if (fieldId === "grterm") {
            let value = parseFloat(pageData.terminalGrowthRate)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "terminalGrowthRate": value.toFixed(2) };
        } else if (fieldId === "discRate") {
            let value = parseFloat(pageData.discountRate)
            value = value - 0.5
            if (value <= 0) {
                value = 0
            }
            updatedValue = { "discountRate": value.toFixed(2) };
        }
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    }

    const handleFCFRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedFCFGRNextFiveYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleFCFTenRateChange = e => {
        let updatedValue = {};
        updatedValue = { "assumedFCFGRFiveTenYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleFCFTerminalChange = e => {
        let updatedValue = {};
        updatedValue = { "terminalGrowthRate": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDiscountRateChange = e => {
        let updatedValue = {};
        updatedValue = { "discountRate": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };

    const handleAvgFCFChange = e => {
        let updatedValue = {};
        updatedValue = { "avgFCFPerYear": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDebtChange = e => {
        let updatedValue = {};
        updatedValue = { "debt": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleCashChange = e => {
        let updatedValue = {};
        updatedValue = { "cashEquivalent": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleShareOutstandingChange = e => {
        let updatedValue = {};
        updatedValue = { "shareOutstanding": e.target.value };
        setpageData(pageData => ({
            ...pageData,
            ...updatedValue
        }));
    };
    const handleDCFCalculation = () => {
        evaluateDCF()
    }
    const getDefaultInput = async () => {
        setpageData({
            "price": 0,
            "mos": 0,
            "pvRatio": 0,
            "avgFCFPerYear": 0,
            "assumedFCFGRNextFiveYear": 0,
            "assumedFCFGRFiveTenYear": 0,
            "terminalGrowthRate": 0,
            "discountRate": 0,
            "debt": 0,
            "cashEquivalent": 0,
            "shareOutstanding": 0,
            "dcfInput": [],
            "discountedFCF": 0,
            "discountedTerminal": 0,
            "valueOfFirm": 0,
            "marketCap": 0,
            "ivps": 0,
            "currentYear": 0,
            "mapData": [],

        })
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/valuation-ratios-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);


        let var_avg_fcf = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR005)) / 10
        let var_current_year = 2023
        let var_dcf_input = [{
            "year": "Current",
            "fcf": Math.round(var_avg_fcf),
            "GrowthRate": "",
            "FCFPV": ""
        }, { "year": "+1", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+2", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+3", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+4", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+5", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+6", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+7", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+8", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+9", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "+10", "fcf": "", "GrowthRate": "", "FCFPV": "" }, { "year": "Terminal", "fcf": "", "GrowthRate": "", "FCFPV": "" }]
        let var_assumed_fcf_gr_next_five_year = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR003))
        let var_assumed_fcf_gr_five_ten_year = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR004))
        let var_terminal_growth_rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR011))
        let var_discount_rate = forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR010))
        let var_debt = (forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR008))).toFixed(0)
        let var_cash_equiv = (forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR009))).toFixed(0)
        let var_share = (forceDefault(parseFloat(cdresponse.data.valuation_ratio.VR017))).toFixed(0)
        let var_market_cap = (forceDefault(parseFloat(cdresponse.data.market_cap))).toFixed(0)
        let var_price = (forceDefault(parseFloat(cdresponse.data.close_price))).toFixed(0)


        let rate = 0
        let lastFCF = var_avg_fcf
        let outputDiscountedFCF = 0
        let outputDiscountedTerminal = 0
        let varMapData = [["", "FCF"], [String(var_current_year), Math.round(var_avg_fcf)]]
        var_dcf_input.map((fcfData, fcfIndex) => {
            if (fcfIndex >= 1) {
                if (fcfIndex < 6) {
                    rate = var_assumed_fcf_gr_next_five_year / 100
                } else if (fcfIndex < 11) {
                    rate = var_assumed_fcf_gr_five_ten_year / 100
                } else {
                    rate = var_terminal_growth_rate / 100
                }
                let varFCF = lastFCF * (1 + rate)
                let finalRate = rate
                lastFCF = varFCF
                let varFCFPV = 0
                if (fcfIndex < 11) {
                    varFCFPV = lastFCF / Math.pow((1 + var_discount_rate / 100), (fcfIndex))
                    outputDiscountedFCF = outputDiscountedFCF + varFCFPV
                } else {
                    varFCFPV = lastFCF / (var_discount_rate / 100 - finalRate)
                    outputDiscountedTerminal = varFCFPV / Math.pow((1 + var_discount_rate / 100), (10))
                }
                let finalFCFPV = 0
                if (fcfIndex < 11) {
                    finalFCFPV = varFCFPV
                } else {
                    finalFCFPV = outputDiscountedTerminal
                }
                // let finalFCFPV = varFCFPV
                varMapData.push([String(var_current_year + fcfIndex), Math.round(lastFCF)])
                fcfData.fcf = Math.round(lastFCF)
                fcfData.GrowthRate = (finalRate * 100).toFixed(2)
                fcfData.FCFPV = Math.round(finalFCFPV)
            }
        })

        let firmValue = outputDiscountedFCF + outputDiscountedTerminal - parseFloat(var_debt) + parseFloat(var_cash_equiv)
        let firmIvps = firmValue / var_share
        let pv = var_price / firmIvps
        let mos = 0
        if (firmIvps > 0) {
            mos = ((firmIvps - var_price) / firmIvps) * 100
        } else {
            mos = 0
        }
        setpageData(
            {
                "price": var_price,
                "mos": mos,
                "pvRatio": Math.round(pv * 100) / 100,
                "avgFCFPerYear": Math.round(var_avg_fcf),
                "assumedFCFGRNextFiveYear": var_assumed_fcf_gr_next_five_year,
                "assumedFCFGRFiveTenYear": var_assumed_fcf_gr_five_ten_year,
                "terminalGrowthRate": var_terminal_growth_rate,
                "discountRate": var_discount_rate,
                "debt": var_debt,
                "cashEquivalent": var_cash_equiv,
                "shareOutstanding": var_share,
                "dcfInput": var_dcf_input,
                "discountedFCF": Math.round(outputDiscountedFCF),
                "discountedTerminal": Math.round(outputDiscountedTerminal),
                "valueOfFirm": Math.round(firmValue),
                "marketCap": Math.round(var_market_cap),
                "ivps": Math.round(firmIvps),
                "currentYear": var_current_year,
                "mapData": varMapData
            }
        )
    }


    useEffect(() => {
        getDefaultInput()
    }, [props.compSymbol, readyToDisplay])

    return (

        <>
            {pageData['price'] !== 0 &&
                <>
                    <div className="ValuationRow" style={{ marginTop: "20px" }}>
                    <div className="ValuationOddDivMobile"><div className="leftIntend middle">Initial Free Cash Flow:  </div><div className="userInputMobile"><div className="grey leftRoundedMobile">₹</div><div className="paddedUserInput"><input name="avg_fcf" type="number" className="customInputMobile" placeholder="Average FCF per year" value={pageData.avgFCFPerYear} onChange={handleAvgFCFChange} /></div><div className="grey rightRoundedMobile">Cr</div></div></div>
                    <div className="ValuationEvenDivMobile"><div className="leftIntend middle">Growth Rate(1-5 yrs): </div><div className="userInputMobile"><div className="grey leftRoundedMobile" >{pageData.assumedFCFGRNextFiveYear}</div><div  onClick={() => handleMinus("grfive")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="gr_five" type="range" className="customInputRangeMobile" min="1" max="30" step="0.5" value={pageData.assumedFCFGRNextFiveYear} onChange={handleFCFRateChange} /></div><div  onClick={() => handlePlus("grfive")}><Icon source="plus" /></div><div className="grey rightRoundedMobile" >%</div></div></div>
                    <div className="ValuationOddDivMobile"><div className="leftIntend middle">Growth Rate(6-10 yrs): </div><div className="userInputMobile"><div className="grey leftRoundedMobile" >{pageData.assumedFCFGRFiveTenYear}</div><div  onClick={() => handleMinus("grten")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="gr_ten" type="range" className="customInputRangeMobile" min="1" max="30" step="0.5" value={pageData.assumedFCFGRFiveTenYear} onChange={handleFCFTenRateChange} /></div><div  onClick={() => handlePlus("grten")}><Icon source="plus" /></div><div className="grey rightRoundedMobile" >%</div></div></div>
                    <div className="ValuationEvenDivMobile"><div className="leftIntend middle">Terminal Growth Rate: </div><div className="userInputMobile"><div className="grey leftRoundedMobile" >{pageData.terminalGrowthRate}</div><div  onClick={() => handleMinus("grterm")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="gr_ter" type="range" className="customInputRangeMobile" min="1" max="10" step="0.5" value={pageData.terminalGrowthRate} onChange={handleFCFTerminalChange} /></div><div  onClick={() => handlePlus("grterm")}><Icon source="plus" /></div><div className="grey rightRoundedMobile">%</div></div></div>
                    <div className="ValuationOddDivMobile"><div className="leftIntend middle">Discount Rate: </div><div className="userInputMobile"><div className="grey leftRoundedMobile" >{pageData.discountRate}</div><div  onClick={() => handleMinus("discRate")}><Icon source="minus" /></div><div className="paddedUserInput"><input name="disc_rate" type="range" className="customInputRangeMobile" min="1" max="30" step="0.5" value={pageData.discountRate} onChange={handleDiscountRateChange} /></div><div  onClick={() => handlePlus("discRate")}><Icon source="plus" /></div><div className="grey rightRoundedMobile">%</div></div></div>
                    <div className="ValuationEvenDivMobile"><div className="leftIntend middle">Total Debt: </div><div className="userInputMobile"><div className="grey leftRoundedMobile">₹</div><div className="paddedUserInput"><input name="debt" type="number" className="customInputMobile" placeholder="Debt" value={pageData.debt} onChange={handleDebtChange} /></div><div className="grey rightRoundedMobile">Cr</div></div></div>
                    <div className="ValuationOddDivMobile"><div className="leftIntend middle">Cash and Cash Eqv: </div><div className="userInputMobile"><div className="grey leftRoundedMobile">₹</div><div className="paddedUserInput"><input name="cash" type="number" className="customInputMobile" placeholder="Cash and Cash Equivalent" value={pageData.cashEquivalent} onChange={handleCashChange} /></div><div className="grey rightRoundedMobile">Cr</div></div></div>
                    <div className="ValuationEvenDivMobile"><div className="leftIntend middle">Shares Outstanding: </div><div className="userInputMobile"><div className="grey leftRoundedMobile">₹</div><div className="paddedUserInput"><input name="share" type="number" className="customInputMobile" placeholder="Share Outstanding" value={pageData.shareOutstanding} onChange={handleShareOutstandingChange} /></div><div className="grey rightRoundedMobile">Cr</div></div></div>
                    <div className="optionButtonPrimary topMarginVal">
                        <div className="optionButtonVal" onClick={handleDCFCalculation}> Calculate DCF</div>
                        <div className="optionButtonVal" onClick={getDefaultInput}> Reset</div>
                    </div>


                    <div style={{ padding: "40px 20px 0px 20px" }}>
                        <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Valuation Result</div>
                        <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Market Capitalization: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{getDisplayValue(pageData.marketCap)}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Value of Firm: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{getDisplayValue(pageData.valueOfFirm)}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Margin of Saftey: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{geMOS(pageData.mos)}%</div><div className="DetailsEnd"></div></div></div></div>
                        
                    </div>

                    <div style={{ padding: "0px 20px 20px 20px" }}>
                        <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Current Price: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{getDisplayValue(pageData.price)}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Intrinsic Value: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{getDisplayValue(pageData.ivps)}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Price/Value: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px", color: getColor(pageData.mos) }}>{getDisplayValue(pageData.pvRatio)}</div><div className="DetailsEnd"></div></div></div></div>
                    </div>



                    <div className="ValuationRowInnerI" style={{ paddingTop: "30px" }}>
                        <div className="FullQuarter borderEnclosed">
                            <div className="headerChart" style={{ paddingLeft: "20px" }}>Future FCF Estimation:</div>
                            <div className="valCharts">
                                <Chart
                                    className="valuationChart"
                                    chartType="Bar"
                                    width="100%"
                                    height="150px"
                                    data={pageData.mapData}
                                    options={options}

                                />
                            </div>
                        </div>
                    </div>
                    </div>


                <div className="ValuationRow" style={{ paddingTop: "20px" }}>
                        {pageData.dcfInput &&
                            <div className="FullQuarter">
                                <h3 class="leftHeading" style={{ color: "#606f7b" }}>Discounted Cash Flow Model (DCF)</h3>
                                <table className="finData valuationResult">
                            <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap" }}>
                                            Year
                            </td >
                                        {
                                            pageData.dcfInput.map(data => (
                                                <td className="rightIntend bold" style={{ color: "#606f7b" }}>{data["year"]}</td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap" }}>
                                            Free Cash Flow
                            </td>
                                        {
                                            pageData.dcfInput.map(data => (
                                                <td className="rightIntend">{getDisplayValue(data["fcf"])}</td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap" }}>
                                            Growth Rate
                            </td>
                                        {
                                            pageData.dcfInput.map(data => (
                                                <td className="rightIntend">{data["GrowthRate"]}</td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                <td className="leftIntend bold" style={{ color: "#606f7b", whiteSpace: "nowrap" }}>
                                            Present Value of FCF
                            </td>
                                        {
                                            pageData.dcfInput.map(data => (
                                                <td className="rightIntend">{getDisplayValue(data["FCFPV"])}</td>
                                            ))
                                        }
                                    </tr>
                                </table>
                                
                            </div>

                        }
                </div>
                <div style={{ textAlign: "left", color: "grey", fontSize: "14px", paddingTop: "10px", marginBottom: "110px" }}>
                    **This data has been generated by a machine. It is necessary to adjust the required inputs for
                    business valuation based on your estimation of future growth.
                        </div>
                </>
            }{
                pageData.price === 0 &&
                <Loading />
            }
        </>
    );
}

export default DcfMobile;
