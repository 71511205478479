import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    useEffect(() => {
        const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
        const user = itemsData?.user
        const accessToken = itemsData?.accessToken;
        const refreshToken = itemsData?.refreshToken;
        const roles = itemsData?.roles;
        const ratios = itemsData?.ratios;
        const historicalRatios = itemsData?.historicalRatios;
        const peerColumns = itemsData?.peerColumns;
        const email = itemsData?.email;
        setAuth({ user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken, email});
    }, [])
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext; 