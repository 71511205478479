import React, { useState } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import Loading from "../Company/Loading"

function PaymentMobile() {
    const [total] = useState(3600);
    const [Phone, setPhone] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const axiosPrivate = useAxiosPrivate();


    const CreateOrder = async () => {
        console.log(Phone.length)
        setIsLoading(true)
        if (!Phone) {
            setError("Please enter Phone Number")
            return
        }
        if (Phone.length != 10) {
            setError("Please enter a valid Phone Number")
            return
        }
        if (isNaN(Phone)) {
            setError("Please enter a valid Phone Number")
            return
        }

        let url = ""
        var body = {
            "notify_url": "",
            "order_amount": total,
            "phone_number": Phone
        }
        url = process.env.REACT_APP_PAYMENT_API_URL + "/api/v1/subscribe";
        const cdresponse = await axiosPrivate.post(url, body);
        window.location.replace(cdresponse.data.data.authLink);
    }


    return (
        <div className="Payment commonContainer">
            <div style={{ border: "2px solid #F5F5F5", maxWidth: "620px", borderRadius: "5px", minHeight: "300px", marginTop: "10px", paddingLeft: "10px" }}>
                {!isLoading &&
                    <>
                        <h2>Subscription Summary</h2>
                        {error && <div style={{ color: "red" }}>{error}</div>}
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}><div style={{ width: "100px", lineHeight: "2" }}>Phone Number</div><div><input id="CPhone" type="text" value={Phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" /></div></div>
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}><div style={{ width: "100px", lineHeight: "2" }}>Amount</div><div style={{ paddingLeft: "20px" }}>₹ 3,050</div></div>
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}><div style={{ width: "100px", lineHeight: "2" }}>GST (18%)</div><div style={{ paddingLeft: "20px" }}>₹ 550</div></div>
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: "40px" }}><div style={{ width: "100px", lineHeight: "2" }}>Total</div><div style={{ paddingLeft: "20px" }}>₹ 3,600</div></div>
                        <div style={{ display: "flex", justifyContent: "right", paddingRight: "30px", paddingTop: "30px" }}><div className="optionButtonMobile" onClick={() => CreateOrder()}>Pay Now</div></div>
                    </>
                }
                {
                    isLoading &&
                    <div style={{ paddingTop: "100px" }}><Loading /></div>
                }
            </div>

        </div>
    );
}

export default PaymentMobile;