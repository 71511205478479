import React from "react";
import CommodityMainChange from './CommodityMainChange'
import CommodityInfraChange from './CommodityInfraChange'

function CommodityChangeWrapper(props) {


    return (
        <div >
            <CommodityInfraChange setUserSelection={props.setUserSelection} setCommoditySymbol={props.setCommoditySymbol} />
            <CommodityMainChange setUserSelection={props.setUserSelection} setCommoditySymbol={props.setCommoditySymbol} />
        </div>
    );
}

export default CommodityChangeWrapper                                                  