import React, { useState } from 'react';
import './Financials.css'
import Cashflow from './Financials/Cashflow'
import Balancesheet from './Financials/Balancesheet'
import ProfitLoss from './Financials/ProfitLoss'
import QuaterlyResults from './Financials/QuaterlyResults'


function Financials(props) {
    const [optionSelected, setValue] = useState('quaterlyResults');
    const [quaterlyResults, togglequaterlyResults] = useState(true);
    const [profitLoss, toggleprofitLoss] = useState(false);
    const [balancesheet, togglebalancesheet] = useState(false);
    const [cashflow, togglecashflow] = useState(false);
    const [checklist, togglechecklist] = useState(false);
    const [proChecklist, setproChecklist] = useState(false);
    return (
        <div className="Financials">
            <section id="finDetails">
                <div className="FinancialNav">
                    <div className="FinancialMenu" id="quaterlyResults" onClick={() => {
                        setValue('quaterlyResults')
                        togglequaterlyResults(true)
                        toggleprofitLoss(false)
                        togglebalancesheet(false)
                        togglecashflow(false)
                    }
                    } style={quaterlyResults ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }} >Quaterly Results</div>
                    <div className="FinancialMenu" id="profitLoss" onClick={() => {
                        setValue('profitLoss')
                        togglequaterlyResults(false)
                        toggleprofitLoss(true)
                        togglebalancesheet(false)
                        togglecashflow(false)
                    }
                    } style={profitLoss ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }}  >Profit & Loss</div>
                    <div className="FinancialMenu" id="balancesheet" onClick={() => {
                        setValue('balancesheet')
                        togglequaterlyResults(false)
                        toggleprofitLoss(false)
                        togglebalancesheet(true)
                        togglecashflow(false)
                    }
                    } style={balancesheet ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }}  >Balance Sheet</div>
                    <div className="FinancialMenu" id="cashflow" onClick={() => {
                        setValue('cashflow')
                        togglequaterlyResults(false)
                        toggleprofitLoss(false)
                        togglebalancesheet(false)
                        togglecashflow(true)
                    }
                    } style={cashflow ? { backgroundColor: '#000', color: '#fff' } : { fontWeight: '500' }}  >Cash Flows</div>
                </div>
                <div id="finNum">
                    <div className="finance">
                        {optionSelected == 'quaterlyResults' &&
                            <QuaterlyResults compSymbol={props.compSymbol} compName={props.compName}/>

                        }
                        {optionSelected == 'profitLoss' &&
                            <ProfitLoss compSymbol={props.compSymbol} compName={props.compName}/>
                        }
                        {optionSelected == 'balancesheet' &&
                            <Balancesheet compSymbol={props.compSymbol} compName={props.compName} />
                        }
                        {optionSelected == 'cashflow' &&
                            <Cashflow compSymbol={props.compSymbol} compName={props.compName} />
                            }
                    </div>
                    {/* <div className="Checklist">
                        <div className="ChecklistWrapper">
                            <div className="checklist-option">
                                <div className="checklist-header">
                                    Investment Checklist
                                </div>
                                <div className="ChecklistOptionButton">
                                    <div className="checklist-basic" onClick={() => { togglechecklist(false) }}
                                        style={{color: checklist ? 'black': 'white' ,
                                            backgroundColor: checklist ? 'white' : 'black' }}>
                                        Basic
                                    </div>
                                    <div className="checklist-pro" onClick={() => { togglechecklist(true) }}
                                        style={{color: checklist ? 'white' : 'black',
                                            backgroundColor: checklist ? 'black': 'white' }}>
                                        Pro
                                    </div>
                                </div>
                            </div>
                            <div className="checklist-content">
                                <Checklist checklist={checklist} proChecklist={proChecklist}/>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </section>
        </div>
    );
}

export default Financials;