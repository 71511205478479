import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./ManageRatioModal.css";
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"


const ManageRatioModal = props => {
    const { auth } = useAuth();

    const axiosPrivate = useAxiosPrivate();
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    const saveRatios = async () => {
        console.log("inside sabe")
        props.setViewLoading(true)
        let url = "https://users.niveshkarma.com/api/v1/update-profile"
        let dataJson = {
            "add_ratios": auth.ratios,
            "historical_ratios": auth.historicalRatios,
            "peer_ratios": auth.peerColumns

        }
        const response = await axiosPrivate.post(url,
            dataJson
        );

        const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
        const updatedItemData = { ...itemsData, "ratios": auth.ratios }
        localStorage.setItem('NKUserProfileInfo', JSON.stringify(updatedItemData))
        props.setViewLoading(false)
        props.setShow(false)
        props.toggleProfile()
    }

    const isDisabled = () => {
        if (props.disableButton || props.viewLoading) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className="modalMRMobile" onClick={props.onClose}>
                <div className="modal-contentMRMobile" onClick={e => e.stopPropagation()}>
                    <div className="modal-headerMRMobile">
                        <h4 className="modal-titleMRMobile">{props.title}</h4>
                    </div>
                    <div className="modal-bodyMRMobile">{props.children}</div>
                    <div className="modal-footerMRMobile">
                        {!props.viewLoading && auth.user && <button onClick={() => saveRatios()} className="button" disabled={props.disableButton ? false : true}>
                            Save Ratios
                        </button>}
                        <button onClick={props.onClose} className="button" disabled={props.viewLoading}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>,
        document.getElementById("root")
    );
};

export default ManageRatioModal;
