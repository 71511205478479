import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "./Loading"
import { Popup } from 'semantic-ui-react'

function PartOne(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const roundValue = (data) => {
        return Math.round(data)
    }

    const roundPercentValue = (data) => {
        if (data) {
            let result = data.replace("%", "");
            let value = parseFloat(result);
            var intvalue = Math.ceil(value);
            return intvalue + "%"
        } else {
            return "-"
        }
    }


    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/summary-one/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, props.consolidated])

    return (
        <div className="finColumn">
            {Object.keys(pageData).length !== 0 &&
                <>
                    <div className="finColumnContainer">
                        <div className="finColumnData">
                            <h3>Price</h3>
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Market Cap : </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["market_cap"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Current Price: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["close_price"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">52 Week High: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["high_52"]}</div><div className="DetailsEnd"></div></div></div></div>
                        <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">52 Week Low: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["low_52"]}</div><div className="DetailsEnd"></div></div></div></div>
                        </div>
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Valuation (TTM)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Valuation - Trailing 12 Months.</div>}
                            style={{ zIndex: "999" }}
                        />
                            
                            <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Price to Earnings: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["pe"]}</div><div className="DetailsEnd"></div></div></div></div>
                            <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Price to Book Value: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["pb"]}</div><div className="DetailsEnd"></div></div></div></div>
                            <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Price to Sales: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["ps"]}</div><div className="DetailsEnd"></div></div></div></div>
                            <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Price to FCF: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["pfcf"]}</div><div className="DetailsEnd"></div></div></div></div>
                        </div>
                    </div>
                    <br />
                <div className="finColumnContainer">
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Liquidity (TTM)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Liquidity - Trailing 12 Months.</div>}
                            style={{ zIndex: "999" }}
                        />
                                
                                <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Current Ratio: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["currentRatioTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                                <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Quick Ratio: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["quickRatioTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                        </div>
                    <div className="finColumnData">
                        <Popup
                            className="popupChart"
                            trigger={<h3>Leverage (TTM)</h3>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Leverage - Trailing 12 Months.</div>}
                            style={{ zIndex: "999" }}
                        />
                                
                                <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading">Total Debt to Equity: </div> <div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["totalDebtToEquityTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                                <div className="EvenDivS"><div className="summaryFont"><div className="detailsHeading">Financial Leverage: </div><div className="details"><div className="DetailsSubHeader"></div><div className="DetailsValue">{pageData["totalDebttoAssetsTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                        </div>
                </div>
                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default PartOne;