import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import './RowChart.css'


function RowChart(props) {
    const options = {
        legend: { position: "none" }  
    };
    function getDisplayValue(data) {
        if (!data) {
            return "TTM/YTD"
        }
        return data
    }
    function getDisplayAmount(data) {
        if (!data) {
            return 0
        } else {
            let tempData = String(data)
            return parseFloat(tempData.replaceAll("%", "").replaceAll(",", ""))
           // tempData
          //  console.log(tempData)
          //  return parseFloat(tempData)
        }
    }
    const [mapData, setMapData] = useState({})

    const addMapData = () => {
        let tempMapData = []
        let tempLabel = []

        props.mapData.map(mapdata => {
            tempMapData.push(getDisplayAmount(mapdata[props.mapkey]))
            tempLabel.push(getDisplayValue(mapdata['date_header']))
        })
        setMapData({
            options: {
                chart: { id: props.mapkey },
                colors: ['#9cb2cf', '#C9D8E9'],
                dataLabels: { enabled: false },
                xaxis: { categories: tempLabel },
                yaxis: [{ forceNiceScale: true }]

            },
            series: [{ name: props.mapkey, data: tempMapData }]
        })
    }
    useEffect(() => {
        addMapData()
    }, [])

    return (
        <div className="RowChart">
            {Object.keys(mapData).length > 0 && 
                <Chart
                    options={mapData.options}
                    series={mapData.series}
                    type="bar"
                    width="800"
                    height="150"
                />
            }
        </div>
    )

}


export default RowChart;