import React, { useState } from 'react';

function Option(props) {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };


    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const getIconPathData = () => {
        return "m1060.8 375.6c46.801 0 80.398-33.602 80.398-79.199 0-45.602-33.602-81.602-80.398-81.602h-920.4c-45.602 0-81.602 36-81.602 81.602 0 45.602 36 79.199 81.602 79.199zm-920.4 448.8c-45.602 0-81.602 36-81.602 80.398s36 81.602 81.602 81.602h921.6c46.801 0 80.398-37.199 80.398-81.602 0-44.398-33.602-80.398-80.398-80.398zm0-306c-45.602 0-81.602 36-81.602 81.602s36 81.602 81.602 81.602h921.6c46.801 0 80.398-36 80.398-81.602s-33.602-81.602-80.398-81.602z"
    }


    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            width="25"
            height="25"
            viewBox="0 0 1200 1200"
            style={!props.fill ? { fill: "#444" } : { fill: "#fff" }}>
            <path d={getIconPathData()}></path>
        </svg>
    )
}
export default Option;