import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from '../../hooks/useAuth';


function AddToWatchlistMobile(props) {
    const [pageData, setpageData] = useState({})
    const [addedWatchlist, setAddedWatchlist] = useState([])
    const [userInput, setUserInput] = useState("")
    const [toggle, setToggle] = useState(false)
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const getWatchlistLimit = () => {
        if (auth.roles) {
            return 20
        } else {
            return 2
        }
    }


    const [watchlistLimit] = useState(getWatchlistLimit())

    function isDisabled(watchlistId) {
        console.log(watchlistId)
        if (addedWatchlist.includes(watchlistId)) {
            console.log("inside")
            return true
        }
        return false

    }

    const updateToggle = () => {
        if (toggle) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }

    const handleChange = async (event, watchlist) => {
        console.log(pageData)
        let tempData = pageData
        for (let i = 0; i < tempData.watch_lists.length; i++) {
            if (tempData.watch_lists[i]["list_name"] === watchlist) {
                if (event) {
                    for (let y = 0; y < props.watchlistList.length; y++) {
                        if (!tempData.watch_lists[i]["watch_list"].includes(props.watchlistList[y])) {
                            tempData.watch_lists[i]["watch_list"].push(props.watchlistList[y])
                        }
                    }
                }
                setAddedWatchlist([...addedWatchlist, watchlist])
            }
        }
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const response = await axiosPrivate.post(url, tempData.watch_lists);
        // runQuery("initial")
    }

    const runQuery = async (load) => {
        if (load === "initial") {
            setpageData({})
        }
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const response = await axiosPrivate.get(url);
        let vWatchlist = response.data.watch_lists
        let vScreeners = response.data.screeners
        let vUser = response.data.username

        if (vWatchlist === null) {
            setpageData({
                watch_lists: [],
                screeners: vScreeners,
                username: vUser
            })
        } else {
            setpageData({
                watch_lists: vWatchlist,
                screeners: vScreeners,
                username: vUser
            })
        }



    }

    const createWatchlist = async () => {
        if (userInput) {
            let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
            let tempData = []
            if (Object.keys(pageData).length !== 0) {
                tempData = pageData.watch_lists
                tempData.push({ "list_name": userInput, watch_list: [] })
            } else {
                tempData.push({ "list_name": userInput, watch_list: [] })
            }
            const response = await axiosPrivate.post(url, tempData);
            setUserInput("")
            runQuery()
        }
    }

    const getWatchlistURL = (watchlistName) => {
        return "/Watchlist/" + watchlistName
    }

    useEffect(() => {
        if (auth.user) {
            const timeout = setTimeout(() => {
                runQuery("initial")
            }, 50)
            return () => clearTimeout(timeout)
        }
    }, [auth.user])

    return (
        <div className="AddWatchlist" >
            {pageData.watch_lists !== undefined && pageData.watch_lists !== null && pageData.watch_lists.length !== 0 && auth.user &&

                <>
                    <div style={{ fontSize: "18px", fontWeight: "500" }}>Create a new watchlist and add companies to either the existing or newly created watchlist.</div>
                    <div className="existingWatchlist">
                        {pageData.watch_lists.map(watchlist => (
                            <div style={{ display: "flex", justifyContent: "left", paddingTop: "10px" }}>


                                <label className="switch">
                                    <input type="checkbox" onChange={(e) => handleChange(e.target.checked, watchlist.list_name)} disabled={isDisabled(watchlist.list_name)} />
                                    <span className="slider round"></span>
                                </label>
                                <div style={{ fontSize: "16px", paddingTop: "3px", paddingLeft: "20px" }}><a href={getWatchlistURL(watchlist.list_name)} style={{ textDecoration: "none", target: "_blank", color: "black" }} >{watchlist.list_name}</a></div>
                            </div>
                        ))
                        }
                    </div>
                </>
            }
            {pageData.watch_lists !== undefined && auth.user && pageData.watch_lists.length < watchlistLimit &&
                <div className="NewWatchlist" style={{ paddingTop: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                        <div style={{ paddingRight: "20px" }}><input type="text" length="50" placeholder="Watchlist Name" value={userInput} onChange={(e) => setUserInput(e.target.value)} style={{ lineHeight: "1.8" }} /></div>
                        <div className="optionButton" style={{ width: "140px", textAlign: "center", paddingTop: "3px" }} onClick={() => createWatchlist()}>Create Watchlist</div>
                    </div>
                </div>
            }
            {pageData.watch_lists !== undefined && pageData.watch_lists.length >= watchlistLimit &&
                <div style={{ color: "grey", paddingTop: "20px" }}>You are allowed to create a maximum of {watchlistLimit} watchlists.</div>
            }

            {!auth.user &&
                <div style={{ textAlign: "center" }}>
                    Please login to view this section.
                </div>
            }

        </div>
    );
}
export default AddToWatchlistMobile;