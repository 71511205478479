import React, { useEffect }  from 'react';
import useAuth from './hooks/useAuth';

function PaymentConfirmation(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const order_status = queryParams.get('status');
    const { auth, setAuth } = useAuth();
    console.log(order_status)
    
    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
        if (order_status === "pending" || order_status === "success") {
            localStorage.clear()
            setAuth({})
        }

    }, [])
    return (
        <div className="Payment">
            <div>
                <div className="commonContainer" >
                    <div className="AboutUs-container" style={{ textAlign: "center", marginTop: "100px" }}>
                        {
                            order_status === "success" &&
                            <div>
                                Your Payment is Successful. Please login Again
                            </div>
                        }
                        {
                            order_status === "pending" &&
                            <div>
                                Your Payment was Pending. Please login Again
                            </div>
                        }
                        {
                            order_status === "failed" &&
                            <div>
                                Your Payment failed. Please try again.
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentConfirmation;