import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import UpArrow from "../icon/UpArrow"
import DownArrow from "../icon/DownArrow"

function ScorePeer(props) {
    const [pageData, setpageData] = useState({});
    const [repaint, setRepaint] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const toggleRepaint = () => {
        if (repaint) {
            setRepaint(false)
        } else {
            setRepaint(true)
        }
    }

    const SortIndicator = (indicator) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        const SortDataItem = localStorage.getItem('SortDataItem')
        if (indicator === SortDataItem) {
            if (sortData["pointer"]) {
                return [true, true]
            } else {
                return [true, false]
            }
        }
        return [false, false]
    }

    const showSorted = (pointer) => {
        const sortData = JSON.parse(localStorage.getItem('SortData'))
        if (sortData !== null) {
            if (Object.keys(sortData).includes('pointer')) {
                if (sortData["pointer"]) {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: false }));
                    localStorage.setItem("SortDataItem", pointer);
                } else {
                    localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                    localStorage.setItem("SortDataItem", pointer);
                }
            } else {
                localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
                localStorage.setItem("SortDataItem", pointer);
            }
        } else {
            localStorage.setItem("SortData", JSON.stringify({ pointer: true }));
            localStorage.setItem("SortDataItem", pointer);
        }
        const finalSortData = JSON.parse(localStorage.getItem('SortData'))

        const itemsData = JSON.parse(localStorage.getItem('TableData'))
        let tempData = itemsData["score_peer"]
        if (finalSortData["pointer"]) {
            if (['sector', 'industry'].includes(pointer)) {
                tempData.sort(dynamicSortAscendingString(pointer));
            } else {
                tempData.sort(dynamicSortAscending(pointer));
            }
        } else {
            if (['sector', 'industry'].includes(pointer)) {
                tempData.sort(dynamicSortDescendingString(pointer));
            } else {
                tempData.sort(dynamicSortDescending(pointer));
            }
        }
        let finalData = itemsData
        finalData["score_peer"] = tempData

        setpageData(tempData)
    }

    function forceDefault(data) {
        if (data === "-") {
            return 0
        } else {
            return data
        }
    }

    function dynamicSortDescending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscending(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) < parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? -1 : (parseFloat(forceDefault(a[property].replace('%', '').replaceAll(',', ''))) > parseFloat(forceDefault(b[property].replace('%', '').replaceAll(',', '')))) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortDescendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) > String(b[property])) ? -1 : (String(a[property]) < String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }


    function dynamicSortAscendingString(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (String(a[property]) < String(b[property])) ? -1 : (String(a[property]) > String(b[property])) ? 1 : 0;
            return result * sortOrder;
        }
    }









    const companyDetails = async () => {
        setpageData({})
        let url = ""
        console.log(encodeURIComponent(props.filter))
        url = "https://api.niveshkarma.com/api/v1/3m-score-peers/" + props.compSymbol+"?filter_by=gsector&filter_value=" + encodeURIComponent(props.filter)  +"&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        localStorage.setItem(
            "TableData", JSON.stringify({
                "score_peer": cdresponse.data
            }));
        setpageData(cdresponse.data)
    }

    const getUrl = (url) => {
        return "/Company/" + url
    }

    function getColorBackGround(score) {
        let result = score.substring(0,1);
        console.log(result)
        if (parseFloat(result) === 1) {
            return "#009900"
        } else if (parseFloat(result) === 2) {
            return "#669900"
        } else if (parseFloat(result) === 3) {
            return "#fac904"
        } else if (parseFloat(result) === 4) {
            return "#ff6666"
        } else if (parseFloat(result) === 5) {
            return "#ff0000"
        }
    }
    

    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, props.filter])


    return (
        <div className="ScoreMainPeer" style={{ paddingBottom: "110px" }}>
            {pageData.length > 0 &&
                <section className="ScoreSubsectionPeer">
                <div className="ScoreRow">
                    <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto", backgroundColor: "white" }}>
                        <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                            <thead>
                                <tr style={{ backgroundColor: "white" }}>
                                    <th style={{ backgroundColor: "white", textAlign: "left", paddingLeft: "10px", left: "0", zIndex: "10" }}>Company Name</th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("sector")}><div>Sector</div><div style={{ paddingTop: "5px" }}>{SortIndicator("sector")[0] ? <div>{SortIndicator("sector")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("industry")}><div>Industry</div><div style={{ paddingTop: "5px" }}>{SortIndicator("industry")[0] ? <div>{SortIndicator("industry")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("relative_rank")}><div>Relative Rank</div><div style={{ paddingTop: "5px" }}>{SortIndicator("relative_rank")[0] ? <div>{SortIndicator("relative_rank")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("predictability")}><div>Predictability</div><div style={{ paddingTop: "5px" }}>{SortIndicator("predictability")[0] ? <div>{SortIndicator("predictability")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("moat")}><div>Moat</div><div style={{ paddingTop: "5px" }}>{SortIndicator("moat")[0] ? <div>{SortIndicator("moat")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("management")}><div>Management</div><div style={{ paddingTop: "5px" }}>{SortIndicator("management")[0] ? <div>{SortIndicator("management")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                    <th style={{ backgroundColor: "white" }}><div style={{ cursor: "pointer", color: "blue", display: "flex", justifyContent: "center", paddingTop: "5px" }} onClick={() => showSorted("margin_of_safety")}><div>Margin of Saftey</div><div style={{ paddingTop: "5px" }}>{SortIndicator("margin_of_safety")[0] ? <div>{SortIndicator("margin_of_safety")[1] ? <DownArrow /> : < UpArrow />}</div> : <div style={{ minHeight: "25px" }}>&nbsp;</div>}</div></div></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                pageData.map(item => (
                                    <tr>
                                    <td style={{ textAlign: "left", paddingLeft: "10px", position: "sticky", left: "0"}}><a href={getUrl(item.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{item.stock_name}</a></td>
                                    <td >{item.sector}</td>
                                    <td >{item.industry}</td>
                                    <td style={{ color: getColorBackGround(item.relative_rank) }}>{item.relative_rank}</td>
                                    <td>{item.predictability}</td>
                                    <td>{item.moat}</td>
                                    <td>{item.management}</td>
                                    <td>{item.margin_of_safety}</td>
                                </tr>
                                )   )
                            }
                            </tbody>
                        </table>
                    </div>
                    </div>
                </section>
            }
        </div>
    );
}

export default ScorePeer;