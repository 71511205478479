import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CompanySummary.css'
import PriceSummary from './Summary/PriceSummary'
import Score from './Summary/Score'
import PartOne from './Summary/PartOne'
import PartTwo from './Summary/PartTwo'
import PartThree from './Summary/PartThree'
import About from './Summary/About'
import Announcement from './Summary/Announcement' 
import AnnualReport from './Summary/AnnualReport' 
import CreditRating from './Summary/CreditRating' 
import ConCalls from './Summary/ConCalls' 
import CompanyMain from './CompanyMain'

function CompanySummary(props) {
    const [optionSelected, setValue] = useState('Annual');
    

    return (
        <div className="CompanySummaryMain">
            <CompanyMain compSymbol={props.compSymbol} setCompName={props.setmaincompanyName} compName={props.compName}/>
            {/*<section className="CompanySummary">
                <div className="CompanySummaryLeft">
                    <PriceSummary compSymbol={props.compSymbol} />

                </div>
            </section>*/}
            <div className="finSummary">
                <div className="finSum">
                    <PartOne compSymbol={props.compSymbol} consolidated={props.consolidated}/>
                    <PartThree compSymbol={props.compSymbol} consolidated={props.consolidated} />
                    
                </div>
            </div>
            <div className="finSummary">
                <div className="finSum">
                    <Score compSymbol={props.compSymbol} ratioData={props.ratioData}/> 
                    
                    <About compSymbol={props.compSymbol} />

                </div>
            </div>


            {/*<div className="finSummary">
                <div className="finSumMenu">
                    <div id="subMenuBar">
                        <div className="subMenu" id="annualReport" onClick={() => { setValue('Annual') }} style={optionSelected === "Annual" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }} >
                            Annual Reports
                        </div>
                        <div className="subMenu" id="creditrating" onClick={() => { setValue('Credit') }} style={optionSelected === "Credit" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }} >
                            Credit Ratings
                        </div>
                        <div className="subMenu" id="announcements" onClick={() => { setValue('Announce') }} style={optionSelected === "Announce" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }} >
                            Announcements
                        </div>
                        <div className="subMenu" id="concalls" onClick={() => { setValue('Concalls') }} style={optionSelected === "Concalls" ? { fontWeight: '600', color: '#4169e1' } : { fontWeight: '600' }} >
                            Concalls
                        </div>
                    </div>


                    <div>


                        {optionSelected === "Annual" && <AnnualReport compSymbol={props.compSymbol} />}
                        {optionSelected === "Credit" && <CreditRating compSymbol={props.compSymbol} />}
                        {optionSelected === "Announce" && <Announcement compSymbol={props.compSymbol} />}
                        {optionSelected === "Concalls" && <ConCalls compSymbol={props.compSymbol} />}
                    </div>
                </div>
            </div>*/}
        </div>

    );
}

export default CompanySummary;