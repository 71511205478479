import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Chart from "react-apexcharts";
import './ValuationChart.css'
import Loading from "./Loading"


function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
}

function Charting(props) {
    const axiosPrivate = useAxiosPrivate();
    const [display, setDisplay] = useState(false);

    const getAlphabeticMonth = (month) => {
        if (month === 0) {
            return "Jan"
        } else if (month === 1) {
            return "Feb"
        } else if (month === 2) {
            return "Mar"
        } else if (month === 3) {
            return "Apr"
        } else if (month === 4) {
            return "May"
        } else if (month === 5) {
            return "Jun"
        } else if (month === 6) {
            return "Jul"
        } else if (month === 7) {
            return "Aug"
        } else if (month === 8) {
            return "Sep"
        } else if (month === 9) {
            return "Oct"
        } else if (month === 10) {
            return "Nov"
        } else if (month === 11) {
            return "Dec"
        }
    }

    function getDataMatch(stockData, commodityData) {
        let returnValue = null
        let year = stockData.getFullYear();
        let month = getAlphabeticMonth(stockData.getMonth());
        let day = stockData.getDate();
        let comparisonDate = [month, year].join("-")

        commodityData.indexes.forEach(data => {
           // if (data.date_header === comparisonDate && day === 1) {
            if (data.date_header === comparisonDate) {
                returnValue = data.value
            }
        })
        return returnValue;
    }

    function getMinValue(array) {
        let tempArr = []
        array.forEach(item => (
            tempArr.push(item[1])
        ))
        return Math.min(...tempArr)
    }
    function getMaxValue(array) {
        let tempArr = []
        array.forEach(item => (
            tempArr.push(item[1])
        ))
        return Math.max(...tempArr)
    }

    const updateData = async (item, data, label) => {
        let url = ""
        let tempData = label
        let tempDataTwo = []
        let tempDataThree = []
        //setMapData([])
        url = "https://api.niveshkarma.com/api/v1/commodities/" + item.value + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let commodityData = cdresponse.data
        tempData.forEach((arr, index) => {
            if (index !== 0) {
                tempDataThree.push(getDataMatch(arr, commodityData))
                tempDataTwo.push([arr, getDataMatch(arr, commodityData)])
            }
        })
        let minVal = Math.min.apply(null, tempDataThree.filter(Boolean))
        let maxVal = Math.max(...tempDataThree)
        let vFinalData = [{ name: "Price", type: 'line', data: data }, { name: "Commodity", type: 'area', data: tempDataTwo }]
        console.log(vFinalData)
        props.setMapData({
            options: {
                chart: { id: "Commodity-chart" },
                colors: ['#3f37ef', '#C9D8E9'],
                dataLabels: { enabled: false },
                xaxis: {
                    type: 'datetime',
                    //  categories: labels,
                    labels: {
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: "MMM 'yy",
                            day: 'dd MMM',
                            hour: 'HH:mm',
                        }
                    }
                },
                yaxis: [
                    {
                    forceNiceScale: true,
                    title: {
                        text: "Stock Price"
                    }
                    },
                    {
                        opposite: true,
                        title: {
                            text: "Commodity Price"
                        },
                        forceNiceScale: true,
                        min: minVal,
                        max: maxVal
                    }
                ],
                fill: {
                    type: "solid",
                    opacity: 0.9,
                    colors: ['#3f37ef', '#C9D8E9']
                },
                stroke: {
                    width: [1.5, .5]
                }
            },
            series: vFinalData
        })
    }


    const companyDetails = async () => {
        setDisplay(false)
        props.setMapData({})
        let url = process.env.REACT_APP_DATA_API_URL + "/api/v1/historical-quotes/" + props.compSymbol + "?timeframe=All&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        const tempData = cdresponse.data['historical_quotes']
        let tempMapData = []
        let label = []
        tempData.forEach(arr => {
            if (arr.close_price) {
                label.push(stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"))
                tempMapData.push([stringToDate(String(arr.quote_date).substring(0, 10), "yyyy-mm-dd", "-"), arr.close_price])
            }
        })
        //props.setMapData(tempMapData)
        updateData(props.commoditySymbol, tempMapData, label)
        setDisplay(true)
    }

    function formatValue(value) {
        return value

        if ((value / 10000) > 1) {
            return value + 5000
        } else if ((value / 1000) > 1) {
            return value + 500
        } else if ((value / 100) > 1) {
            return value + 50
        } else if ((value / 10) > 1) {
            return value + 5
        } else {
            return 10
        }
    }



    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div className="ValuationChart" style={{ marginBottom: "110px" }}>
            <div className="CompanyChartMap" >
                <section className="CompanyChart" id="CompanyChartGraph" >
                    <div className="chartContainer">
                        <div className="chartHeader">
                            <div style={{ fontWeight: "600", fontSize: "16px" }}>
                                {props.compName}
                            </div>
                        </div>
                        <div>
                            {display && props.repaint && Object.keys(props.mapData).length > 0 &&
                               
                                <Chart
                                        options={props.mapData.options}
                                        series={props.mapData.series}
                                        type="line"
                                        width="100%"
                                        height="400"
                                    /> 
                            }
                            {!display && !props.repaint &&
                                <Loading />
                            }
                            {Object.keys(props.mapData).length === 0 &&
                                < Loading />
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Charting;