import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "./Loading"
import AboutFull from './AboutFull'
import HelpModal from '../Financials/HelpModal'
import { Popup } from 'semantic-ui-react'

function About(props) {
    const [pageData, setpageData] = useState({});
    const [show, setShow] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/profile/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData({
            "Sector": cdresponse.data.gsector,
            "Industry": cdresponse.data.gind,
            "Description": cdresponse.data.description,
            "sectorUrl": "/Sector/" + cdresponse.data.gsector,
            "industryUrl": "/Industry/" + cdresponse.data.gind
        })
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div className="finColumn" style={{ overflowY: "auto", marginLeft: "20px" }}>
            {Object.keys(pageData).length !== 0 &&
                <>
                <div className="AboutHeaderTop">
                    <h3>
                        Company Profile
                    </h3>
                    <div style={{ marginTop:"-10px" }}>

                        <Popup
                            className="popupChart"
                            trigger={<svg
                                onClick={() => setShow(true)}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 120 120"
                                shapeRendering="geometricPrecision"
                                textRendering="geometricPrecision"
                                width="40px"
                                height="40px"
                            
                                {...props}
                            >
                                <rect
                                    width={60}
                                    height={7}
                                    rx={2}
                                    ry={2}
                                    transform="translate(10.65 33.752)"
                                    strokeWidth={0}
                                />
                                <rect
                                    width={40}
                                    height={7}
                                    rx={2}
                                    ry={2}
                                    transform="translate(10.65 48.382)"
                                    strokeWidth={0}
                                />
                                <rect
                                    width={40}
                                    height={6.999972}
                                    rx={2}
                                    ry={2}
                                    transform="translate(10.65 63.71)"
                                    strokeWidth={0}
                                />
                                <rect
                                    width={60}
                                    height={7.00007}
                                    rx={2}
                                    ry={2}
                                    transform="matrix(1 0 0 .99999 10.65 79.037)"
                                    strokeWidth={0}
                                />
                                <path
                                    transform="matrix(2.0196 0 0 1.96469 90.368 68.71)"
                                    stroke="#000"
                                    strokeWidth={3}
                                    d="M-10.996863 -10.327475L10.996863 10.327475"
                                />
                                <ellipse
                                    rx={20.268728}
                                    ry={17.827475}
                                    transform="translate(84.371 61.382)"
                                    fill="#fff"
                                    stroke="#000"
                                    strokeWidth={6}
                                />
                            </svg>}
                            content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px", backgroundColor: "white" }}>View More.</div>}
                            style={{ zIndex: "999" }}
                        />

                        
                    </div>



                        <HelpModal title="Company Info" onClose={() => setShow(false)} show={show}>
                            <AboutFull compSymbol={props.compSymbol}/>
                        </HelpModal>

                </div>
                <div className="MainRow"><div className="SubRow"><div className="AboutHeader">Sector:&nbsp; </div><div className="AboutValue"> <a href={pageData["sectorUrl"]} target="_blank">{pageData["Sector"]}</a></div></div><div className="SubRow"><div className="AboutHeader">Industry:&nbsp; </div><div className="AboutValue">  <a href={pageData["industryUrl"]} target="_blank">{pageData["Industry"]}</a></div></div></div>
                <div className="OddDivS"><div className="summaryFont"><div className="detailsHeading"></div> <div className="details"><div className="DetailsSubHeader"></div><div className="AboutValue" style={{ textAlign: "justify" }}>{pageData["Description"]}</div><div className="DetailsEnd"></div></div></div></div>
                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }
        </div>
    );
}

export default About;