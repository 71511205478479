import React, {useEffect } from "react";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';

function Searchbox(props) {
    console.log(props.optionData)
    const navigate = useNavigate();
    const filterCompany = (inputValue) => {
   //     let urlTwo = ""
     //   urlTwo = "https://api.niveshkarma.com/api/v1/stocks-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
    //    const cdresponse = axios.get(urlTwo);
   //     return cdresponse.data.filter((i) =>
  //          i.label.toLowerCase().includes(inputValue.toLowerCase())
   //     );
        return props.optionData.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = (inputValue) =>
        new Promise ((resolve) => {
            setTimeout(() => {
                resolve(filterCompany(inputValue));
            }, 100);
        });

    const navigateCompany = (inputValue) => {
        document.getElementById("HeaderSearchBox").value = { label: 'Search for a Company', value: 1 }
        props.setShowSearchArea(false)
        navigate("/Company/"+inputValue.value );
        
    }
    useEffect(() => {
    }, [props.optionData]);

    return (
        <div className="Searchbox" style={{ fontSize: "14px" }}>
            <AsyncSelect id="HeaderSearchBox" defaultOptions loadOptions={promiseOptions} onChange={navigateCompany} value={{ label: 'Search for a Company', value: 1 }} />
        </div>
    );
}

export default Searchbox