import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import './Cashflow.css'
import FinancialData from "./FinancialData"


export const options = {
    legend: { position: "bottom" },
    title: "Quality of Earning",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

export const optionsTwo = {
    legend: { position: "bottom" },
    title: "Operating, Investing and Financing",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};



function Cashflow(props) {
    const [pageData, setpageData] = useState({
        "data": [],
    //    "horizontal_analysis": [],
        "extended_mapping": [],
    //    "mapData": [],
    //    "mapDataTwo": [],
        "CompanyName": "Company Name Placeholder"
    });
    const axiosPrivate = useAxiosPrivate();
   // const [collapse, setcollapse] = useState(false);
   // const [modal, setmodal] = useState(false);
    const [sort, setsort] = useState(false);
    
    const companyDetails = async () => {
        setpageData({
            "data": [],
            //    "horizontal_analysis": [],
            "extended_mapping": [],
            //    "mapData": [],
            //    "mapDataTwo": [],
            "CompanyName": "Company Name Placeholder"
        })
        let url = ""
      //  let url_two = ""
        let cashflow_data = ""
      //  let profitLoss_data = ""
        let cashflow_map = ""
        let horizontal_data = ""
      //  let map_data = []
      //  let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/cash-flow/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
     //   url_two = process.env.REACT_APP_DATA_API_URL + "/profitloss_consolidated/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
     //   const cdresponseTwo = await axios.get(url_two, { headers });
        if (sort) {
            cashflow_data = cdresponse.data['cash_flow']
            horizontal_data = cdresponse.data['yoy_growth']
        //    horizontal_data = await cdresponse.data['horizontal_analysis']
        } else {
            cashflow_data = cdresponse.data['cash_flow'].reverse()
            horizontal_data = cdresponse.data['yoy_growth'].reverse()
        //    horizontal_data = await cdresponse.data['horizontal_analysis'].reverse()
        }
        cashflow_map = cdresponse.data['prompts']
        company_name = cdresponse.data['stock_name']
       // map_data.push(["", "COA", "FCF", "Net Profit"])
       // cashflow_data.map(mapdata => (
       //     profitLoss_data.filter(s => mapdata['date_header']['data'] === s['date_header']['data']).map(item => (
       //             map_data.push([getDisplayValue(mapdata['date_header'], 'm'), parseFloat(getDisplayValue(mapdata['Cash from Operating Activity'], 'm')), parseFloat(getDisplayValue(mapdata['Free Cash Flow (FCF)'], 'm')), parseFloat(getDisplayValue(item['Net Profit'], 'm'))])
       //     ))
      //  ))
      //  map_data_two.push(["", "COA", "CIA", "CFA"])
      //  cashflow_data.map(mapdata => (
      //      map_data_two.push([getDisplayValue(mapdata['date_header'], "m"), parseFloat(getDisplayValue(mapdata['Cash from Operating Activity'], 'm')), parseFloat(getDisplayValue(mapdata['Cash from Investing Activity'], 'm')), parseFloat(getDisplayValue(mapdata['Cash from Financing Activity'], 'm'))])
      //  ))
      //  console.log(map_data)
        document.title = company_name + "- Cash Flows Statement | Horizonal Analysis | Free Cash Flow (FCF)";
        setpageData({
            "data": cashflow_data,
            "yoyGrowth": horizontal_data,
            "extended_mapping": cashflow_map,
        //    "mapData": map_data,
       //     "mapDataTwo": map_data_two,
            "CompanyName": company_name
        })
        
    }
    

    useEffect(() => {
        companyDetails()
        
    }, [props.compSymbol, sort])

    return (
        <>
            <FinancialData pageData={pageData} sort={sort} setsort={setsort} source="cashflow" horizontal={true} vertical={false } />
        </>
    );
}

export default Cashflow;