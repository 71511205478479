import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Loading from "../../Company/Summary/Loading"
import { Popup } from 'semantic-ui-react'


function SummaryOneMobile(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const numberFormat = (value) => {
        if (value) {
            return value.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const roundValue = (data) => {
        return Math.round(data)
    }

    const roundPercentValue = (data) => {
        if (data) {
            let result = data.replace("%", "");
            let value = parseFloat(result);
            var intvalue = Math.ceil(value);
            return intvalue + "%"
        } else {
            return "-"
        }
    }


    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/summary-one/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, props.consolidated])


    return (
        <div className="SummaryOneMobile">
            {Object.keys(pageData).length !== 0 &&
                <>

                <div style={{ padding: "20px" }}>
                    <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Price</div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Market Cap : </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["market_cap"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Current Price: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["close_price"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>52 Week High: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["high_52"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>52 Week Low: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["low_52"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>
                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Valuation (TTM)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Valuation - Trailing 12 Months.</div>}
                        style={{ zIndex: "999" }}
                    />

                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Price to Earnings: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["pe"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Price to Book Value: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["pb"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Price to Sales: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["ps"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Price to FCF: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["pfcf"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>


                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Liquidity (TTM)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Liquidity - Trailing 12 Months.</div>}
                        style={{ zIndex: "999" }}
                    />

                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Current Ratio: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["currentRatioTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Quick Ratio: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["quickRatioTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>
                <div style={{ padding: "20px" }}>
                    <Popup
                        className="popupChart"
                        trigger={<div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>Leverage (TTM)</div>}
                        content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>Leverage - Trailing 12 Months.</div>}
                        style={{ zIndex: "999" }}
                    />

                    <div className="OddDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Total Debt to Equity: </div> <div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["totalDebtToEquityTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                    <div className="EvenDivS"><div className="summaryFont"><div style={{ fontSize: "13px" }}>Financial Leverage: </div><div className="details"><div className="DetailsSubHeader"></div><div style={{ fontSize: "13px" }}>{pageData["totalDebttoAssetsTTM"]}</div><div className="DetailsEnd"></div></div></div></div>
                </div>
                </>
            } {Object.keys(pageData).length === 0 &&
                <Loading />
            }

        </div>

    );
}
export default SummaryOneMobile;