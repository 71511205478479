import React, { useState, useEffect } from 'react';
import './MobileHeader.css';
import Searchbox from './Searchbox';
//import LoginButton from './Loginbutton';
import { useNavigate } from "react-router-dom";
//import HomeContent from './Content'
import { useGoogleLogin } from '@react-oauth/google';
//import DropdownArrow from "./util/DropdownArrow";
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import Option from '../icon/Option'
import SearchIcon from "../icon/HomeIcon"
import User from "../icon/User"

const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL + "/google-auth/api/v1/token";
//const LOGIN_URL = "https://nk-auth.onrender.com" + "/google-auth/api/v1/token";


function MobileHeader(props) {
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [showNavArea, setShowNavArea] = useState(false);
    const { auth, setAuth } = useAuth();
    let navigate = useNavigate();

    const routeChange = () => {
        let path = `./`;
        navigate(path);
    }

    const toggleSearchArea = () => {
        setShowNavArea(false)
        if (showSearchArea) {
            setShowSearchArea(false)
        } else {
            setShowSearchArea(true)
        }
    }

    const toggleNavArea = () => {
        setShowSearchArea(false)
        if (showNavArea) {
            setShowNavArea(false)
        } else {
            setShowNavArea(true)
        }
    }

    const logout = () => {
        if (auth.user) {
            localStorage.clear()
            setAuth({})
            navigate("/");
        }
    }



    const handleCallbackResponse = async (dataResponse) => {
        try {
            const response = await axios.post(LOGIN_URL,
                '',
                {
                    headers: { 'Content-Type': 'application/json', 'x-token-type': 'Bearer', "x-token": dataResponse.access_token, 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL, },

                }
            );

            const user = response?.data?.user?.first_name + " " + response?.data?.user?.last_name;
            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const roles = response?.data?.user?.premium;
            const ratios = response?.data?.user?.add_ratios;
            const historicalRatios = response?.data?.user?.historical_ratios;
            const peerColumns = response?.data?.user?.peer_ratios;
            const itemsData = { user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken }
            console.log(itemsData)
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(itemsData))
            setAuth({ user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken });
            setShowNavArea(false)
            navigate("/");
        } catch (err) {
            console.log(err)
            /*if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password'); 
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }*/
        }
    }

    const login = useGoogleLogin({
        onSuccess: codeResponse => handleCallbackResponse(codeResponse),
        googleAccountConfigs: {
            client_id: "293375405749-i8qhl8aujplmiapclijjv0ffqob8enr4.apps.googleusercontent.com"
        },
    });



    useEffect(() => {

    }, [auth.user]);
    return (
        <div className="HomeHeaderMobileContainer">
            <div className="HomeHeaderMobileContainerChild"> 
                <div className="HomeHeaderMobile">
                    <div className="leftHeaderMobile">
                        <div className="leftOptionIconMobile" onClick={() => toggleNavArea()}>
                            <Option fill={true} />
                        </div>
                        <a className="logoAnchorMobile" href="/">
                            <div id="logo" onClick={routeChange}>
                                <img id="HomeHeaderLogoMobile" src={process.env.PUBLIC_URL + '/logo1.svg'} alt="NK Logo"></img>
                                <div className="LogoTextMobile">Nivesh&nbsp;Karma</div>
                            </div>
                        </a>
                    </div>
                    <div className="searchContainerMobile" onClick={() => toggleSearchArea()}>
                        <SearchIcon ind="search" mobile={ true}/>
                    </div>
                </div> 
            </div>
            {showSearchArea &&
                <div className="SearchAreaMobile">
                    <Searchbox setShow={true} optionData={props.optionData} setShowSearchArea={setShowSearchArea}/>
                </div>
            }
            {showNavArea &&
            <div className="NavAreaMobile">
                <div className="userInfoMobile">
                    <div className="userContainerMobile">
                        <User ind="user" mobile={ true} />
                    </div>
                    <div className="userInfoContainerMobile">
                        {auth.user ? auth.user : "Guest" } 
                    </div>
                </div>
                {auth.user ?
                    <div className="NavOptionMobile">
                        <a className="NavOptionAnchorMobile" href="/Profile"><div className="NavMemberMobile" style={{ fontSize: "17px", }}>Profile</ div></a>
                        <a className="NavOptionAnchorMobile" href="/Watchlist"><div className="NavMemberMobile" style={{ fontSize: "17px", }}>Watchlist</ div></a>
                        <a className="NavOptionAnchorMobile" href="/Screener"><div className="NavMemberMobile" style={{ fontSize: "17px", }}>Screener</ div></a>
                        <a className="NavOptionAnchorMobile" href="/Superinvestor"><div className="NavMemberMobile" style={{ fontSize: "17px" }}>Superinvestors</ div></a>
                        <a className="NavOptionAnchorMobile" href="/InvestingFramework"><div className="NavMemberMobile" style={{ fontSize: "17px", }}>3M Investing Framework</ div></a>
                        <a className="NavOptionAnchorMobile" href="/InvestingBooks"><div className="NavMemberMobile" style={{ fontSize: "17px" }}>Investing Books</ div></a>
                    </div>
                    :
                    <div className="NavOptionMobile">

                        <a className="NavOptionAnchorMobile" href="/Screener"><div className="NavMemberMobile" style={{ fontSize: "17px", }}>Screener</ div></a>
                        <a className="NavOptionAnchorMobile" href="/Superinvestor"><div className="NavMemberMobile" style={{ fontSize: "17px" }}>Superinvestors</ div></a>
                        <a className="NavOptionAnchorMobile" href="/InvestingFramework"><div className="NavMemberMobile" style={{ fontSize: "17px", }}>3M Investing Framework</ div></a>
                        <a className="NavOptionAnchorMobile" href="/InvestingBooks"><div className="NavMemberMobile" style={{ fontSize: "17px" }}>Investing Books</ div></a>
                    </div>
                }
                <div className="userLogoutMobile">
                    {auth.user ?
                        <div className="NavMemberMobileLogin" style={{ fontSize: "17px" }} onClick={() => logout() }>Logout</ div>
                        :
                        <div className="NavOptionAnchorMobile" onClick={() => login()}><div className="NavMemberMobileLogin" style={{ fontSize: "17px", }}>Login</ div></div>
                    }
                </div>
            </div>
            }
        </div>
    );
}

export default MobileHeader;