import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import useAxiosPrivate from "../hooks/useAxiosPrivate"




export const options = {
    title: "Sector % analysis",

    legend: { position: "none" },
    bar: { groupWidth: '40px' },
    hAxis: {
        minValue: 0,
        maxValue: 100
    }
};

function InvestorSectorChartMobile(props) {




    const [mapData, setMapData] = useState([])
    const axiosPrivate = useAxiosPrivate();

    const removePercent = (data) => {
        return data.replace("%", "")
    }
    const addMapData = async () => {

        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/investor-sector/" + props.investor + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        let userData = cdresponse.data

        let tempMapData = [[
            "Sector",
            "Percentage"
        ]];
        userData.investorSector.map(data => (
            tempMapData.push([data.sector, parseFloat(removePercent(data.pctOfProfile))])
        ))
        console.log(tempMapData)
        setMapData(tempMapData)
    }
    useEffect(() => {
        addMapData()
    }, [])

    return (
        <div className="SectorChart">
            <Chart
                chartType="BarChart"
                width="100%"
                height="400px"
                data={mapData}
                options={options}
            />
        </div>
    )

}


export default InvestorSectorChartMobile;