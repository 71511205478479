import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate"



function InvestmentHistoryMobile(props) {
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const companyDetails = async () => {
        setpageData({})
        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/ownership-history/" + props.investorID + "/" + props.stock + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const cdresponse = await axiosPrivate.get(url);
        setpageData(cdresponse.data)
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])

    return (
        <div>
            {Object.keys(pageData).length !== 0 &&
                <>
                    <div className="AboutHeaderTop">
                        <div style={{ fontWeight: '600', fontSize: "18px" }}>
                            {pageData.investor_name}
                        </div>
                    </div>
                    <div className="AboutHeaderTop">

                        <div style={{ fontWeight: '600' }}>
                            Holding activity History for {props.stock}
                        </div>
                </div>
                <div style={{ overflowY: "auto" }}>
                    <table className="PeersTable finData">
                        <tbody>
                            <tr>
                            <td className="leftPeer" style={{ fontWeight: 600 }}>Sr.No.</td>
                            <td className="columnHeading" style={{ whiteSpace: "nowrap" }}>Filing Date</td>
                            <td className="columnHeading" style={{ whiteSpace: "nowrap" }}>Shares</td>
                            <td className="columnHeading" style={{ whiteSpace: "nowrap" }}>% of Portfolio</td>
                            <td className="columnHeading" style={{ whiteSpace: "nowrap" }}>Change</td>
                            <td className="columnHeading" style={{ whiteSpace: "nowrap" }}>Reported Price</td>
                            </tr>
                        </tbody>
                        <tbody>
                            {pageData.history.map((dataKey, index) => (
                                <tr>
                                    <td className="leftPeer">{index + 1}</td>
                                    <td className="tabledata SubHeader" style={{ whiteSpace: "nowrap" }}>{dataKey.filingDate}</td>
                                    <td className="tabledata SubHeader" style={{ whiteSpace: "nowrap" }}>{dataKey.share}</td>
                                    <td className="tabledata SubHeader" style={{ whiteSpace: "nowrap" }}>{dataKey.pctOfProfile}</td>
                                    <td className="tabledata SubHeader" style={{ whiteSpace: "nowrap" }}>{dataKey.change}</td>
                                    <td className="tabledata SubHeader" style={{ whiteSpace: "nowrap" }}>-</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </>
            }
        </div>
    );
}

export default InvestmentHistoryMobile;