import React, { useEffect} from 'react';
import useAuth from './hooks/useAuth';
import { useNavigate } from "react-router-dom";


const Logout = () => {
    let navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    
    useEffect(() => {
        if (auth.user) {
            localStorage.clear()
            setAuth({})
            navigate("/");
        }
        
    }, [])
    return (
        <div className="Logout" style={{ paddingTop: "100px" }}>

            You have been successfully logged out !!

        </div>
    );
};

export default Logout;