import React, { useState } from 'react';

function UpArrow(props) {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };


    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const getIconPathData = () => {
        return "M114.845,363.274l143.569,182.584c12.577,22.044,32.968,22.044,45.551,0l143.587-182.609c2.804 - 3.996, 5.826 - 11.119, 6.438 - 15.967c1.971 - 15.691, 3.531 - 47.038 - 15.496 - 47.038H123.89c - 19.027, 0 - 17.46, 31.365 - 15.483, 47.062C109.019, 352.147, 112.042, 359.277, 114.845, 363.274z"
    }


    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            width="20"
            height="20"
            viewBox="0 0 1024 1024"
            style={{ fill: "blue" }}>
            <path d={getIconPathData()}></path>
        </svg>
    )
}
export default UpArrow;