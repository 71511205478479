import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import './Balancesheet.css'
import FinancialData from "./FinancialData"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"



export const options = {
    legend: { position: "bottom" },
    title: "Equity, Total Liabilities and Total Assets",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};

export const optionsTwo = {
    legend: { position: "bottom" },
    title: "Gross Block, Accumulated Decreciation and Net Block",
    curveType: "function",
    titleTextStyle: { fontSize: 20, }
};



function Balancesheet(props) {
    const [pageData, setpageData] = useState({
        "data": [],
        //"horizontal_analysis": [],
        "extended_mapping": [],
       // "mapData": [],
       // "mapDataTwo": [],
        "CompanyName": "Company Name Placeholder"
    });
   // const [collapse, setcollapse] = useState(false);
   // const [modal, setmodal] = useState(false);
   // const [modalTwo, setmodalTwo] = useState(false);
    const [sort, setsort] = useState(false);
    
    const axiosPrivate = useAxiosPrivate();

    const companyDetails = async () => {
        setpageData({
            "data": [],
            //"horizontal_analysis": [],
            "extended_mapping": [],
            // "mapData": [],
            // "mapDataTwo": [],
            "CompanyName": "Company Name Placeholder"
        })
        const headers = {
            'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL,
            "accept": "application/json"
        };
        let url = ""
        let urlTwo = ""
        let balancesheet_data = ""
        let balancesheet_map = ""
        let horizontal_data = ""
        let vertical_data = ""
        let balancesheet_data_two = ""
        let balancesheet_map_two = ""
        let horizontal_data_two = ""
        let vertical_data_two = ""
        //let map_data = []
       // let map_data_two = []
        let company_name = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/balance-sheet/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        urlTwo = process.env.REACT_APP_DATA_API_URL + "/api/v1/balance-sheet-two/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url, { headers });
        const cdresponseTwo = await axiosPrivate.get(urlTwo, { headers });

        if (sort) {
            balancesheet_data = cdresponse.data['balance_sheet']
            horizontal_data = cdresponse.data['yoy_growth']
            vertical_data = cdresponse.data['as_pct_of_assets_or_liabilities']
            balancesheet_data_two = cdresponseTwo.data['balance_sheet']
            horizontal_data_two = cdresponseTwo.data['yoy_growth']
            vertical_data_two = cdresponseTwo.data['as_pct_of_assets_or_liabilities']
        } else {
            balancesheet_data = cdresponse.data['balance_sheet'].reverse()
            horizontal_data = cdresponse.data['yoy_growth'].reverse()
            vertical_data = cdresponse.data['as_pct_of_assets_or_liabilities'].reverse()
            balancesheet_data_two = cdresponseTwo.data['balance_sheet'].reverse()
            horizontal_data_two = cdresponseTwo.data['yoy_growth'].reverse()
            vertical_data_two = cdresponseTwo.data['as_pct_of_assets_or_liabilities'].reverse()
        }
        balancesheet_map = cdresponse.data['prompts']
        balancesheet_map_two = cdresponseTwo.data['prompts']
        company_name = cdresponse.data['stock_name']
        //map_data.push(["", "Equity", "Total Liabilities", "Total Assets"])
        //balancesheet_data.filter(mapItem => mapItem.date_header.data !== "TTM").map(mapdata => (
       //     map_data.push([getDisplayValue(mapdata['date_header'], 'map'), parseFloat(getDisplayValue(mapdata['Share Holder Equity'], 'm')), parseFloat(getDisplayValue(mapdata['Total Assets'], 'm')) - parseFloat(getDisplayValue(mapdata['Share Holder Equity'], 'm')), parseFloat(getDisplayValue(mapdata['Total Assets'], 'm'))])
       // ))
      //  map_data_two.push(["", "Gross Block", "Accumulated Depreciation", "Net Block"])
      //  balancesheet_data.filter(mapItem => mapItem.date_header.data !== "TTM").map(mapdata => (
      //      map_data_two.push([getDisplayValue(mapdata['date_header'], "map"), parseFloat(getDisplayValue(mapdata['Gross Block'], 'm')), parseFloat(getDisplayValue(mapdata['Accumulated Depreciation'], 'm')), parseFloat(getDisplayValue(mapdata['Fixed Assets'], 'm'))])
      //  ))
        document.title = company_name + "- Balance Sheet | Vertical & Horizontal analysis";
        setpageData({
            "data": balancesheet_data,
            "percentOfIncome": vertical_data,
            "yoyGrowth": horizontal_data,
            "dataTwo": balancesheet_data_two,
            "percentOfIncomeTwo": vertical_data_two,
            "yoyGrowthTwo": horizontal_data_two,
            "extended_mapping": balancesheet_map,
            "extended_mapping_two": balancesheet_map_two,
         //   "mapData": map_data,
         //   "mapDataTwo": map_data_two,
            "CompanyName": company_name
        })
        
    }


    useEffect(() => {
        companyDetails()
    }, [props.compSymbol, sort])
    
    return (
        <>
            <FinancialData pageData={pageData} sort={sort} setsort={setsort} source="balancesheet" horizontal={true} vertical={true} /> 
        </>
            );
            }

export default Balancesheet;