import React, { useState, useEffect } from 'react';
import WatchlistAction from './WatchlistAction'
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import HomeContents from '../Home/HomeContents'

function WatchlistOption(props) {
    const [pageData, setpageData] = useState({ watchlistList: [], companiesList: [] });
    const [variableChanged, setVariableChanged] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const toggleVariableChange = () => {
        if (variableChanged) {
            setVariableChanged(false)
        } else {
            setVariableChanged(true)
        }
    }

    const watchlistDetails = async () => {
        setpageData({ watchlistList: [], companiesList: [] })
        setLoaded(false)
        let url = "https://users.niveshkarma.com/api/v1/watch-list?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;

        const response = await axiosPrivate.get(url);
        let tempData = []
        let listData = []
        if (response.data.watch_lists != null) {
            for (let i = 0; i < response.data.watch_lists.length; i++) {
                tempData.push(response.data.watch_lists[i]["list_name"])
                for (let j = 0; j < response.data.watch_lists[i]["watch_list"].length; j++) {
                    if (!tempData.includes(response.data.watch_lists[i]["watch_list"][j])) {
                        listData.push(response.data.watch_lists[i]["watch_list"][j])
                    }

                }
            }
        }
        setLoaded(true)
        setpageData({ watchlistList: tempData, companiesList: listData, fullData: response.data})
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (auth.user) {
                watchlistDetails()()
            } 
        }, 50)
        return () => clearTimeout(timeout)
        
    }, [props.symbol, variableChanged, auth.user])
    return (
        <>
            <WatchlistAction watchlistData={pageData} compSymbol={props.symbol} variableChanged={variableChanged} toggleVariableChange={toggleVariableChange} loaded={loaded} />
        </>
    );
}
export default WatchlistOption;
