import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import Searchbox from './Searchbox';
import LoginButton from './Loginbutton';
import { useNavigate } from "react-router-dom";
import HomeContent from './Content'
import { useGoogleLogin} from '@react-oauth/google';
import axios from 'axios';
import useAuth from './hooks/useAuth';
import Option from './icon/Option'

const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL + "/google-auth/api/v1/token";
//const LOGIN_URL = "https://nk-auth.onrender.com" + "/google-auth/api/v1/token";


function HomeHeader(props) {
    const [showMobileOption, setShowMobileOption] = useState(false);
    const [showTool, setShowTool] = useState(false);
    const ref = useRef();
    const { auth, setAuth } = useAuth();
    const options = [
        { value: "/Watchlist", label: "Watchlist" },
        { value: "/Profile", label: "Profile" },
        { value: "/Logout", label: "Logout" }
    ];

    const toggleTool = () => {
        if (showTool) {
            setShowTool(false)
        } else {
            setShowTool(true)
        }
    }

    const Icon = () => {
        return (
            <svg height="20" width="20" viewBox="0 0 20 20" fill="#606f7b">
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg >
        );
    }


    let navigate = useNavigate();
    const [userLoggedIn, setUserLoggedIn] = useState(false);


    const handleCallbackResponse = async (dataResponse) => {
        try {
            const response = await axios.post(LOGIN_URL,
                '',
                {
                    headers: { 'Content-Type': 'application/json', 'x-token-type': 'Bearer', "x-token": dataResponse.access_token, 'Access-Control-Allow-Origin': process.env.REACT_APP_DATA_API_URL, },

                }
            );
            const email = response?.data?.user?.email
            const user = response?.data?.user?.first_name + " " + response?.data?.user?.last_name;
            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const roles = response?.data?.user?.premium;
            const ratios = response?.data?.user?.add_ratios;
            const historicalRatios = response?.data?.user?.historical_ratios;
            const peerColumns = response?.data?.user?.peer_ratios;
            const itemsData = { user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken, email }
            console.log(itemsData)
            localStorage.setItem('NKUserProfileInfo', JSON.stringify(itemsData))
            setAuth({ user, roles, ratios, historicalRatios, peerColumns, accessToken, refreshToken, email});
            navigate("/");
        } catch (err) {
            console.log(err)
            /*if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password'); 
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }*/
        }
    }

    const login = useGoogleLogin({
        onSuccess: codeResponse => handleCallbackResponse(codeResponse),
        googleAccountConfigs: {
            client_id: "293375405749-i8qhl8aujplmiapclijjv0ffqob8enr4.apps.googleusercontent.com"
        },
    });

    const routeChange = () => {
          let path = `./`;
          navigate(path);
    }

    const logout = () => {
        localStorage.clear()
        setAuth({})
        navigate("/");
    }


    useEffect(() => {
        if (!auth.user) {
            setUserLoggedIn(false)
        }
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowTool(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [userLoggedIn, auth.user, props.showSearch, ref]);
    return (
        <div className="HomeHeader" style={{ backgroundColor: "hsl(245, 40%, 98%)" }}>
            <a className="logoAnchor" href="/" style={{ textDecoration: "none", textAlign: "left", paddingLeft: "40px" }}>
            <div id="logo" onClick={routeChange}>
                <img id="HomeHeaderLogo" src={process.env.PUBLIC_URL + '/logo1.svg'} alt="NK Logo"></img>
                <div className="LogoText">Nivesh&nbsp;Karma</div>
                </div>
            </a>
            <div id="headerOption">
                <HomeContent />
                <div>
                    {props.optionData.length > 0 && props.showSearch &&
                        <Searchbox setShow={setShowMobileOption} optionData={props.optionData} searchHistory={props.searchHistory} setSearchHistory={props.setSearchHistory}/>
                    }
                </div> 
                
            </div>
            <div id="rightoptions" style={{ paddingRight: "50px" }}>
                
                {auth.user ?
                    <div className="userInfo" style={{ display: "flex", justifyContent: "space-between" }}>

                        <div className="userInfoName" style={{ cursor: "pointer", display: "grid", gridTemplateColumns: "90% 10%" }}>
                            <div style={{ textAlign: "center", paddingTop: "8px", color: "#606f7b" }} onClick={() => toggleTool()}>{auth.user} </div>
                            <div style={{ paddingTop: "1px", marginRight: "10px" }} onClick={() => toggleTool()}>
                                <div style={{fontSize: "30px", paddingTop: "4px" }}>
                                <svg
                                    width="20px"
                                    height="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 300 300"
                                    shapeRendering="geometricPrecision"
                                    textRendering="geometricPrecision"
                                    fill="#606f7b"
                                    {...props}
                                >
                                    <path
                                        d="M67.79 65.49l170.343-.697L150 176.963 67.79 65.49z"
                                        transform="matrix(1 0 0 .68815 -8 16.35)"
                                        stroke="#606f7b"
                                        strokeWidth={0.6}
                                    />
                                </svg>
                                </div>
                            </div>
                            {showTool && <div className="user-menu" ref={ref}>
                                <div >
                                    <a href="/Profile" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px", minWidth: "300px" }}>
                                            <div style={{ paddingTop: "10px" }}>
                                                <img  width="30" height="30" src={process.env.PUBLIC_URL + '/user.svg'} alt="User Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left", paddingTop: "10px"  }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>Profile</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px", paddingTop: "5px" }}>Personalize your Experience.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/Watchlist" style={{ textDecoration: "none" }}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px", minWidth: "300px" }}>
                                            <div style={{ paddingTop: "15px" }}>
                                                <img  width="30" height="30" src={process.env.PUBLIC_URL + '/watchlist.svg'} alt="Watchlist Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left", paddingTop: "10px"  }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>Watchlist</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px", paddingTop: "5px"  }}>Track, analyze and conquer with watchlist.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/" style={{ textDecoration: "none" }} onClick={() => logout()}>
                                        <div className="ToolOptionElement" style={{ display: "grid", gridTemplateColumns: "20% 80%", padding: "10px 20px", maxWidth: "450px", minWidth: "300px" }}>
                                            <div style={{ paddingTop: "5px" }}>
                                                <img  width="30" height="30" src={process.env.PUBLIC_URL + '/logout.svg'} alt="Logout Logo"></img>
                                            </div>
                                            <div style={{ textAlign: "left", paddingTop: "10px" }}>
                                                <div style={{ color: "black", fontWeight: "600", fontSize: "16px", paddingTop: "5px" }}>Logout</div>
                                                <div style={{ color: "#606f7b", fontSize: "14px" }}></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>}
                        </div>
                    </div>
                    :
                    <>
                        <LoginButton onClick={() => login()} setUserLogin={setUserLoggedIn }/>

                    </>
                }

                <div id="MobileOptions" onClick={() => setShowMobileOption(true)}>
                    <Option />
                </div>
            </div>
            {showMobileOption &&
                <div className="MobileOptionList">
                    <HomeContent setShow={setShowMobileOption}/>
                    <Searchbox setShow={setShowMobileOption} optionData={props.optionData}/>
                </div>
            }
        </div>
    );
}

export default HomeHeader;