import React from 'react';
import useAuth from '../../hooks/useAuth';


function PersonalDetails() {
    const { auth } = useAuth();
    return (
        <div style={{ paddingTop: "10px", paddingLeft: "10px" }} >
            <div style={{ fontWeight: "600", fontSize: "14px", textAlign: "left" }}>
                Personal Details
            </div>
            <div style={{ paddingLeft: "40px", paddingTop: "30px", fontSize: "13px" }}>
                <div style={{ display: "grid", gridTemplateColumns: "30% 70%", maxWidth:"500px" }}>
                    <div style={{ textAlign: "left" }}>Name</div>
                    <div style={{ textAlign:"left" }}>{auth.user}</div>
                </div>
            </div>
        </div>
    );
}
export default PersonalDetails;