import React, { useState, useEffect } from 'react';
import Loading from "../Company/Loading"
import useAxiosPrivate from "../hooks/useAxiosPrivate"

function SuperInvestorMobile() {

    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();

    const companyDetails = async () => {
        let url = ""
        url = "https://investors-api.niveshkarma.com/api/v1/super-investors?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data
        document.title = "Superinvestors in India | Invest alongside the best investors | Superinvestors Portfolio holdings.  Nivesh Karma - Superinvestors";
        console.log(userData)
        setpageData(userData)
    }

    const getUrl = (url) => {
        return "/Investor/" + url
    }
    const getCompanyUrl = (url) => {
        return "/Company/" + url
    }


    useEffect(() => {
        setTimeout(function () { companyDetails(); }, 50);

    }, [])




    return (
        <div className="PeersMain">

            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>
                    <div className="AboutHeaderTop" style={{ paddingLeft: "0px" }}>
                        <div style={{ textAlign: "left", fontSize: "15px", fontWeight: "600" }}>
                                Superinvestors
                        </div>
                        </div>
                        <div className="AboutHeaderTop" style={{ paddingLeft: "0px", paddingTop: "20px" }}>
                        <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                                Individuals
                        </div>
                        </div>
                        <div style={{ overflowX: "auto" }}>
                            <table className="PeersTable finData">
                                <tbody>
                                    <tr>
                                        <td className="leftPeer" style={{ fontWeight: "600" }}>S.&nbsp;No.</td>
                                        <td className="leftPeer" style={{ maxWidth: "100px", fontWeight: "600" , textAlign: "left", paddingLeft: "10px" }}>Superinvestors</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>No of Stocks</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>Investment Value (Cr)</td>
                                        <td className="columnHeading" style={{ textAlign: "left", paddingLeft: "10px" }}>Top 10 Holdings (left to right)</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.Individuals.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer">{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100px", textAlign: "left", paddingLeft: "10px" }}><a href={getUrl(dataKey.investor_id)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.name}</a></td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.numberOfStocks}</td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.investmentValue}</td>
                                            <td className="tabledata SubHeader" style={{ textAlign: "left", paddingLeft: "10px", whiteSpace: "nowrap" }}>
                                                {dataKey.topHoldings.map(data => (
                                                    <a href={getCompanyUrl(data)} target="_blank" style={{ textDecoration: "none" }}>{data} &nbsp; &nbsp;</a>
                                                ))}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="AboutHeaderTop" style={{ paddingLeft: "0px" }}>
                        <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                                DIIs
                        </div>
                        </div>
                        <div style={{ overflowX: "auto" }}>
                            <table className="PeersTable finData">
                                <tbody>
                                    <tr>
                                        <td className="leftPeer" style={{ fontWeight: 600 }}>S.&nbsp;No.</td>
                                    <td className="leftPeer" style={{ maxWidth: "100px", fontWeight: "600", textAlign: "left", paddingLeft: "10px" }}>Superinvestors</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>No of Stocks</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>Investment Value (Cr)</td>
                                        <td className="columnHeading" style={{ textAlign: "left", paddingLeft: "10px" }}>Top 10 Holdings (left to right)</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.DIIs.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer">{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100px", textAlign: "left", paddingLeft: "10px" }}><a href={getUrl(dataKey.investor_id)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.name}</a></td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.numberOfStocks}</td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.investmentValue}</td>
                                            <td className="tabledata SubHeader" style={{ textAlign: "left", paddingLeft: "10px", whiteSpace: "nowrap" }}>
                                                {dataKey.topHoldings.map(data => (
                                                    <a href={getCompanyUrl(data)} target="_blank" style={{ textDecoration: "none" }}>{data} &nbsp; &nbsp;</a>
                                                ))}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="AboutHeaderTop" style={{ paddingLeft: "0px" }}>
                        <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                                FIIs
                        </div>
                        </div>
                        <div style={{ overflowX: "auto" }}>
                            <table className="PeersTable finData">
                                <tbody>
                                    <tr>
                                        <td className="leftPeer" style={{ fontWeight: 600 }}>S.&nbsp;No.</td>
                                    <td className="leftPeer" style={{ maxWidth: "100px", fontWeight: "600", textAlign: "left", paddingLeft: "10px" }}>Superinvestors</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>No of Stocks</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>Investment Value (Cr)</td>
                                        <td className="columnHeading" style={{ textAlign: "left", paddingLeft: "10px" }}>Top 10 Holdings (left to right)</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.FIIs.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer">{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100px", textAlign: "left", paddingLeft: "10px" }}><a href={getUrl(dataKey.investor_id)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.name}</a></td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.numberOfStocks}</td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.investmentValue}</td>
                                            <td className="tabledata SubHeader" style={{ textAlign: "left", paddingLeft: "10px", whiteSpace: "nowrap" }}>
                                                {dataKey.topHoldings.map(data => (
                                                    <a href={getCompanyUrl(data)} target="_blank" style={{ textDecoration: "none" }}>{data} &nbsp; &nbsp;</a>
                                                ))}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>




                        <div className="AboutHeaderTop" style={{ paddingLeft: "0px" }}>
                        <div style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                                Mutual Funds
                        </div>
                        </div>
                        <div style={{ overflowX: "auto" }}>
                            <table className="PeersTable finData">
                                <tbody>
                                    <tr>
                                        <td className="leftPeer" style={{ fontWeight: 600 }}>S.&nbsp;No.</td>
                                    <td className="leftPeer" style={{ maxWidth: "100px", fontWeight: "600", textAlign: "left", paddingLeft: "10px" }}>Superinvestors</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>No of Stocks</td>
                                        <td className="columnHeading" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>Investment Value (Cr)</td>
                                        <td className="columnHeading" style={{ textAlign: "left", paddingLeft: "10px" }}>Top 10 Holdings (left to right)</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {pageData.MutualFunds.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer">{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100px", textAlign: "left", paddingLeft: "10px" }}><a href={getUrl(dataKey.investor_id)} target="_blank" style={{ textDecoration: "none" }}>{dataKey.name}</a></td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.numberOfStocks}</td>
                                            <td className="tabledata SubHeader" style={{ maxWidth: "100%", whiteSpace: "nowrap", textAlign: "center" }}>{dataKey.investmentValue}</td>
                                            <td className="tabledata SubHeader" style={{ textAlign: "left", paddingLeft: "10px", whiteSpace: "nowrap" }}>
                                                {dataKey.topHoldings.map(data => (
                                                    <a href={getCompanyUrl(data)} target="_blank" style={{ textDecoration: "none" }}>{data} &nbsp; &nbsp;</a>
                                                ))}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>

        </div>
    );
}

export default SuperInvestorMobile;