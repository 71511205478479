import React, { useState } from 'react';
import HelpModal from './Company/Modal/CommonModal'


const PdfThumbnailMobile = (props) => {
	const [show, setShow] = useState(false);

	return (
		<div style={{ paddingTop: "40px" }}>
			<img height="400px" width="300px" onClick={() => setShow(true)} src={"data:image/png;base64, " + props.imageData} alt="Investing Book Image" />
			<HelpModal title={props.DocumentName} onClose={() => setShow(false)} show={show}>
				<embed src={"https://api.niveshkarma.com/" + props.url} width="100%" height="100%" />
			</HelpModal>
		</div>
	);
};

export default PdfThumbnailMobile; 