import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { Popup } from 'semantic-ui-react'
//import './Peers.css'
import Loading from "../../Company/Loading"
import { downloadExcel } from "react-export-table-to-excel";

function PredefinedScreenerMobile(props) {
    const { id } = useParams();
    const [pageData, setpageData] = useState({});
    const axiosPrivate = useAxiosPrivate();
    const getUrl = (url) => {
        return "/Company/" + url
    }
    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }



    const getClassName = (data) => {
        if (data === "Sector") {
            return "Sector columnHeadingScreenerLeft"
        } else if (data === "Industry") {
            return "Industry columnHeadingScreenerLeft"
        } else {
            return "columnHeadingScreener"
        }
    }

    const getSubClassName = (data) => {
        if (data === "Sector") {
            return "Sector SubHeader"
        } else if (data === "Industry") {
            return "Industry SubHeader"
        } else {
            return "tabledataPeer SubHeader"
        }
    }

    const companyDetails = async () => {
        let url = ""
        console.log(props.screener)
        url = "https://api.niveshkarma.com/api/v1/default-screener/" + id + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data
        document.title = "NK - Screeners | Explore stock screener | Best Stock Screener in India | Popular Investing themes";
        console.log(userData)
        setpageData({
            "screener": id,
            "data": userData
        })
    }

    const getCompanyCountMessage = () => {
        try {
            let count = pageData.data.stocks.length
            if (count > 0) {
                return count.toString() + " companies found."
            } else {
                return "No companies found. Please adjust your filters"
            }
        } catch (e) {
            return "No companies found. Please adjust your filters"
        }
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.data.keys.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData.data.stocks.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.data.keys.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: id,
            sheet: id,
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }

    useEffect(() => {
        setTimeout(function () { companyDetails(); }, 50);

    }, [props.screener])




    return (
        <div className="PeersMain">
            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ paddingTop: "10px", fontWeight: "600", display: "flex", justifyContent: "start" }}>
                            <div style={{ fontSize: "14px", fontWeight: "600", textAlign: "left", paddingBottom: "10px" }}>{pageData.data.screener_name + ":"}</div>
                            <div style={{ fontSize: "14px", paddingLeft: "10px", fontWeight: "400", paddingTop: "1px" }}>{getCompanyCountMessage()}</div>
                            </div>

                        </div>
                        <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                            <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                <thead>
                                    <tr>
                                        <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ fontWeight: "600", fontSize: "14px" }}>S.&nbsp;No.</th>
                                        <th className="columnHeadingScreenerLeft leftPeer intersection" style={{ fontWeight: "600", fontSize: "14px" }}>Company Name</th>

                                        {pageData?.data?.keys.map(item => (
                                            <th className="columnHeadingScreener" className={getClassName(getDisplayValueAndPrompt(item)[0])}>
                                                <Popup
                                                    className="popupChart"
                                                    trigger={<div style={{ cursor: "pointer" }} ><div>{getDisplayValueAndPrompt(item)[0]}</div></div>}
                                                    content={<div className="borderedContent" style={{ padding: "5px", border: "1px solid grey", borderRadius: "5px" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                    style={{ zIndex: "999" }}
                                                />
                                            </th>
                                        ))

                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    {pageData?.data?.stocks.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100px"}}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                            {pageData?.data?.keys.map(item => (
                                                <td className={getSubClassName(getDisplayValueAndPrompt(item)[0])}>{dataKey[item]}</td>
                                            ))
                                            }



                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>
        </div>
    );
}


export default PredefinedScreenerMobile;