import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Popup } from 'semantic-ui-react'
//import './Peers.css'
import Loading from "../Company/Loading"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useAuth from '../hooks/useAuth';
import ManageRatiosMobile from './ManageRatios/ManageRatiosMobile'
import HelpModal from '../Company/HelpModal'
import { downloadExcel } from "react-export-table-to-excel";


function IndustryMobile(props) {
    const { industry } = useParams();
    const [pageData, setpageData] = useState({});

    const [isProfileUpdated, setIsProfileUpdated] = useState(false);
    const [show, setShow] = useState(false);
    const { auth, setAuth } = useAuth();
    const [viewLoading, setViewLoading] = useState(false)
    const [restrictCount, setRestrictCount] = useState(5);
    const [disableButton, setDisableButton] = useState(true);

    const cancelAction = () => {
        if (auth.user) {
            const itemsData = JSON.parse(localStorage.getItem('NKUserProfileInfo'))
            setAuth(prevAuth => ({ ...prevAuth, peerColumns: itemsData.peerColumns }))

        }
        setShow(false)
    }
    const toggleProfile = () => {
        if (isProfileUpdated) {
            setIsProfileUpdated(false)
        } else {
            setIsProfileUpdated(true)
        }
    }
    const showManageRatios = () => {
        setShow(true)
    }

    const getUrl = (url) => {
        return "/Company/" + url
    }
    const axiosPrivate = useAxiosPrivate();
    const getDisplayValueAndPrompt = (itemCode) => {
        let returnData = ["", ""]
        Object.keys(props.ratioData).map(item => (
            Object.keys(props.ratioData[item]).map(childItem => (
                props.ratioData[item][childItem].map(data => {
                    if (data.code === itemCode) {
                        console.log(data.prompt)
                        returnData = [data.prompt, data.hint]
                    }
                })
            ))

        ))
        return returnData
    }
    const companyDetails = async () => {
        let url = ""
        url = process.env.REACT_APP_DATA_API_URL + "/api/v1/all-peers/?filter_by=gind&filter_value=" + encodeURIComponent(industry) + "&access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);

        let userData = cdresponse.data
        document.title = industry + "- Companies";
        if (auth.roles === true) {
            setRestrictCount(30)
        } else {
            setRestrictCount(5)
        }
        setpageData({
            "industry": industry,
            "data": userData
        })
    }

    function handleDownloadExcel() {

        let header = ["S. No.", "Company Name"]
        let body = []
        pageData.data.keys.forEach(item => {
            header.push(getDisplayValueAndPrompt(item)[1])
        })

        pageData?.data.peers.forEach((item, index) => {
            let tempData = [index + 1]
            tempData.push(item.stock_name)
            pageData.data.keys.forEach(data => {
                tempData.push(item[data])
            })
            body.push(tempData)
        })


        downloadExcel({
            fileName: "Industry",
            sheet: "Industry",
            tablePayload: {
                header,
                // accept two different data structures
                body: body,
            },
        });
    }


    useEffect(() => {
        setTimeout(function () { companyDetails(); }, 50);
    }, [industry, isProfileUpdated, auth.user])




    return (
        <div className="PeersMain">
            <section className="Peers">
                {Object.keys(pageData).length !== 0 &&
                    <>

                        <section className="optionsFinData">
                            <div className="leftAlign growthCompanyName" style={{ paddingLeft: "0px" }}>
                                {industry} Companies
                        </div>
                            <div className="rightAlign">
                                <div className="optionButtonPrimary">
                                    <div className="optionButtonMobile" onClick={() => showManageRatios()}> Manage Columns</div>
                                    <HelpModal title="Manage Columns" onClose={() => cancelAction()} show={show} toggleProfile={toggleProfile} setShow={setShow} viewLoading={viewLoading} setViewLoading={setViewLoading} source="peerColumns" disableButton={disableButton}>
                                    <ManageRatiosMobile ratioData={props.ratioData} source="peerColumns" viewLoading={viewLoading} restrictCount={restrictCount} setDisableButton={setDisableButton} />
                                    </HelpModal>
                                </div>

                            </div>
                        </section>


                        <div className="PeerSectionTable" style={{ maxHeight: "550px", overflowY: "auto" }}>
                            <table className="PeersTable finData" style={{ overflowX: "auto" }}>
                                <thead>
                                    <tr>
                                        <th className="leftPeer intersection" style={{ fontWeight: "600" }}>S.&nbsp;No.</th>
                                        <th className="leftPeer intersection" style={{ fontWeight: "600" }}>Company Name</th>

                                        {pageData?.data?.keys.map(item => (
                                            <th className="columnHeadingScreener">
                                                <Popup
                                                    className="popupChart"
                                                    trigger={<div style={{ cursor: "pointer" }}>{getDisplayValueAndPrompt(item)[0]}</div>}
                                                    content={<div className="borderedContent" style={{ padding: "5px", border: "2px solid black" }}>{getDisplayValueAndPrompt(item)[1]}</div>}
                                                    style={{ zIndex: "999" }}
                                                />
                                            </th>
                                        ))

                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    {pageData?.data?.peers.map((dataKey, index) => (
                                        <tr>
                                            <td className="leftPeer" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="leftPeer" style={{ maxWidth: "100px"}}><a href={getUrl(dataKey.stock_symbol)} target="_blank" style={{ textDecoration: "None" }}>{dataKey.stock_name}</a></td>


                                            {pageData?.data?.keys.map(item => (
                                                <td className="tabledataPeer SubHeader">{dataKey[item]}</td>
                                            ))
                                            }



                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }{
                    Object.keys(pageData).length === 0 &&
                    <Loading />
                }
            </section>
        </div>
    );
}

export default IndustryMobile;