import React, { useEffect } from 'react'; 
import './faq.css'


function Faq(props) {
    window.scrollTo(0, 0) 
    document.title = "Nivesh Karma - FAQ";

    useEffect(() => {
        if (!props.showSearch) {
            props.setShowSearch(true)
        }
    }, [])
    return (
        <div className="faq commonContainer" >
            <div className="AboutUs-container" style={{ backgroundColor: "white" }}>
            <h2>FAQ</h2>
            <p><b>1.	How is data reported for various financial statements? </b><br/>
                    Indian Accounting Standards (Ind AS) are based on and substantially converged with International Financial Reporting Standards (IFRS). 
                    We are reporting data for various financial statements as per International Financial Reporting Standards (IFRS).
            </p>
            <p><b>2.	Who don't I see standalone and consolidated statements?  </b><br />
                A standalone statement represents a company's financial performance as a single entity, while a consolidated statement reports a company's financial performance including subsidiary companies, associate companies, and joint ventures.
                When a company has over 50% stake in another company, it is its subsidiary. If the stake is 100%, it is known as a wholly owned subsidiary.
                When a company has between 20% and 50% stake in another company, it is its associate company. 
                We display a consolidated financial statement which shows the actual health of the company. Further, minority interests in consolidated subsidiaries are identified and adjusted against the profit and loss statement and balance sheet to arrive at the net income attributable to the owners of the parent and the equity of the parent's shareholders. 
                This gives an accurate view of company's net profit and equity. All listed Companies on BSE & NSE report net profit & equity attributed to the Owners of the parent in annual reports under consolidated financial statements. 
            </p>
            <p>
                <b>3.	Why are EBIT and EBITDA being not available for some companies? </b><br />
                Banking and insurance companies derive its revenue mainly from interests on loans. Therefore, EBIT and EBITDA are not an accurate measure of a bank's financial position and would be useless as any sort of reporting.
            </p>
            <p>
                <b> 4.	What are companies supported by Nivesh Karma? </b><br />
                As of now we support all the 5,000+ companies listed on Bombay Stock Exchange (BSE) and National Stock Exchange (NSE).  
            </p>
            <p>
                <b>5.	How much time does it take to get company quarterly results updated?</b><br />
                The update time can vary anywhere from 1 day to 1 month depending on the company market capitalization and importance.
            </p>
            <p>
                <b>6.	How much time does it take to get stock prices updated?</b><br />
                We update daily stock prices and dependent ratios or multiples by the end of day (EOD) basis. 
            </p>
            <p>
                <b>7.	How do I leverage valuation tools? </b><br />
                Our valuation tool is developed to estimate intrinsic value for businesses with predictable earnings and cash flows. You must adjust inputs like cash flow, expected growth rate, forward PE etc. to estimate stock value. 
                We leverage various valuation models to determine business intrinsic value depending on business predictability and industry. No single valuation model fits every situation, but by knowing the characteristics of the company, you can select a valuation model that best suits the situation. 
                You can refer to our valuation blog for more details. 
            </p>
                <p>
                    <b>8.  Why don't I see few listed companies in "Search for a company" dropdown? </b><br />
                    There are a few reasons you may not see a few listed companies in the "Search for a company" dropdown. These reasons could include: the stock may have been recently listed through an IPO, a recent merger of two companies, the company may not have published their financial results, or the company may be too small for us to track due to the riskiness of investment.
                </p>
            </div>
        </div>
    );
}
export default Faq;