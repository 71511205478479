import React, { useState } from 'react';
import './FinancialMobile.css'
import CashflowMobile from './Financials/CashflowMobile'
import BalanceSheetMobile from './Financials/BalanceSheetMobile'
import ProfitLossMobile from './Financials/ProfitLossMobile'
import QuarterlyResultMobile from './Financials/QuarterlyResultMobile'




function FinancialMobile(props) {
    const [optionSelected, setValue] = useState('quaterlyResults');
    return (
        <div className="Financials">
            <section id="finDetails">
                <div className="FinancialNavMobile">
                    <div className="FinancialMenuMobile" id="quaterlyResults" onClick={() => {
                        setValue('quaterlyResults')
                    }
                    } style={optionSelected === 'quaterlyResults' ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }} >Quaterly Results</div>
                    <div className="FinancialMenuMobile" id="profitLoss" onClick={() => {
                        setValue('profitLoss')
                    }
                    } style={optionSelected === 'profitLoss' ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}  >Profit & Loss</div>
                    <div className="FinancialMenuMobile" id="balancesheet" onClick={() => {
                        setValue('balancesheet')
                    }
                    } style={optionSelected === 'balancesheet' ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}  >Balance Sheet</div>
                    <div className="FinancialMenuMobile" id="cashflow" onClick={() => {
                        setValue('cashflow')
                    }
                    } style={optionSelected === 'cashflow' ? { borderBottom: "2px solid #36454f", color: '#3e424b' } : { color: '#3e424b' }}  >Cash Flows</div>
                </div>
                 <div id="finNum">
                    <div className="finance">
                        {optionSelected == 'quaterlyResults' &&
                            <QuarterlyResultMobile compSymbol={props.compSymbol} compName={props.compName} />

                        }
                        {optionSelected == 'profitLoss' &&
                            <ProfitLossMobile compSymbol={props.compSymbol} compName={props.compName} />
                        }
                        {optionSelected == 'balancesheet' &&
                            <BalanceSheetMobile compSymbol={props.compSymbol} compName={props.compName} />
                        }
                        {optionSelected == 'cashflow' &&
                            <CashflowMobile compSymbol={props.compSymbol} compName={props.compName} />
                        }
                    </div>
                     
                 </div>
            </section>
        </div>
    );
}

export default FinancialMobile;