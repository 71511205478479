import React, { useState } from 'react';

function DownArrow(props) {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };


    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const getIconPathData = () => {
        return "M123.89,262.141h314.604c19.027,0,17.467-31.347,15.496-47.039c-0.605-4.841-3.636-11.971-6.438-15.967L303.965,16.533c - 12.577 - 22.044 - 32.968 - 22.044 - 45.551, 0L114.845, 199.111c - 2.803, 3.996 - 5.832, 11.126 - 6.438, 15.967C106.43, 230.776, 104.863, 262.141, 123.89, 262.141z"
    }



    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            width={props.big ? "18" : "20px"}
            height={props.big ? "18" : "20px"}
            viewBox="0 0 1024 1024"
            style={{ fill: "blue" }}>
            <path d={getIconPathData()}></path>
        </svg>
    )
}
export default DownArrow;