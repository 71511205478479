import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import Chart from "react-apexcharts";
import DividendSummary from './DividendSummary'
import Loading from "./Loading"


export const options = {
    legend: { position: "none" },
    colors: ["#b8d1f3"]
};

function addRupeeSign(amount) {
    if (isNaN(amount)) {
        return amount
    } else {
        return "₹" + amount
    }
}

function Dividend(props) {
    const [pageData, setpageData] = useState({
        "dividendDetails": [],
        "mapData": {},
        "companyName": ""
    });
    const axiosPrivate = useAxiosPrivate();
    function getYear(data, indicator) {
        if (indicator === true) {
            return "TTM"
        }
        const myArray = data.split("-");
        return myArray[0]
    }

    function getAmount(data) {
        let finalData = parseFloat(data)
        return finalData
    }
    const companyDetails = async () => {
        setpageData({
            "dividendDetails": [],
            "mapData": {},
            "companyName": ""
        })
        const url = process.env.REACT_APP_DATA_API_URL + "/api/v1/dividend/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const urlTwo = process.env.REACT_APP_DATA_API_URL + "/api/v1/yearly-dividend/" + props.compSymbol + "?access_token=" + process.env.REACT_APP_DATA_API_SECRET_KEY;
        const cdresponse = await axiosPrivate.get(url);
        const cdresponseTwo = await axiosPrivate.get(urlTwo);
        var compName = cdresponse.data['stock_name']
        document.title = compName + "– Corporate Actions | Announcements | Dividend Yield & Payout ";
        const dataArr = cdresponseTwo.data['dividend_yearly']
        let dataArrTwo = []
        console.log(cdresponse.data['dividend'])
        if (cdresponse.data['dividend'].length !==0) {
            dataArrTwo = cdresponse.data['dividend']
        } else {
            dataArrTwo = [{ "date_header": "", "amount": "No Dividend History" }]
        }
        let dividentArr = []
        let label = []
        if (dataArr) {
            dataArr.reverse().map(item => {
                label.push(getYear(item["period"], item["ttm_indicator"]))
                dividentArr.push(getAmount(item["dividend_amount"]))
            })

        } else {
            dividentArr.push(0)
        }

        setpageData({
            "dividendDetails": dataArrTwo,
            "mapData": {
                options: {
                    chart: { id: "dividend" },
                    colors: ['#0070f3', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                    dataLabels: { enabled: false },
                    xaxis: { categories: label },
                    yaxis: [{ forceNiceScale: true }]

                },
                series: [{ name: "Dividend", data: dividentArr }]
            },
            "companyName": compName
        })
    }
    useEffect(() => {
        companyDetails()
    }, [props.compSymbol])
    return (
        <>
            {pageData.dividendDetails.length > 0 &&
                <div className="Dividend">
                    <section className="optionsFinData">
                        <div className="leftAlign">
                            {pageData.companyName}
                        </div>
                    </section>
                    <DividendSummary compSymbol={props.compSymbol} />
                <div className="DividendRow">
                    <div className="CAOneQuarter" style={{ backgroundColor: "white" }}>
                            {pageData.dividendDetails.length > 0 &&
                                <>
                                    <div className="HeighlightHeader leftAligned">
                                        DividendHistory
                        </div>
                                    <table className="finData">
                                        <tbody className="finDataBody">
                                            <tr><td className="SubHeader last leftAligned"><b>Date</b></td><td className="SubHeader last rightAligned"><b>Dividend Paid</b></td></tr>
                                            {pageData.dividendDetails &&
                                                pageData.dividendDetails.map(item => (
                                                    <tr>
                                                        <td className="SubHeader last leftAligned">
                                                            {item.date}
                                                        </td>
                                                        <td className="SubHeader last rightAligned">
                                                            <text> {addRupeeSign(item.amount)}</text>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </>
                            }{pageData.dividendDetails.length === 0 &&
                                <Loading />
                            }
                        </div>

                    <div className="CAThreeQuarter" style={{ backgroundColor: "white" }}>
                            <div className="HeighlightHeader leftAligned">Dividend Paid</div>

                        <div className="CAChartContainer">
                            {Object.keys(pageData.mapData).length > 0 &&
                                <Chart
                                options={pageData.mapData.options}
                                series={pageData.mapData.series}
                                type="bar"
                                height="400"
                                />
                                }{
                                    pageData.mapData.length === 0 &&
                                    <Loading />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }{
                pageData.dividendDetails.length === 0 && 
                <Loading />
            }
        </>
        
        );



}
export default Dividend;